// import { reactHtmlEntities } from "../utils/helpers";

function RichTextViewer({ content }) {
  // return <p>{reactHtmlEntities(content)}</p>
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
}

export default RichTextViewer;

// function RichTextViewer({content})
// {
// return <p>{reactHtmlEntities(content)}</p>
// }

// export default RichTextViewer;

// const RichTextViewer = ({ rawContent }) => {
//   const contentState = convertFromRaw(JSON.parse(rawContent));

//   const editorState = EditorState.createWithContent(contentState);

//   return <DraftEditor toolbarHidden editorState={editorState} readOnly />;
// };

// export default RichTextViewer;

// import React from 'react';

// function parseHTML(htmlString) {
//   const parser = new DOMParser();
//   const parsedDocument = parser.parseFromString(htmlString, 'text/html');
//   return parsedDocument.body;
// }

// function renderNode(node) {
//   if (node.nodeType === Node.TEXT_NODE) {
//     return node.textContent;
//   } else if (node.nodeType === Node.ELEMENT_NODE) {
//     const tagName = node.tagName.toLowerCase();
//     const fontWeight = node.style.fontWeight || window.getComputedStyle(node).fontWeight;

//     // Handle inline styles - bold
//     if (tagName === 'strong' || fontWeight === 'bolder' || fontWeight >= 700) {
//       return <strong>{Array.from(node.childNodes).map(renderNode)}</strong>;
//     }

//     // Handle inline styles - italic
//     if (tagName === 'i' || node.style.fontStyle === 'italic') {
//       return <em>{Array.from(node.childNodes).map(renderNode)}</em>;
//     }

//     const children = Array.from(node.childNodes).map(renderNode);
//     return React.createElement(tagName, {}, children);
//   }
//   return null;
// }

// function RichTextViewer({ content }) {
//   const parsedContent = parseHTML(content);
//   const renderedContent = renderNode(parsedContent);

//   return <div>{renderedContent}</div>;
// }

// export default RichTextViewer;


