import { v4 as uuidv4 } from "uuid";
import api from "../api";
import { useEffect } from "react";
import { osName, osVersion } from "react-device-detect";

const DeviceInfo = () => {
  const getFingerprint = () => {
    let device = JSON.parse(localStorage.getItem("device"));

    if (!device?.fingerprint) {
      device = {
        fingerprint: uuidv4(),
        syncedAt: new Date().getTime(),
      };

      localStorage.setItem("device", JSON.stringify(device));
    }

    return device.fingerprint;
  };

  let data = {
    type: "browser",
    fingerprint: getFingerprint(),
    name: getBrowserName(),
    os_type: osName ?? navigator.platform,
    os_version: getOSVersion(),
    app_version: "",
    language: navigator.language,
    locale: navigator.languages
      ? navigator.languages[0]
      : navigator.language || "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    browser_agent: navigator.userAgent,
    screen_resolution: `${window.screen.width}x${window.screen.height}`,
    user_agent: navigator.userAgent,
  };

  function getOSVersion() {
    return osVersion ?? "";
    // const userAgent = navigator.userAgent;
    // if (userAgent.includes("Windows")) {
    //   return userAgent.match(/Windows NT (\d+\.\d+)/)[1];
    // } else if (userAgent.includes("Mac OS X")) {
    //   return userAgent.match(/Mac OS X (\d+\_\d+)/)[1].replace("_", ".");
    // } else if (userAgent.includes("Linux")) {
    //   return "Linux";
    // } else {
    //   return "Unknown";
    // }
  }

  function getBrowserName() {
    const test = (regexp) => {
      return regexp.test(navigator.userAgent);
    };

    if (test(/opr\//i) || !!window.opr) {
      return "Opera";
    } else if (test(/edg/i)) {
      return "Microsoft Edge";
    } else if (test(/chrome|chromium|crios/i)) {
      return "Google Chrome";
    } else if (test(/firefox|fxios/i)) {
      return "Mozilla Firefox";
    } else if (test(/safari/i)) {
      return "Apple Safari";
    } else if (test(/trident/i)) {
      return "Microsoft Internet Explorer";
    } else if (test(/ucbrowser/i)) {
      return "UC Browser";
    } else if (test(/samsungbrowser/i)) {
      return "Samsung Browser";
    } else {
      return "Unknown browser";
    }
  }

  const syncDevice = () => {
    api
      .post("devices", {
        ...data,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => syncDevice(), []);
};

export default DeviceInfo;
