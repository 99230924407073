import { Link } from "react-router-dom";

const InvitationDeclinedMessage = () => {
  return (
    <div>
      <p>You have declined the invitation</p>
      
      <Link to="/" className="btn btn-primary rounded-1">
        Go to Home
      </Link>
    </div>
  );
};

export default InvitationDeclinedMessage;
