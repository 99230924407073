import "../../assets/css/side_bar.css";
import {
  faBank,
  faCog,
  faPenToSquare,
  faTachometerAlt,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import SidebarNotificationLink from "./partials/SidebarNotificationLink";

function AdminSidebar() {
  return (
    <>
      <div className="sidebar__title">
        <p>User</p>
      </div>

      <div className="sidebar__separator"></div>

      <NavLink to="/" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faPenToSquare}
          style={{ fontSize: 20 }}
        ></FontAwesomeIcon>
        <span>Program Library</span>
      </NavLink>

      <NavLink to="/my-programs" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faPenToSquare}
        ></FontAwesomeIcon>
        <span>My Programs</span>
      </NavLink>

      <SidebarNotificationLink />

      <div className="sidebar__title">
        <p>Administrator</p>
      </div>

      <div className="sidebar__separator"></div>

      <NavLink to="/dashboard" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faTachometerAlt}
        ></FontAwesomeIcon>
        <span>Dashboard</span>
      </NavLink>

      <NavLink to="/programs" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faPenToSquare}
          style={{ fontSize: 20 }}
        ></FontAwesomeIcon>
        <span>Programs</span>
      </NavLink>

      {/* <div className="sidebar__title">
        <p>Organisation</p>
      </div> */}

      {/* <div className="sidebar__separator"></div> */}

      <NavLink to="/organizations-list" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faBank}
        ></FontAwesomeIcon>
        <span>Organizations</span>
      </NavLink>

      {/* <div className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faBuilding}
        ></FontAwesomeIcon>
        <span>Our Programs</span>
      </div> */}

      <NavLink to="/users" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faUserFriends}
        ></FontAwesomeIcon>
        <span>Users</span>
      </NavLink>

      <NavLink to="/settings/app-settings" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faCog}
          style={{ fontSize: 20 }}
        ></FontAwesomeIcon>
        <span>Settings</span>
      </NavLink>
    </>
  );
}
export default AdminSidebar;
