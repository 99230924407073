import LoginForm from "../../Components/auth/LoginForm";

export default function Login() {
  return (
    <section className="mt-5">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <LoginForm></LoginForm>
        </div>
      </div>
    </section>
  );
}
