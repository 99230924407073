import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import api from "../../api";
import Errors from "../../utils/Errors";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { useSearchParams } from "react-router-dom";

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const user = new URLSearchParams(window.location.search);
  let email = user.get("email");
  let otp = user.get("otp");

  const [state, setState] = useState({
    email: searchParams.get("email"),
    otp: searchParams.get("otp"),
  });

  useEffect(() => {
    if (!searchParams.has("email")) {
      navigate("/404");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setProcessing(true);
    api
      .post("verify-email", {
        email: state.email,
        otp: state.otp,
      })
      .then((response) => {
        //console.log(response.data.status);
        //check if account is already verifed
        if (response.data.status === 403) {
          toast.error(response.data.message);
          navigate("/login");
        } else {
          //verify user
          toast.success("Account Verfied !");
          // Log the user in
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          window.location.href = "/";
        }

      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          toast.error("Error while verification");
          return;
        }
      })
      .finally(() => setProcessing(false));
  };

  const otpInputStyle = {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "0.25rem",
    border: "1px solid #ced4da",
  };

  return (
    <Container className="p-4">
      <Row md={4}>
        <Col
          md={{ span: 6, offset: 3 }}
          className="border bg-white p-4 rounded"
        >
          <div>
            <div className="text-center">
              <h1>CONFIRM EMAIL</h1>
              <p className="text-muted">
                Enter the verification code which you have received in your
                email
              </p>
            </div>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Enter OTP</Form.Label>
                <OTPInput
                  value={state.otp}
                  onChange={(value) => setState({ ...state, otp: value })}
                  numInputs={6}
                  renderSeparator={<span className="mx-2">-</span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={otpInputStyle}
                  inputType="text"
                />
                {errors.has("otp") && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.first("otp")}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="d-grid mb-3">
                <Button
                  type="submit"
                  variant="primary btn-block"
                  size="lg"
                  className="d-inline-flex justify-content-center align-items-center gap-2"
                  //disabled={processing}
                  onClick={handleSubmit}
                >
                  <span>Submit</span>
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
