import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import EntryFormModal from "../wizard/EntryFormModal";
import { useCallback, useEffect, useState } from "react";
import api from "../../../api";
import { toast } from "react-toastify";
import {
  ACCESS_TYPE,
  ANSWERING_OPTION,
  PROMPT_TYPE,
} from "../../../const/Entry";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LoadingBlock from "../../../Components/LoadingBlock";
import TabHeading from "./TabHeading";
import RichTextViewer from "../../../Components/RichTextViewer";

function EntriesTab() {
  const { programId } = useParams();
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [activeIndex, setActiveIndex] = useState(false);
  const [showAddEntryModal, setShowAddEntryModal] = useState(false);
  const ReactSwal = withReactContent(Swal);

  const newEntry = {
    program_id: programId,
    title: "",
    answering_options: ANSWERING_OPTION.TEXT_AND_VIDEO,
    intro_video_id: "",
    intro_video_url: "",
    explanation_text: "",
    resource_id: "",
    response_privacy: false,
    notify_instructor: false,
    prompt_type: PROMPT_TYPE.NONE,
    prompt_id: "",
    access_type: ACCESS_TYPE.IMMEDIATE,
    access_time: { value: "", unit: "" },
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    api
      .get(`program-wizard/get-edit-entry-data/${programId}`)
      .then((res) => {
        res.data && setEntries(res.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  }, [programId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddEntryModalClose = () => {
    setShowAddEntryModal(false);
  };

  const handleEditEntryModalClose = () => {
    setActiveIndex(false);
  };

  const deleteEntry = async (id) => {
    const result = await ReactSwal.fire({
      title: <p className="text-start">Confirm delete</p>,
      html: `<div class="text-start">
              <div>Are you sure you want to delete this prompt?</div>
            </div>`,
      showCancelButton: true,
      confirmButtonText: "Delete",
    });

    if (!result.isConfirmed) {
      return;
    }

    api
      .delete(`program-wizard/delete-entry/${id}`)
      .then((res) => {
        toast.success("Entry deleted");
        fetchData();
      })
      .catch((error) => toast.error("Something went wrong"));
  };

  return (
    <main className="edit-entry-page pt-3" style={{ maxWidth: "1000px" }}>
      <section className="d-flex justify-content-between align-items-center mb-3">
        <TabHeading
          title="Entries"
          subTitle="Each of the items and excercises within this program."
        ></TabHeading>
        <div>
          <div>
            <button
              type="button"
              className="btn btn-outline-white rounded-1"
              onClick={() => setShowAddEntryModal(true)}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-green fa-lg me-2"
              />
              <span>Add New</span>
            </button>
          </div>
        </div>
      </section>

      {loading && (
        <div className="py-5 text-center">
          <LoadingBlock />
        </div>
      )}

      {!loading && (
        <section>
          {entries?.map((entry, index) => (
            <article className="panel__white mb-3" key={entry.id}>
              <h5 className="mb-2">{entry.title}</h5>
              <div>
                <div className="my-2">
                  <RichTextViewer content={entry.explanation_text} />
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label className="text-muted">Answering Options</label>
                    <div className="text-break">
                      {entry.answering_options ===
                        ANSWERING_OPTION.TEXT_AND_VIDEO && "Text/Video"}
                      {entry.answering_options === ANSWERING_OPTION.TEXT_ONLY &&
                        "Text"}
                      {entry.answering_options ===
                        ANSWERING_OPTION.VIDEO_ONLY && "Video"}
                      {entry.answering_options ===
                        ANSWERING_OPTION.NO_RESPONSE_REQUIRED &&
                        "No Response Required"}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="text-muted">Available</label>
                    <div className="text-break">
                      {entry.access_type === ACCESS_TYPE.IMMEDIATE &&
                        "Immediately"}
                      {entry.access_type === ACCESS_TYPE.TIMED && (
                        <div>
                          After {entry?.access_time?.value}{" "}
                          {entry?.access_time?.unit}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="text-muted">Prompt</label>
                    <div className="text-break">
                      {entry.prompt_type === PROMPT_TYPE.NONE && "No Prompts"}
                      {entry.prompt_type === PROMPT_TYPE.ANY && "Any"}
                      {entry.prompt_type === PROMPT_TYPE.SELECTED && "Selected"}
                    </div>
                  </div>
                </div>
              </div>

              <footer className="d-flex gap-3 mt-3">
                <button
                  type="button"
                  className="btn btn-outline-white rounded-1 px-4"
                  onClick={() => setActiveIndex(index)}
                >
                  <FontAwesomeIcon icon={faEdit} className="text-green" />
                  <span className="ms-2">Edit</span>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-white rounded-1 px-4"
                  onClick={() => deleteEntry(entry.id)}
                >
                  <FontAwesomeIcon icon={faTrashCan} className="text-red" />
                  <span className="ms-2">Delete</span>
                </button>
              </footer>
            </article>
          ))}

          {entries?.map((entry, index) => (
            <EntryFormModal
              show={activeIndex === index}
              onHide={handleEditEntryModalClose}
              entry={entry}
              refetchData={fetchData}
              key={entry.id}
            ></EntryFormModal>
          ))}

          <EntryFormModal
            show={showAddEntryModal}
            onHide={handleAddEntryModalClose}
            entry={newEntry}
            refetchData={fetchData}
          ></EntryFormModal>
        </section>
      )}

      {!loading && entries.length === 0 && (
        <div className="mt-5 d-flex justify-content-center align-items-center flex-column gap-3">
          <div>There are no entries added yet.</div>
          <div>
            <button
              type="button"
              className="btn btn-outline-white rounded-1"
              onClick={() => setShowAddEntryModal(true)}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-green fa-lg me-2"
              />
              <span>Add New</span>
            </button>
          </div>
        </div>
      )}
    </main>
  );
}

export default EntriesTab;
