import { Link, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons";
import RichTextViewer from "../../../../Components/RichTextViewer";
import VideoPlayer from "../../../../Components/VideoPlayer";

function EntryDetail({ entry, showTitle = true }) {
  const [searchParams] = useSearchParams();
  let autoplay = searchParams.has("autoplay")
    ? searchParams.get("autoplay") === "true"
    : true;
  autoplay = autoplay && !entry.isCompleted;

  return (
    <div className="position-name">
      <div className="exercise-grid-3">
        {showTitle && <h4>{entry.title}</h4>}
      </div>

      {entry.intro_video_url && (
        <section className="my-2 vid">
          <VideoPlayer
            url={entry.intro_video_url}
            name="entry_intro_video"
            autoplay={autoplay}
          />
        </section>
      )}

      <RichTextViewer content={entry.explanation_text} />

      {entry.resources.length > 0 && (
        <section className="mb-3 resource-container">
          <h5>Resources:</h5>
          {entry.resources.map((reso, i) => (
            <div className="box-all" key={i}>
              <div className="ptag">
                <p>{i + 1 + ". "}</p>
                <Link to={reso.url} target="_detials">
                  <p className="width-980">
                    {reso.display_name
                      ? reso.display_name.length > 20
                        ? reso.display_name.slice(0, 20) + "..."
                        : reso.display_name
                      : "Download Resource"}
                  </p>
                  <p className="full-width">
                    {reso.display_name ?? "Download Resource"}
                  </p>
                </Link>
              </div>
              <div className="linktag">
                <a href={reso.url} download={reso.display_name} target="_blank">
                  <FontAwesomeIcon
                    className="exercise-download-icon ms-2"
                    icon={faCircleArrowDown}
                  />
                </a>
              </div>
            </div>
          ))}
        </section>
      )}
    </div>
  );
}

export default EntryDetail;
