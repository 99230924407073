import { useState } from "react";
import { Button } from "react-bootstrap";
import BasicTextEditor from "../editor/BasicTextEditor";

function WrittenAnswer({ content = null, onSubmit, onCancel }) {
  const [answer, setAnswer] = useState(content);

  const handleAnswerChange = (value) => {
    window.dispatchEvent(new CustomEvent("writtenAnswerChanged"));
    setAnswer(value);
  };

  function saveAnswer(e) {
    e.preventDefault();
    onSubmit(answer);
  }

  return (
    <div>
      <BasicTextEditor
        value={answer}
        onChange={(value) => handleAnswerChange(value)}
      />
      <div className="exercise-popup-6 d-flex justify-content-center">
        <Button
          className="btn btn-primary exercise-button me-3"
          type="submit"
          onClick={saveAnswer}
        >
          Save Your Entry
        </Button>
        <Button
          type="button"
          className="exercise-cancel-button btn-light"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default WrittenAnswer;
