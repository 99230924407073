import { toast } from "react-toastify";
import api from "../../api";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import ENCAP_LOGO from "../../assets/image/logo.PNG";
import { ButtonSpinner } from "../../Components/Encap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const InvitationConfirmationPrompt = ({
  token,
  organizationName,
  onAccept,
  onDecline,
}) => {
  const [processing, setProcessing] = useState(false);
  const [declining, setDeclining] = useState(false);
  const { syncUserInformation } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);

  const handleAccept = () => {
    setProcessing(true);
    api
      .post(`invitation/accept/${token}`)
      .then((response) => {
        syncUserInformation();
        onAccept();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return false;
        }
        toast.error("Something went wrong while accepting invitation");
      })
      .finally(() => setProcessing(false));
  };

  const handleDecline = async () => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      html: `<div>
            <div>Are you sure you do not want to accept this invitation?</div>
          </div>`,
      showCancelButton: true,
      confirmButtonText: "Yes, Decline Invitation",
      confirmButtonColor: "#f33f3f",
      cancelButtonText: "Cancel",
    });

    if (!result.isConfirmed) {
      setDeclining(false);
      return;
    }

    api
      .post(`invitation/decline/${token}`)
      .then((response) => {
        onDecline();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return false;
        }
        toast.error("Something went wrong. Please try again.");
      })
      .finally(() => setDeclining(false));
  };

  return (
    <section className="text-center">
      <img
        src={ENCAP_LOGO}
        alt="Encapsulator"
        className="img-fluid mb-4"
        style={{ height: "4rem" }}
      />
      <h3 className="h3-responsive mb-4">Welcome to Encapsulator</h3>
      <p>
        You have been invited to join{" "}
        <span className="fw-bold">{organizationName}</span>.
      </p>
      <div className="d-flex gap-2">
        <button
          className="btn btn-primary flex-grow-1 rounded-1"
          onClick={handleAccept}
          disabled={processing}
        >
          <ButtonSpinner processing={processing} />
          Accept
        </button>
        <button
          className="btn btn-danger flex-grow-1 rounded-1"
          onClick={handleDecline}
          disabled={declining}
        >
          <ButtonSpinner processing={declining} />
          Decline
        </button>
      </div>
    </section>
  );
};

export default InvitationConfirmationPrompt;
