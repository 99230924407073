import React, { useState, useEffect, useContext, useMemo } from "react";
import "./../../../assets/css/prog_library.css";
import api from "../../../api";
import ProgramLibraryCard from "../../../Components/program/ProgramLibraryCard";
import { AuthContext } from "../../../context/AuthContext";
import NoDataCard from "../../../Components/NoDataCard";
import { Helmet } from "react-helmet-async";
import InfiniteScroll from "react-infinite-scroll-component";
import ProgramPlaceholder from "../../../Components/program/ProgramPlaceholder";
import OrganizationFilterBar from "./OrganizationFilterBar";
import debounce from "lodash.debounce";

function ProgramLibrary() {
  const [programs, setPrograms] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { getAuthUser } = useContext(AuthContext);
  const authUser = getAuthUser();

  const [filter, setFilter] = useState({
    search: "",
    organization_id: authUser?.organizations?.length
      ? authUser.organizations[0].id
      : null,
    page: 1,
  });

  const fetchPrograms = () => {
    setLoading(true);
    api
      .get("program-templates", {
        params: {
          page: filter.page,
          search: filter.search,
          organization_id: filter.organization_id,
          per_page: 16,
        },
      })
      .then((response) => {
        if (!response.data.links.next) {
          setHasMore(false);
        }
        if (response.data.meta.current_page === 1) {
          setPrograms(response.data.data);
        } else {
          setPrograms([...programs, ...response.data.data]);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPrograms();
  }, [filter]);

  const applyFilter = (key, value) => {
    setHasMore(true);
    setPrograms([]);
    setFilter({
      ...filter,
      [key]: value,
      page: 1,
    });
  };

  const handleScrolled = () => {
    setFilter({ ...filter, page: filter.page + 1 });
  };

  const handleSearch = (e) => {
    applyFilter("search", e.target.value);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 300);
  }, [filter]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  const getErrorText = () => {
    if (filter.organization_id) {
      const organizationIndex = authUser.organizations.findIndex(
        (organization) => parseInt(organization.id) === filter.organization_id
      );
      const organizationName = authUser.organizations[organizationIndex].name;
      return `${organizationName} doesn't have any programs yet`;
    }

    return "There are no public programs yet";
  };

  const LoadingPlaceholder = () => {
    return [...Array(8)].map((e, i) => (
      <div className="col col-sm-6 col-md-4 col-lg-3 mb-3" key={i}>
        <ProgramPlaceholder />
      </div>
    ));
  };

  const EndOfPageMessage = () => {
    return (
      <div className="text-center py-3 text-muted">
        You have reached the end of page.
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Program Library | Encapsulator</title>
        <meta name="description" content="Browse all the programs" />
      </Helmet>
      <div className="lib-flex">
        <div className="library-grid-2">
          <h4>Program Library</h4>
          <p>Browse all the programs.</p>
        </div>
      </div>

      <section className="d-md-flex gap-3 align-items-center mt-2">
        <OrganizationFilterBar
          value={filter.organization_id}
          onChange={(key, value) => applyFilter(key, value)}
        />

        <div className="ms-auto">
          <div className="input-group rounded lib-search-box">
            <input
              type="search"
              className="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              onChange={(e) => debouncedSearch(e)}
            />
          </div>
        </div>
      </section>

      <InfiniteScroll
        next={() => handleScrolled()}
        hasMore={hasMore}
        loader={<LoadingPlaceholder />}
        dataLength={programs.length}
        className="row prog_card"
        endMessage={programs?.length > 16 && <EndOfPageMessage />}
      >
        {programs.map((program) => (
          <ProgramLibraryCard program={program} key={program.id} />
        ))}
      </InfiniteScroll>

      {!loading && !programs?.length && (
        <div className="mt-4">
          <NoDataCard
            title={getErrorText()}
            text={
              filter.organization_id
                ? `You can browse public programs until any program is available here`
                : null
            }
          />
        </div>
      )}
    </>
  );
}

export default ProgramLibrary;
