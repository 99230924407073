import { Button, Modal } from "react-bootstrap";

export default function PromptSelectionModal({
  show,
  prompts,
  onPromptSelected,
  onPromptSelectionClose,
}) {
  const onHide = () => {
    onPromptSelectionClose && onPromptSelectionClose();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="prompt-selection-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose Prompt</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="p-3">
          <p style={{ fontSize: "0.9rem" }}>
            Choose one of the following prompt below that will show you question
            you can view while you answer. If you don't need any prompt, click
            "Continue without prompt".
          </p>
          <div className="d-flex flex-column gap-3 ">
            {prompts &&
              prompts.map((prompt) => (
                <button
                  type="button"
                  className="prompt-selection-button btn bg-white border text-start"
                  onClick={() => onPromptSelected(prompt.id)}
                  key={prompt.id}
                >
                  {prompt.title}
                </button>
              ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className="continue-without-prompt"
          onClick={() => onPromptSelected(null)}
        >
          Continue without prompt
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
