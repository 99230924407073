import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../api";

const HeaderNotificationIcon = () => {
  const [count, setCount] = useState(0);

  const fetchNotificationsCount = () => {
    api
      .get("unread-notifications-count")
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => fetchNotificationsCount(), []);

  return (
    <Link to="notifications" className="header__icon bell__box">
      <FontAwesomeIcon icon={faBell} />
      {count > 0 && <span className="counter__badge"></span>}
    </Link>
  );
};

export default HeaderNotificationIcon;
