import { guestApi } from "../api";

export const submitEmail = async (email) => {
  return guestApi.post("forgot-password/send-code", { email });
};

export const verifyOTP = (email, otp) => {
  return guestApi.post("forgot-password/verify-code", { email, otp });
};

// export const resendOTP = (email) => {
//   return guestApi.post("forgot-password/onboarding/resend-otp", { email });
// };

export const setNewPassword = (payload) => {
  return guestApi.post("forgot-password/set-new-password", payload);
};
