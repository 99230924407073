import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, useNavigate } from "react-router-dom";
import ProgramForm from "./ProgramForm";
import ProgramMainNav from "../../../Components/layouts/ProgramMainNav";

function CreateProgram() {
  const navigate = useNavigate();

  return (
    <>
      
      <ProgramMainNav />

      <ProgramForm />
    </>
  );
}

export default CreateProgram;
