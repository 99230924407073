import RegisterForm from "../../Components/auth/RegisterForm";
import UserOnboarding from "../../Components/auth/UserOnboarding";

export default function Register() {
  return (
    <section className="mt-5">
      <div className="d-flex justify-content-center">
        {/* <div className="col-md-3"> */}
          {/* <RegisterForm></RegisterForm> */}
          <UserOnboarding/>
        {/* </div> */}
      </div>
    </section>
  );
}
