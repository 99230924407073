import classNames from "classnames";
import "../../../assets/scss/organization_wizard_stepper.scss";

const Stepper = ({ current }) => {
  return (
    <section className="org-stepper">
      <div className="d-flex gap-2 align-items-center">
        <div className={classNames('point', {
          completed: current > 1 
        })}><span>1</span></div>
        <div>Create Organization</div>
      </div>
      <div className="border" style={{ width: "100px" }}></div>
      <div className="d-flex gap-2 align-items-center">
        <div className="point"><span>2</span></div>
        <div>Billing & Licensing</div>
      </div>
    </section>
  );
};

export default Stepper;
