import { useContext, useEffect, useState } from "react";
import { guestApi } from "../../api";
import { AuthContext } from "../../context/AuthContext";
import LoadingBlock from "../../Components/LoadingBlock";

const AutoLogin = ({ token, onLoggedIn }) => {
  const { logout, login } = useContext(AuthContext);

  useEffect(() => {
    logout();
    guestApi
      .post("invitation/auto-login", {
        token: token,
      })
      .then(async (response) => {
        await login(response.data.token, response.data.user);
        onLoggedIn();
      });
  }, []);

  return <LoadingBlock />;
};

export default AutoLogin;
