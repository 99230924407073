import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import api from "../../../api";

// Register the file type validation plugin
registerPlugin(FilePondPluginFileValidateType);

function MyFilePond({ maxFiles = 5, allowedFileTypes = [], onUploadComplete, serverUrl = '/upload' }) {
  const [fileIds, setFileIds] = useState([]);
  //const serverUrl = "/upload";

  useEffect(() => {
    onUploadComplete(fileIds);
  }, [fileIds]);

  const handleInit = () => {
    console.log("FilePond instance has initialised");
  };

  // Define the function that will be called when a file is added
  const handleFileAdded = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const response = await api.post(serverUrl, formData, {
      onUploadProgress: (progressEvent) => {
        const loaded = progressEvent.loaded;
        const total = progressEvent.total;
        const percent = Math.round((loaded / total) * 100);
        progress(percent);
      },
    });

    let fileId = response.data.id;
    setFileIds((prevIds) => [...prevIds, fileId]);
    load(fileId); // To make filepond remember of the file
  };

  return (
    <FilePond
      maxFiles={maxFiles}
      allowMultiple={maxFiles > 1}
      allowFileTypeValidation={allowedFileTypes && true}
      acceptedFileTypes={allowedFileTypes}
      server={{ url: serverUrl, process: handleFileAdded }}
      oninit={() => handleInit()}
      onupdatefiles={() => {
        // Reset file IDs when files are removed
        if (fileIds.length > maxFiles) {
          setFileIds([]);
        }
      }}
      credits={false}
    />
  );
}

export default MyFilePond;
