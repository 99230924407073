import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import api from "../../api";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import visaCard from "../../assets/image/visaCard.jpeg";
import { syncUserInformation } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Errors from "../../utils/Errors";
import "./../../assets/css/card_element.css";
import classNames from "classnames";
import { Elements } from "@stripe/react-stripe-js";

function StripePaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [Error, setError] = useState();
  const [errors, setErrors] = useState(new Errors());

  useEffect(() => {
    api
      .get("plans")
      .then((response) => {
        setPlans(response.data);
        if (response.data) {
          setSelectedPlan(response.data[0].price_id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Use Stripe.js to handle payment method collection
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        throw new Error(error.message);
      }

      // Send a request to your Laravel backend API to create a subscription
      await api
        .post("subscribe", {
          plan: selectedPlan,
          paymentMethod: paymentMethod.id,
        })
        .then((response) => {
          if (response.status === 200) {
            syncUserInformation();
            navigate("/settings/billing-and-subscription");
          }
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response && error.response.data.errors) {
            console.error(error);
            setErrors(new Errors(error.response.data.errors));
            toast.error("Error while subscription");
            return;
          }
        });

      // Subscription created successfully
      setProcessing(false);

      // Redirect or show success message
    } catch (error) {
      setProcessing(false);
      setError("An error occurred. Please try again.");
    }
  };

  const style = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      ":focus": {
        border: "1px solid hsla(210, 96%, 45%, 50%)",
      },
    },
    invalid: {
      color: "##dc2727",
      iconColor: "#fa755a",
    },
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="subscribeContainer">
        <div className="subscribe-flex-1">
          <div className="d-flex w-full gap-3">
            {plans &&
              plans.map((plan) => {
                return (
                  <label
                    className={classNames("bill-plan-box", {
                      active: selectedPlan === plan.price_id,
                    })}
                    key={plan.price_id}
                  >
                    <input
                      type="radio"
                      className="d-none"
                      name="plan"
                      value={plan.price_id}
                      checked={selectedPlan === plan.price_id}
                      onChange={() => setSelectedPlan(plan.price_id)}
                    />
                    <div className="indicator">
                      <span></span>
                    </div>
                    <div>
                      <div className="plan-name">{plan.name}</div>
                      <div className="price">{plan.description}</div>
                    </div>
                  </label>
                );
              })}
          </div>
        </div>
        <div className="subscribe-flex-2">
          <div className="mb-4 subscribe-flex-2-1">
            <p>Payment Details</p>
          </div>

          <div className="mb-4 subscribe-flex-2-2">
            <label className="form-label">Card Number</label>
            <div className="stripe-input">
              <CardNumberElement
                options={{ showIcon: true, iconStyle: "solid", style }}
              />
            </div>
          </div>

          <div className="d-flex gap-3 subscribe-flex-2-2">
            <div className="w-100">
              <label className="form-label">Expiration</label>
              <div className="stripe-input">
                <CardExpiryElement
                  options={{ showIcon: true, iconStyle: "solid", style }}
                />
              </div>
            </div>
            <div className="w-100">
              <label className="form-label">CVC</label>
              <div className="stripe-input">
                <CardCvcElement
                  options={{ showIcon: true, iconStyle: "solid", style }}
                  placeholder="cardCvcPlaceholder"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 mb-4 subscribe-flex-2-5">
            <p>
              powered by <span>Stripe</span>
            </p>
            <div className="VLine"></div>
            <img src={visaCard} alt="Visa " />
          </div>
          <div className="subscribe-flex-2-6">
            {processing ? (
              <Button className="btn">Please wait...</Button>
            ) : (
              <Button className="btn" onClick={handleSubmit}>
                Subscribe
              </Button>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}

export default StripePaymentForm;
