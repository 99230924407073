import { useContext, useRef } from "react";
import api from "../../../api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../context/AuthContext";

const AvatarUpload = () => {
  const { getAuthUser, syncUserInformation } = useContext(AuthContext);
  const user = getAuthUser();
  const inputFile = useRef(null);

  const handleAvatarChange = (e) => {
    const files = e.target.files;
    if (files) {
      const formData = new FormData();
      formData.append("file", files[0], files[0].name);
      api
        .post(`change-profile-picture`, formData)
        .then((res) => {
          toast.success("Profile Changed successfully !");
          syncUserInformation();
        })
        .catch((error) => {
          toast.error("something went wrong !");
        });
    }
  };

  const handleProfileUpload = () => {
    inputFile.current.click();
  };

  return (
    <div className="setting-grid-3-3">
      <h6 className="col-sm-2 label">Profile Pic</h6>
      <div className="setting-grid-profile-pic me-3 ms-0">
        {user.avatar && (
          <img
            src={user.avatar}
            className="setting-profile-pic"
            alt={user.first_name}
            onClick={handleProfileUpload}
          />
        )}
      </div>

      <input
        type="file"
        style={{ display: "none" }}
        ref={inputFile}
        onChange={handleAvatarChange}
        accept="image/*"
      />
      <button
        className="setting-grid-3-5"
        onClick={handleProfileUpload}
        type="button"
      >
        <FontAwesomeIcon
          icon={faCirclePlus}
          color="#529C00"
          style={{ fontSize: 18 }}
        ></FontAwesomeIcon>
        <h5>Choose Photo</h5>
      </button>
    </div>
  );
};

export default AvatarUpload;
