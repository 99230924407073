import { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond/dist/filepond.min.css'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css";
import filepondPluginMediaPreview from "filepond-plugin-media-preview";

registerPlugin(filepondPluginMediaPreview)

function FilepondPreview(){
    const [files, initFiles] = useState([]);

    return (
        <div className="container mt-5">
            
            {/* <FilePond files={files}/> */}

        </div>
    )
}

export default FilepondPreview;