import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function ProgramMainNav() {
  const navigate = useNavigate();

  function CloseForm() {
    navigate("/programs");
  }
  return (
    <div className="add-program-header" onClick={CloseForm}>
      <h5>Add A New Program</h5>
      <FontAwesomeIcon
        icon={faCircleXmark}
        color="#fff"
        style={{ fontSize: 25 }}
      ></FontAwesomeIcon>
    </div>
  );
}

export default ProgramMainNav;
