import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import WizardProgressBar from "./StepsProgressBar";
import "../../../assets/css/dashboard_addprogram1.css";
import ProgramMainNav from "../../../Components/layouts/ProgramMainNav";
import CloseFormButton from "../../../Components/layouts/CloseFormButton";

export default function AddProgram() {
  const navigate = useNavigate();
  
  return (
    <>
      <ProgramMainNav />

      <div className="add-program-grid">
        <div className="add-program-grid-1">
          <div className="add-program-grid-1-1">
            <h5>Let’s Add a New Program</h5>
            <p>Please choose a program type.</p>
          </div>
          {/* <ProgressBar bgcolor="#529C00" progress='20'/> */}
          <WizardProgressBar current="1" total="4" />
        </div>

        <div className="add-program-grid-2">
          <div className="add-program-grid-2-1">
            <div>
              <h5>Premade Program</h5>
              <h6>Recommended</h6>
              <p>
                Select one of Encapsulator’s world class programs. You can adopt
                them as they are, or make changes to better suit your
                organisation.
              </p>
            </div>
            <Link to="/premade-program">
              <button className="add-program-grid-2-3">Choose a Program</button>
            </Link>
          </div>

          <div className="add-program-grid-2-2">
            <div>
              <h5>Blank Program</h5>
              <p>
                Start with a blank slate and create any type of program that
                suits your organisation
              </p>
            </div>
            <Link to="/programs/add">
              <button className="add-program-grid-2-3">
                Start from Scratch
              </button>
            </Link>
          </div>
        </div>

        {/* <button className="add-program-grid-2-4" onClick={navigate('/programs')}>Cancel</button> */}
        <CloseFormButton />
      </div>
    </>
  );
}
