import Rating from "react-rating";
import "./../../assets/scss/program_feedback.scss";
import api from "../../api";
import Errors from "../../utils/Errors";
import { toast } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProgramFeedback = ({ programId }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    rating: 0,
    comment: "",
  });
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());

  const handleRated = (value) => {
    setState({ ...state, rating: value });
  };

  const handleSubmit = () => {
    setProcessing(true);
    api
      .post(`program-feedback/${programId}`, {
        rating: state.rating,
        comment: state.comment,
      })
      .then((res) => {
        toast.success("Thank you! Your feedback has been submitted");
        navigate("/");
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to submit feedback");
          return;
        }
        toast.error("Something went wrong");
      })
      .finally(() => setProcessing(false));
  };

  return (
    <section className="program-feedback">
      <h4 className="font-secondary fw-bold mb-3">
        We'd love to hear your feedback
      </h4>
      <div className="mb-3">
        <Rating
          initialRating={state.rating}
          emptySymbol={
            <svg
              style={{ height: "3rem", width: "3rem", color: "#9e9e9e" }}
              fill="none"
              stroke="currentColor"
              stroke-width="1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
              />
            </svg>
          }
          fullSymbol={
            <svg
              style={{ height: "3rem", width: "3rem", color: "#fece00" }}
              fill="currentColor"
              stroke="currentColor"
              stroke-width="1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
              ></path>
            </svg>
          }
          onChange={handleRated}
        />
        {errors.has("rating") && (
          <div className="d-block invalid-feedback error-text">
            Please rate this program
          </div>
        )}
      </div>
      <div className="mb-3">
        <textarea
          cols="30"
          rows="4"
          placeholder="Please share your experience using this program"
          value={state.comment}
          onChange={(e) => setState({ ...state, comment: e.target.value })}
        ></textarea>
        {errors.has("review") && (
          <div className="d-block invalid-feedback error-text">
            {errors.first("review")}
          </div>
        )}
      </div>

      <div>
        <button
          type="button"
          className="font-secondary"
          onClick={handleSubmit}
          disabled={processing}
        >
          {processing && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span>Submit Feedback</span>
        </button>
      </div>
    </section>
  );
};

export default ProgramFeedback;
