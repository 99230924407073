import { useEffect, useState } from "react";
import api from "../../api";
import Errors from "../../utils/Errors";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import LoadingBlock from "../../Components/LoadingBlock";
import { syncUserInformation } from "../../utils/helpers";

function ViewSubscription() {
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState([]);
  const [customer, setCustomer] = useState([]);

  const [cancelling, setCancelling] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState(new Errors());
  const [cancelled, setCancelled] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      api.get("/subscriptions").then((res) => {
        //let v= res.json();
        setSubscription(res.data.subscription);
        setCustomer(res.data.customer);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  function cancelSubscription(e) {
    e.preventDefault();
    setCancelling(true);
    api
      .post(`cancel-subscription`)
      .then((res) => {
        toast.success("Your Subscription has been cancelled");
        // localStorage.setItem("user", JSON.stringify(res.data.user));
        syncUserInformation();
        setCancelled(true);
        navigate("/");
      })
      .catch((error) => {
        if (error.res && error.res.data.errors) {
          console.log(error);
          setErrors(new Errors(error.res.data.errors));
          toast.error("Error while canceling subscription");
          return;
        }
      });
  }

  if (cancelled) {
    toast.error("You have not subscribed yet !");
    navigate("/");
  }

  if (loading) {
    return <LoadingBlock />;
  }

  return (
    <section>
      <hr />
      <div className="account-subscribe">
        <h4>
          Subscription Status :{" "}
          <span className="text-capitalize">{subscription.status}</span>
        </h4>

        <p>
          Start Date:{" "}
          {new Date(
            subscription.current_period_start * 1000
          ).toLocaleDateString()}
        </p>

        <p>
          End Date:{" "}
          {new Date(
            subscription.current_period_end * 1000
          ).toLocaleDateString()}
        </p>

        {/* <Link to={{pathname: '/cancel', state: {subscription: subscription.id }}}>Cancel</Link> */}
        <div className="subscribe-flex-2-6">
          <Button
            className="btn cancel"
            onClick={cancelSubscription}
            disabled={cancelling}
          >
            {cancelling ? "Please wait..." : "Cancel Subscription"}
          </Button>
        </div>
      </div>
    </section>
  );
}

export default ViewSubscription;
