import React, { useContext } from "react";
import "../../assets/scss/header.scss";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faGear } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthContext";
import HeaderBadges from "./partials/HeaderBadges";
import UserOptionDropdown from "./partials/UserOptionDropdown";
import HeaderNotificationIcon from "./partials/HeaderNotificationIcon";
import classNames from "classnames";

function Header() {
  const navigate = useNavigate();
  const { isLoggedIn, getAuthUser, logout } = useContext(AuthContext);
  const user = getAuthUser();

  function LogoutUser() {
    logout();
    navigate("/");
  }

  const WelcomeMessage = () => {
    if (!isLoggedIn()) return <span>Welcome To Encapsulator</span>;

    return <span>Welcome back, {user?.first_name}!</span>;
  };

  return (
    <div className="dash-header">
      <div className="dash-header-flex">
        <div>
          <h4
            className={classNames("greetings", {
              authenticated: isLoggedIn(),
            })}
          >
            <WelcomeMessage />
          </h4>
        </div>
        <div className="header-right">
          {isLoggedIn() && (
            <div className="d-flex align-items-center gap-2 gap-lg-4">
              <HeaderBadges />

              <HeaderNotificationIcon />

              {/* <Link to={"help"} className="header__icon question-box">
                <FontAwesomeIcon icon={faCircleQuestion} />
              </Link> */}

              <Link to="settings/profile" className="header__icon gear-box">
                <FontAwesomeIcon icon={faGear} />
              </Link>

              <UserOptionDropdown user={user} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Header;
