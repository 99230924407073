import { getAuthUser } from "../../utils/helpers";
import Subscribe from "./Subscribe";
import ViewSubscription from "./ViewSubscription";

function SubscriptionPage() {
  const user = getAuthUser();

  if (user.is_subscribed) {
    return <ViewSubscription />
  }
  return <Subscribe />;
}

export default SubscriptionPage;
