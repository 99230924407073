import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import ProgramForm from "../wizard/ProgramForm";
import TabHeading from "./TabHeading";
import LoadingBlock from "../../../Components/LoadingBlock";

function OverviewTab() {
  const { programId } = useParams();
  const { data: program, loading } = useFetch(
    `program-wizard/edit-program/${programId}`
  );

  if (loading || !program) {
    return <LoadingBlock />;
  }

  return (
    <div className="pt-3">
      <TabHeading
        title="Program Overview"
        subTitle="Enter the basic details of the program."
      />
      <ProgramForm program={program} isWizard={false} />;
    </div>
  );
}

export default OverviewTab;
