import React, { useContext, useEffect, useState } from "react";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "../../../assets/css/dash_setting.css";
import "../../../assets/css/billing_subscriptions.css";
import visaLogo from "../../../assets/image/Visa-logo.png";
import api from "../../../api";
import moment from "moment";
import InvoicesTable from ".//InvoicesTable";
import LoadingBlock from "../../../Components/LoadingBlock";
import CancelSubscriptionButton from "./CancelSubscriptionButton";
import ChangePaymentMethodModal from "./ChangePaymentMethodModal";
import OrganizationSubscriptionList from "./OrganizationSubscriptionList";
import LicensePurchaseHistory from "../../organisation/subscription/LicensePurchaseHistory";
import { AuthContext } from "../../../context/AuthContext";

const UserSubscriptionDetail = () => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();
  const [subscription, setSubscription] = useState();
  const [loading, setLoading] = useState(true);
  const [showChangePaymentMethodModal, setShowChangePaymentMethodModal] =
    useState(false);

  const getCurrentPlan = () => {
    setLoading(true);
    api
      .get("get-current-plan")
      .then((response) => {
        setSubscription(response.data);
      })
      .finally(() => setLoading(false));
  };

  const getStatus = (subscription) => {
    if (subscription.stripe) {
      return subscription.stripe.status;
    }

    return subscription.is_subscribed ? "Active" : "Inactive";
  };

  const formatRenewalDate = (timestamp) => {
    return moment.unix(timestamp).format("MMMM D");
  };

  const formattedAmount = (cents) => {
    let amount = (cents / 100).toFixed(2);
    return "AU $" + amount;
  };

  const getRemainingDays = (timestamp) => {
    let renewalDate = moment.unix(timestamp);
    return renewalDate.diff(moment(), "days");
  };

  useEffect(() => {
    getCurrentPlan();
  }, []);

  if (loading || !subscription) {
    return <LoadingBlock />;
  }

  return (
    <>
      <div className="setting-grid-3">
        <div className="bs-title">
          <h6>Billing & Subscriptions</h6>
        </div>
        <div className="bs-content">
          <div className="bs-content-7">
            <div className="label">
              <p>Subscription Status</p>
            </div>
            <div className="input-value">
              <div className="input-value-1">
                <p className="text-capitalize">{getStatus(subscription)}</p>
              </div>

              {!subscription.is_subscribed && (
                <Link to="/subscribe" className="button-plus">
                  <FontAwesomeIcon
                    className="bs-plus-icon"
                    icon={faPlusCircle}
                    color="#FFFFFF"
                  ></FontAwesomeIcon>
                  <p>Upgrade</p>
                </Link>
              )}
            </div>
          </div>
          <div className="bs-content-1">
            <div className="label">
              <p>Current Plan</p>
            </div>
            <div className="input-value">
              <div className="input-value-1">
                <p>{subscription.current_plan}</p>
                {subscription.is_subscribed && <CancelSubscriptionButton />}
              </div>

              {/* {!subscription.is_subscribed && (
              <Link to="/subscribe" className="button-plus">
                <FontAwesomeIcon
                  className="bs-plus-icon"
                  icon={faPlusCircle}
                  color="#FFFFFF"
                ></FontAwesomeIcon>
                <p>Upgrade</p>
              </Link>
            )} */}
            </div>
          </div>

          <div className="bs-content-2">
            <div className="label">
              <p>Renewal Date</p>
            </div>
            <div className="input-value">
              {subscription?.stripe?.renewal_date ? (
                <div className="input-value-1">
                  <p>{formatRenewalDate(subscription.stripe.renewal_date)}</p>
                  <span>
                    in {getRemainingDays(subscription.stripe.renewal_date)} days
                  </span>
                </div>
              ) : (
                <div className="input-value-1">
                  <p>N/A</p>
                </div>
              )}
            </div>
          </div>

          {subscription.is_subscribed &&
            subscription?.stripe?.next_payment_amount && (
              <div className="bs-content-3">
                <div className="label">
                  <p>Next Payment</p>
                </div>
                <div className="input-value">
                  <div className="input-value-1">
                    <p>
                      {formattedAmount(subscription.stripe.next_payment_amount)}
                    </p>
                  </div>
                </div>
              </div>
            )}

          {subscription?.stripe?.payment_method && (
            <div className="bs-content-4">
              <div className="label">
                <p>Payment Details</p>
              </div>
              <div className="input-value">
                <img src={visaLogo} alt="visa" />
                <div className="input-value-1">
                  <p>
                    **** **** ****{" "}
                    {subscription.stripe.payment_method.card.last4}
                  </p>
                </div>
                <button
                  className="button-plus"
                  onClick={() => setShowChangePaymentMethodModal(true)}
                >
                  <FontAwesomeIcon
                    className="bs-plus-icon"
                    icon={faPlusCircle}
                    color="#FFFFFF"
                  ></FontAwesomeIcon>
                  <p>Update Card</p>
                </button>
              </div>
            </div>
          )}

          {/* TODO::We are not saving billing information for now */}
          {/* <div className="bs-content-5">
            <div className="label">
              <p>Billing Information</p>
            </div>
            <div className="input-value">
              <div className="input-value-1">
                <p>Demo Mc Test</p>
                <p>C/- Fun Time Big Company</p>
                <p>1234 Main Street</p>
                <p>Sydney NSW 2000</p>
              </div>
              <button className="button-plus">
                <FontAwesomeIcon
                  className="bs-plus-icon"
                  icon={faPlusCircle}
                  color="#FFFFFF"
                ></FontAwesomeIcon>
                <p>Update Details</p>
              </button>
            </div>
          </div> */}

          <div className="bs-content-6">
            <div className="label">
              <p>Invoice History</p>
            </div>
            <div className="input-value">
              <InvoicesTable />
              {user?.organization?.id && (
                <LicensePurchaseHistory id={user?.organization?.id} />
              )}
            </div>
          </div>

          {subscription?.organization?.length > 0 && (
            <OrganizationSubscriptionList
              organizations={subscription.organization}
            />
          )}
        </div>
      </div>
      <ChangePaymentMethodModal
        show={showChangePaymentMethodModal}
        onHide={() => setShowChangePaymentMethodModal(false)}
      />
    </>
  );
};

export default UserSubscriptionDetail;
