import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { hasRole } from "../../../utils/helpers";

const SettingsNavigation = () => {
  const { getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();

  return (
    <div className="setting-1">
      <NavLink to="/settings/profile" className="setting-grid-2">
        <h6>My Profile</h6>
        <FontAwesomeIcon
          className="setting-arrow-icon"
          icon={faArrowRight}
          color="#FFFFFF"
          style={{ fontSize: 12 }}
        ></FontAwesomeIcon>
      </NavLink>
      <NavLink
        to="/settings/billing-and-subscription"
        className="setting-grid-2"
      >
        <h6>Billing and Subscription</h6>
        <FontAwesomeIcon
          className="setting-arrow-icon"
          icon={faArrowRight}
          color="#FFFFFF"
          style={{ fontSize: 12 }}
        ></FontAwesomeIcon>
      </NavLink>
      {/* <Link to="/settings/notifications" className="setting-grid-2">
        <h6>Notification Settings</h6>
        <FontAwesomeIcon
          className="setting-arrow-icon"
          icon={faArrowRight}
          color="#FFFFFF"
          style={{ fontSize: 12 }}
        ></FontAwesomeIcon>
      </Link> */}

          {hasRole("orgadmin") && 
           <NavLink
           to="/settings/organization-settings"
           className="setting-grid-2"
         >
           <h6>Organization Settings</h6>
           <FontAwesomeIcon
             className="setting-arrow-icon"
             icon={faArrowRight}
             color="#FFFFFF"
             style={{ fontSize: 12 }}
           ></FontAwesomeIcon>
         </NavLink>
         }
    </div>
  );
};

export default SettingsNavigation;
