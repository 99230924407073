import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import OTPInput from "react-otp-input";
import {
  completeRegistration,
  resendOTP,
  submitEmail,
  verifyOTP,
} from "../../utils/UserOnboarding";
import Errors from "../../utils/Errors";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { redirect, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ButtonSpinner } from "../Encap";
import "./../../assets/scss/onboarding.scss";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";

const STAGE = {
  EMAIL: "email",
  OTP: "otp",
  FINAL: "final",
  REGISTERED: "registered",
};

const UserOnboarding = () => {
  const [stage, setStage] = useState(STAGE.EMAIL);
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [allowResendOTP, setAllowResendOTP] = useState(false);
  const [resendingOTP, setResendingOTP] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [showTOSModal, setShowTOSModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);

  const [state, setState] = useState({
    email: "",
    otp: "",
    first_name: "",
    password: "",
    password_confirmation: "",
  });

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setState({ ...state, [name]: value });
  };

  const otpInputStyle = {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "0.25rem",
    border: "1px solid #ced4da",
  };

  const handleSubmitEmail = () => {
    setProcessing(true);
    submitEmail(state.email)
      .then((response) => {
        setStage(STAGE.OTP);
        setTimeout(() => {
          setAllowResendOTP(true);
        }, 30000);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => setProcessing(false));
  };

  const handleVerifyOTP = () => {
    setProcessing(true);
    verifyOTP(state.email, state.otp)
      .then((response) => {
        setStage(STAGE.FINAL);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => setProcessing(false));
  };

  const handleResendOTP = () => {
    setResendingOTP(true);
    resendOTP(state.email)
      .then((response) => {
        toast.success("Verication code has been sent");
        setAllowResendOTP(false);
        setTimeout(() => {
          setAllowResendOTP(true);
        }, 30000);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => setResendingOTP(false));
  };

  const handleCompleteRegistration = () => {
    setProcessing(true);
    completeRegistration(state)
      .then((response) => {
        if (response.data.token && response.data.user) {
          login(response.data.token, response.data.user);
        }
        setStage(STAGE.REGISTERED);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => setProcessing(false));
  };

  const handleApiError = (error) => {
    if (error?.response?.data?.errors) {
      console.error(error);
      setErrors(new Errors(error.response.data.errors));
      return;
    }
    toast.error("Server Error");
  };

  const handleContinue = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectTo = urlParams.get("redirectTo");
    if (redirectTo) {
      navigate(redirectTo);
    } else {
      navigate(`/program-library`);
    }
  };

  const RegisterIcon = () => {
    return (
      <div className="step-icon-wrap">
        <div className="step-icon">
          <FontAwesomeIcon icon={faArrowRightToBracket}></FontAwesomeIcon>
        </div>
      </div>
    );
  };

  return (
    <Form
      onSubmit={(e) => e.preventDefault()}
      className="p-4"
      autoComplete="off"
    >
      <div className="user-onboarding">
        {stage === STAGE.EMAIL && (
          <section>
            <RegisterIcon />
            <h5 className="step-heading">Register</h5>
            <p className="text-muted text-start my-4">
              Enter your email for the verification process. We will send a
              verification code to your email.
            </p>
            <div className="my-4">
              {/* <label htmlFor="" className="form-label font-secondary">
                Email Address
              </label> */}
              <input
                type="email"
                name="email"
                className={classNames(
                  "form-control form-control-lg rounded-1",
                  {
                    "is-invalid": errors.has("email"),
                  }
                )}
                onChange={handleInputChange}
                placeholder="Enter your email"
              />
              {errors.has("email") && (
                <div className="d-block invalid-feedback">
                  {errors.first("email")}
                </div>
              )}
            </div>

            <div>
              <button
                type="button"
                className="btn btn-primary btn-lg rounded-1 px-5 font-secondary"
                onClick={handleSubmitEmail}
                disabled={processing}
              >
                <ButtonSpinner processing={processing} />
                Send verification code
              </button>
            </div>
          </section>
        )}

        {stage === STAGE.OTP && (
          <section>
            <RegisterIcon />
            <h5 className="step-heading">Verification</h5>
            {/* <p className="">
              An email with a verfication code has been sent to{" "}
              <span className="fw-bolder">{state.email}</span>
            </p> */}
            <p className="text-muted">
              Enter your 6 digits code that you received on your email.
            </p>
            <div className="my-4">
              {/* <label htmlFor="" className="form-label font-secondary">
                Please enter the OTP code here
              </label> */}
              <OTPInput
                value={state.otp}
                onChange={(value) => setState({ ...state, otp: value })}
                numInputs={6}
                renderSeparator={<span className="mx-2"></span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className={classNames("py-3", {
                      "border-danger": errors.has("otp"),
                    })}
                  />
                )}
                inputStyle={otpInputStyle}
                inputType="text"
              />
              {errors.has("otp") && (
                <div className="mt-2 text-danger">{errors.first("otp")}</div>
              )}
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary rounded-1 px-5 font-secondary fw-bolder"
                onClick={handleVerifyOTP}
                // disabled={processing || !state.otp}
              >
                Verify Code
              </button>

              <p className="mt-4 text-muted text-center">
                Please check your spam folder if you don't receive your email in
                5 minutes.
                {allowResendOTP && (
                  <button
                    type="button"
                    className="resend-otp-btn d-inline"
                    onClick={handleResendOTP}
                    disabled={resendingOTP}
                  >
                    <ButtonSpinner processing={resendingOTP} />
                    Resend
                  </button>
                )}
              </p>
            </div>
          </section>
        )}

        {stage === STAGE.FINAL && (
          <section>
            <RegisterIcon />
            <h5 className="step-heading">Register Detail</h5>

            <div className="mb-3">
              {/* <label htmlFor="" className="form-label font-secondary">
                  First Name
                </label> */}
              <input
                type="text"
                name="first_name"
                className={classNames("form-control rounded-1", {
                  "is-invalid": errors.has("first_name"),
                })}
                onChange={handleInputChange}
                placeholder="First name"
              />
              {errors.has("first_name") && (
                <div className="d-block invalid-feedback">
                  {errors.first("first_name")}
                </div>
              )}
            </div>
            <div className="mb-3">
              {/* <label htmlFor="" className="form-label font-secondary">
                  Last Name
                </label> */}
              <input
                type="text"
                name="last_name"
                className={classNames("form-control rounded-1", {
                  "is-invalid": errors.has("last_name"),
                })}
                onChange={handleInputChange}
                placeholder="Last name"
              />
              {errors.has("last_name") && (
                <div className="d-block invalid-feedback">
                  {errors.first("last_name")}
                </div>
              )}
            </div>

            <div className="password-help-text mt-1 text-muted">
              Minimum 8 characters,Uppercase & lowercase
              characters(A-Z),Digits(0-9)
            </div>
            <div className="mb-3">
              {/* <label htmlFor="" className="form-label font-secondary">
                Password
              </label> */}
              <input
                type="password"
                name="password"
                className={classNames("form-control rounded-1", {
                  "is-invalid": errors.has("password"),
                })}
                onChange={handleInputChange}
                placeholder="Password"
              />
              {errors.has("password") && (
                <div className="d-block invalid-feedback">
                  {errors.first("password")}
                </div>
              )}
            </div>
            <div className="mb-3">
              {/* <label htmlFor="" className="form-label font-secondary">
                Confirm Password
              </label> */}
              <input
                type="password"
                name="password_confirmation"
                className="form-control rounded-1"
                onChange={handleInputChange}
                placeholder="Confirm password"
              />
            </div>
            <div className="mb-3 d-flex justify-content-center text-muted">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="terms-and-conditions"
                  onChange={() => setPolicyAccepted(!policyAccepted)}
                  checked={policyAccepted}
                />
                <label class="form-check-label" for="terms-and-conditions">
                  I agree to the{" "}
                  <button type="button" onClick={() => setShowTOSModal(true)}>
                    Terms of Service
                  </button>{" "}
                  and{" "}
                  <button
                    type="button"
                    onClick={() => setShowPrivacyPolicyModal(true)}
                  >
                    Privacy Policy
                  </button>
                </label>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCompleteRegistration}
                disabled={processing || !policyAccepted}
              >
                <ButtonSpinner processing={processing} />
                Register
              </button>
            </div>
          </section>
        )}

        {stage === STAGE.REGISTERED && (
          <section>
            <div className="step-icon-wrap">
              <div className="registered-icon">
                <FontAwesomeIcon
                  // className="right-arrow"
                  icon={faCheck}
                  color="#0d6efd"
                ></FontAwesomeIcon>
              </div>
            </div>

            <h5 className="step-heading">Account Created</h5>
            <p className="text-center text-muted">
              Your account has been created successfully
            </p>
            <button
              type="button"
              className="btn btn-primary mt-4"
              onClick={() => handleContinue()}
            >
              Continue
            </button>
          </section>
        )}
      </div>
      <TermsOfService
        show={showTOSModal}
        onHide={() => setShowTOSModal(false)}
      />
      <PrivacyPolicy
        show={showPrivacyPolicyModal}
        onHide={() => setShowPrivacyPolicyModal(false)}
      />
    </Form>
  );
};

export default UserOnboarding;
