import "../../../assets/css/dash_program.css";
import { useEffect, useMemo, useState } from "react";
import MyProgramCard from "./MyProgramCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";
import NoDataCard from "../../../Components/NoDataCard";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import ProgramPlaceholder from "../../../Components/program/ProgramPlaceholder";
import debounce from "lodash.debounce";

function MyProgram() {
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [counts, setCounts] = useState([]);

  const STATUS = {
    ACTIVE: "active",
    COMPLETED: "completed",
    ARCHIVED: "archived",
    ALL: "all",
  };

  const [filter, setFilter] = useState({
    page: 1,
    status: STATUS.ACTIVE,
    search: "",
  });

  const fetchPrograms = () => {
    setLoading(true);
    api
      .get("my-programs", {
        params: {
          page: filter.page,
          status: filter.status,
          search: filter.search,
          per_page: 16,
        },
      })
      .then((response) => {
        if (filter.page == 1) {
          setPrograms(response.data.data);
        } else {
          console.log([...programs, ...response.data.data]);
          setPrograms([...programs, ...response.data.data]);
        }
        if (!response.data.links.next) {
          setHasMore(false);
        }
        setCounts(response.data.counts);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPrograms();
  }, [filter]);

  const handleFilterByStatus = (value) => {
    setHasMore(true);
    setPrograms([]);
    setFilter({
      ...filter,
      status: value,
      page: 1,
    });
  };

  const handleScrolled = () => {
    setFilter({ ...filter, page: filter.page + 1 });
  };

  const handleSearch = (e) => {
    setHasMore(true);
    setPrograms([]);
    setFilter({ ...filter, search: e.target.value, page: 1 });
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 300);
  }, [filter]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  const LoadingPlaceholder = () => {
    return [...Array(8)].map((e, i) => (
      <div className="col col-sm-6 col-md-4 col-lg-3 mb-3" key={i}>
        <ProgramPlaceholder />
      </div>
    ));
  };

  return (
    <>
      <div className="prog-grid-2">
        <h4>My Programs</h4>
        <p>Keep track of the programs you’re working on.</p>
      </div>

      <div className="row my-prog-card"></div>

      <div className="mt-4 prog-nav-search-box">
        <nav>
          <div
            className="nav nav-tabs nav-fill nav-tabs-scrollable"
            id="nav-tab"
            role="tablist"
          >
            <button
              className={classNames("nav-link", {
                active: filter.status === STATUS.ACTIVE,
              })}
              type="button"
              onClick={() => handleFilterByStatus(STATUS.ACTIVE)}
            >
              <span>Active</span>
              {counts.hasOwnProperty("active") && (
                <span className="tab-counts-badge">{counts.active}</span>
              )}
            </button>

            <button
              className={classNames("nav-link", {
                active: filter.status === STATUS.COMPLETED,
              })}
              type="button"
              onClick={() => handleFilterByStatus(STATUS.COMPLETED)}
            >
              <span>Completed</span>
              {counts.hasOwnProperty("completed") && (
                <span className="tab-counts-badge">{counts.completed}</span>
              )}
            </button>

            <button
              className={classNames("nav-link", {
                active: filter.status === STATUS.ARCHIVED,
              })}
              type="button"
              onClick={() => handleFilterByStatus(STATUS.ARCHIVED)}
            >
              <span>Archived</span>
              {counts.hasOwnProperty("archived") && (
                <span className="tab-counts-badge">{counts.archived}</span>
              )}
            </button>

            <button
              className={classNames("nav-link", {
                active: filter.status === STATUS.ALL,
              })}
              type="button"
              onClick={() => handleFilterByStatus(STATUS.ALL)}
            >
              <span>All</span>
              {counts.hasOwnProperty("all") && (
                <span className="tab-counts-badge">{counts.all}</span>
              )}
            </button>
          </div>
        </nav>
        <div>
          <div className="input-group rounded organization-search-box">
            <input
              type="search"
              className="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              onChange={(e) => debouncedSearch(e)}
            />
            <span className="input-group-text border-0" id="search-addon">
              <FontAwesomeIcon
                icon={faSearch}
                className="search-icon"
              ></FontAwesomeIcon>
            </span>
          </div>
        </div>
      </div>

      <InfiniteScroll
        next={() => handleScrolled()}
        hasMore={hasMore}
        loader={<LoadingPlaceholder />}
        dataLength={programs.length ?? 0}
        className="row my-prog-card"
        endMessage={
          programs?.length > 16 && (
            <div className="text-center py-3 text-muted">
              You have reached the end of page.
            </div>
          )
        }
      >
        {programs?.map((program) => (
          <div className="col col-sm-6 col-md-4 col-lg-3" key={program.id}>
            <MyProgramCard program={program} />
          </div>
        ))}
      </InfiniteScroll>

      {!loading && !programs?.length && (
        <NoDataCard
          title="Sorry no program found!"
          text="Try adjusting your filter. If you have not used any programs yet, go to Program Library to browse programs"
        />
      )}
    </>
  );
}

export default MyProgram;
