import { useState } from "react";
import Errors from "../../utils/Errors";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DeactivateOrganizationSection = ({ id, onStatusChange }) => {
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const MySwal = withReactContent(Swal);

  const handleSubmit = async () => {
    const result = await MySwal.fire({
      title: <p className="text-center">Deactivate organization</p>,

      html: `<div>
              <div>Are you sure to Deactivate this organization?</div>
            </div>`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) {
      return;
    }

    api
      .post(`deactivate-organization/${id}`)
      .then((res) => {
        toast.success('organization Deactivated')
        onStatusChange && onStatusChange();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return false;
        }
        toast.error("Server Error");
        return false;
      });
  };

  return (
    <section className="bg-white border-danger mt-4" style={{ maxWidth: "900px", border: '1px dashed' }}>
      <div className="p-4">
        <div>
          <h4 className="font-secondary fw-bolder mb-3 text-danger">Danger Zone</h4>
        </div>

        <div className="text-muted">
          <p>Users will not be able to access any of the programs from this organization.</p>
        </div>

        <div>
          <button
            className="btn btn-outline-danger rounded-1 px-4"
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={processing}
          >
            {processing ? "Please Wait" : "Deactivate Organization"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default DeactivateOrganizationSection;
