import { useContext } from "react";
import { loginAsAdmin } from "../../utils/Admin";
import { AuthContext } from "../../context/AuthContext";
import "../../assets/css/organization-header.css";

const LoggedInAsOrganizationTopbar = () => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();

  return (
    <header className="p-3 organization-header d-flex justify-content-center align-items-center">
      <div>You are logged in as admin of {user?.organization?.name}.</div>
      <div className="flex-shrink-0">
        <button type="button" onClick={loginAsAdmin} className="btn">
          Back to Admin
        </button>
      </div>
    </header>
  );
};

export default LoggedInAsOrganizationTopbar;
