import { toast } from "react-toastify";
import api from "../api";

export function loginAsOrganization(userId) {
  api
    .post(`login-as-organization/${userId}`)
    .then((response) => {
      if (response.data.token && response.data.user) {
        localStorage.setItem("original_token", localStorage.getItem("token"));
        localStorage.setItem("original_user", localStorage.getItem("user"));

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        toast.success("Logged in as organization");
        window.location.href = "/organization-dashboard";
        return;
      }
      toast.error("Something went wrong");
    })
    .catch((error) => toast.error("Something went wrong"));
}

export function loginAsAdmin() {
  // Copy the backed up original details
  localStorage.setItem("token", localStorage.getItem("original_token"));
  localStorage.setItem("user", localStorage.getItem("original_user"));
  // Remove the copied details of admin
  localStorage.removeItem("original_token");
  localStorage.removeItem("original_user");

  toast.success("Logged in as admin");
  window.location.href = "/";
}

export function isLoggedInAsOrganization() {
  if (
    localStorage.getItem("original_token") &&
    localStorage.getItem("original_user")
  ) {
    return true;
  }
  return false;
}
