import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaCheckCircle, FaCrown } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const UpgradeAccountModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleHide = () => {
    setModalOpen(false);
  };

  const redirectToSubscribePage = () => {
    handleHide();
    navigate('/subscribe');
  }

  useEffect(() => {
    const showModalHandler = () => {
      setModalOpen(true);
    };

    window.addEventListener("showUpgradeModal", showModalHandler);
  }, []);

  return (
    <Modal show={modalOpen} onHide={handleHide} centered>
      <Modal.Body>
        <div className="p-3">
          <div className="d-flex gap-2">
            <h3 className="mb-3">Upgrade Plan</h3>
            <FaCrown size="2rem" className="text-primary" />
          </div>

          <div className="d-flex flex-column gap-2">
            <p>You have already used one professional program. To use unlimited program please upgrade your plan.</p>
          </div>

          <div className="mt-3">
            <button type="button" onClick={redirectToSubscribePage} className="btn btn-primary btn-lg">
              Upgrade
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Feature = ({ title }) => {
  return (
    <div>
      <FaCheckCircle className="me-2 text-primary" /> <span>{title}</span>
    </div>
  );
};

export default UpgradeAccountModal;
