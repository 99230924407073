import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function ProgramStartOrContinuebutton({ program }) {
  if (program.progress.completedCount < 1) {
    return (
      <Link to={`/continue-program/${program.id}`} className="start-continue-program-btn">
          <div>Start Program</div>
          <FontAwesomeIcon
            icon={faArrowRight}
            color="#FFFFFF"
            style={{ fontSize: 12 }}
          ></FontAwesomeIcon>
      </Link>
    );
  }

  return (
    <Link to={`/continue-program/${program.id}`} className="start-continue-program-btn">
      <div>Continue Program</div>
      <FontAwesomeIcon
        icon={faArrowRight}
        color="#FFFFFF"
        style={{ fontSize: 12 }}
      ></FontAwesomeIcon>
    </Link>
  );
}
