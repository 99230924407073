import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../assets/css/session-expired-modal.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function SessionExpiredModal() {
  const { hasTokenExpired, setTokenExpired } = useContext(AuthContext);

  const handleLoginClick = () => {
    setTokenExpired(false);
    window.location.href = "/login";
  };

  return (
    <Modal show={hasTokenExpired} className="session-expired-modal " centered>
      <Modal.Body className="bg-white rounded-2">
        <div className="p-4">
          <div class="d-flex gap-4" role="alert">
            <div>
              <div className="icon-wrap">
                {/* <FontAwesomeIcon icon={faWarning} className="text-warning fa-" /> */}
                <svg
                  className="warning-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>
              </div>
            </div>
            <div>
              <h4 class="fw-normal">Your session has expired</h4>
              <p className="text-muted">
                Please log in again to continue using the app.
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-1">
            <button
              type="button"
              onClick={handleLoginClick}
              className="btn btn-outline-dark rounded-1"
            >
              Log in
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
