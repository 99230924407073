import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";
import { toast } from "react-toastify";
import LoadingBlock from "../../../Components/LoadingBlock";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import TabHeading from "./TabHeading";
import ContributorFormModal from "../wizard/ContributorFormModal";

function ContributorsTab() {
  const { programId } = useParams();
  const [loading, setLoading] = useState(false);
  const [contributors, setContributors] = useState([]);
  const [showAddContributorModal, setshowAddContributorModal] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    api
      .get(`program-contributors/${programId}`)
      .then((response) => {
        setContributors(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  }, [programId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddContributorModalHide = () => {
    setshowAddContributorModal(false);
  };

  const handleContributedRemoved = (id) => {
    setContributors((prevContributors) => {
      const newData = prevContributors.filter(
        (contributor) => contributor.id !== id
      );
      return newData;
    });
  };

  return (
    <main className="edit-prompts-page pt-3" style={{ maxWidth: "1000px" }}>
      <section className="d-flex justify-content-between align-items-center mb-3">
        <TabHeading title="Contributors"></TabHeading>
        <div>
          <button
            type="button"
            className="btn btn-outline-white rounded-1"
            onClick={() => setshowAddContributorModal(true)}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="text-green fa-lg me-2"
            />
            <span>Add New</span>
          </button>
        </div>
      </section>

      {loading && (
        <div className="py-5 text-center">
          <LoadingBlock />
        </div>
      )}

      {!loading && (
        <section>
          <div className="row">
            {contributors?.map((contributor) => (
              <div className="col-md-12" key={contributor.id}>
                <ContributorCard
                  contributor={contributor}
                  onRemove={() => handleContributedRemoved(contributor.id)}
                  onEdit={fetchData}
                />
              </div>
            ))}
          </div>

          <ContributorFormModal
            show={showAddContributorModal}
            onHide={handleAddContributorModalHide}
            programId={programId}
            onChange={fetchData}
          ></ContributorFormModal>
        </section>
      )}

      {!loading && contributors.length === 0 && (
        <div className="mt-5 d-flex justify-content-center align-items-center flex-column gap-3">
          <div>There are no contributors added yet.</div>
          <div>
            <button
              type="button"
              className="btn btn-outline-white rounded-1"
              onClick={() => setshowAddContributorModal(true)}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-green fa-lg me-2"
              />
              <span>Add New</span>
            </button>
          </div>
        </div>
      )}
    </main>
  );
}

const ContributorCard = ({ contributor, onEdit, onRemove }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const ReactSwal = withReactContent(Swal);

  const handleRemove = async (id) => {
    const result = await ReactSwal.fire({
      title: <h4 className="font-secondary">Sure to remove?</h4>,
      html: `<div class="text-center">Are you sure you want to remove this contributor?</div>`,
      showCancelButton: true,
      confirmButtonText: "Yes, Remove",
      cancelButtonText: "Cancel",
    });

    if (!result.isConfirmed) {
      return;
    }

    api
      .delete(`program-contributors/${contributor.id}`)
      .then((res) => {
        toast.success("Prompt deleted");
        onRemove();
      })
      .catch((error) => toast.error("Something went wrong"));
  };

  return (
    <article className="panel__white mb-3" key={contributor.id}>
      <div className="d-flex align-items-center gap-3">
        <div>
          <img
            style={{ height: "5rem", width: "5rem", borderRadius: "50%" }}
            src={contributor.avatar}
            alt=""
          />
        </div>
        <div className="flex-grow-1 d-flex justify-content-between">
          <div>
            <h5 className="mb-2 font-secondary">{contributor.name}</h5>
            <div>
              <a
                href={`mailto:${contributor.email}`}
                target="_blank"
                rel="noreferrer"
              >
                {contributor.email}
              </a>
            </div>
          </div>
          <div className="text-end">
            <div>{contributor.contact}</div>
            <a
              href={contributor.url}
              className="d-inline-block text-truncate"
              style={{ maxWidth: "30ch" }}
              target="_blank"
              rel="noreferrer"
            >
              {contributor.url}
            </a>
          </div>
        </div>
      </div>
      <p className="my-3 text-muted">{contributor.bio}</p>
      <div className="mt-3">
        <div className="d-flex gap-3">
          <button
            type="button"
            className="btn btn-outline-white rounded-1 px-4"
            onClick={() => setShowEditModal(true)}
          >
            <FontAwesomeIcon icon={faEdit} className="text-green me-2" />
            <span>Edit</span>
          </button>
          <button
            type="button"
            className="btn btn-outline-white rounded-1 px-4"
            onClick={() => handleRemove()}
          >
            <FontAwesomeIcon icon={faTrashCan} className="text-red me-2" />
            <span>Remove</span>
          </button>
        </div>
      </div>
      <ContributorFormModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        programId={contributor.programId}
        contributor={contributor}
        onChange={onEdit}
      ></ContributorFormModal>
    </article>
  );
};

export default ContributorsTab;
