import { useNavigate, useParams } from "react-router-dom";
import "./../../../assets/scss/authorize_user_page.scss";
import AuthorizeUserModal from "../wizard/partials/AuthorizeUserModal";
import { useContext, useEffect, useState } from "react";
import AuthorizedUserListing from "../wizard/partials/AuthorizedUserListing";
import api from "../../../api";
import WizardNavigation from "./WizardNavigation";
import NoAuthorizedUserBlock from "../wizard/partials/NoAuthorizedUserBlock";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";

const AuthorizeUserTab = () => {
  const { programId } = useParams();
  const navigate = useNavigate();
  const [showAuthorizeUserModal, setShowAuthorizeUserModal] = useState(false);
  const [users, setUsers] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [programAccessType, setProgramAccessType] = useState(null);
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();

  useEffect(() => {
    api
      .get(`program-authorization/get-access-type/${programId}`)
      .then((response) => {
        setProgramAccessType(response.data);
        if (response.data === "INVITATION_ONLY") {
          fetchUsers();
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const fetchUsers = () => {
    setLoadingUsers(true);
    api
      .get(`program-authorization/get-authorized-users/${programId}`)
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingUsers(false));
  };

  // useEffect(() => {
  //   fetchUsers();
  // }, []);

  const revokeAccess = (userId) => {
    api
      .post(`program-authorization/revoke-access/${programId}`, {
        user_id: userId,
      })
      .then((res) => {
        toast.success("Removed user from program");
        setUsers((prevUserData) => {
          const newUserData = prevUserData.filter((user) => user.id !== userId);

          return {
            newUserData,
          };
        });
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return;
        }
        toast.error("Error");
      });
  };

  const InvitationSection = () => {
    return (
      <>
        <section className="add-program4-grid-1">
          <div className="add-program4-grid-1-1">
            <h5>Authorized Users</h5>
            <p>Users who are authorized to use this program.</p>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary rounded-1"
              onClick={() => setShowAuthorizeUserModal(true)}
            >
              Add Users
            </button>
          </div>
        </section>

        <div className="add-program4-grid">
          <AuthorizeUserModal
            show={showAuthorizeUserModal}
            onHide={() => {
              setShowAuthorizeUserModal(false);
            }}
            programId={programId}
            onUsersAdded={fetchUsers}
          />

          {users?.length > 0 && (
            <AuthorizedUserListing
              users={users}
              onRevokeAccess={revokeAccess}
            />
          )}
          {!loadingUsers && !users?.length && <NoAuthorizedUserBlock />}
        </div>
      </>
    );
  };

  return (
    <main>
      {programAccessType === "INVITATION_ONLY" ? (
        <InvitationSection />
      ) : (
        <div className="mt-4">
          <div className="no-authorized-user-block">
            {user.role === "orgadmin"
              ? "This program is available to all members"
              : "This program is available to the world"}
          </div>
        </div>
      )}
    </main>
  );
};

export default AuthorizeUserTab;
