import { useContext, useRef } from "react";
import api from "../../../api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../context/AuthContext";

const OrganizationLogoUpload = () => {
  const { getAuthUser, syncUserInformation } = useContext(AuthContext);
  const user = getAuthUser();
  const organization = getAuthUser().organization;
  const inputFile = useRef(null);

  const handleLogoChange = (e) => {
    const files = e.target.files;
    if (files) {
      const formData = new FormData();
      formData.append("logo", files[0], files[0].name);
      api
        .post(`save-logo/${organization.id}`, formData)
        .then((res) => {
          toast.success("Logo Changed !");
          syncUserInformation();
        })
        .catch((error) => {
          toast.error("something went wrong !");
        });
    }
  };

  const handleChoose = () => {
    inputFile.current.click();
  };

  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="mx-auto" style={{ aspectRatio: "1/1", maxWidth: "6rem" }}>
          {organization.logo_url && (
            <img
              src={organization.logo_url}
              style={{ width: "100%", height: "100%" }}
              alt={organization.name}
              onClick={handleChoose}
            />
          )}
        </div>

        <input
          type="file"
          style={{ display: "none" }}
          ref={inputFile}
          onChange={handleLogoChange}
          accept="image/*"
        />
        <button
          className="mt-4 setting-grid-3-5"
          onClick={handleChoose}
          type="button"
        >
          <FontAwesomeIcon
            icon={faCirclePlus}
            color="#529C00"
            style={{ fontSize: 18 }}
          ></FontAwesomeIcon>
          <h5>Choose Photo</h5>
        </button>
      </div>
    </div>
  );
};

export default OrganizationLogoUpload;
