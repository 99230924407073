import { useNavigate, useParams } from "react-router-dom";
import LoadingBlock from "../../../Components/LoadingBlock";
import { useEffect } from "react";
import api from "../../../api";

function ContinueMasterProgram() {
  const { masterProgramId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    api(`continue-master-program/${masterProgramId}`).then((response) => {
      let programId = response.data.programId;
      let entryId = response.data.entryId;
      navigate(`/programs/${programId}/entries/${entryId}`);
    });
  }, []);

  return <LoadingBlock />;
}

export default ContinueMasterProgram;
