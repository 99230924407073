import SideBar from "./sidebar";
import Header from "./header";
import "../../assets/css/app.css";
import { Outlet, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Suspense, useContext, useEffect, useState } from "react";
import UpgradeAccountModal from "../Modals/UpgradeAccountModal";
import classNames from "classnames";
import LoggedInAsOrganizationTopbar from "./LoggedInAsOrganizationTopbar";
import { isLoggedInAsOrganization } from "../../utils/Admin";
import LoadingSpinnerBlock from "../LoadingSpinnerBlock";
import { AuthContext } from "../../context/AuthContext";

function AuthLayout({ children }) {
  const [showSideBar, setShowSideBar] = useState(false);
  const location = useLocation();
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    setShowSideBar(false);
  }, [location]);

  return (
    <div
      className={classNames("app__container", {
        authenticated: isLoggedIn(),
        guest: !isLoggedIn(),
      })}
    >
      {showSideBar === false ? (
        <button
          type="button"
          className="bar-btn"
          onClick={() => setShowSideBar(true)}
        >
          <FaBars className="bar" />
        </button>
      ) : null}
      {showSideBar === true ? (
        <button
          type="button"
          className="x-btn"
          onClick={() => setShowSideBar(false)}
        >
          <IoClose className="xmark" />
        </button>
      ) : null}

      <aside
        className={classNames("app__sidebar", {
          "sidebar-show": showSideBar,
        })}
      >
        <SideBar setShowSideBar={setShowSideBar} />
      </aside>

      <main className="main">
        {isLoggedInAsOrganization() && <LoggedInAsOrganizationTopbar />}
        <Header />
        <div className="p-3">
          <Suspense fallback={<LoadingSpinnerBlock />}>
            <Outlet />
          </Suspense>
        </div>
      </main>
      <UpgradeAccountModal />
    </div>
  );
}

export default AuthLayout;
