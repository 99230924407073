import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import ProgramForm from "../wizard/ProgramForm";
import WizardNavigation from "./WizardNavigation";

function EditProgramPage() {
  const { id } = useParams();
  const { data: program, loading } = useFetch(
    `program-wizard/edit-program/${id}`
  );

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <>
      <WizardNavigation programId={id} active="program-detail" />

      {program && <ProgramForm program={program} isWizard={false} />}
    </>
  );
}

export default EditProgramPage;
