import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  convertToRaw,
  convertFromRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function BasicTextEditor({ value, onChange }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!value) {
      return;
    }
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(value)
    );
    const state = EditorState.createWithContent(contentState);
    setEditorState(state);
  }, []);

  async function handleEditorStateChange(state) {
    await setEditorState(state);
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange(data);
  }

  // https://github.com/jpuri/react-draft-wysiwyg/issues/295#issuecomment-391041673
  // For a full options
  // https://github.com/jpuri/react-draft-wysiwyg/blob/master/docs/src/components/Demo/EditorStyledToolbar/index.js
  const toolbarOptions = {
    options: ["inline", "list"],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleEditorStateChange}
      wrapperClassName="rich-editor basic"
      editorClassName=""
      toolbarClassName="bg-light"
      toolbar={toolbarOptions}
      stripPastedStyles={true}
    />
  );
}

export default BasicTextEditor;
