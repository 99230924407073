import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../api";
import Errors from "../../utils/Errors";
import { toast } from "react-toastify";
import LoadingBlock from "../../Components/LoadingBlock";
import ReactDatePicker from "react-datepicker";

const EditOrganizationForm = ({ organization, id }) => {
  const [state, setState] = useState({
    email: organization.email,
    name: organization.name,
    contact: organization.contact,
    address: organization.address,
    allowed_users_count: organization.allowed_users_count,
    licensed_until: organization.licensed_until,
  });
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    console.log(state);
  }

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    api
      .post(`/edit-organization/${id}`, state)
      .then((res) => {
        toast.success("Organization Updated !");
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
          return;
        }
        toast.error("Server error. Please try again");
      })
      .finally(() => setLoading(false));
  }

  if (processing) {
    return <LoadingBlock></LoadingBlock>;
  }

  return (
    <section className="bg-white border" style={{ maxWidth: "900px" }}>
      <div className="p-4">
        <div>
          <h4 className="font-secondary fw-bolder mb-3">General</h4>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3 pt-0">
            <label class="form-label">Organization Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              controlid="name"
              value={state.name}
              onChange={(e) => handleInputChange(e)}
              placeholder="Organization Name"
            />
            {errors.has("name") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("name")}
              </div>
            )}
          </div>

          <div className="col-md-6 mb-3">
            <label class="form-label">Email</label>
            <input
              className="form-control"
              type="email"
              placeholder="Email address"
              controlid="email-id"
              name="email"
              value={state.email}
              onChange={(e) => handleInputChange(e)}
            />
            {errors.has("email") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("email")}
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Address</label>
            <input
              className="form-control"
              type="text"
              name="address"
              controlid="address"
              value={state.address}
              onChange={(e) => handleInputChange(e)}
              placeholder="Address"
            />
            {errors.has("address") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("address")}
              </div>
            )}
          </div>

          <div className="col-md-6 mb-3">
            <label className="form-label">Contact</label>
            <input
              className="form-control"
              type="text"
              name="contact"
              controlid="contact-id"
              value={state.contact}
              onChange={(e) => handleInputChange(e)}
              placeholder="Contact"
            />
            {errors.has("contact") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("contact")}
              </div>
            )}
          </div>
        </div>

        {/* <div className="mb-3">
            <label className="form-label">No. of users allowed</label>
            <input
              className="form-control"
              type="number"
              name="allowed_users_count"
              controlid="allowed_users_count"
              value={state.allowed_users_count}
              onChange={(e) => handleInputChange(e)}
              min={1}
              placeholder="Allowed Users Count"
            />
            {errors.has("allowed_users_count") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("allowed_users_count")}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">Licensed Until</label>
            <ReactDatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              {...(state.licensed_until
                ? {
                    selected: Date.parse(state.licensed_until),
                  }
                : {})}
              onChange={(date) =>
                setState({ ...state, licensed_until: date })
              }
              wrapperClassName="d-block"
              showIcon
            />
            {errors.has("licensed_until") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("licensed_until")}
              </div>
            )}
          </div> */}

        <div>
          <Button
            className="btn btn-primary rounded-1 px-4"
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Please Wait" : "Update"}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default EditOrganizationForm;
