import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const LicenseAlert = () => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();
  const organization = user.organization;
  const licenseRenewalDate = organization.licensed_until.raw;
  const currentDate = new Date();
  const expiryDateObj = new Date(licenseRenewalDate);
  const timeDifference = expiryDateObj - currentDate;
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference <= 0) {
    // Expired
    return (
      <div class="alert alert-danger py-2" role="alert">
        <div className="d-flex gap-2 justify-content-between align-items-center">
          <div className="d-flex gap-2 align-items-center">
            <div className="bg- p-2 rounded-1">
              <FontAwesomeIcon icon={faBan} />
            </div>
            <div>Your subscription has expired.</div>
          </div>
          <button type="button" className="btn btn-light btn-sm rounded-1 px-2">
            Purchase
          </button>
        </div>
      </div>
    );
  }

  if (daysDifference <= 15) {
    // Expiring soon
    return (
      <div class="alert alert-warning" role="alert">
        Your subscription will expire in {daysDifference} days.
      </div>
    );
  }
};

export default LicenseAlert;
