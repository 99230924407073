import React, { useState, useEffect, useContext } from "react";
import "../../assets/css/login-modal.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import api, { guestApi } from "../../api";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { sendVerificationEmail } from "../../utils/User";

export default function LoginForm() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const { login } = useContext(AuthContext);
  const { state: locationState } = useLocation();
  let intendedRoute = "";

  if (locationState) {
    const { redirectTo } = locationState;
    if (redirectTo?.pathname) {
      intendedRoute = `${redirectTo.pathname}${redirectTo?.search}`;
    }
  }

  useEffect(() => {
    setErrorMessage("");
  }, [email, password]);

  function redirectUserBasedOnRole(user) {
    if (user.role === "superadmin") {
      navigate("/dashboard");
      return;
    } else if (user.role === "orgadmin") {
      navigate("/organization-dashboard");
      return;
    }

    navigate("/");
  }

  function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);
    api
      .post("login", {
        email: email,
        password: password,
      })
      .then(async (response) => {
        await login(response.data.token, response.data.user);

        let invitationToken = localStorage.getItem("invitation_token");
        //console.log('inv token' + invitationToken);
        if (invitationToken) {
          window.location.href = `/accept-invitation/${invitationToken}`;
        } else {
          // We are delaying the navigation to provide time for the
          // useEffect hook setting up the axios request interceptor
          setTimeout(() => {
            // Redirect for intended route
            handleRedirection(response.data.user);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.error) {
          setErrorMessage(error.response.data.error);
          return;
        }
        setErrorMessage("Credentials do not match!!");
      })
      .finally(() => setProcessing(false));
  }

  const handleRedirection = (user) => {
    // check to see if the user has pending invitations
    try {
      api
        .get("invitation/my-invitations")
        .then((response) => {
          let invitations = response.data.data;
          if (invitations.length > 0) {
            let firstInvitation = invitations[0];
            navigate(`/accept-invitation/${firstInvitation.token}`);
            return;
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }

    // Redirect to intended route if available
    if (intendedRoute) {
      navigate(intendedRoute);
      return;
    }

    redirectUserBasedOnRole(user);
  };

  const resendVerificationEmail = () => {
    if (email) {
      sendVerificationEmail(email).then((response) =>
        navigate(`/verify-email/?email=${encodeURIComponent(email)}`)
      );
    }
  };

  const ErroMessage = () => {
    if (errorMessage === "Account not verified") {
      return (
        <div className="alert alert-danger">
          <h5 class="alert-heading">Account not verified</h5>
          <p>
            If you are having problem verifying you account,
            <button
              type="button"
              onClick={resendVerificationEmail}
              className="d-inline btn-link m-0 p-0"
            >
              Resend Verification Email
            </button>
          </p>
        </div>
      );
    }

    if (errorMessage) {
      return <div className="alert alert-danger">{errorMessage}</div>;
    }
  };

  return (
    <div className=" p-4 ">
      <div className="login-grid-1">
        <div className="right-arrow-center">
          <FontAwesomeIcon
            className="right-arrow"
            icon={faArrowRightToBracket}
            color="#fff"
            style={{ fontSize: 30 }}
          ></FontAwesomeIcon>
        </div>
        <h5>LOG IN</h5>
        <p>You can login to your email here.</p>
      </div>

      <ErroMessage />

      <form onSubmit={(e) => handleSubmit(e)}>
        <Form.Group className="mb-3 login-grid-2" controlId="formBasicEmail">
          <Form.Control
            className="mb-3"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
          />
        </Form.Group>

        <Form.Group className="mb-3 login-grid-2" controlId="formBasicPassword">
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </Form.Group>

        {/* <Stack className="mt-4 gap-1">
          <div>Use this credentials</div>
          <div>User:admin@admin.com</div>
          <div>password:password</div>
        </Stack> */}
        <div className="forget-box">
          <Link to="/forgot-password" className="forget-btn">
            Forgot Password? Click here to Reset
          </Link>
        </div>
        <Form.Group className="mb-3">
          {processing ? (
            <Button className="login-button">Please wait...</Button>
          ) : (
            <Button className="login-button" type="submit">
              Login
            </Button>
          )}
        </Form.Group>

        <div className="login-btn">
          <div className="group">
            <p>Don't Have an Account yet?</p>
            {intendedRoute ? (
              <Link
                to={`/register?redirectTo=${intendedRoute}`}
                className="signup-btn"
              >
                Register
              </Link>
            ) : (
              <Link to={`/register`} className="signup-btn">
                Register
              </Link>
            )}
          </div>
          <p className="center">
            Create account and start using programs relevant to your personal
            development.
          </p>
        </div>
      </form>
    </div>
  );
}
