import { useContext, useState } from "react";
import { ButtonSpinner } from "../../Components/Encap";
import api from "../../api";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import classNames from "classnames";

const InvitationNotificationItem = ({ invitation }) => {
  const [processing, setProcessing] = useState(false);
  const [declining, setDeclining] = useState(false);
  const [responded, setResponded] = useState(false);
  const { syncUserInformation } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);

  const handleAccept = () => {
    setProcessing(true);
    api
      .post(`invitation/accept/${invitation.token}`)
      .then((response) => {
        syncUserInformation();
        setResponded(true);
        MySwal.fire({
          icon: "success",
          title: <div class="font-secondary">Invitation Accepted</div>,
          html: `<div class="font-secondary">
              <div>You will be granted access to the programs owned by ${invitation.organization_name}</div>
            </div>`,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return false;
        }
        toast.error("Something went wrong while accepting invitation");
      })
      .finally(() => setProcessing(false));
  };

  const handleDecline = async () => {
    setDeclining(true);

    const result = await MySwal.fire({
      title: "Are you sure?",
      html: `<div>
            <div>Are you sure you do not want to accept this invitation?</div>
          </div>`,
      showCancelButton: true,
      confirmButtonText: "Yes, Decline Invitation",
      confirmButtonColor: "#f33f3f",
      cancelButtonText: "Cancel",
    });

    if (!result.isConfirmed) {
      setDeclining(false);
      return;
    }

    api
      .post(`invitation/decline/${invitation.token}`)
      .then((response) => {
        setResponded(true);
        toast.success("Invitation Declined");
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return false;
        }
        toast.error("Something went wrong. Please try again.");
      })
      .finally(() => setDeclining(false));
  };

  return (
    <div
      className={classNames("invitation-notification", {
        "d-none": responded,
      })}
    >
      <div>
        You have been invited to join{" "}
        <span className="fw-bold">{invitation.organization_name}</span>.
      </div>
      <div className="d-flex gap-2">
        <button
          className="btn btn-primary px-3 rounded-1"
          onClick={handleAccept}
          disabled={processing}
        >
          <ButtonSpinner processing={processing} />
          Accept
        </button>
        <button
          className="btn btn-danger px-3 rounded-1"
          onClick={handleDecline}
          disabled={declining}
        >
          <ButtonSpinner processing={declining} />
          Decline
        </button>
      </div>
    </div>
  );
};

export default InvitationNotificationItem;
