import { NavLink, useNavigate } from "react-router-dom";
import "../../../assets/css/wiz_nav.css";
import PublicationStatus from "./PublicationStatus";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  DeleteProgramAction,
  PublishProgramAction,
  UnpublishProgramAction,
} from "../../../utils/Program";

function WizardNavigation({ program, onProgramChange }) {
  const [isPublished, setIsPublished] = useState(program.is_published);
  const navigate = useNavigate();
  const ReactSwal = withReactContent(Swal);

  const publishProgram = () => {
    PublishProgramAction(program.id).then((res) => {
      onProgramChange();
      setIsPublished(true);
    });
  };

  const unpublishProgram = () => {
    UnpublishProgramAction(program.id).then((res) => {
      onProgramChange();
      setIsPublished(false);
    });
  };

  const deleteProgram = async () => {
    const result = await ReactSwal.fire({
      title: <p className="text-start">Confirm delete</p>,
      html: `<div class="text-start">
                  <div>Are you sure you want to delete this program?</div>
                </div>`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    });

    if (!result.isConfirmed) {
      return;
    }

    DeleteProgramAction(program.id).then((res) => {
      if (res) {
        navigate("/programs");
      }
    });
  };

  return (
    <nav className="wizard-nav">
      <div className="d-flex justify-content-between align-self-center">
        <h4 className="mb-4 fw-bolder font-secondary">
          Edit Program: {program.program_name}
        </h4>
        <div>
          <PublicationStatus program={program} />
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <nav className="nav nav-tabs" role="tablist">
          <NavLink to="." className="nav-link" end>
            Overview
          </NavLink>
          <NavLink to="prompts" className="nav-link">
            Prompts
          </NavLink>
          <NavLink to="entries" className="nav-link">
            Entries
          </NavLink>
          <NavLink to="completion" className="nav-link">
            Program Completion
          </NavLink>
          <NavLink to="authorized-users" className="nav-link">
            Authorized Users
          </NavLink>
          <NavLink to="contributors" className="nav-link">
            Contributors
          </NavLink>
        </nav>
        <div className="d-flex align-items-center gap-3">
          {!isPublished && (
            <button
              type="button"
              className="btn btn-outline-white rounded-1 px-3"
              onClick={publishProgram}
            >
              <FontAwesomeIcon icon={faBookmark} className="text-green me-2" />
              <span>Publish</span>
            </button>
          )}

          {isPublished && (
            <button
              type="button"
              className="btn btn-outline-white rounded-1 px-3"
              onClick={unpublishProgram}
            >
              <FontAwesomeIcon icon={faUndo} className="text-red me-2" />
              <span>Unpublish</span>
            </button>
          )}

          <button
            type="button"
            className="btn btn-outline-red rounded-1 px-3"
            onClick={deleteProgram}
          >
            <FontAwesomeIcon icon={faTrash} className="me-2" />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </nav>
  );
}

export default WizardNavigation;
