import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../../api";
import { useState } from "react";
import { toast } from "react-toastify";

const PremadeProgramCard = ({ program }) => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  const handleSelection = () => {
    setProcessing(true);
    api
      .post(`program-wizard/create-program-from-template/${program.id}`)
      .then((response) => {
        let newProgram = response.data;
        toast.success('Program Used Sucessfully !');
        navigate(`/programs/${newProgram.id}/edit`);
      })
      .catch((error) => console.error(error))
      .finally(() => setProcessing(false));
  };

  return (
    <div className="col col-sm-6 col-md-4 col-lg-3">
      <div className="organization-prog-grid-3-1 card">
        <div className="imgBox">
          <img src={program.cover_image_url} className="img" alt="img" />
        </div>

        <div className="program-card-body">
          {program.category && <h6 className="training">{program.category}</h6>}
          {/* <Link to={`/programs/${program.id}`}> */}
            <h5>
              {program.program_name && program.program_name.length > 20
                ? `${program.program_name.substring(0, 20)}...`
                : program.program_name}
            </h5>
          {/* </Link> */}

          <div className="addprogram-1a-grid-2-1 d-none">
            <div className="addprogram-1a-grid-2-2">
              <h5>13</h5>
              <h6>steps</h6>
            </div>
            <div className="addprogram-1a-grid-2-2" style={{ marginLeft: 20 }}>
              <h5>23</h5>
              <h6>Weeks</h6>
            </div>
          </div>
          {/* <Link to={`/programs/${program.id}/edit`}> */}
          <div className="library-grid-3-1">
            <Button
              type="button"
              className="d-inline-flex btn"
              onClick={handleSelection}
              disabled={processing}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="#529C00"
                style={{ fontSize: 25 }}
              ></FontAwesomeIcon>
              <h5>Select This Program</h5>
            </Button>
          </div>

          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default PremadeProgramCard;
