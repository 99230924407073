import {
  faCheck,
  faCircleXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import VideoAnswer from "../Answer/VideoAnswer";
import WrittenAnswer from "../Answer/WrittenAnswer";
import "../../assets/css/answer_modal.css";
import { useEffect, useState } from "react";
import api from "../../api";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import DownloadAppMiniBanner from "../Answer/DownloadAppMiniBanner";

function AdditionalEntryModal({ show, onHide, program, prompt = null }) {
  const navigate = useNavigate();
  const [title, setTitle] = useState("My Entry"); // TODO get it from API
  const [hasUnsavedVideo, setHasUnsavedVideo] = useState(false);
  const [hasUnsavedWrittenAnswer, setHasUnsavedWrittenAnswer] = useState(false);
  const MySwal = withReactContent(Swal);

  const handleHide = async () => {
    if (hasUnsavedVideo || hasUnsavedWrittenAnswer) {
      const result = await MySwal.fire({
        title: <p className="text-start">Discard changes</p>,
        html: `<div class="text-start">
                <div>You have made changes that haven't been saved yet. Are you sure to discard changes?</div>
              </div>`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!result.isConfirmed) {
        return;
      }
    }
    onHide && onHide();
  };

  const submit = async (type, value) => {
    let data = {
      type,
      title: title,
      prompt_id: prompt?.id ?? null,
    };

    switch (type) {
      case "written":
        data.written_answer = value;
        break;

      case "video":
        data.video_file_id = value;
        break;

      default:
        throw new Error("Answer type must be either written or video");
    }

    try {
      const promise = api.post(`additional-entry/${program.id}`, data);

      toast.promise(promise, {
        pending: "Submitting answer ",
        success: "Answer has bee added.",
        error: "Error while adding this answer !",
      });

      const res = await promise;

      setHasUnsavedWrittenAnswer(false);
      setHasUnsavedVideo(false);

      // const event = new CustomEvent("answerSubmitted");
      // window.dispatchEvent(event);

      const entryId = res.data.entry_id;
      navigate(`/programs/${program.id}/entries/${entryId}`);
      onHide && onHide();
    } catch (error) {
      toast.error(error.mesage);
    }
  };

  const handleWrittenAnswerSubmit = (answer) => {
    submit("written", answer);
  };

  const handleVideoAnswerSubmit = (videoId) => {
    submit("video", videoId);
  };

  useEffect(() => {
    const handleVideoRecordingStartedEvent = () => {
      setHasUnsavedVideo(true);
    };
    window.addEventListener(
      "videoRecordingStarted",
      handleVideoRecordingStartedEvent
    );

    const handleWrittenAnswerChangedEvent = () => {
      setHasUnsavedWrittenAnswer(true);
    };
    window.addEventListener(
      "writtenAnswerChanged",
      handleWrittenAnswerChangedEvent
    );

    return () => {
      window.removeEventListener(
        "videoRecordingStarted",
        handleVideoRecordingStartedEvent
      );
      window.removeEventListener(
        "writtenAnswerChanged",
        handleVideoRecordingStartedEvent
      );
    };
  });

  return (
    <Modal onHide={handleHide} show={show} size="lg" centered>
      <Modal.Header className="close-button">
        <div className="exercise-popup-header">
          <div className="exercise-popup-1">
            {/* <EditableTitle entry={entry} /> */}
            <input
              type="text"
              style={{
                backgroundColor: "transparent",
                outline: "none",
                border: 0,
                padding: "10px 10px 10px 5px",
                color: "#fff",
                borderBottom: "1px solid  whitesmoke",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="exercise-popup-close-btn"
              onClick={() => handleHide()}
            ></FontAwesomeIcon>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="exercise-popup">
          <div className="exercise-popup-5">
            {prompt && (
              <div className="exercise-popup-2">
                <p>
                  Here are some prompts to get you started (don’t worry, you can
                  have them show up while recording too).
                </p>
              </div>
            )}

            <div className="exercise-popup-3">
              <div className="exercise-popup-3">
                <ul>
                  {prompt &&
                    prompt.questions.map((question) => (
                      <li key="question">{question}</li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="exercise-popup-4">
              <p>
                You can either record a video response, or type in your answers.
              </p>
            </div>

            <nav>
              <div
                className="nav nav-tabs nav-fill"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-video-answer-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-video-answer"
                  type="button"
                  role="tab"
                  aria-controls="nav-video-answer"
                  aria-selected="true"
                >
                  Video Entry
                </button>
                <button
                  className="nav-link"
                  id="nav-written-answer-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-written-answer"
                  type="button"
                  role="tab"
                  aria-controls="nav-written-answer"
                  aria-selected="false"
                >
                  Written Entry
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-video-answer"
                role="tabpanel"
                aria-labelledby="nav-video-answer-tab"
                tabIndex="0"
              >
                <div className="exercise-popup-5">
                  <VideoAnswer
                    prompt={prompt}
                    onSubmit={(videoFileId) =>
                      handleVideoAnswerSubmit(videoFileId)
                    }
                    onCancel={handleHide}
                  />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-written-answer"
                role="tabpanel"
                aria-labelledby="nav-written-answer-tab"
                tabIndex="0"
              >
                <div className="mb-3" style={{ marginTop: 20 }}>
                  <WrittenAnswer
                    prompt={prompt}
                    onSubmit={(answer) => {
                      console.log("sbmitting", answer);
                      handleWrittenAnswerSubmit(answer);
                    }}
                    onCancel={handleHide}
                  />
                </div>
              </div>
            </div>

            <DownloadAppMiniBanner />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// function EditableTitle({ entry }) {
//   const [title, setTitle] = useState(entry.title);
//   const [editMode, setEditMode] = useState(false);

//   const saveTitle = () => {
//     api
//       .post(`update-entry-title/${entry.id}`, {
//         title,
//       })
//       .then((res) => {
//         toast.success("Title saved");
//       });
//     setEditMode(false);
//   };

//   return (
//     <div className="d-inline-flex align-items-center gap-2">
//       {editMode ? (
//         <input
//           type="text"
//           className="form-control"
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//         />
//       ) : (
//         <div className="text-white">{title}</div>
//       )}
//       <button
//         type="button"
//         className={classNames("btn btn-link", { "d-none": editMode })}
//         onClick={() => setEditMode(true)}
//       >
//         <FontAwesomeIcon icon={faEdit} className="text-white" />
//       </button>
//       <button
//         type="button"
//         className={classNames("btn btn-light", { "d-none": !editMode })}
//         onClick={saveTitle}
//       >
//         <FontAwesomeIcon icon={faCheck} />
//       </button>
//     </div>
//   );
// }

export default AdditionalEntryModal;
