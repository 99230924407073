import classNames from "classnames";
import api from "../../api";
import { useNavigate } from "react-router-dom";

const NotificationItem = ({ notification }) => {
  const navigate = useNavigate();

  const handleClick = async () => {
    await api.post(`notifications/mark-read/${notification.id}`).then().catch();

    if (notification?.data?.action?.web) {
      navigate(notification.data.action.web);
    }
  };

  return (
    <button
      type="button"
      className={classNames("notification", {
        unread: !notification.read_at,
      })}
      onClick={handleClick}
    >
      <div>
        <div className="notification-time">{notification.created_at.human}</div>
        <div className="notification-title">{notification.data.title}</div>
        <div className="notification-body">{notification.data.body}</div>
      </div>
      {notification.data.image && (
        <div>
          <div className="notification-image-container">
            <img src={notification.data.image} alt="" />
          </div>
        </div>
      )}
    </button>
  );
};

export default NotificationItem;
