import { Button, Modal } from "react-bootstrap";

const PrivacyPolicy = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Privacy Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <p>
            The Time Capsule Corporation Pty Ltd trading as“Encapsulator” ABN 16
            623 084 248 (“the Encapsulator”, “us”, “we”, or “our”) operates the
            website&nbsp;
            <a href="https://encapsulator.io" target="_blank" rel="noreferrer">
              https://encapsulator.io
            </a>
          </p>
          <p>
            This page informs you of our policies regarding the collection, use
            and disclosure of Personal Information when you use our Service.
          </p>
          <p>
            We will not use or share your information with anyone except as
            described in this Privacy Policy.
          </p>
          <p>
            We use your Personal Information for providing and improving the
            Service. By using the Service, you agree to the collection and use
            of information in accordance with this policy. Unless otherwise
            defined in this Privacy Policy, terms used in this Privacy Policy
            have the same meanings as in our Terms and Conditions, accessible
            at&nbsp;
            <a href="https://encapsulator.io" target="_blank" rel="noreferrer">
              https://encapsulator.io
            </a>
          </p>
          <p>
            If you need to get into contact with us,&nbsp;admin@encapsulator.io
          </p>
          <p>
            <strong>Information Collection and Use</strong>
            <br />
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally, identifiable information may include, but
            is not limited to, your email address, name, phone number, postal
            address and date of birth (“Personal Information”).
            <br />
            The purpose for which we collect personal information is to provide
            you with the best service experience possible on the Service and for
            our internal business purposes that form part of normal business
            practices. Some provision of personal information is optional.
            However, if you do not provide us with certain types of personal
            information, you may be unable to enjoy the full functionality of
            the Service.
            <br />
            Your personal information may be used for the purposes of sending
            newsletters or communications due to the subject matter of the
            website.
          </p>
          <p>
            <strong>Log Data</strong>
            <br />
            We may also collect information that your browser sends whenever you
            visit our Service (“Log Data”). This Log Data may include
            information such as your computer’s Internet Protocol (“IP”)
            address, browser type, browser version, the pages of our Service
            that you visit, the time and date of your visit, the time spent on
            those pages and other statistics.
            <br />
            In addition, we may use third-party services, that collect, monitor
            and analyse this type of information in order to increase our
            Service’s functionality.
            <br />
            These third-parties may use cookies to help us analyse how our users
            are using the Service and they have their own Privacy Policies
            addressing how they use such information.
          </p>
          <p>
            <strong>
              Legal Basis for Processing Personal Data Under General Data
              Protection Regulation (GDPR)
            </strong>
            <br />
            If you are from the European Economic Area (EEA), Encapsulator legal
            basis for collecting and using the personal information described in
            this Privacy Policy depends on the Personal Data we collect and the
            specific context in which we collect it.
            <br />
            We may process your Personal Data because:
            <br />• We need to perform a contract with you
            <br />• You have given us permission to do so
            <br />• The processing is in our legitimate interests and it’s not
            overridden by your rights
            <br />• To comply with the law
          </p>
          <p>
            <strong>Service Providers</strong>
            <br />
            We may employ third-party companies and individuals to facilitate
            our Service, to provide the Service on our behalf, to perform
            Service-related services or to assist us in analysing how our
            Service is used.
            <br />
            These third parties have access to your Personal Information only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <p>
            <strong>Communications</strong>
            <br />
            We may use your Personal Information to contact you with
            newsletters, marketing or promotional materials and other
            information that may be of interest to you. You may opt out of
            receiving any, or all, of these communications from us by following
            the unsubscribe link or instructions provided in any email we send.
          </p>
          <p>
            <strong>Compliance with Laws</strong>
            <br />
            We may disclose personal information in special situations where we
            have reason to believe that doing so is necessary to identify,
            contact or bring legal action against anyone damaging, injuring or
            interfering (intentionally or unintentionally) with our rights or
            property, users or anyone else who could be harmed by such
            activities. We will disclose your Personal Information where
            required to do so by law or subpoena or if we believe that such
            action is necessary to comply with the law and the reasonable
            requests of law enforcement or to protect the security or integrity
            of our Service.
          </p>
          <p>
            <strong>Security</strong>
            <br />
            The security of your Personal Information is important to us but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
          <p>
            <strong>International Transfer</strong>
            <br />
            Your information, including Personal Information, may be transferred
            to video and data storage service provider whose online storage is
            maintained on computers located outside of your state, province,
            country or other governmental jurisdiction where the data protection
            laws may differ than those from your jurisdiction.
            <br />
            If you are located outside Australia and choose to provide
            information to us, please note that we transfer the information,
            including Personal Information, to Australia and process it there.
            <br />
            There may be an instance where work may be done overseas. In such
            circumstance, we shallexpressly inform you and that your consent may
            be collectedvoluntarily. We note that there are exceptions under
            Australian Privacy Principles (APPs) where your consent is
            unnecessary.
            <br />
            <br />
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement that your Personal
            Information will be used or disclosed for the purpose for which it
            was collected.
          </p>
          <p>
            <strong>
              Your Data Protection Rights Under General Data Protection
              Regulation (GDPR)
            </strong>
            <br />
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. Encapsulator aims to take reasonable
            steps to allow you to correct, amend, delete, or limit the use of
            your Personal Data.
            <br />
            If you wish to be informed what Personal Data we hold about you and
            if you want it to be removed from our systems, please contact us.
            <br />
            In certain circumstances, you have the following data protection
            rights:
            <br />
            • The right to access, update or to delete the information we
            have on you. Whenever made possible, you can access, update or
            request deletion of your Personal Data directly within your account
            settings section. If you are unable to perform these actions
            yourself, please contact us to assist you.
            <br />
            • The right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete.
            <br />
            • The right to object. You have the right to object to our processing
            of your Personal Data.
            <br />
            • The right of restriction. You have the right to request that we
            restrict the processing of your personal information.
            <br />
            • The right to data portability. You have the right to be provided
            with a copy of the information we have on you in a structured,
            machine-readable and commonly used format.
            <br />
            • The right to withdraw consent. You also have the right to withdraw
            your consent at any time where Encapsulator relied on your consent
            to process your personal information.
            <br />
            Please note that we may ask you to verify your identity before
            responding to such requests.
            <br />
            <br />
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>
          <p>
            <strong>Access and Correction</strong>
            <br />
            Australian Privacy Principle 1 of the Privacy Act 1988 (Cth) allows
            you to get access to, and correct, the personal information we hold
            about you in certain circumstances. If you would like to obtain such
            access, please contact us on the email address set out above.
            <br />
            Please note that the access and correction requirements under this
            Privacy Policy operates alongside and do not replace other informal
            or legal procedures by which an individual can be provided access
            to, or correction of, their personal information, including the
            requirements under the Freedom of Information Act 1982 (Cth).
          </p>
          <p>
            <strong>Complaints</strong>
            <br />
            Australian Privacy Principle 1 of the Privacy Act 1988 (Cth) allows
            you to make a complaint about any alleged breaches of privacy. In
            order to lodge a complaint with us, please contact us using the
            email address above with the following information:
            <br />
            <br />
            • Your name and address;
            <br />
            • Details of the alleged breach of privacy; and
            <br />
            • URL link to the alleged breach of privacy (if applicable).
            <br />
            <br />
            Please allow us 30 days to investigate your complaint, after which
            we will contact you immediately to resolve the issue.
          </p>
          <p>
            <strong>Retention of Information</strong>
            <br />
            We retain information for as long as required, allowed or we believe
            it useful, but do not undertake retention obligations. We may
            dispose of information in our discretion without notice, subject to
            applicable law that specifically requires the handling or retention
            of information. You must keep your own, separate back-up records.
          </p>
          <p>
            <strong>Links to Other Sites</strong>
            <br />
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third-party link, you will be directed to
            that third-party’s site. We strongly advise you to review the
            Privacy Policy of every site you visit.
            <br />
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third-party sites or
            services.
          </p>
          <p>
            <strong>Children’s Privacy</strong>
            <br />
            Our Service does not address anyone under the age of 18
            (“Children”).
            <br />
            We do not knowingly collect personally identifiable information from
            children under 18. If you are a parent or guardian and you are aware
            that your Children have provided us with Personal Information,
            please contact us. If we become aware that we have collected
            Personal Information from children under 18 without verification of
            parental consent, we take steps to remove that information from our
            servers or replace it with the Personal Information of the
            Children’s parent or guardian.
          </p>
          <p>
            <strong>Changes to This Privacy Policy</strong>
            <br />
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
            <br />
            If we make any material changes to this Privacy Policy, we will
            notify you either through the email address you have provided us, or
            by placing a prominent notice on our website.
          </p>
          <p>
            <strong>Consent</strong>
            <br />
            You warrant that you are able to give consents under Australian Law
            or, in the event that you do not have the capacity to give consent,
            you warrant that your guardian or attorney is able to give any
            consent required under this Privacy Policy on your behalf.
            <br />
            You hereby expressly and voluntarily grant your informed consent to
            us to deal with your personal information in accordance with the
            terms and conditions of this Privacy Policy. Should you retract your
            consent, please contact us. If you retract your consent, you
            acknowledge and agree that failure to provide certain types of
            personal information may not give you access to the full
            functionality of the Service.
          </p>
          <p>
            <strong>Contact Us</strong>
            <br />
            If you have any questions about this Privacy Policy, please contact
            us on the email address provided at the beginning of this document.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyPolicy;
