import { useNavigate } from "react-router-dom";
import AdditionalEntry from "./AdditionalEntry";
import EntryAnswerSection from "./EntryAnswerSection";
import ItemAvailableCountdown from "./ItemAvailableCountdown";
import EntryDetail from "./EntryDetail";
import EntryDetailAccordion from "./EntryDetailAccordion";

const ShowProgramEntryRightSection = ({ entry, program }) => {
  const navigate = useNavigate();

  if (!entry.isAvailable) {
    return (
      <div className="bg-white rounded py-5 px-4 text-center shadow-sm">
        <h3
          className="mb-4"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          Not available just yet
        </h3>
        {entry.available_at && (
          <ItemAvailableCountdown
            availableAt={entry.available_at}
            onReady={() => {
              navigate(0);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      {!program.isCompleted && (
        <div className="additional-entry-btn">
          <AdditionalEntry program={program} />
        </div>
      )}
      <div className="entry-detail-answer">
        {program.isCompleted && <EntryDetailAccordion entry={entry} />}

        {!program.isCompleted && (
          <EntryDetail entry={entry} program={program} />
        )}

        <EntryAnswerSection entry={entry} program={program} />
      </div>
    </div>
  );
};

export default ShowProgramEntryRightSection;
