import "../../../assets/scss/resources_preview.scss";
import ResourcePreviewItem from "./ResourcePreviewItem";

const ResourcesPreview = ({ resources }) => {
  return (
    <div className="d-flex flex-column gap-3">
      {resources.map((resource) => (
        <ResourcePreviewItem resource={resource} />
      ))}
    </div>
  );
};

export default ResourcesPreview;
