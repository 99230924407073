import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { addProgramToUserList } from "../../../utils/Program";

const StartProgramButton = ({ program }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, getAuthUser, syncUserInformation } =
    useContext(AuthContext);
  const user = getAuthUser();
  const MySwal = withReactContent(Swal);

  const cloneProgram = (programId) => {
    addProgramToUserList(programId)
      .then((res) => {
        syncUserInformation();
        navigate(`/my-programs/${res.data.program.id}`);
      })
      .catch((error) => {
        let errorCode = error?.response?.data?.error;
        console.log("catching", errorCode);
        if (errorCode) {
          switch (errorCode) {
            case "NOT_SUBSCRIBED":
              let event = new CustomEvent("showUpgradeModal");
              window.dispatchEvent(event);
              break;
            case "PROGRAM_NOT_COMPLETED":
              // toast.error("Program already started");
              navigate(`/continue-master-program/${programId}`);
              break;
            case "ORGANIZATION_LICENSE_EXPIRED":
              toast.error("Organizations's license has expired");
              break;
            default:
              toast.error("Something went wrong");
          }
        }
      });
  };

  async function handleStartProgram() {
    let programId = program.id;

    if (!isLoggedIn()) {
      toast.error("Please login or singup to use program");
      navigate("/login", { state: { redirectTo: location } });
      return;
    }

    if (
      !user.is_subscribed &&
      !program.is_free &&
      !user.first_paid_program_id
    ) {
      // Show confirmation modal
      const result = await MySwal.fire({
        title: <p className="text-start">Confirm Program</p>,
        html: `<div class="text-start">
                  <div>As a free user you only can use one professional program. Are you sure you want to use "${program.program_name}" as your free program</div>
                  <div class="mt-2 fst-italic">Note: You can upgrade your plan anytime to access unlimited programs.</div>
                  </div>`,
        showCancelButton: true,
        confirmButtonText: "Confirm",
      });
      if (!result.isConfirmed) {
        return;
      }
    }

    cloneProgram(programId);
  }

  return (
    <button
      type="submit"
      onClick={handleStartProgram}
      className="start-continue-program-btn"
    >
      <div>{!isLoggedIn() ? "Login to start" : "Start Program"}</div>
      <FontAwesomeIcon
        icon={faArrowRight}
        color="#FFFFFF"
        style={{ fontSize: 12 }}
      ></FontAwesomeIcon>
    </button>
  );
};

export default StartProgramButton;
