import { useEffect, useState } from "react";
import api from "../../../../api";
import ProgressBar from "../../../../Components/progressbar/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import LoadingBlock from "../../../../Components/LoadingBlock";
import { Link } from "react-router-dom";

const ProgramUsersList = ({ programId }) => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`get-program-users/${programId}`)
      .then((response) => {
        setUsers(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const canViewResponse = (user) => {
    return user.response_privacy === false ? true : false;
  };

  if (loading) {
    return <LoadingBlock>Loading..</LoadingBlock>;
  }

  if (!loading && !users.length) {
    return (
      <div className="bg-white border p-5 rounded-1 text-center">
        <h4 className="font-secondary">Not used yet</h4>
      </div>
    );
  }

  return (
    <div className="row">
      {users &&
        users.map((user, i) => (
          <div className="col-sm-4" key={i}>
            <div className="card">
              <div className="card-body">
                <p className="card-title">
                  {user.user.first_name} {user.user.last_name}
                </p>
                <ProgressBar
                  bgcolor="#529C00"
                  current={user.progress.completedCount}
                  total={user.progress.entriesCount}
                  progress={user.progress.completionPercentage}
                />
                {canViewResponse(user) && (
                  <Link
                    to={`/view-response/${user.program_id}`}
                    className="card-btn"
                  >
                    <p>View Response</p>
                    <FontAwesomeIcon
                      className="arrow-right-icon"
                      icon={faCircleArrowRight}
                    ></FontAwesomeIcon>
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProgramUsersList;
