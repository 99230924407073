export const ButtonSpinner = ({ processing }) => {
  if (!processing) {
    return <></>;
  }

  return (
    <span
      className="spinner-border spinner-border-sm me-2"
      role="status"
      aria-hidden="true"
    ></span>
  );
};
