import {
  faCircleArrowRight,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import "../../../assets/css/program_details.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingBlock from "../../../Components/LoadingBlock";
import {
  DeleteProgramAction,
  PublishProgramAction,
} from "../../../utils/Program";
import { hasRole } from "../../../utils/helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProgramUsersList from "./partials/ProgramUsersList";
import api from "../../../api";
import ProgramEnrollmentCount from "./partials/ProgramEnrollmentCount";
import moment from "moment";
import ProgramPublishText from "./partials/ProgramPublishText";

function ProgramDetail() {
  const { programId } = useParams();
  const navigate = useNavigate();
  const ReactSwal = withReactContent(Swal);
  const [program, setProgram] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`admin/programs/${programId}`)
      .then((response) => {
        setProgram(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // function PublishProgram(programId) {
  //   PublishProgramAction(programId).then((res) => {
  //     if (res) {
  //       window.location.reload();
  //     }
  //   });
  // }

  // async function DeleteProgram(programId) {
  //   const result = await ReactSwal.fire({
  //     title: <p className="text-start">Confirm delete</p>,
  //     html: `<div class="text-start">
  //             <div>Are you sure you want to delete this program?</div>
  //           </div>`,
  //     showCancelButton: true,
  //     confirmButtonText: "Delete",
  //     // cancelButtonText: "No",
  //   });

  //   if (!result.isConfirmed) {
  //     return;
  //   }

  //   DeleteProgramAction(programId).then((res) => {
  //     if (res) {
  //       navigate("/programs");
  //     }
  //   });
  // }

  function formatDate(date) {
    return moment(date).format("D MMM, YYYY");
  }

  if (loading || !program) {
    return <LoadingBlock>Loading..</LoadingBlock>;
  }

  return (
    <div className="management-container">
      <div className="row">
        <div className="col-sm-12">
          <div className="head">
            <div className="head-left">
              <p className="head-text-1">{program.program_name}</p>
              <p className="head-text-2">
                Created: {formatDate(program.created_at.raw)} | Modified:{" "}
                {formatDate(program.updated_at.raw)}
              </p>
            </div>
            {/* need to discuess if we need to show this section in detail, better to show this in program summary page */}

            <ProgramPublishText program={program} />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="subhead">
            <div className="subhead-left">
              <Link to={`/program-library/${program.slug}`}>
                <button className="button">
                  <FontAwesomeIcon icon={faCirclePlus} className="plus-icon" />
                  <p>View/Edit Program Details</p>
                </button>
              </Link>
            </div>
            <ProgramEnrollmentCount program={program} />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="left">
            <div className="left-1">
              <div className="sub-text">
                <p className="sub-text-1">Entries</p>
                <p className="sub-text-2">View and Edit entries</p>
              </div>
              <ul className="left-list">
                {program?.entries &&
                  program.entries.map((en, index) => (
                    <li className="button" key={index}>
                      <div className="left-list-text">
                        <p>{index + 1}. </p>
                        <p> {en.title}</p>
                      </div>
                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        className="arrow-right-icon"
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="right">
            <div className="right-1">
              <div className="sub-text">
                <p className="sub-text-1">User List</p>
                <p className="sub-text-2">
                  View user responses and manage enrollments.
                </p>
              </div>
              {/* <Nav variant="underline" defaultActiveKey="/active">
                <Nav.Item>
                  <Nav.Link href="/active">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>Completed</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>Removed</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>All</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="search-box">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
              </div> */}
            </div>
            <div className="right-2">
              <ProgramUsersList programId={programId} />
            </div>
            {/* <div className="mt-4 d-flex justify-content-center">
            <Pagination
              meta={programs.meta}
              onChange={(page) => fetchPrograms(page)}
            />
          </div> */}
            <div className="right-3 d-none">
              <button className="button">
                <div className="text">
                  <p className="text-1">Invite New Students</p>
                  <p className="text-2">
                    Invite a student to take this program
                  </p>
                </div>
                <FontAwesomeIcon icon={faCirclePlus} className="plus-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramDetail;
