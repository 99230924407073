import { ScaleLoader } from "react-spinners";

const LoadingBlock = ({ loading }) => {
  return (
    <div className="py-5 text-center">
      <ScaleLoader
        loading={loading}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoadingBlock;
