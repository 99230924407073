import { faLock, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addProgramToUserList } from "../../utils/Program";
import { useContext, useState } from "react";
import {} from "../../utils/helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthContext } from "../../context/AuthContext";
import classNames from "classnames";
import ProgramCoverImage from "./ProgramCoverImage";

const ProgramLibraryCard = ({ program }) => {
  const { isLoggedIn, getAuthUser, syncUserInformation } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [expandedIds, setExpandedIds] = useState([]);
  const user = getAuthUser();
  const MySwal = withReactContent(Swal);

  const getStartButtonText = () => {
    if (!isLoggedIn()) {
      return "Login to start";
    }

    return program.isAlreadyStarted ? "Continue Program" : "Start Program";
  };

  const toggleExpanded = (id) => {
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter((item) => item !== id));
    } else {
      setExpandedIds([...expandedIds, id]);
    }
  };

  const cloneProgram = (programId) => {
    addProgramToUserList(programId)
      .then((res) => {
        syncUserInformation();
        navigate(`/my-programs/${res.data.program.id}`);
      })
      .catch((error) => {
        let errorCode = error?.response?.data?.error;
        console.log("catching", errorCode);
        if (errorCode) {
          switch (errorCode) {
            case "NOT_SUBSCRIBED":
              let event = new CustomEvent("showUpgradeModal");
              window.dispatchEvent(event);
              break;
            case "PROGRAM_NOT_COMPLETED":
              // toast.error("Program already started");
              navigate(`/continue-master-program/${programId}`);
              break;
            case "ORGANIZATION_LICENSE_EXPIRED":
              toast.error("Organizations's license has expired");
              break;
            default:
              toast.error("Something went wrong");
          }
        }
      });
  };

  async function handleStartProgram() {
    let programId = program.id;

    // TODO::Instead use the helper method
    let userToken = localStorage.getItem("token");
    if (!userToken) {
      toast.error("Please login or singup to use program");
      navigate("/login");
      return;
    }

    if (
      !user.is_subscribed &&
      !program.is_free &&
      !user.first_paid_program_id
    ) {
      // Show confirmation modal
      const result = await MySwal.fire({
        title: <p className="text-start">Confirm Program</p>,
        html: `<div class="text-start">
              <div>As a free user you only can use one professional program. Are you sure you want to use "${program.program_name}" as your free program</div>
              <div class="mt-2 fst-italic">Note: You can upgrade your plan anytime to access unlimited programs.</div>
              </div>`,
        showCancelButton: true,
        confirmButtonText: "Confirm",
      });
      if (!result.isConfirmed) {
        return;
      }
    }

    cloneProgram(programId);
  }

  return (
    <div className="col col-sm-4 col-md-3">
      <div
        className={classNames("library-grid-3 card", {
          free: program.is_free,
          paid: !program.is_free,
          darken: isLoggedIn() && !user.is_subscribed && !program.is_free,
          "can-glow": isLoggedIn() && !user.first_paid_program_id,
        })}
      >
        {/* {isLoggedIn() && (
          <div className="program-type">
            {program.is_free && <p className="free">Lite</p>}
          </div>
        )} */}
        <Link to={`/program-library/${program.slug}`}>
          <div className="imgBox">
            <ProgramCoverImage program={program} className="img" />
          </div>
        </Link>
        <div className="cardBody">
          {program.category && <h6>{program.category}</h6>}
          <Link to={`/program-library/${program.slug}`}>
            <div className="cardBody-1">
              <p className="title">
                {isLoggedIn() && !program.is_free && !user.is_subscribed  && (
                  <span>
                    <svg
                      className="d-inline-block me-2"
                      data-slot="icon"
                      fill="none"
                      strokeWidth="2"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                      ></path>
                    </svg>
                  </span>
                )}
                <span>{program.program_name}</span>
              </p>
            </div>
          </Link>
          <p className="title2">{program.organization_name}</p>
          <div className="cardBody-2">
            <div className="desc">
              {program.short_description &&
              program.short_description.length > 70 ? (
                <div>
                  {expandedIds.includes(program.id) ? (
                    <>
                      {program.short_description}
                      <button onClick={() => toggleExpanded(program.id)}>
                        Show less
                      </button>
                    </>
                  ) : (
                    <>
                      {program.short_description.substring(0, 70)}...
                      <button onClick={() => toggleExpanded(program.id)}>
                        Show more
                      </button>
                    </>
                  )}
                </div>
              ) : (
                program.short_description
              )}
            </div>
          </div>
          <div className="library-grid-3-1">
            <Button
              type="button"
              role="button"
              className="d-inline-flex btn"
              onClick={(e) => {
                e.preventDefault();
                handleStartProgram();
              }}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="green"
                style={{ fontSize: 26 }}
              ></FontAwesomeIcon>
              <h5>{getStartButtonText()}</h5>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramLibraryCard;
