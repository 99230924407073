import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AnswerModal from "../../../../Components/Modals/AnswerModal";
import React, { useEffect, useState } from "react";
import { submitMarkedAnswer } from "../../../../utils/Answer";
import { reactHtmlEntities } from "../../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faTrashAlt,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import { PROMPT_TYPE } from "../../../../const/Entry";
import PromptSelectionModal from "./PromptSelectionModal";
import ViewVideoAnswer from "./ViewVideoAnswer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { async } from "videojs-record";
import api from "../../../../api";
import { RemoveAnswer } from "../../../../utils/Program";
import NotApplicable from "../../../../Components/Answer/NotApplicable";

const EntryAnswerSection = React.memo(({ entry, program }) => {
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [prompt, setPrompt] = useState();
  const [question, setQuestion] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);

  const [promptSelectionModal, setShowPromptSelectionModal] = useState(false);

  const MySwal = withReactContent(Swal);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  const [form, setForm] = useState({
    type: "mark_completed",
    entry_id: entry.id,
  });

  // useEffect(() => {
  //   function handleModalEvent() {
  //     console.log("event listened");
  //     setShowAnswerModal(true);
  //   }
  //   window.addEventListener("answerSubmitted", handleModalEvent);
  //   console.log('event listener created');
  //   // window.addEventListener("answerSubmitted",setShowAnswerModal(true));
  //   return () => {
  //     window.removeEventListener("answerSubmitted", handleModalEvent);
  //   };
  // }, []);

  useEffect(() => {
    const addAnswer = urlParams.get("addAnswer");
    if (addAnswer === "true" && !entry.answer) {
      console.log("auto opening modal");
      setShowAnswerModal(true);
    }

    // Set the question and prompt for selected prompt type
    if (entry.prompt_type === PROMPT_TYPE.SELECTED && entry.prompts?.length) {
      setQuestion(entry.prompts[0].title);
      setPrompt(entry.prompts[0]);
    }

    // If already answered we will show the used prompt question
    if (entry?.answer?.prompt_id) {
      let prompt = entry.prompts.find((prompt) => {
        return prompt.id === entry.answer.prompt_id;
      });
      setQuestion(prompt.title);
    }

    window.addEventListener("open-answer-modal", handleGiveAnswer);

    return () => {
      setShowAnswerModal(false);
      window.removeEventListener("open-answer-modal", handleGiveAnswer);
    };
  }, []);

  function submitCompletedAnswer() {
    submitMarkedAnswer(form).then((response) => {
      window.location.reload();
    });
  }

  // TODO::Cleanup after verification
  function showPromptVal(e) {
    let prompt = entry.prompts.find((prompt) => {
      return prompt.id == e.target.value;
    });
    setPrompt(prompt);
  }

  function handleGiveAnswer() {
    // We will pause the entry's intro video when this modal is shown
    let event = new CustomEvent("videoPlaybackChange", {
      detail: {
        name: "entry_intro_video",
        playing: false,
      },
    });
    dispatchEvent(event);

    // if answer type is any and prompts available, we need to allow selecting prompts
    if (entry.prompt_type === PROMPT_TYPE.ANY) {
      if (entry.prompts.length) {
        setShowPromptSelectionModal(true);
        return;
      }
    }
    setShowAnswerModal(true);
  }

  async function handleRemoveAnswer() {
    const result = await MySwal.fire({
      title: <p className="text-start">Confirmation</p>,
      html: `<div>
              <div>Are you sure to delete this entry ?</div>
            </div>`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) {
      return;
    }
    let ansId = entry.answer.id;

    RemoveAnswer(ansId).then((res) => {
      if (res) {
        window.location.reload();
      }
    });
  }

  const handlePromptSelected = (id) => {
    let prompt = entry.prompts.find((prompt) => {
      return prompt.id == id;
    });
    setPrompt(prompt);
    setShowPromptSelectionModal(false);
    setShowAnswerModal(true);
  };

  const handlePromptSelectionclose = () => {
    setShowPromptSelectionModal(false);
  };

  return (
    <section className="entry-ans-section">
      {/* Question section removed on client's request */}
      {/* {question && !program.isCompleted && (
        <div className="exercise-question">
          <h5>Question:</h5>
          <p>{question}</p>
        </div>
      )} */}

      {entry.answer && (
        <section>
          <div className="exercise-question">
            <h5>Your Entry</h5>
            {/* TODO::What to do for ANY where we have option to choose from prompts  */}
            <p>{reactHtmlEntities(entry.answer.written_answer)}</p>
          </div>
          {entry.answer.video_response_url && (
            // <div className="mb-3 entry-video-answer">
            //   <ReactPlayer
            //     url={entry.answer.video_response_url}
            //     controls={true}
            //     pip={true}
            //   />
            // </div>
            <ViewVideoAnswer answer={entry.answer} />
          )}
        </section>
      )}
      {!(entry.answering_options === "NO_RESPONSE_REQUIRED") && (
        <div className="exercise-btn">
          {!entry.isCompleted && (
            <div className="mt-5 text-center">
              <Button className="add-entry-btn" onClick={handleGiveAnswer}>
                <FontAwesomeIcon
                  className="exercise-plus-icon"
                  icon={faPlus}
                  color="#FFFFFF"
                ></FontAwesomeIcon>

                <span>Add your entry</span>
              </Button>

              <NotApplicable
              entry={entry}
                onSubmit={(answer) => alert(answer)}
              />
            </div>
          )}

          {!program.isCompleted && entry.isCompleted && (
            <Button className="btn-danger" onClick={handleRemoveAnswer}>
              <FontAwesomeIcon
                className="exercise-trash-icon"
                icon={faTrashCan}
                color="#FFFFFF"
              ></FontAwesomeIcon>

              <span className="remove-entry">Remove entry</span>
            </Button>
          )}
        </div>
      )}

      {/* TODO::Review this */}
      {entry.answering_options === "NO_RESPONSE_REQUIRED" &&
        (entry.isCompleted ? (
          <Button
            type="button"
            className="completed"
            disabled
            onClick={submitCompletedAnswer}
          >
            Mark as completed
          </Button>
        ) : (
          <Button
            type="button"
            className="completed"
            onClick={submitCompletedAnswer}
          >
            Mark as completed
          </Button>
        ))}

      <AnswerModal
        show={showAnswerModal}
        onHide={() => setShowAnswerModal(false)}
        entry={entry}
        prompt={prompt}
      />

      <PromptSelectionModal
        show={promptSelectionModal}
        prompts={entry.prompts}
        onPromptSelected={handlePromptSelected}
        onPromptSelectionClose={handlePromptSelectionclose}
      />
    </section>
  );
});

export default EntryAnswerSection;
