import { useContext, useEffect, useState } from "react";
import "../../../src/assets/css/ForgotPassword.css";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Errors from "../../utils/Errors";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";
import "../../assets/scss/forgot_password.scss";
import { ButtonSpinner } from "../../Components/Encap";
import OTPInput from "react-otp-input";
import classNames from "classnames";
import {
  setNewPassword,
  submitEmail,
  verifyOTP,
} from "../../utils/ForgotPassword";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faLock, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const STAGE = {
  EMAIL: "email",
  OTP: "otp",
  FINAL: "final",
  SUCCESS: "success",
};

export default function ForgotPassword() {
  const [stage, setStage] = useState(STAGE.EMAIL);
  const { login } = useContext(AuthContext);
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const [allowResendOTP, setAllowResendOTP] = useState(false);
  const [resendingOTP, setResendingOTP] = useState(false);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "admin@admin.com",
    otp: "",
    password: "",
    password_confirmation: "",
  });

  const otpInputStyle = {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "0.25rem",
    border: "1px solid #ced4da",
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setState({ ...state, [name]: value });
  };

  function handleSubmitEmail(e) {
    setProcessing(true);
    submitEmail(state.email)
      .then((response) => {
        setStage(STAGE.OTP);
        setTimeout(() => {
          setAllowResendOTP(true);
        }, 30000);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => setProcessing(false));
  }

  const handleVerifyOTP = () => {
    setProcessing(true);
    verifyOTP(state.email, state.otp)
      .then((response) => {
        setStage(STAGE.FINAL);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => setProcessing(false));
  };

  const handleResendOTP = () => {
    setResendingOTP(true);
    submitEmail(state.email)
      .then((response) => {
        toast.success("Verication code has been sent");
        setAllowResendOTP(false);
        setTimeout(() => {
          setAllowResendOTP(true);
        }, 30000);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => setResendingOTP(false));
  };

  const handleSetNewPassword = async () => {
    setProcessing(true);

    setNewPassword(state)
      .then(async (response) => {
        if (response.data.token && response.data.user) {
          login(response.data.token, response.data.user);
          await MySwal.fire({
            title: <p className="text-center">Password Changed !</p>,
            html: `<div>
                  <div>Your password has been changed successfully.</div>
                </div>`,
            icon: "success",
            confirmButtonText: "Back to My Programs",
          });

          navigate("/my-programs");
        }
        setStage(STAGE.SUCCESS);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => setProcessing(false));
  };

  const handleApiError = (error) => {
    if (error?.response?.data?.errors) {
      console.error(error);
      setErrors(new Errors(error.response.data.errors));
      return;
    }
    toast.error("Server Error");
  };

  useEffect(() => setErrors(new Errors()), [state]);

  const LockIcon = () => {
    return (
      <div className="step-icon-wrap">
        <div className="step-icon">
          <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
        </div>
      </div>
    );
  };

  return (
    <section className="mt-5">
      <div className="d-flex justify-content-center">
        <Form
          onSubmit={(e) => e.preventDefault()}
          autoComplete="off"
          className="p-4"
        >
          <div className="forget-password">
            {stage === STAGE.EMAIL && (
              <section>
                <LockIcon />
                <h5 className="step-heading">Forgot Password</h5>
                <p className="text-muted text-center my-4">
                  Please enter your email address to receive a verification
                  code.
                </p>
                <div className="my-4">
                  {/* <label htmlFor="" className="form-label font-secondary">
                     Email Address
                   </label> */}
                  <input
                    type="email"
                    name="email"
                    className={classNames(
                      "form-control form-control-lg rounded-1",
                      {
                        "is-invalid": errors.has("email"),
                      }
                    )}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                  />
                  {errors.has("email") && (
                    <div className="d-block invalid-feedback">
                      {errors.first("email")}
                    </div>
                  )}
                </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg rounded-1 px-5 font-secondary"
                    onClick={handleSubmitEmail}
                    disabled={processing}
                  >
                    <ButtonSpinner processing={processing} />
                    Send verification code
                  </button>
                </div>
              </section>
            )}

            {stage === STAGE.OTP && (
              <section>
                <LockIcon />
                <h5 className="step-heading">Verification</h5>
                <p className="text-muted">
                  Enter your 6 digits code sent to{" "}
                  <strong>{state.email}</strong>
                </p>
                <div className="my-4">
                  {/* <label htmlFor="" className="form-label font-secondary">
                Please enter the OTP code here
              </label> */}
                  <OTPInput
                    value={state.otp}
                    onChange={(value) => setState({ ...state, otp: value })}
                    numInputs={6}
                    renderSeparator={<span className="mx-2"></span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className={classNames("py-3", {
                          "border-danger": errors.has("otp"),
                        })}
                      />
                    )}
                    inputStyle={otpInputStyle}
                    inputType="text"
                  />
                  {errors.has("otp") && (
                    <div className="mt-2 text-danger">
                      {errors.first("otp")}
                    </div>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary rounded-1 px-5 font-secondary fw-bolder"
                    onClick={handleVerifyOTP}
                    disabled={processing || !state.otp}
                  >
                    Verify Code
                  </button>

                  <p className="mt-4 text-muted text-center">
                    Please check your spam folder if you don't receive your
                    email in 5 minutes.
                    {allowResendOTP && (
                      <button
                        type="button"
                        className="resend-otp-btn d-inline"
                        onClick={handleResendOTP}
                        disabled={resendingOTP}
                      >
                        <ButtonSpinner processing={resendingOTP} />
                        Resend
                      </button>
                    )}
                  </p>
                </div>
              </section>
            )}

            {stage === STAGE.FINAL && (
              <section>
                <LockIcon />
                <h5 className="step-heading">Create New Password</h5>
                <div className="password-help-text mt-1 text-muted">
                  Minimum 8 characters,Uppercase & lowercase
                  characters(A-Z),Digits(0-9)
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="" className="form-label font-secondary">
                Password
              </label> */}
                  <input
                    type="password"
                    name="password"
                    className={classNames("form-control rounded-1", {
                      "is-invalid": errors.has("password"),
                    })}
                    onChange={handleInputChange}
                    placeholder="New password"
                  />
                  {errors.has("password") && (
                    <div className="d-block invalid-feedback">
                      {errors.first("password")}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="" className="form-label font-secondary">
                Confirm Password
              </label> */}
                  <input
                    type="password"
                    name="password_confirmation"
                    className="form-control rounded-1"
                    onChange={handleInputChange}
                    placeholder="Confirm password"
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSetNewPassword}
                    disabled={processing}
                  >
                    <ButtonSpinner processing={processing} />
                    Register
                  </button>
                </div>
              </section>
            )}
             <div className="mt-4 text-center">
              <Link to="/login" style={{ color: "#0f5c93" }}><FontAwesomeIcon icon={faArrowLeft}/> Back to Login</Link>
            </div>
          </div>
        </Form>
      </div>
    </section>
  );
}
