import { guestApi } from "../api";

export const submitEmail = async (email) => {
  return guestApi.post("register/onboarding/email", { email });
};

export const verifyOTP = (email, otp) => {
  return guestApi.post("register/onboarding/verify-otp", { email, otp });
};

export const resendOTP = (email) => {
  return guestApi.post("register/onboarding/resend-otp", { email });
};

export const completeRegistration = (payload) => {
  return guestApi.post("register/onboarding/complete", payload);
};
