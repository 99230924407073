import { Link } from "react-router-dom";
import "./../../assets/scss/page_not_found.scss";

function PageNotFound() {
  return (
    <section className="page__not__found">
      <div className="page__not__found__content">
        <h4>404</h4>
        <h6>Page Not Found</h6>
        <Link to="/" className="btn btn-primary rounded-1 fw-bold">
          Go to Home
        </Link>
      </div>
    </section>
  );
}

export default PageNotFound;
