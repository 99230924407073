export const ACCESS_TYPE = {
  IMMEDIATE: "IMMEDIATE",
  TIMED: "TIMED",
};

export const PROMPT_TYPE = {
  NONE: "NONE",
  ANY: "ANY",
  SELECTED: "SELECTED",
};

export const ANSWERING_OPTION = {
  TEXT_AND_VIDEO: "TEXT_AND_VIDEO",
  TEXT_ONLY: "TEXT_ONLY",
  VIDEO_ONLY: "VIDEO_ONLY",
  NO_RESPONSE_REQUIRED: "NO_RESPONSE_REQUIRED",
};
