import { createContext, useEffect, useState } from "react";
import api from "../api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [hasTokenExpired, setTokenExpired] = useState(false);

  const login = async (token, user) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    setToken(token);
    setUser(user);
    console.log("user logged in");
  };

  const logout = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("original_token");
    localStorage.removeItem("original_user");
    setToken(null);
    setUser(null);
  };

  const isLoggedIn = () => {
    return token != null;
  };

  const getAuthUser = () => {
    return user;
  };

  const syncUserInformation = async () => {
    console.log("Syncing user information");
    if (!token) {
      return;
    }
    await api.get("get-auth-user-info").then((response) => {
      const user = response.data.data;
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    });
  };

  useEffect(() => {
    // To prevent redirect loop, exclude login and register routes
    const { pathname: currentPath } = window.location;
    const excludePaths = ["/login", "/register", "/accept-invitation"];
    const isExcluded = excludePaths.some((excludePath) =>
      currentPath.startsWith(excludePath)
    );

    if (!isExcluded) {
      syncUserInformation();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        login,
        logout,
        isLoggedIn,
        getAuthUser,
        syncUserInformation,
        hasTokenExpired,
        setTokenExpired
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
