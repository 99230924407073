import {useState , useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { AuthContext } from "../../../context/AuthContext";
import "./../../../assets/scss/my_badges.scss";

const MyBadges = () => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();
  const { data: badges } = useFetch(`/user/${user.id}/badges`);
  const [showAllBadges, setShowAllBadges] = useState(false);

 
  const maxVisibleBadges = 4;

  const toggleShowAllBadges = () => {
    setShowAllBadges((prevShowAllBadges) => !prevShowAllBadges);
  };


  const displayedBadges = Array.isArray(badges)
    ? showAllBadges
      ? badges
      : badges.slice(0, maxVisibleBadges)
    : [];

  return (
    <>
      {displayedBadges.length > 0 && (
        <div className="setting-grid-3-3">
          <h6 className="col-sm-2 label">Your Badges</h6>
          <div className="col-md-10">
          <div className="my-badges">
            {displayedBadges.map((badge, i) => (
              <div className="badge-item" key={i}>
                <div className="badge-square">

                <img
                  className="badge-icon"
                  src={badge.badge_url}
                  height={40}
                  width={40} 
                  alt={badge.program}
                  />
                  </div>
                <div className="badge-title">{badge.program}</div>
              </div>
            ))}
          </div>
          </div>
          {badges && badges.length > maxVisibleBadges && (
            <div className="see-more-less">
              <span onClick={toggleShowAllBadges}>
                {showAllBadges ? "See Less" : "See More"}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MyBadges;