import { useState } from "react";
import {
  faCloudUploadAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../api";
import { toast } from "react-toastify";
import "../../../assets/scss/badge-upload.scss";
import BADGE_PLACECHOLDER from "../../../assets/image/badge-placeholder.jpg";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { async } from "videojs-record";

const BadgeUpload = ({ program }) => {
  const [badgeUrl, setBadgeUrl] = useState(program?.badge);
  const MySwal = withReactContent(Swal);

  const handleBadgeChnage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setBadgeUrl(URL.createObjectURL(file));
    program.badge = URL.createObjectURL(file);
    if (file) {
      const formData = new FormData();
      formData.append("file", file, file.name);
      api
        .post(`/save-program-badge/${program.id}`, formData)
        .then((response) => {
          toast.success("Badge uploaded");
        })
        .catch((error) => {
          toast.error("something went wrong !");
        });
    }
  };

  const handleRemove = async () => {
    const result = await MySwal.fire({
      title: <p className="text-center">Confirmation</p>,

      html: `<div>
            <div>Are you sure to remove this badge?</div>
          </div>`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) {
      return;
    }

    api
      .delete(`remove-program-badge/${program.id}`)
      .then((res) => {
        toast.success("Badge removed");
        setBadgeUrl();
      })
      .catch((error) => toast.error("Something went wrong"));
  };

  return (
    <div className="badge-uploader mb-5">
      <div>
        <img src={badgeUrl ?? BADGE_PLACECHOLDER} alt="Program Badge" />
      </div>
      <div className="d-flex flex-column gap-3">
        <label className="upload-btn" type="button">
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleBadgeChnage}
            accept="image/*"
          />
          <FontAwesomeIcon icon={faCloudUploadAlt}></FontAwesomeIcon>
          <span>Upload badge</span>
        </label>
        {badgeUrl && (
          <button
            className="remove-badge-btn"
            type="button"
            onClick={handleRemove}
          >
            <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
            <span>Remove Badge</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default BadgeUpload;
