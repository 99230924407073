import { useState } from "react";
import { Modal } from "react-bootstrap";
import Errors from "../../../utils/Errors";
import api from "../../../api";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../../Components/Encap";

const RequestSeatsModal = ({ show, onHide }) => {
  const [state, setState] = useState({
    seats: 0,
  });
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);

  const submit = () => {
    setProcessing(true);
    api
      .post(`request-additional-seats`, {
        seats: state.seats,
      })
      .then((response) => {
        toast.success("Your request has been submitted");
        onHide();
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          toast.error("Validation Failed");
          return;
        }
        console.log(error);
        if (error?.response?.data?.error) {
          toast.error("Server error: " + error.response.data.error);
          return;
        }
        toast.error("Server Error");
        console.log(error);
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="p-4 bg-white rounded">
          <div className="d-flex gap-2">
            <h4 className="mb-3 font-secondary fw-bolder">
              Request Additional Seats
            </h4>
          </div>

          <div className="mb-3">
            <label className="mb-1">Number of seats</label>
            <input
              type="number"
              className="form-control"
              value={state.seats}
              onChange={(e) => setState({ ...state, seats: e.target.value })}
              required
            />
          </div>

          <div className="d-flex justify-content-end gap-2">
            <button
              type="button"
              className="btn btn-light rounded-1 font-secondary"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-1 font-secondary fw-bolder"
              onClick={submit}
              disabled={processing}
            >
              <ButtonSpinner processing={processing} />
              <span>Request</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestSeatsModal;
