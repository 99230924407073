import React from "react";
import "../../assets/css/login-modal.css";
import Modal from "react-bootstrap/Modal";
import LoginForm from "../auth/LoginForm";

function LoginModal(props) {
  return (
        <Modal {...props} className="login-main">
          <Modal.Body>
            <LoginForm></LoginForm>
          </Modal.Body>
        </Modal>
  );
}
export default LoginModal;
