import { useEffect, useState } from "react";
import api from "../../api";
import "./../../assets/scss/notifications_page.scss";
import NotificationItem from "./NotificationItem";
import InvitationNotifications from "./InvitationNotifications";

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState();
  const [nextPageUrl, setNextPageUrl] = useState(null);

  const fetchNotifications = () => {
    api
      .get("notifications")
      .then((response) => {
        setNotifications(response.data.data);
        setNextPageUrl(response.data.data.nextPageUrl);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => fetchNotifications(), []);

  return (
    <main>
      <div className="admin-user-grid-3">
        <h5>Notifications </h5>
        <p>Here's what's going on.</p>
      </div>
      <div>
        <InvitationNotifications/>
        <div className="notifications-container">
          {notifications?.map((notification) => (
            <NotificationItem notification={notification} key={notification.id} />
          ))}
          <div></div>
        </div>
      </div>
    </main>
  );
};

export default NotificationsPage;
