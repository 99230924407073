import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "./../../api";
import Errors from "./../../utils/Errors";

function ChangePasswordForm() {
  const initialState = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };
  const [state, setState] = useState(initialState);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());

  function handleChange(e) {
    let { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  useEffect(() => {
    setErrors(new Errors);
  }, [state])
  

  function submit(e) {
    e.preventDefault();
    setProcessing(true);
    api
      .post("/change-password", { ...state })
      .then((response) => {
        toast.success("Your password has been changed");
        setState(initialState);
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
          return;
        }
        toast.error("Something went wrong");
      })
      .finally(() => setProcessing(false));
  }

  return (
    <div>
      <h4 className="mb-4">Change your password</h4>
      <form onSubmit={(e) => submit(e)}>
        <div className="mb-3">
          <label htmlFor="" className="form-label">
            Password
          </label>
          <input
            type="password"
            name="old_password"
            className="form-control"
            value={state.old_password}
            onChange={(e) => handleChange(e)}
          />
          {errors.has("old_password") && (
            <div className="d-block invalid-feedback">
              {errors.first("old_password")}
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="" className="form-label">
            New Password
          </label>
          <input
            type="password"
            name="password"
            className="form-control"
            value={state.password}
            onChange={(e) => handleChange(e)}
          />
          {errors.has("password") && (
            <div className="d-block invalid-feedback">
              {errors.first("password")}
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="" className="form-label">
            Confirm New Password
          </label>
          <input
            type="password"
            name="password_confirmation"
            className="form-control"
            value={state.password_confirmation}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="mb-3">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={processing}
          >
            {processing && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangePasswordForm;
