import { toast } from "react-toastify";
import api from "./../api";

export function addProgramToUserList(programId) {
  const promise = api.post(`use-program/${programId}`);

  toast.promise(promise, {
    pending: "Adding program",
    success: "Program has been added to your list",
    // error: "Error while adding this program !",
  });

  return promise;
}

// action button in program
export function PublishProgramAction(programId) {
  const promise = api.post(`programs/${programId}/publish`);

  toast.promise(promise, {
    pending: "Publising program",
    success: "Program has been published",
    error: "Error while publising this program !",
  });

  return promise;
}

export function DeleteProgramAction(programId) {
  const promise = api.delete(`programs/${programId}`);

  toast.promise(promise, {
    pending: "Deleting program",
    success: "Program has been archived",
    error: "Error while deleting this program !",
  });

  return promise;
}

export function RestoreProgramAction(programId) {
  const promise = api.post(`programs/${programId}/restore`);

  toast.promise(promise, {
    pending: "Restoring program",
    success: "Program has been restored",
    error: "Error while restoring this program !",
  });

  return promise;
}

export function UnpublishProgramAction(programId) {
  const promise = api.post(`programs/${programId}/unpublish`);

  toast.promise(promise, {
    pending: "Unpublising program",
    success: "Program has been unpublished",
    error: "Error while unpublising this program !",
  });

  return promise;
}

export function RemoveAnswer(ansId){
  const promise = api.post(`delete-answer/${ansId}`);

  toast.promise(promise, {
    pending: "Deleting Answer",
    success: "Answer Removed Successfully !",
    error: "Error while removing answer !"
  });

  return promise;
}

// export function VerifyUser(UserId){
//   const promise = api.post(`mark-user-verified/${UserId}`);

//   toast.promise(promise, {
//     pending: "verifying User !",
//     success: 'User Verified !',
//     error: "There was problem while verifying user !"
//   })
// }
