import { useState } from "react";
import { Modal } from "react-bootstrap";
import api from "../../../api";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";

const RemoveSeatsModal = ({ show, onHide, id }) => {
  const [state, setState] = useState({
    number_of_seats: 0,
  });
  const [errors, setErrors] = useState(new Errors());

  const submit = () => {
    api
      .post(`remove-organization-seats/${id}`, {
        organization_id: id,
        number_of_seats: state.number_of_seats,
      })
      .then((response) => {
        toast.success("Action completed");
        window.dispatchEvent(new CustomEvent("organizationSeatsChanged"));
        onHide();
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          toast.error("Validation Failed");
          return;
        }
        toast.error("Server Error");
        console.log(error);
      });
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="p-4 bg-white rounded">
          <div className="d-flex gap-2">
            <h4 className="mb-3 font-secondary fw-bolder">Remove Seats</h4>
          </div>

          <div className="mb-3">
            <label className="mb-1">
              How many seats do you want to remove?
            </label>
            <input
              type="number"
              className="form-control"
              value={state.number_of_seats}
              onChange={(e) =>
                setState({ ...state, number_of_seats: e.target.value })
              }
            />
            {errors.has("number_of_seats") && (
              <div className="d-block invalid-feedback">
                {errors.first("number_of_seats")}
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end gap-2">
            <button
              type="button"
              className="btn btn-light rounded-1 font-secondary"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-1 font-secondary fw-bolder"
              onClick={submit}
            >
              Remove
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveSeatsModal;
