import { toast } from "react-toastify";
import { useContext, useState } from "react";
import ENCAP_LOGO from "./../../../assets/image/logo.PNG";
import { AuthContext } from "../../../context/AuthContext";
import { ButtonSpinner } from "../../../Components/Encap";
import api from "../../../api";

const InvitationConfirmationPrompt = ({
  token,
  organizationName,
  onAccept,
  onDecline,
}) => {
  const [processing, setProcessing] = useState(false);
  const { syncUserInformation } = useContext(AuthContext);

  const handleAccept = () => {
    setProcessing(true);
    api
      .post(`organization-admin-invitation/accept-invitation`, {
        token: token,
      })
      .then((response) => {
        syncUserInformation();
        onAccept();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return false;
        }
        toast.error("Something went wrong while accepting invitation");
      })
      .finally(() => setProcessing(false));
  };

  const handleDecline = () => {
    onDecline();
  };

  return (
    <section className="text-center">
      <img
        src={ENCAP_LOGO}
        alt="Encapsulator"
        className="img-fluid mb-4"
        style={{ height: "4rem" }}
      />
      <h3 className="h3-responsive mb-4">Welcome to Encapsulator</h3>
      <p>
        We're delighted to invite you to join{" "}
        <span className="fw-bold">{organizationName}</span> as a manager.
      </p>
      <div className="d-flex gap-2">
        <button
          className="btn btn-primary flex-grow-1 rounded-1"
          onClick={handleAccept}
          disabled={processing}
        >
          <ButtonSpinner processing={processing} />
          Accept
        </button>
        <button
          className="btn btn-danger flex-grow-1 rounded-1"
          onClick={handleDecline}
        >
          Decline
        </button>
      </div>
    </section>
  );
};

export default InvitationConfirmationPrompt;
