import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ProgressBar from "../../../Components/progressbar/ProgressBar";
import moment from "moment";
import defaultImage from "../../../assets/image/default-image.png";
import ProgramCoverImage from "../../../Components/program/ProgramCoverImage";
import api from "../../../api";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useState } from "react";

function MyProgramCard({ program }) {
  const [unarchived, setUnarchived] = useState(false);
  const formatDate = (date) => {
    return moment(date).format("D MMM, YYYY");
  };

  const restore = (e) => {
    e.preventDefault();
    api
      .post(`my-programs/${program.id}/unarchive`)
      .then((response) => {
        setUnarchived(true);
        toast.success("Program has been restored");
      })
      .catch((error) => toast.error("Something went wrong. Try again"));
  };

  const linkTarget = program.progress?.completedCount
    ? `/continue-program/${program.id}`
    : `/my-programs/${program.id}`;

  return (
    <Link
      to={linkTarget}
      className={classNames({
        "d-none": unarchived,
      })}
    >
      <div className="prog-grid-3 card">
        {/* <div className="program-type">
          {program.is_free && <p className="free">Lite</p>}
          <p className="paid">Pro</p>
        </div> */}
        {program.organization_name && (
          <div className="org-name-badge">
            {program.organization_name.substring(0, 5)}
          </div>
        )}
        <div className="imgBox">
          <ProgramCoverImage program={program} className="img" />
        </div>
        <div className="prog-card-body">
          <h5>{program.program_name}</h5>
          <p className="title2">{program.organization_name}</p>
          {/* TODO::what's the logic for last open date */}
          <p>Last opened: {formatDate(program.updated_at.raw)}</p>

          <ProgressBar
            bgcolor="#529C00"
            current={program.progress.completedCount}
            total={program.progress.entriesCount}
            progress={program.progress.completionPercentage}
          />

          {!program.deleted_at && (
            <div className="prog-grid-3-1">
              <h5>Open Program</h5>
              <FontAwesomeIcon
                className="arrow-icon"
                icon={faArrowRight}
                color="#FFFFFF"
                style={{ fontSize: 15 }}
              ></FontAwesomeIcon>
            </div>
          )}

          {program.deleted_at && (
            <div>
              <button
                type="button"
                className="btn btn-primary rounded-1"
                onClick={restore}
              >
                Restore
              </button>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default MyProgramCard;
