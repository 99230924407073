import React, { useContext, useState } from "react";
import "../../assets/css/side_bar.css";
import encap from "../../assets/image/logo.PNG";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faGear,
  faQuestionCircle,
  faRightFromBracket,
  faRightToBracket,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-brands-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { hasRole, isoToAusDate } from "../../utils/helpers";
import AdminSidebar from "./AdminSidebar";
import LoginModal from "../Modals/LoginModal";
import RegisterModalForm from "../Modals/RegisterModal";
import OrgSidebar from "./OrgSidebar";
import { AuthContext } from "../../context/AuthContext";
import SidebarNotificationLink from "./partials/SidebarNotificationLink";
import SupportFeedbackLink from "./partials/SupportFeedbackLink";

function SideBar(props) {
  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();
  const [loginModal, setModalShow] = useState(false);
  const [RegisterModal, setRegisterModalShow] = useState(false);

  const [users, getUsers] = useState(false);
  const navigate = useNavigate();

  function LogoutUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("subscribed");
    getUsers(false); //remove user
    navigate("/program-library");
    window.location.reload();
  }

  const sideBarClose = () => {
    props.setShowSideBar(false);
  };

  const handleRegisterClick = () => {
    props.setShowSideBar(false);
    setRegisterModalShow(true);
  };

  const handleLoginClick = () => {
    props.setShowSideBar(false);
    setModalShow(true);
  };

  return (
    <div className="sidebar__wrap">
      <div className="sidebar__content__area">
        <div className="sidebar__encap__logo">
          <img src={encap} alt="encapsulator-logo" />
        </div>
        <nav>
          {/* For Guest */}
          {!isLoggedIn() && (
            <React.Fragment>
              <NavLink to="/" className="sidebar__link" onClick={sideBarClose}>
                <FontAwesomeIcon
                  className="sidebar__icon"
                  icon={faPenToSquare}
                  color="#5A5A5C"
                  style={{ fontSize: 20 }}
                ></FontAwesomeIcon>
                <span>Program Library</span>
              </NavLink>
              {!user && (
                <div>
                  <div
                    className="header-login sidebar__link"
                    style={{ cursor: "pointer" }}
                    onClick={handleRegisterClick}
                    //onClick={setSuscribe}
                  >
                    <FontAwesomeIcon
                      className="sidebar__icon"
                      icon={faUserPlus}
                    ></FontAwesomeIcon>
                    <span>Register</span>
                    {/* <h5>Register</h5> */}
                  </div>
                  {/* <Subscribe
                show={Suscribe}
                onHide={() => setSuscribe(false)}
                /> */}
                  <RegisterModalForm
                    show={RegisterModal}
                    onHide={() => setRegisterModalShow(false)}
                  />
                </div>
              )}
              {!user && (
                <div
                  className="header-login sidebar__link"
                  style={{ cursor: "pointer" }}
                  onClick={handleLoginClick}
                >
                  <FontAwesomeIcon
                    className="sidebar__icon"
                    icon={faRightToBracket}
                  ></FontAwesomeIcon>
                  {/* <h5>Login</h5> */}
                  <span>Login</span>
                </div>
              )}
              {!user && (
                <LoginModal
                  show={loginModal}
                  onHide={() => setModalShow(false)}
                />
              )}
            </React.Fragment>
          )}

          {hasRole("superadmin") ? (
            <AdminSidebar setShowSideBar={props.setShowSideBar} />
          ) : (
            ""
          )}
          {hasRole("orgadmin") ? <OrgSidebar /> : ""}

          {hasRole("user") && (
            <React.Fragment>
              <NavLink to="/program-library" className="sidebar__link">
                <FontAwesomeIcon
                  className="sidebar__icon"
                  icon={faPenToSquare}
                ></FontAwesomeIcon>
                <span>Program Library</span>
              </NavLink>
              <NavLink to="/my-programs" className="sidebar__link">
                <FontAwesomeIcon
                  className="sidebar__icon"
                  icon={faPenToSquare}
                ></FontAwesomeIcon>
                <span>My Programs</span>
              </NavLink>

              <SidebarNotificationLink />
              <SupportFeedbackLink />
            </React.Fragment>
          )}
        </nav>
      </div>
      <div className="sidebar__content__area">
        <div className="sidebar__separator"></div>

        {/* {isLoggedIn() && hasRole("user") && (
          <React.Fragment>
            <NavLink to="/subscribe" className="sidebar__link">
              <FontAwesomeIcon
                className="sidebar__icon"
                icon={faGear}
              ></FontAwesomeIcon>
              <span>Subscribe</span>
            </NavLink>

            <NavLink to="/help" className="sidebar__link">
              <FontAwesomeIcon
                className="sidebar__icon"
                icon={faQuestionCircle}
              ></FontAwesomeIcon>
              <span>Help</span>
            </NavLink>
          </React.Fragment>
        )} */}

        {user?.organizations?.map((organization) => (
          <div className="sidebar__link" key={organization.id}>
            <img
              className="sidebar__icon"
              src={organization.logo_url}
              style={{
                height: "2rem",
                width: "2rem",
                borderRadius: "50%",
              }}
              alt={organization.name}
            />
            <div>
              <div>{organization.name}</div>
              {organization.licensed_until?.raw && (
                <small className="fw-normal">
                  Expires at: {isoToAusDate(organization.licensed_until.raw)}
                </small>
              )}
            </div>
          </div>
        ))}

        {isLoggedIn() && (
          <div
            className="header-login-logout sidebar__link"
            style={{ cursor: "pointer" }}
            onClickCapture={LogoutUser}
          >
            <FontAwesomeIcon
              className="sidebar__icon"
              icon={faRightFromBracket}
            ></FontAwesomeIcon>
            <span>Logout</span>
            {/* <h5>Logout</h5> */}
          </div>
        )}
        <NavLink to={"settings/profile"} className="sidebar__link">
          {user && (
            <>
              {user?.avatar && (
                <img
                  className="sidebar__icon"
                  src={user.avatar}
                  style={{
                    height: "2rem",
                    width: "2rem",
                    borderRadius: "50%",
                  }}
                  alt={user.first_name}
                />
              )}
              <div>
                <div>{user.first_name}</div>
                {hasRole("superadmin") ? (
                  <small className="fw-normal">Super Admin</small>
                ) : (
                  <small className="fw-normal">
                    {user.plan} {hasRole("orgadmin") && "Admin"} Plan
                  </small>
                )}
              </div>
            </>
          )}
        </NavLink>
      </div>
    </div>
  );
}
export default SideBar;
