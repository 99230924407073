import { Link } from "react-router-dom";

const InvalidInvitationMessage = () => {
  return (
    <div className="card-body p-md-4">
      <p>This invitation is either accepted or invalid.</p>
      <Link to="/" className="btn btn-primary rounded-1">
        Go to Home
      </Link>
    </div>
  );
};

export default InvalidInvitationMessage;
