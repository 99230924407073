import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function PromptQuestionOverlay({ questions, element, promtp, arrow }) {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  function nextQuestion() {
    if (activeQuestionIndex === questions.length - 1) {
      return;
    }
    setActiveQuestionIndex(activeQuestionIndex + 1);
  }

  function prevQuestion() {
    if (activeQuestionIndex === 0) {
      return;
    }
    setActiveQuestionIndex(activeQuestionIndex - 1);
  }
  return (
    <>
      {element && (
        <div className="main-caption">
            Hit record when you are ready, you have 10 minutes to respond to the
            following questions. Use the arrows or swipe for next question !
        </div>
      )}
      <div className="caption">
        {promtp ? questions[activeQuestionIndex] : ""}
      </div>
      {arrow && (
        <div className="nav-arrows">
          <button
            type="button"
            className="nav-arrow prev-arrow"
            onClick={prevQuestion}
          >
            <FontAwesomeIcon icon={faCircleArrowLeft} />
          </button>
          <button
            type="button"
            className="nav-arrow next-arrow"
            onClick={nextQuestion}
          >
            <FontAwesomeIcon icon={faCircleArrowRight} />
          </button>
        </div>
      )}
    </>
  );
}

export default PromptQuestionOverlay;
