function ProgressBar({ current, total, progress, bgcolor }) {
  const Parentdiv = {
    height: 12,
    width: "100%",
    backgroundColor: "#E2E8F0",
    borderRadius: 40,
  };

  const Childdiv = {
    height: 12,
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "black",
    fontWeight: 500,
  };

  const integer1 = {
    color: "#529C00",
    fontWeight: 400,
    fontSize: 18,
  };

  const integer2 = {
    paddingTop: 5,
    color: "#A3BACA",
    fontWeight: 400,
    fontSize: 12,
  };

  return (
    <>
      <div className="d-flex align-item-center justify-content-between">
        <div className="d-flex align-item-center">
          <div className="integer-1" style={integer1}>
            {current}
          </div>
          <div style={integer2}>/{total} steps</div>
        </div>
        {/* <div className='col-sm-12 d-flex justify-content-end'> */}
        <div>{`${progress}%`}</div>
      </div>
      <div style={Parentdiv}>
        <div style={Childdiv}>
          <span style={progresstext}></span>
        </div>
      </div>
    </>
  );
}

export default ProgressBar;
