const PaginationSize = ({ perPage, options, onChange }) => {
  const handleChange = (e) => {
    console.log("page size changed: " + e.target.value);
    onChange && onChange(e.target.value);
  };

  return (
    <div className="d-flex align-items-center">
      <select onChange={handleChange} className="form-select" value={perPage}>
        {options?.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </select>
      <div className="text-nowrap ps-2">items per page</div>
    </div>
  );
};

export default PaginationSize;
