import {
  faCheck,
  faCircleXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import VideoAnswer from "../Answer/VideoAnswer";
import WrittenAnswer from "../Answer/WrittenAnswer";
import "../../assets/css/answer_modal.css";
import { useEffect, useState } from "react";
import classNames from "classnames";
import api from "../../api";
import { toast } from "react-toastify";
import { submitVideoAnswer, submitWrittenAnswer } from "../../utils/Answer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ANSWERING_OPTION } from "../../const/Entry";
import DownloadAppMiniBanner from "../Answer/DownloadAppMiniBanner";

function AnswerModal({ show, onHide, entry, prompt = null }) {
  const MySwal = withReactContent(Swal);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleHide = async () => {
    if (hasUnsavedChanges) {
      const result = await MySwal.fire({
        title: <p className="text-start">Discard changes</p>,
        html: `<div class="text-start">
                <div>You have made changes that haven't been saved yet. Are you sure to discard changes?</div>
              </div>`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!result.isConfirmed) {
        return;
      }
    }
    onHide && onHide();
  };

  const handleWrittenAnswerSubmit = (answer) => {
    const form = {
      type: "written",
      entry_id: entry.id,
      written_answer: answer,
      prompt_id: prompt?.id ?? null,
    };

    submitWrittenAnswer(form).then((response) => {
      onHide();
      // Trigger an event that will reload the data
      const event = new CustomEvent("answerSubmitted", {
        detail: {
          entryId: entry.id,
        },
      });
      window.dispatchEvent(event);
    });
  };

  const handleVideoAnswerSubmit = (videoFileId) => {
    const form = {
      type: "video",
      entry_id: entry.id,
      video_file_id: videoFileId,
      prompt_id: prompt?.id ?? null,
    };

    submitVideoAnswer(form).then((response) => {
      onHide();
      // Trigger an event that will reload the data
      const event = new CustomEvent("answerSubmitted", {
        detail: {
          entryId: entry.id,
        },
      });
      window.dispatchEvent(event);
    });
  };

  useEffect(() => {
    const handleVideoRecordingStartedEvent = () => {
      setHasUnsavedChanges(true);
    };
    window.addEventListener(
      "videoRecordingStarted",
      handleVideoRecordingStartedEvent
    );

    const handleWrittenAnswerChangedEvent = () => {
      setHasUnsavedChanges(true);
    };
    window.addEventListener(
      "writtenAnswerChanged",
      handleWrittenAnswerChangedEvent
    );
  }, []);

  return (
    <Modal onHide={handleHide} show={show} size="lg" centered>
      <Modal.Header className="close-button">
        <div className="exercise-popup-header">
          <div className="exercise-popup-1">
            {entry.is_additional ? (
              <EditableTitle entry={entry} />
            ) : (
              <p>{prompt?.title || "Submit your answer "}</p>
            )}
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="exercise-popup-close-btn"
              onClick={() => handleHide()}
            ></FontAwesomeIcon>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="exercise-popup">
          <div className="exercise-popup-5">
            {prompt && (
              <div className="exercise-popup-2">
                <p>
                  Here are some prompts to get you started (don’t worry, you can
                  have them show up while recording too).
                </p>
              </div>
            )}

            <div className="exercise-popup-3">
              <div className="exercise-popup-3">
                <ul>
                  {prompt &&
                    prompt.questions.map((question) => (
                      <li key="question">{question}</li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="exercise-popup-4">
              <p>
                You can either record a video response, or type in your answers.
              </p>
            </div>

            {entry.answering_options === ANSWERING_OPTION.TEXT_AND_VIDEO && (
              <nav>
                <div
                  className="nav nav-tabs nav-fill"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    className="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Video Answer
                  </button>
                  <button
                    className="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Written Answer
                  </button>
                </div>
              </nav>
            )}

            <div className="tab-content" id="nav-tabContent">
              {(entry.answering_options === "TEXT_AND_VIDEO" ||
                entry.answering_options === "VIDEO_ONLY") && (
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                  tabIndex="0"
                >
                  <div className="exercise-popup-5">
                    <VideoAnswer
                      entry={entry}
                      prompt={prompt}
                      onSubmit={(videoFileId) =>
                        handleVideoAnswerSubmit(videoFileId)
                      }
                      onCancel={handleHide}
                    />
                  </div>
                </div>
              )}

              <div
                className={classNames("tab-pane fade", {
                  "show active":
                    entry.answering_options === ANSWERING_OPTION.TEXT_ONLY,
                })}
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
                tabIndex="0"
              >
                <div className="mb-3" style={{ marginTop: 20 }}>
                  <WrittenAnswer
                    content={entry?.answer?.written_answer || null}
                    onSubmit={(answer) => handleWrittenAnswerSubmit(answer)}
                    onCancel={handleHide}
                  />
                </div>
              </div>
            </div>
          </div>

            <DownloadAppMiniBanner />
        </div>
      </Modal.Body>
    </Modal>
  );
}

function EditableTitle({ entry }) {
  const [title, setTitle] = useState(entry.title);
  const [editMode, setEditMode] = useState(false);

  const saveTitle = () => {
    api
      .post(`update-entry-title/${entry.id}`, {
        title,
      })
      .then((res) => {
        toast.success("Title saved");
      });
    setEditMode(false);
  };

  return (
    <div className="d-inline-flex align-items-center gap-2">
      {editMode ? (
        <input
          type="text"
          className="form-control"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      ) : (
        <div className="text-white">{title}</div>
      )}
      <button
        type="button"
        className={classNames("btn btn-link", { "d-none": editMode })}
        onClick={() => setEditMode(true)}
      >
        <FontAwesomeIcon icon={faEdit} className="text-white" />
      </button>
      <button
        type="button"
        className={classNames("btn btn-light", { "d-none": !editMode })}
        onClick={saveTitle}
      >
        <FontAwesomeIcon icon={faCheck} />
      </button>
    </div>
  );
}

export default AnswerModal;
