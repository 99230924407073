import React from "react";
import "../../assets/css/RegisterModal.css";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import RegisterForm from "../auth/RegisterForm";
import UserOnboarding from "../auth/UserOnboarding";

function RegisterModalForm(props) 
{
  return (
    <Form>
      <Modal {...props}>
        <Modal.Body className="modal-body">
          {/* <RegisterForm></RegisterForm> */}
          <UserOnboarding />
        </Modal.Body>
      </Modal>
    </Form>
  );
}

export default RegisterModalForm;
