const ContributorsList = ({ contributors }) => {
  return (
    <div>
      <h5 className="fw-bolder font-secondary">Contributors</h5>
      {contributors.map((contributor) => (
        <article className="panel__white mb-3" key={contributor.id}>
          <div className="d-flex align-items-center gap-3">
            <div>
              <img
                style={{
                  height: "4rem",
                  width: "4rem",
                  borderRadius: "50%",
                }}
                src={contributor.avatar}
                alt={contributor.name}
              />
            </div>
            <div className="flex-grow-1 d-flex justify-content-between">
              <div>
                <h6 className="mb-1 font-secondary">{contributor.name}</h6>
                <div>
                  <a
                    href={`mailto:${contributor.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {contributor.email}
                  </a>
                </div>
              </div>
              <div className="text-end">
                <div>{contributor.contact}</div>
                <a
                  href={contributor.url}
                  className="d-inline-block text-truncate"
                  style={{ maxWidth: "30ch" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contributor.url}
                </a>
              </div>
            </div>
          </div>
          <p className="my-3 text-muted">{contributor.bio}</p>
        </article>
      ))}
    </div>
  );
};

export default ContributorsList;
