import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import api from "../../../api";
import Errors from "../../../utils/Errors";
import { AuthContext } from "../../../context/AuthContext";

function UserProfileForm() {
  const { getAuthUser, syncUserInformation } = useContext(AuthContext);
  const user = getAuthUser();
  const [state, setState] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    profile_pic: user.avatar,
  });
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());

  function handleChange(e) {
    let { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  useEffect(() => {
    setErrors(new Errors());
  }, [state]);

  function submit(e) {
    e.preventDefault();
    setProcessing(true);
    api
      .post("/update-profile", { ...state })
      .then((response) => {
        syncUserInformation();
        toast.success("Your profile has been updated");
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
          return;
        }
        toast.error("Something went wrong");
      })
      .finally(() => setProcessing(false));
  }

  return (
    <form onSubmit={(e) => submit(e)} className="my-profile-form">
      <div className="setting-grid-3-2">
        <h6 className="col-sm-2 label">Your Name</h6>
        <div className="mb-3 col-sm-4 input">
          <input
            type="text"
            name="first_name"
            className="form-control"
            aria-describedby="firstNameHelp"
            value={state.first_name}
            onChange={(e) => handleChange(e)}
          />
          <div id="firstNameHelp" className="form-text">
            First Name
          </div>
        </div>

        <div className="mb-3 col-sm-4 input">
          <input
            type="text"
            name="last_name"
            className="form-control"
            aria-describedby="lastNameHelp"
            value={state.last_name}
            onChange={(e) => handleChange(e)}
          />
          <div id="lastNameHelp" className="form-text">
            Last Name
          </div>
        </div>
      </div>

      <div className="setting-grid-3-3">
        <h6 className="col-sm-2 label">Your Email</h6>
        <div className="mb-3 col-sm-8 input">
          <input
            type="email"
            name="email"
            className="form-control"
            value={state.email}
            disabled
            onChange={(e) => handleChange(e)}
            style={{ backgroundColor: "#e9ecef" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="offset-md-2">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={processing}
          >
            {processing && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default UserProfileForm;
