import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  DeleteProgramAction,
  PublishProgramAction,
} from "../../../../utils/Program";
import { hasRole } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const ProgramActionButton = ({ program }) => {
  const navigate = useNavigate();
  const programEditLink = `/programs/${program.id}/edit`;
  const ReactSwal = withReactContent(Swal);

  function PublishProgram(programId) {
    PublishProgramAction(programId).then((res) => {
      if (res) {
        navigate("/program-library");
      }
    });
  }

  async function DeleteProgram(programId) {
    const result = await ReactSwal.fire({
      title: <p className="text-start">Confirm delete</p>,
      html: `<div class="text-start">
                  <div>Are you sure you want to delete this program?</div>
                </div>`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      // cancelButtonText: "No",
    });

    if (!result.isConfirmed) {
      return;
    }

    DeleteProgramAction(programId).then((res) => {
      if (res) {
        navigate("/programs");
      }
    });
  }
  return (
    <>
      {!program.is_published && (
        <button
          type="button"
          className="btn btn-outline-white rounded-1"
          onClick={() => PublishProgram(program.id)}
        >
          <FontAwesomeIcon icon={faBookmark} className="text-green me-2" />
          <span>Publish</span>
        </button>
      )}
      {(hasRole("superadmin") || hasRole("orgadmin")) && (
        <Link to={programEditLink} className="btn btn-outline-white rounded-1">
          <FontAwesomeIcon icon={faEdit} className="text-green me-2" />
          <span>Edit Program</span>
        </Link>
      )}

      {(hasRole("superadmin") || hasRole("orgadmin")) && (
        <button
          type="button"
          className="btn btn-outline-red rounded-1"
          onClick={() => DeleteProgram(program.id)}
        >
          <FontAwesomeIcon icon={faTrash} className="me-2" />
          <span>Delete Program</span>
        </button>
      )}
    </>
  );
};

export default ProgramActionButton;
