import { useNavigate, useParams } from "react-router-dom";
import Stepper from "./Stepper";
import { ButtonSpinner } from "../../../Components/Encap";
import { useState } from "react";
import Errors from "../../../utils/Errors";
import api from "../../../api";
import { toast } from "react-toastify";

const SubscriptionStep = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    number_of_license: "",
    years: 1,
    cost_per_license: 99,
    total_cost: "",
    invoice_number: "",
  });
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);

  const submit = () => {
    setProcessing(true);
    api
      .post(`setup-initial-organization-license/${orgId}`, {
        ...state,
      })
      .then((response) => {
        toast.success("Action completed");
        window.dispatchEvent(new CustomEvent("organizationSeatsChanged"));
        navigate(`/edit-organization/${response.data.id}`);
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          toast.error("Validation Failed");
          return;
        }
        console.log(error);
        if (error?.response?.data?.error) {
          toast.error("Server error: " + error.response.data.error);
          return;
        }
        toast.error("Server Error");
        console.log(error);
      })
      .finally(() => setProcessing(false));
  };
  return (
    <div className="p-4 container-fluid">
      <div className="admin-user-grid-3">
        <h5>Add Organization </h5>
        <p>Add a new organization</p>
      </div>

      <Stepper current="2" />

      <section className="bg-white border" style={{ maxWidth: "900px" }}>
        <div className="p-4">
          <h5 className="font-secondary fw-bolder mb-3">Billing & Licensing</h5>
          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="mb-1">No. of seats</label>
              <input
                type="number"
                className="form-control"
                value={state.number_of_license}
                onChange={(e) =>
                  setState({ ...state, number_of_license: e.target.value })
                }
                min={1}
              />
              {errors.has("years") && (
                <div className="d-block invalid-feedback">
                  {errors.first("years")}
                </div>
              )}
            </div>

            <div className="mb-3 col-md-6">
              <label className="mb-1">Number of years</label>
              <input
                type="number"
                className="form-control"
                value={state.years}
                onChange={(e) => setState({ ...state, years: e.target.value })}
                min={1}
              />
              {errors.has("years") && (
                <div className="d-block invalid-feedback">
                  {errors.first("years")}
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="mb-1">Cost per seat</label>
              <input
                type="number"
                className="form-control"
                value={state.cost_per_license}
                onChange={(e) =>
                  setState({ ...state, cost_per_license: e.target.value })
                }
                min={0}
              />
              {errors.has("cost_per_license") && (
                <div className="d-block invalid-feedback">
                  {errors.first("cost_per_license")}
                </div>
              )}
            </div>

            <div className="mb-3 col-md-6">
              <label className="mb-1">Total Price</label>
              <input
                type="number"
                className="form-control"
                value={state.total_cost}
                onChange={(e) =>
                  setState({ ...state, total_cost: e.target.value })
                }
                min={0}
              />
              {errors.has("total_cost") && (
                <div className="d-block invalid-feedback">
                  {errors.first("total_cost")}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="mb-1">Invoice number</label>
            <input
              type="text"
              className="form-control"
              value={state.invoice_number}
              onChange={(e) =>
                setState({ ...state, invoice_number: e.target.value })
              }
            />
            {errors.has("invoice_number") && (
              <div className="d-block invalid-feedback">
                {errors.first("invoice_number")}
              </div>
            )}
          </div>

          <div>
            <button
              type="button"
              className="btn btn-primary rounded-1 font-secondary fw-bolder px-4"
              onClick={submit}
              disabled={processing}
            >
              <ButtonSpinner processing={processing} />
              <span>Submit</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubscriptionStep;
