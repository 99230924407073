import { Button, Modal } from "react-bootstrap";

const TermsOfService = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Terms of Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <p>
            <strong>Introduction</strong>
          </p>
          <ol>
            <li>
              These Terms and Conditions are applicable to all services provided
              by Encapsulator 16 623 084 248 (“Encapsulator”, “We”) and to the
              use of the Website:&nbsp;
              <a
                href="https://encapsulator.io"
                target="_blank"
                rel="noreferrer"
              >
                https://encapsulator.io
              </a>
            </li>
            <li>
              Your agreement to these Terms and Conditions will be indicated by:
              the execution of any contract, commencement or completion of any
              business activities, or use of any of our services, including the
              Website, whichever occurs first.
            </li>
            <li>
              This Agreement shall be governed by the laws in force in the State
              of New South Wales where the services are provided.
            </li>
            <li>
              These Terms and Conditions may be amended at any time without
              notice.
            </li>
            <li>
              If any clause of these Terms and Conditions is held to be invalid
              or unenforceable in whole or in part, the invalid or unenforceable
              clause shall be deemed to be omitted.
            </li>
          </ol>
          <p>
            <strong>Our Services</strong>
          </p>
          <ol start="6">
            <li>
              Our services include, but not limited to:
              <br />
              a. Store files such as videos, photos and voice recording of any
              kind, and release it privately or share it at the certain period
              stated by the Client.
              <br />
              b. Encapsulator features a recovery program wherein it will be
              lock away for 12 months to 60 months and the Client can access the
              information privately or the Client can choose to share it.
            </li>
            <li>
              All services are subject to the Client’s final approval of the
              customer agreement.
            </li>
          </ol>
          <p>
            <strong>Storing Files</strong>
          </p>
          <ol start="8">
            <li>
              The Client can record and store the following:
              <br />
              a. Video files
              <br />
              b. Image files
              <br />
              c. Text files
              <br />
              d. Other personal file that the Client wants to store in various
              formats
            </li>
            <li>
              After uploading and storing the file, the Client cannot view it
              before the nominated date of capsule expiry.
            </li>
            <li>
              Encapsulator does not guarantee the quality of the file that is
              being stored.
            </li>
            <li>
              The Client can choose to store the file in private or to share it.
              If the Client choses to share it, Encapsulator has the right to
              publish the files to the public.
            </li>
            <li>The Client can download the file once it has been released.</li>
          </ol>
          <p>
            <strong>Fees</strong>
          </p>
          <ol start="13">
            <li>
              The current Fees for service are fixed as at the date of
              agreement. They are outlined in our packages and remain the same
              for the lifetime of that agreed service.
            </li>
          </ol>
          <p>
            <strong>Payment</strong>
          </p>
          <ol start="14">
            <li>All prices are subject to change without prior notice.</li>
            <li>
              All payments are to be made in full through our payment gateway.
            </li>
          </ol>
          <p>
            <strong>Termination and Cancellation Policy</strong>
          </p>
          <ol start="16">
            <li>
              We retain the right to terminate this Agreement if the Client
              breach or violate any provisions of this Terms or Conditions.
            </li>
            <li>
              We have the right to terminate this Agreement by giving you a
              minimum of 2-weeks’ notice.
            </li>
            <li>
              If you cancel the Services at any stage for any reason, then you
              agree to forfeit in favour of Encapsulator all amounts that you
              have paid.
            </li>
          </ol>
          <p>
            <strong>Refund policy</strong>
          </p>
          <ol start="19">
            <li>Encapsulator have NO REFUND policy.</li>
          </ol>
          <p>
            <strong>Confidentiality</strong>
          </p>
          <ol start="20">
            <li>
              Encapsulatormay collect confidential and personal information
              about you.
            </li>
            <li>
              As far as the law permits,Encapsulator will keep the information
              confidential and not share it with third parties.
            </li>
          </ol>
          <p>
            <strong>Indemnity</strong>
          </p>
          <ol start="22">
            <li>
              The Client indemnifies Encapsulatoragainst all losses, damage or
              liability arising in connection with:
              <br />• claims by third parties of infringement of intellectual
              property;
              <br />• competing claims to title;
              <br />• loss or damage to the property of Encapsulator;
              <br />• claims by any person in respect of personal injury,
              illness or death, or loss of or damage to any property;
              <br />• claims by government authorities for breach of laws; or
              <br />• liability for taxes, duties, fines or penalties.
            </li>
          </ol>
          <p>
            <strong>Limitation of Liability</strong>
          </p>
          <ol start="23">
            <li>
              To the maximum extent permitted by law, Encapsulatorgives no
              warranties, and shall have no liability to the Client in relation
              to:
              <br />• Use or implementation of Encapsulatorservices;
              <br />• Unsuitability of Encapsulatorservices for any specific
              purpose;
              <br />• Any other liability relating to the project works or
              outcomes fromEncapsulatorservices;
              <br />• Without limiting the foregoing provisions of this clause,
              neitherEncapsulator, its employees nor contractors shall have any
              liability in relation to any indirect or consequential loss,
              relating to any of the services provided.
              <br />• Any advice provided by Encapsulatoris general advice only
              and Encapsulatoraccept no liability if the desired result is not
              achieved by the Client.
            </li>
            <li>
              Encapsulator takes no liability on any copyright infringements
              that may arise on any of the Services that we have provided.
            </li>
          </ol>
          <p>
            <strong>Intellectual Property</strong>
          </p>
          <ol start="25">
            <li>
              Save and except for any third-party copyright included on this
              website under a licence or agreement, all the content of this
              Website and downloads from it, is owned by Encapsulator. All
              rights reserved.
            </li>
            <li>
              Other than for the purposes of and subject to the conditions
              prescribed under the Copyright Act 1968 (Cth) (or any other
              applicable legislation throughout the world), or as otherwise
              provided for in this copyright notice, no part of any Content may
              in any form or by any means (including electronic, mechanical,
              photocopying or recording) be reproduced, adapted, stored in a
              retrieval system, transmitted or reproduced on any other Internet
              website without the prior written permission of the copyright
              owner.
            </li>
            <li>
              Encapsulatormakes no warranties or representations that
              third-party material on this Website or material on other websites
              to which this Website is linked, does not infringe the
              intellectual property rights of any person anywhere in the world.
              Encapsulatoris not, and must not be taken to be, authorising
              infringement of any intellectual property rights contained in
              material on other sites by linking this Website to such material
              on other sites.
            </li>
            <li>
              This Website may contain trademarks or logos of other companies or
              organisations and these are proprietary to the registered owner(s)
              of such marks.
            </li>
          </ol>
          <p>
            <strong>Third Party Material</strong>
          </p>
          <ol start="29">
            <li>
              Encapsulatordoes not sell or provide your personal information to
              third parties. However,Encapsulatormay use your information
              without making reference to your name, to create marketing
              statistics, identity user demands and to assist in meeting your
              need.
            </li>
          </ol>
          <p>
            <strong>Cookies</strong>
          </p>
          <ol start="30">
            <li>
              Encapsulator may use cookies to tailor your online experience to
              you. These cookies will allow the website to remember your actions
              or preferences over time. Users can delete cookies from their
              browser at any time.
            </li>
          </ol>
          <p>
            <strong>Consequences of use</strong>
          </p>
          <ol start="31">
            <li>
              This Website may only be used for lawful purposes. Any unlawful
              use of the Website may give rise to an offence or claim being made
              against you.
            </li>
          </ol>
          <p>
            <strong>Links to other websites</strong>
          </p>
          <ol start="32">
            <li>
              Encapsulatormay provide links to other websites on the Website.
              This does not imply any commercial relationship
              betweenEncapsulatorand the owners of those website.
              Encapsulatortakes no responsibility for any content displayed on
              those websites. Encapsulatorwebsite may contain information
              provided by third parties. Encapsulatoraccepts no responsibility
              for any advice provided by third parties.
            </li>
          </ol>
          <p>
            <strong>Disclaimer</strong>
          </p>
          <ol start="33">
            <li>
              All information provided on this website is general in nature and
              does not apply to your personal circumstances.
            </li>
          </ol>{" "}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsOfService;
