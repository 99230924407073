import "./../assets/scss/loading_spinner_block.scss";

const LoadingSpinnerBlock = () => {
  return (
    <section className="loading-spinner-block">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
};

export default LoadingSpinnerBlock;
