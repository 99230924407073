import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import Errors from "../../../utils/Errors";
import "../../../assets/css/dashboard_addprogram3.css";
import "../../../assets/css/entry_form_modal.css";
import BasicTextEditor from "../../../Components/editor/BasicTextEditor";
import { toast } from "react-toastify";
import MyFilePond from "./MyFilepond";
import FilepondPreview from "./FilepondPreview";
import {
  ACCESS_TYPE,
  ANSWERING_OPTION,
  PROMPT_TYPE,
} from "../../../const/Entry";
import { hasRole } from "../../../utils/helpers";
import ResourcesPreview from "./ResourcesPreview";
import CloseFormButton from "../../../Components/layouts/CloseFormButton";
import SinglePromptModal from "./SinglePromptModal";

export default function EntryForm({
  entry = null,
  onComplete,
  forWizard = true,
  onHide,
}) {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [errors, setErrors] = useState(new Errors());

  const [showAddPromptModal, setShowAddPromptModal] = useState(false);

  let updateMode = false;

  const initialState = {};

  if (entry) {
    if (entry.id) {
      updateMode = true;
    }

    //console.log('hello', entry.resources)
    initialState.program_id = entry.program_id;
    initialState.title = entry.title;
    initialState.answering_options = entry.answering_options;
    initialState.intro_video_id = entry.intro_video_id;
    initialState.intro_video_url = entry.intro_video_url;
    initialState.explanation_text = entry.explanation_text;
    initialState.resource_id = entry.resource_id;
    initialState.response_privacy = entry.response_privacy;
    initialState.notify_instructor = entry.notify_instructor;
    initialState.prompt_type = entry.prompt_type;
    initialState.prompt_id = entry.prompt_id;
    initialState.access_type = entry.access_type;
    initialState.access_time = entry.access_time;
    initialState.resources = [];
    initialState.intro_video_url_uploaded = entry.intro_video_url_uploaded;
  }

  const [forms, setForms] = useState(initialState);

  // handle date type unit
  function handleScheduleChange(e) {
    setForms({
      ...forms,
      access_time: {
        ...forms.access_time,
        [e.target.name]: e.target.value,
      },
    });
  }

  function handleChange(e) {
    let { name, value } = e.target;
    setForms({
      ...forms,
      [name]: value,
    });
  }

  const fetchPrompts = () => {
    api
      .get("program-prompt-list/" + entry.program_id, {})
      .then((response) => {
        setPrompts(response.data.data);
      })
      .catch((error) => console.error(error));
  };

  // API to show prompt list
  useEffect(() => {
    fetchPrompts();
  }, []);

  // handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let next = e.nativeEvent.submitter.value;
    setProcessing(true);

    if (updateMode) {
      handleUpdate(next);
      return;
    }

    handleCreate(next);
    return;
  };

  function handleCreate(next) {
    api
      .post(`program-wizard/store-entry`, forms)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          onComplete && onComplete();
          switch (next) {
            case "add":
              navigate(0);
              break;
            case "exit":
              navigate("/");
              break;
            case "next":
              navigate(`/programs/${entry.program_id}/completion`);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response.headers) {
          // 403 unauthorized error
          setErrors(new Errors(error.response.data.message));
          toast.error(
            updateMode
              ? "Access denied ! As organization you are not allowed to update public program"
              : "Errors"
          );
        }
        if (error.response && error.response.data.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      })
      .finally(() => setProcessing(false));
  }

  function handleUpdate() {
    api
      .put(`program-wizard/update-entry/${entry.id}`, forms)
      .then((response) => {
        toast.success("Entry updated");
        onComplete();
      })
      .catch((error) => {
        if (error.response.headers) {
          // 403 unauthorized error
          setErrors(new Errors(error.response.data.message));
          toast.error(
            "Access denied ! As organization you are not allowed to update public program"
          );
        }
        if (error.response && error.response.data.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      })
      .finally(() => setProcessing(false));
  }

  return (
    <Form method="post" onSubmit={handleSubmit}>
      <div className="add-program3-grid-3-1">
        <div className="col col-sm-3">
          <h6>Item Title</h6>
        </div>
        <div className="col col-sm-6">
          <Form.Control
            type="text"
            name="title"
            value={forms.title}
            className="add-program3-form"
            onChange={handleChange}
          />
          {errors.has("title") && (
            <div className="d-block invalid-feedback">
              {errors.first("title")}
            </div>
          )}
        </div>
      </div>

      <div className="add-program3-grid-3-2">
        <div className="col col-sm-3">
          <h6>Item Video (Optional)</h6>
        </div>
        <div className="col col-sm-6">
          <div>
            {/* {
              !updateMode ? (

                <MyFilePond
                  maxFiles={1}
                  allowedFileTypes={["video/*"]}
                  onUploadComplete={(ids) => {
                    ids.length && setForms({ ...forms, intro_video_id: ids[0] });
                  }}
                />
              ):(
                <UpdateEntryIntroVideo entry_id={entry.id}/>
              )
            } */}
            <MyFilePond
              maxFiles={1}
              allowedFileTypes={["video/*"]}
              onUploadComplete={(ids) => {
                ids.length && setForms({ ...forms, intro_video_id: ids[0] });
              }}
            />
          </div>
          <FilepondPreview />
          <div>
            {forms.intro_video_url_uploaded &&
            forms.intro_video_url_uploaded ? (
              <div className="vid">
                <video controls className="video">
                  <source
                    src={forms.intro_video_url_uploaded}
                    type="video/mp4"
                  />
                </video>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="text-center my-2">OR</div>
          <Form.Control
            className="add-program2-form-1"
            type="text"
            name="intro_video_url"
            size="50"
            value={forms.intro_video_url}
            onChange={handleChange}
            placeholder="Paste an video URL"
          />
        </div>
      </div>

      <div className="add-program3-grid-3-3">
        <div className="col col-sm-3">
          <h6>Explanation Text</h6>
        </div>
        <div className="col col-sm-6">
          <BasicTextEditor
            value={forms.explanation_text}
            onChange={(value) =>
              setForms({ ...forms, explanation_text: value })
            }
          />
          {errors.has("explanation_text") && (
            <div className="d-block invalid-feedback">
              {errors.first("explanation_text")}
            </div>
          )}
        </div>
      </div>

      <div className="add-program3-grid-3-4">
        <div className="col col-sm-3">
          <h6>Resources (Optional)</h6>
          <p>
            You can attach worksheets, video transcriptions, or any other file
            you’d like.
          </p>
        </div>

        <div className="col col-sm-6">
          <MyFilePond
            maxFiles={5}
            allowedFileTypes={["application/pdf"]}
            serverUrl={
              entry.id
                ? `/upload-entry-resource?entry_id=${entry.id}`
                : "/upload-entry-resource"
            }
            onUploadComplete={(ids) => {
              console.log(ids);
              setForms({ ...forms, resources: ids });
            }}
          />
          {entry.resources && <ResourcesPreview resources={entry.resources} />}
          {/* {entry.resources && entry.resources ? (
            <div className="vid">
              {entry.resources.map((reso, i) => (
                <div className="resource-section" key={i}>
                  <iframe src={reso.url} key={i}></iframe>
                </div>
              ))}
             </div>
          ) : (
            <></>
          )} */}
        </div>
      </div>

      <div className="add-program3-grid-3-5">
        <div className="col col-sm-3">
          <h6>Answering Options</h6>
          <p>How would you like the student to respond to this exercise.</p>
        </div>
        <div className="add-program3-grid-100 col col-sm-9">
          <Form.Check
            type="radio"
            className="add-program3-grid-3-51"
            name="answering_options"
            aria-label="radio 1"
            id="radio-text-and-video"
            label="Text and Video"
            checked={
              forms.answering_options === ANSWERING_OPTION.TEXT_AND_VIDEO
            }
            onChange={() =>
              setForms({
                ...forms,
                answering_options: ANSWERING_OPTION.TEXT_AND_VIDEO,
              })
            }
          />
          <Form.Check
            type="radio"
            className="add-program3-grid-3-51"
            name="answering_options"
            aria-label="radio 2"
            id="radio-text-only"
            label="Text Only"
            checked={forms.answering_options === ANSWERING_OPTION.TEXT_ONLY}
            onChange={() =>
              setForms({
                ...forms,
                answering_options: ANSWERING_OPTION.TEXT_ONLY,
              })
            }
          />
          <Form.Check
            type="radio"
            className="add-program3-grid-3-51"
            name="answering_options"
            aria-label="radio 3"
            id="radio-video-only"
            label="Video Only"
            checked={forms.answering_options === ANSWERING_OPTION.VIDEO_ONLY}
            onChange={() =>
              setForms({
                ...forms,
                answering_options: ANSWERING_OPTION.VIDEO_ONLY,
              })
            }
          />
          <Form.Check
            type="radio"
            name="answering_options"
            className="add-program3-grid-3-51"
            aria-label="radio 4"
            id="radio-no-response-required"
            label="No Response Required"
            checked={
              forms.answering_options === ANSWERING_OPTION.NO_RESPONSE_REQUIRED
            }
            onChange={() =>
              setForms({
                ...forms,
                answering_options: ANSWERING_OPTION.NO_RESPONSE_REQUIRED,
              })
            }
          />
        </div>
      </div>

      <div className="add-program3-grid-3-6">
        <div className="col col-sm-3">
          <h6>Question Promts</h6>
          <p>You can add some promts to help guide answering.</p>
        </div>

        <div className="col col-sm-9">
          <div className="add-program3-grid-100">
            <Form.Check
              type="radio"
              className="add-program3-grid-3-61"
              name="prompt_type"
              id="radio-check-prompt-none"
              label="None"
              checked={forms.prompt_type === PROMPT_TYPE.NONE}
              onChange={() =>
                setForms({ ...forms, prompt_type: PROMPT_TYPE.NONE })
              }
            />
            <Form.Check
              type="radio"
              className="add-program3-grid-3-61"
              name="prompt_type"
              id="radio-check-prompt-any"
              label="Any"
              checked={forms.prompt_type === PROMPT_TYPE.ANY}
              onChange={() =>
                setForms({ ...forms, prompt_type: PROMPT_TYPE.ANY })
              }
            />
            <Form.Check
              type="radio"
              className="add-program3-grid-3-61"
              name="prompt_type"
              id="radio-check-prompt-selected"
              label="Selected"
              checked={forms.prompt_type === PROMPT_TYPE.SELECTED}
              onChange={() =>
                setForms({ ...forms, prompt_type: PROMPT_TYPE.SELECTED })
              }
            />
          </div>

          {forms.prompt_type === PROMPT_TYPE.SELECTED && (
            <div className="select-prompt-question mt-2">
              <div className="d-flex align-items-baseline gap-3">
                <Form.Select
                  name="prompt_id"
                  onChange={handleChange}
                  className="d-inline-block"
                >
                  <option value={"some"} name="prompt">
                    Select Prompt Question
                  </option>
                  {prompts.map((prompt, index) => (
                    <option
                      value={prompt.id}
                      selected={forms.prompt_id === prompt.id}
                      key={index}
                    >
                      {prompt.title}
                    </option>
                  ))}
                </Form.Select>
                <button
                  type="button"
                  onClick={() => setShowAddPromptModal(true)}
                  className="add-prompt-btn"
                >
                  Add New
                </button>
                <SinglePromptModal
                  show={showAddPromptModal}
                  onHide={() => setShowAddPromptModal(false)}
                  refetchData={fetchPrompts}
                  programId={forms.program_id}
                ></SinglePromptModal>
              </div>
            </div>
          )}
          {errors.has("prompt_type") && (
            <div className="d-block invalid-feedback">
              {errors.first("prompt_type")}
            </div>
          )}
          {errors.has("prompt_id") && (
            <div className="d-block invalid-feedback">
              {errors.first("prompt_id")}
            </div>
          )}
        </div>
      </div>
      {hasRole("orgadmin") && (
        <>
          <div className="add-program3-grid-3-7">
            <div className="col col-sm-3">
              <h6>Response Privacy</h6>
              <p>who can see the response.</p>
            </div>
            <div className="add-program3-grid-100 col col-sm-9">
              <Form.Check
                type="radio"
                className="add-program3-grid-3-71"
                name="response_privacy"
                id="check-private-response"
                label="Private Response"
                onChange={() => setForms({ ...forms, response_privacy: false })}
                checked={forms.response_privacy === false}
              />

              <Form.Check
                type="radio"
                className="add-program3-grid-3-71"
                name="response_privacy"
                id="response-visible-to-instuctor"
                label="Response visible to instructor"
                onChange={() => setForms({ ...forms, response_privacy: true })}
                checked={forms.response_privacy === true}
              />
            </div>
          </div>

          <div className="add-program3-grid-3-8">
            <div className="col col-sm-3">
              <h6>Notification Setting</h6>
              <p>Get an alert when this item is completed.</p>
            </div>
            <div className="add-program3-grid-100 col col-sm-9">
              <Form.Check
                type="radio"
                className="add-program3-grid-3-81"
                id="radio-do-not-notify"
                label="Don't notify instructor"
                onChange={() =>
                  setForms({ ...forms, notify_instructor: false })
                }
                checked={forms.notify_instructor === false}
              />

              <Form.Check
                type="radio"
                className="add-program3-grid-3-81"
                id="radio-notify-instructor"
                label="Notify instructor"
                onChange={() => setForms({ ...forms, notify_instructor: true })}
                checked={forms.notify_instructor === true}
              />
            </div>
          </div>
        </>
      )}

      <div className="add-program3-grid-3-9">
        <div className="col col-sm-3">
          <h6>Timing</h6>
          <p>When can a student access this item?</p>
        </div>
        <div className="col col-sm-9">
          <div className="add-program3-grid-100">
            <Form.Check
              type="radio"
              className="add-program3-grid-3-91"
              name="timing"
              id="check-immediate-access"
              label="Immedidate Access"
              checked={forms.access_type === ACCESS_TYPE.IMMEDIATE}
              onChange={() =>
                setForms({ ...forms, access_type: ACCESS_TYPE.IMMEDIATE })
              }
            />
            <Form.Check
              type="radio"
              className="add-program3-grid-3-91"
              name="timing"
              id="check-timed-access"
              label="Timed Access"
              checked={forms.access_type === ACCESS_TYPE.TIMED}
              onChange={() =>
                setForms({ ...forms, access_type: ACCESS_TYPE.TIMED })
              }
            />
          </div>

          {errors.has("access_type") && (
            <div className="d-block invalid-feedback">
              {errors.first("access_type")}
            </div>
          )}

          {forms.access_type === ACCESS_TYPE.TIMED && (
            <div className="mt-2 timed-access">
              <label> Become available -</label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="number"
                  name="value"
                  placeholder="visible after"
                  size="sm"
                  value={forms.access_time.value}
                  onChange={(e) => handleScheduleChange(e)}
                ></Form.Control>

                <select
                  name="unit"
                  className="form-select form-select-sm"
                  onChange={(e) => handleScheduleChange(e)}
                >
                  <option value="">Select Date Type</option>
                  <option
                    value={"hours"}
                    selected={forms.access_time.unit === "hours"}
                  >
                    hours
                  </option>
                  <option
                    value={"days"}
                    selected={forms.access_time.unit === "days"}
                  >
                    days
                  </option>
                  <option
                    value={"month"}
                    selected={forms.access_time.unit === "month"}
                  >
                    month
                  </option>
                </select>
              </div>
              {errors.has("access_time.value") && (
                <div className="d-block invalid-feedback">
                  {errors.first("access_time.value")}
                </div>
              )}
              {errors.has("access_time.unit") && (
                <div className="d-block invalid-feedback">
                  {errors.first("access_time.unit")}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {forWizard && (
        <div className="entry-form-buttons" style={{ marginTop: 60 }}>
          <div className="entry-form-btn-1">
            <CloseFormButton />
            {/* <Button className="entry-form-btn-1-1" type="reset">
              <p>Cancel</p>
            </Button> */}

            <Button
              className="entry-form-btn-1-2"
              type="submit"
              value="exit"
              disabled={processing}
            >
              <p> Save and Exit</p>
            </Button>
          </div>
          <div className="entry-form-btn-2">
            <Button className="entry-form-btn-2-1" type="submit" value="add">
              <FontAwesomeIcon
                className="add-button"
                icon={faCirclePlus}
                style={{ fontSize: 16 }}
              ></FontAwesomeIcon>
              <p> Save and Add Item</p>
              {/* <h6 className="add-program3-grid-2-3"></h6> */}
            </Button>
            <Button
              className="entry-form-btn-2-2"
              type="submit"
              value="next"
              disabled={processing}
            >
              <p> Save and Next</p>
            </Button>
          </div>
        </div>
      )}

      {!forWizard && (
        <div className="d-flex justify-content-end gap-2 border-top py-3 mt-3 entry-modal">
          <Button type="button" className="close-btn" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" className="save-btn">
            Save
          </Button>
        </div>
      )}
    </Form>
  );
}
