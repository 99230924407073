import { toast } from "react-toastify";
import api from "../api";

export function submitWrittenAnswer(data) {
  const promise = api.post(`answer`, data);

  toast.promise(promise, {
    pending: "Submitting answer ",
    success: "Answer has bee added.",
    error: "Error while adding this answer !",
  });

  return promise;
}

export function submitVideoAnswer(data) {
  const promise = api.post(`answer`, data);

  toast.promise(promise, {
    pending: "Submitting answer ",
    success: "Your answer has been submitted.",
    error: "Error while adding this answer !",
  });

  return promise;
}

export function submitMarkedAnswer(data){
  const promise = api.post(`answer`, data);
  toast.promise(promise, {
    pending: "Submitting answer ",
    success: "Your answer has been submitted.",
    error: "Error while adding this answer !",

  });
  return promise
}
