import { useState } from "react";
import { Button } from "react-bootstrap";
import VideoRecorder from "../VideoRecorder";
import VideoRecorderS3 from "../VideoRecorderS3";

function VideoAnswer({ entry = null, prompt, onSubmit, onCancel }) {
  const [videoFileId, setVideoFileId] = useState(null);

  function saveAnswer(e) {
    e.preventDefault();
    onSubmit(videoFileId);
  }

  const isProduction = () => {
    return window.location.hostname === "app.encapsulator.io";
  };

  return (
    <div>
      <div className="text-muted my-2">
        Hit Start Recording when you are ready
      </div>
      <div>
        {isProduction() ? (
          <VideoRecorder
            entry={entry}
            prompt={prompt}
            onRecordComplete={(videoId) => setVideoFileId(videoId)}
          />
        ) : (
          <VideoRecorderS3
            entry={entry}
            prompt={prompt}
            onRecordComplete={(videoId) => setVideoFileId(videoId)}
          />
        )}
      </div>
      {videoFileId && (
        <div className="d-flex justify-content-around mb-3">
          <Button
            className="btn btn-primary exercise-button me-3"
            type="button"
            onClick={saveAnswer}
          >
            Submit Your Entry
          </Button>
          <Button
            type="button"
            className="exercise-cancel-button btn-light"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}

export default VideoAnswer;
