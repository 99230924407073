import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api";
import Errors from "../../../utils/Errors";
import "../../../assets/css/dashboard_addprogram4.css";
import StepsProgressBar from "./StepsProgressBar";
import BasicTextEditor from "../../../Components/editor/BasicTextEditor";
import { toast } from "react-toastify";
import MyFilePond from "./MyFilepond";
import FilepondPreview from "./FilepondPreview";
import FilePondImagePreview from "./FilePondImagePreview";
import UpdateProgramFinalVideo from "../../../Components/file/UpdateProgramFinalVideo";
import CloseFormButton from "../../../Components/layouts/CloseFormButton";
import BadgeUpload from "../../setting/partials/BadgeUpload";

export default function ProgramCompletionForm({ program = null }) {
  const { programId } = useParams();
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const { editMode } = queryParameters.get("edit") ? true : false;

  let updateMode = false;

  const initialState = {
    programId: programId,
    congratulation_heading: "",
    final_video_id: "",
    final_video_url: "",
    congratulation_text: "",
    review_reminder: false,
    explanation_text: "",
  };

  if (program) {
    updateMode = true;
    // button = "Update";
    initialState.programId = programId;
    initialState.congratulation_heading = program.congratulation_heading;
    initialState.final_video_id = program.final_video_id;
    initialState.final_video_url = program.final_video_url;
    initialState.congratulation_text = program.congratulation_text;
    initialState.review_reminder = program.review_reminder;
    initialState.explanation_text = program.explanation_text;
    initialState.final_video_url_uploaded = program.final_video_url_uploaded;
  }

  const [forms, setForms] = useState(initialState);

  function handleChange(e) {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await api({
        method: updateMode ? "put" : "post",
        url: updateMode
          ? `program-wizard/update-program-completion/${program.id}`
          : `program-wizard/save-program-completion/${programId}`,
        data: forms,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success(
          updateMode ? "Program has been updated" : "Program details saved"
        );
        if (!updateMode) {
          navigate(`/admin/programs/${programId}`);
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.headers) {
          setErrors(new Errors(error.response.data.message));

          toast.error(
            updateMode
              ? "Access denied ! As organization you are not allowed to update public program"
              : "Failed to process form"
          );
        }

        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }

    setProcessing(false);
  }

  return (
    <Form method="post" onSubmit={(e) => handleSubmit(e)}>
      <div className="add-program4-grid">
        <div>
          <div className="add-program4-grid-4-1">
            <div className="col col-sm-3">
              <h6>Congratulations Heading</h6>
            </div>

            <div className="col col-sm-6">
              <Form.Control
                type="text"
                name="congratulation_heading"
                className="add-program3-form"
                value={forms.congratulation_heading}
                onChange={handleChange}
              />
              {errors.has("congratulation_heading") && (
                <div className="d-block invalid-feedback">
                  {errors.first("congratulation_heading")}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="add-program4-grid-4-2">
          <div className="col col-sm-3">
            <h6>Final Video (Optional)</h6>
            <p>
              An optional video introducing the program and what people can
              expect.
            </p>
          </div>

          <div className="col col-sm-6">
            <div>
              <MyFilePond
                maxFiles={1}
                allowedFileTypes={["video/*"]}
                onUploadComplete={(ids) => {
                  ids.length && setForms({ ...forms, final_video_id: ids[0] });
                }}
              />
            </div>
            <FilepondPreview />
            <div>
              {forms.final_video_url_uploaded &&
              forms.final_video_url_uploaded ? (
                <div className="vid">
                  <video controls className="video">
                    <source
                      src={forms.final_video_url_uploaded}
                      type="video/mp4"
                    />
                  </video>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="text-center my-2">OR</div>
            <Form.Control
              className="add-program2-form-1"
              type="text"
              name="final_video_url"
              size="50"
              onChange={handleChange}
              value={forms.final_video_url}
              placeholder="Paste an video URL"
            />
          </div>
        </div>

        <div className="add-program4-grid-4-3">
          <div className="col col-sm-3">
            <h6>Congratulations Text</h6>
          </div>
          <div className="col col-sm-6">
            <BasicTextEditor
              value={forms.congratulation_text}
              onChange={(value) =>
                setForms({ ...forms, congratulation_text: value })
              }
            />
            {errors.has("congratulation_text") && (
              <div className="d-block invalid-feedback">
                {errors.first("congratulation_text")}
              </div>
            )}
          </div>
        </div>

        <div className="add-program4-grid-4-4">
          <div className="col col-sm-3">
            <h6>Review Reminder</h6>
            <p>Remind users to review and reflect on their answers.</p>
          </div>
          <div className="col col-sm-9">
            <div className="add-program4-grid-100">
              <Form.Check
                type="radio"
                className="add-program4-grid-4-41"
                name="review_reminder"
                id="donot-remind"
                label="Don’t remind"
                onChange={() => setForms({ ...forms, review_reminder: true })}
                checked={forms.review_reminder === true}
              />
              <Form.Check
                type="radio"
                className="add-program2-grid-4-42"
                name="review_reminder"
                id="review-and-reflect"
                label="Review and Reflect"
                onChange={() => setForms({ ...forms, review_reminder: false })}
                checked={forms.review_reminder === false}
              />
            </div>
            {errors.has("review_reminder") && (
              <div className="d-block invalid-feedback">
                {errors.first("review_reminder")}
              </div>
            )}
          </div>
        </div>

        <div className="add-program4-grid-4-5">
          <div className="col col-sm-3">
            <h6>Explanation Text</h6>
            <p>Remind users to review and reflect on their answers.</p>
          </div>
          <div className="col col-sm-6">
            <BasicTextEditor
              value={forms.explanation_text}
              onChange={(value) =>
                setForms({ ...forms, explanation_text: value })
              }
            />
            {errors.has("explanation_text") && (
              <div className="d-block invalid-feedback">
                {errors.first("explanation_text")}
              </div>
            )}
          </div>
        </div>

        <div className="add-program4-grid-4-6">
          <div className="col col-sm-3">
            <h6>Profile Badge (Optional)</h6>
            <p>Users will receive a badge to show on their profile.</p>
          </div>

          <div className="col col-sm-6">
            <BadgeUpload program={program} />
          </div>
        </div>

        <div
          className="program-completion-form-buttons"
          style={{ marginTop: 60 }}
        >
          <div className="program-completion-form-btn-1">
            {/* <Button
              className="program-completion-form-btn-1-1"
              type="reset"
              disabled={processing}
            >
              <p>Cancel</p>
            </Button> */}
            <CloseFormButton />
          </div>
          <div className="program-completion-form-btn-2">
            <Button
              className="program-completion-form-btn-2-1"
              type="submit"
              disabled={processing}
            >
              <p>{editMode ? "Update" : "Save"} Program</p>
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
}
