import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { async } from "videojs-record";
import api from "../../../api";
import { toast } from "react-toastify";

export default function CancelSubscriptionButton() {
  const MySwal = withReactContent(Swal);

  const cancelSubscription = () => {
    api.post("cancel-subscription").then((res) => {
      MySwal.fire("Your subscription has been cancelled").then(() =>
        window.location.reload()
      );
    });
  };

  const handleSubmit = async () => {
    const result = await MySwal.fire({
      title: <p className="text-">Confirm Cancellation</p>,
      html: `<div class="text-start">
                  <div>Are you sure you want to cancel your subscription? </div>
            </div>`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    });
    if (result.isConfirmed) {
      cancelSubscription();
    }
  };

  return (
    <button type="button" onClick={handleSubmit} className="cancel-plan">
      cancel plan
    </button>
  );
}
