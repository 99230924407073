import ENCAP_LOGO from "../../../assets/image/logo.PNG";

const SetupAccountPrompt = ({ organizationName, email, onAccept }) => {
  return (
    <section className="text-center">
      <img
        src={ENCAP_LOGO}
        alt="Encapsulator"
        className="img-fluid mb-4"
        style={{ height: "4rem" }}
      />
      <h3 className="h3-responsive mb-4">Welcome to Encapsulator</h3>
      <p>
        We're delighted to invite you to join{" "}
        <span className="fw-bold">{organizationName}</span> as a manager.
        <span>
          It seems that you don't have an account in Encapsulator yet. Please
          follow the link below to setup your account and accept the invite.
        </span>
      </p>
      <div className="">
        <button
          type="button"
          onClick={() => onAccept && onAccept()}
          className="btn btn-primary rounded-1"
        >
          Set up your account
        </button>
      </div>
      <p className="mt-4 text-muted fs-6">
        Note: This invitation was intended for{" "}
        <span className="fw-bolder">{email}</span>. If you were not expecting
        this invitation, you can ignore this.
      </p>
    </section>
  );
};

export default SetupAccountPrompt;
