import { faCircleXmark, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../Components/layouts/header";
import SideBar from "../../Components/layouts/sidebar";

import "../../assets/css/dash_notification.css";

function Notification() {
  return (
    <>
      <div className="noti-grid-1">
        <div className="noti-grid-1-1">
          <h4>Notifications</h4>
          <p>Here’s what’s going on.</p>
        </div>

        <button className="noti-grid-1-2">
          <FontAwesomeIcon
            icon={faCircleXmark}
            color="green"
            style={{ fontSize: 15 }}
          ></FontAwesomeIcon>
          <h5>Clear All</h5>
        </button>
      </div>

      <div style={{ marginTop: 20 }}>
        <div className="noti-grid-2">
          <h6 className="noti-grid-2-1">Yesterday</h6>
          <div className="noti-grid-2-2">
            <p>Don’t forget to add to your</p>
            <button>Journal</button>
          </div>
          <button className="noti-grid-2-3">
            <FontAwesomeIcon
              icon={faXmark}
              style={{ fontSize: 15 }}
            ></FontAwesomeIcon>
            <h6>Clear</h6>
          </button>
        </div>
      </div>

      <div className="noti-grid-2">
        <h6 className="noti-grid-2-1">3d ago</h6>
        <div className="noti-grid-2-2">
          <p>There is a new exercise available in</p>
          <button>Management Training</button>
        </div>
        <button className="noti-grid-2-3">
          <FontAwesomeIcon
            icon={faXmark}
            style={{ fontSize: 15 }}
          ></FontAwesomeIcon>
          <h6>Clear</h6>
        </button>
      </div>

      <div className="noti-grid-2">
        <h6 className="noti-grid-2-1">5d ago</h6>
        <div className="noti-grid-2-2">
          <p>There is a new exercise available in</p>
          <button>Gratitude Program</button>
        </div>
        <button className="noti-grid-2-3">
          <FontAwesomeIcon
            icon={faXmark}
            style={{ fontSize: 15 }}
          ></FontAwesomeIcon>
          <h6>Clear</h6>
        </button>
      </div>

      <div className="noti-grid-2">
        <h6 className="noti-grid-2-1">7d ago</h6>
        <div className="noti-grid-2-2">
          <p>You’ve been invited to join the</p>
          <button>Gratitude Program</button>
        </div>
        <button className="noti-grid-2-3">
          <FontAwesomeIcon
            icon={faXmark}
            style={{ fontSize: 15 }}
          ></FontAwesomeIcon>
          <h6>Clear</h6>
        </button>
      </div>
    </>
  );
}

export default Notification;
