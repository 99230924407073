import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import classNames from "classnames";
import api from "../../../api";

const OrganizationFilterBar = ({ value, onChange }) => {
  const [counts, setCounts] = useState({});

  const { getAuthUser, isLoggedIn } = useContext(AuthContext);
  const authUser = getAuthUser();

  useEffect(() => {
    const fetchTabItemCounts = () => {
      if (!isLoggedIn()) return;
      api
        .get("tab-counts/program-library-page")
        .then((response) => {
          setCounts(response.data);
        })
        .catch((error) => console.error(error));
    };
    fetchTabItemCounts();
  }, [isLoggedIn]);

  const applyFilter = (key, value) => {
    onChange && onChange(key, value);
  };

  if (!authUser?.organizations) {
    return null;
  }

  return (
    <nav className="nav nav-tabs nav-fill nav-tabs-scrollable mb-3 mb-md-0" role="tablist">
      {authUser?.organizations?.map((org, i) => (
        <button
          className={classNames("nav-link ", {
            active: value === org.id,
          })}
          type="button"
          onClick={() => applyFilter("organization_id", org.id)}
          key={org.id}
        >
          {org.name}
          {counts.hasOwnProperty(org.id) && (
            <span className="tab-counts-badge">{counts[org.id]}</span>
          )}
        </button>
      ))}
      <button
        className={classNames("nav-link ", {
          active: value === null,
        })}
        type="button"
        onClick={() => applyFilter("organization_id", null)}
      >
        <span>Public Program</span>
        {counts.hasOwnProperty("0") && (
          <span className="tab-counts-badge">{counts[0]}</span>
        )}
      </button>
    </nav>
  );
};

export default OrganizationFilterBar;
