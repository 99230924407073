import { useState } from "react";
import { Button } from "react-bootstrap";
import Errors from "../../utils/Errors";
import { useRef } from "react";
import FilepondPreview from "../../pages/program/wizard/FilepondPreview";
import api from "../../api";
import { toast } from "react-toastify";

function UpdateProgramFinalVideo({program_id}){
    const [errors, setErrors] = useState(new Errors());
    const [processing, setProcessing] = useState(false);
    const [file, setFile] = useState("");
    const inputFile = useRef(null);

    const handleFileUpload = (e) => {
        e.preventDefault();
        setProcessing(true);
        const files = e.target.files;
        if(files){
            setFile(URL.createObjectURL(e.target.files[0]));

            const formData = new FormData();
            formData.append('file', files[0], files[0].name);

            api.post(`update-program-final-video/${program_id}`, formData).then((res) => {
                if(res){
                    toast.success('Video Updated Sucessfully!');
                }
            }).catch((error) => {
                toast.error('Failed to process update video');
            })
            .finally(() => setProcessing(false));

        }
    };

    const handleFinalVideoUpdate = () => {
        inputFile.current.click();
    }
    return ( <div>
        <input type="file" style={{display:"none"}} ref={inputFile} onChange={handleFileUpload}/>
        
        {processing ? <Button>Please wait...</Button> : <Button onClick={handleFinalVideoUpdate}>Edit</Button>}
        <FilepondPreview />
    </div>)
}

export default UpdateProgramFinalVideo;