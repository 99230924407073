import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url, name = "", autoplay = true }) => {
  const [playing, setPlaying] = useState(autoplay);
  function isYouTubeLink(link) {
    // Regular expression to match YouTube video links
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]{11}$/;

    return youtubeRegex.test(link);
  }

  let lightMode = isYouTubeLink(url);

  const handleVideoPlaybackStatus = (event) => {
    const { name: videoName, playing: status } = event.detail;
    if (videoName === name) {
      setPlaying(status);
    }
  };

  useEffect(() => {
    window.addEventListener("videoPlaybackChange", handleVideoPlaybackStatus);

    return () => {
      window.removeEventListener(
        "videoPlaybackChange",
        handleVideoPlaybackStatus
      );
    };
  }, []);

  return (
    <div className="aspect-ratio-wide rounded overflow-hidden">
      <ReactPlayer
        url={url}
        controls={true}
        pip={true}
        width="100%"
        height="100%"
        light={lightMode}
        playing={playing}
        onPlay={() => setPlaying(true)}
      />
    </div>
  );
};

export default VideoPlayer;
