import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function BeforeSurveyModal(props) {
//   console.log(props);

  const handleClose = () => props.onHide();
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Start Your Survey</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          
          <iframe src={props.survey_before} width="100%" height="600px"></iframe>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default BeforeSurveyModal;
