import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import SupportFeedbackModal from "../../../pages/feedback/SupportFeedbackModal";

const SupportFeedbackLink = () => {
  const [show, setShow] = useState(0);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  return (
    <>
      <button type="button" className="sidebar__link" onClick={handleShow}>
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faQuestionCircle}
        ></FontAwesomeIcon>
        <span>Support & Feedback</span>
      </button>
      <SupportFeedbackModal show={show} onHide={handleHide} />
    </>
  );
};

export default SupportFeedbackLink;
