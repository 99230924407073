import axios from "axios";
import { baseURL } from "./config/config";
import { useContext, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";

const api = axios.create({
  baseURL: baseURL,
  // headers: {
  //   Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  // },
});
export default api;

export const guestApi = axios.create({
  baseURL: baseURL.replace(/\/$/, ""),
});

const setAuthHeader = (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export const useAxiosAuthInterceptor = () => {
  const { token, setTokenExpired, logout } = useContext(AuthContext);
  setAuthHeader(localStorage.getItem("token") || 'No token found');

  useEffect(() => {
    const interceptor = api.interceptors.request.use(
      (config) => {
        // console.log("setting token");
        setAuthHeader(token);
        return config;
      },
      (error) => {
        console.log("removing token");
        new Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.request.eject(interceptor);
    };
  }, [token]);

  useEffect(() => {
    const responseIntercecptor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 419)
        ) {
          // Unauthenticated or token mismatch error occured
          const excludePaths = ["/login", "/register", "accept-invitation", "/get-auth-user-info"];
          const currentPath = error.config.url.split(axios.defaults.baseURL)[0];
          console.log('request path' + currentPath);

          const isExcluded = excludePaths.some((excludePath) =>
            currentPath.startsWith(excludePath)
          );

          if (!isExcluded) {
            // window.location.href = "/login";
            logout();
            setTokenExpired(true);
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      api.interceptors.response.eject(responseIntercecptor);
    };
  });
};
