import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../../../assets/css/prog_summary.css";
import classNames from "classnames";

function EntryLists({ entries, program }) {
  const { entryId: activeEntryId } = useParams();
  const navigate = useNavigate();

  // entries = entries.filter(
  //   (entry) => !entry.is_additional || entry.is_completed
  // );

  const handleEntryItemClick = (entry) => {
    if (entry.id === parseInt(activeEntryId)) {
      dispatchEvent(new Event("open-answer-modal"));
      return;
    }
    navigate(`/programs/${entry.program_id}/entries/${entry.id}`);
  };

  const CompletedIcon = () => {
    return (
      <FontAwesomeIcon
        className="exercise-check-icon"
        icon={faCheck}
        color="#fff"
        style={{ fontSize: 12 }}
      ></FontAwesomeIcon>
    );
  };

  const UncompletedIcon = () => {
    return (
      <FontAwesomeIcon
        className="exercise-arrow-icon"
        icon={faArrowRight}
        color="#fff"
        style={{ fontSize: 12 }}
      ></FontAwesomeIcon>
    );
  };

  return (
    <div className="d-flex flex-column gap-2">
      {entries.map((entry, index) => (
        <button
          type="button"
          onClick={() => handleEntryItemClick(entry)}
          className={classNames(
            "entry-left-list",
            {
              "not-available": !entry.is_available,
              active: entry.id === parseInt(activeEntryId),
              "is-additional": entry.is_additional,
            }
          )}
          key={index}
        >
          <div className="entry-title">
            {index + 1}.{" "}
            {/* {entry.title && entry.title.length > 20
              ? entry.title.substring(0, 19) + "..."
            : entry.title} */}
            {entry.title}
          </div>
          <div className="entry-icon-prgp">
            {!program.isCompleted && (
              <>
                {entry.is_available ? (
                  <span className="availability-label">Available Now</span>
                ) : (
                  <span className="availability-label">
                    {entry.available_at?.human ?? "Later"}
                  </span>
                )}
              </>
            )}
            {entry.is_completed ? <CompletedIcon /> : <UncompletedIcon />}
          </div>
        </button>
      ))}
    </div>
  );
}

export default EntryLists;
