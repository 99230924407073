import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { AuthContext } from "../../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const UserOptionDropdown = ({ user }) => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getUserPlan = () => {
    if (user.role === "superadmin") {
      return "Super Admin";
    }

    return `${user.plan} Plan`;
  };

  return (
    <Dropdown className="user-options-dropdown">
      <Dropdown.Toggle variant="light">
        <img
          src={user.avatar ? user.avatar : ""}
          style={{
            height: "2rem",
            width: "2rem",
            borderRadius: "50%",
          }}
          alt={user.first_name}
        />
        {/* <span className="name">{user.first_name } John MacDorthy</span> */}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Link to="settings/profile" className="dropdown-item d-flex gap-2">
          <img
            src={user.avatar ? user.avatar : ""}
            style={{
              height: "3rem",
              width: "3rem",
              borderRadius: "8px",
            }}
            alt={user.first_name}
          />
          <div>
            <div>
              {user.first_name} {user.last_name}
            </div>
            <small>{getUserPlan()}</small>
          </div>
        </Link>
        <Dropdown.Item onClick={() => handleLogout()}>
          <FontAwesomeIcon icon={faSignOut} className="me-2"></FontAwesomeIcon>
          <span>Logout</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserOptionDropdown;
