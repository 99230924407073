import EntryDetail from "./EntryDetail";

const EntryDetailAccordion = ({ entry }) => {
  return (
    <div class="accordion mb-4" id="entry-detail-accordion">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#entry-accordion-one"
            aria-expanded="false"
            aria-controls="entry-accordion-one"
          >
            {entry.title}
          </button>
        </h2>
        <div
          id="entry-accordion-one"
          class="accordion-collapse collapse"
          data-bs-parent="#entry-detail-accordion"
        >
          <div class="accordion-body">
            <EntryDetail entry={entry} showTitle={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntryDetailAccordion;
