import { Navigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import WizardNavigation from "./WizardNavigation";
import ProgramCompletionForm from "../wizard/ProgramCompletionForm";

function CompletionTab() {
  const { programId } = useParams();
  const { data: program, loading } = useFetch(
    `program-wizard/edit-program-completion/${programId}`
  );

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <main>
      <section>
        <div className="add-program4-grid-11">
          <h5>Program Completion</h5>
          <p>Determine what happens when users finish a program.</p>
        </div>
      </section>

      {program && <ProgramCompletionForm program={program} />}
    </main>
  );
}

export default CompletionTab;
