import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "./../../../context/AuthContext";

const OrganizationSubscriptionItem = ({ organization }) => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();

  const formatDate = (date) => {
    return moment(date).format("MMM D, YYYY");
  };

  return (
    <div className="d-flex justify-content-between p-3 bg-white rounded border">
      <div>
        <label className="text-muted">Organization</label>
        <div>{organization.name}</div>
      </div>
      <div>
        <label className="text-muted">Role</label>
        <div>
          {user?.organization?.id === organization.id ? "Admin" : "Member"}
        </div>
      </div>
      <div>
        <label className="text-muted">Joined At</label>
        <div>{formatDate(organization.joined_at)}</div>
      </div>
      <div>
        <label className="text-muted">Expires At</label>
        <div>{formatDate(organization.licensed_until)}</div>
      </div>
      <div>
        <label className="text-muted">Status</label>
        {organization.is_subscription_active ? (
          <div className="d-block badge bg-success">Active</div>
        ) : (
          <div className="d-block badge bg-danger">Expired</div>
        )}
      </div>
    </div>
  );
};

export default OrganizationSubscriptionItem;
