import { Navigate, useNavigate, useParams } from "react-router-dom";
import StepsProgressBar from "./StepsProgressBar";
import ProgramCompletionForm from "./ProgramCompletionForm";
import LoadingBlock from "../../../Components/LoadingBlock";
import useFetch from "../../../hooks/useFetch";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgramMainNav from "../../../Components/layouts/ProgramMainNav";

export default function ProgramCompletionPage() {
  const { programId } = useParams();
  const navigate = useNavigate();
  const { data: program, loading } = useFetch(`programs/${programId}`);

  if (loading) {
    return <LoadingBlock />;
  }

  return (
    <main>
      <section>
        <ProgramMainNav />
      </section>
      <section>
        <div className="add-program4-grid-1">
          <div className="add-program4-grid-1-1">
            <h5>Program Completion</h5>
            <p>Determine what happens when users finish a program.</p>
          </div>
          <StepsProgressBar current="4" total="4" />
        </div>
      </section>

      {program && <ProgramCompletionForm program={program.data} />}
    </main>
  );
}
