// import SAD_EMOJI from "../assets/image/sad-emoji.png";
import SAD_EMOJI from "../assets/image/NODATA.png";

export default function NoDataCard({ title, text = null, action = null }) {
  return (
    <div className="no-data-found">
      <img
        src={SAD_EMOJI}
        className="mb-2"
        alt="No Data Found"
        style={{ maxWidth: "200px" }}
      />
      <h2>{title}</h2>
      {text && <p className="text-muted">{text}</p>}
      {action}
    </div>
  );
}
