// import useWindowSize from "react-use/lib/useWindowSize";
import ReactConfetti from "react-confetti";
import { useEffect, useState } from "react";

export default function Confetti() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ReactConfetti
      width={windowSize.width}
      height={windowSize.height}
      numberOfPieces={500}
      recycle={false}
      gravity={0.3}
      wind={0.01}
      initialVelocityX={8}
      initialVelocityY={10}
      colors={["#ff9c4a", "#fc3c3c", "#ffaa00", "#fc2c2c"]}
    />
  );
}

// export default Confetti;
