import { Modal } from "react-bootstrap";
import ChangePaymentMethodForm from "./ChangePaymentMethodForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripe_publishable_key } from "../../../config/config";

export default function ChangePaymentMethodModal({ show, onHide }) {
  const stripePromise = loadStripe(stripe_publishable_key);

  const handleHide = () => {
    onHide && onHide();
  };

  return (
    <Modal onHide={handleHide} show={show} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>Update card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <Elements stripe={stripePromise}>
            <ChangePaymentMethodForm />
          </Elements>
        </div>
      </Modal.Body>
    </Modal>
  );
}
