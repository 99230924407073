import {
  faArrowRight,
  faPlusCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/organization_program.css";
import { useEffect, useState } from "react";
import api from "../../api";
import Pagination from "../../Components/Pagination";
import moment from "moment";
import { Button, Card } from "react-bootstrap";
import { RestoreProgramAction } from "../../utils/Program";
import LoadingBlock from "../../Components/LoadingBlock";
import NoDataCard from "../../Components/NoDataCard";
import classNames from "classnames";
import defaultImage from "../../assets/image/default-image.png";
import PaginationSize from "../../Components/PaginationSize";

function AdminProgram() {
  const [programs, setPrograms] = useState([]);
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const STATUS = {
    ACTIVE: "active",
    ARCHIVED: "archived",
    DRAFT: "draft",
    ALL: "all",
  };
  const [filter, setFilter] = useState({
    status: STATUS.ACTIVE,
    search: "",
    per_page: 16,
  });

  function fetchPrograms(page = 1) {
    setLoading(true);
    setPrograms([]);
    api
      .get("admin/programs", {
        params: {
          page,
          status: filter.status,
          search: filter.search,
          per_page: filter.per_page,
        },
      })
      .then((response) => {
        setPrograms(response.data);
        setCounts(response.data.counts);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPrograms();
  }, [filter]);

  function handleFilterByStatus(value) {
    setFilter({ ...filter, status: value });
  }

  function formatDate(date) {
    return moment(date).format("D MMM, YYYY");
  }

  function RestoreProgram(programId) {
    RestoreProgramAction(programId).then((res) => {
      if (res) {
        navigate("/program-library");
      }
    });
  }

  return (
    <>
      <div className="d-flex admin-prog">
        <div className="organization-prog-grid-1">
          <h4>Programs</h4>
        </div>
        <div className="ms-auto admin-prog-1">
          <Link to="/add-program" className="organization-prog-4">
            <div className="organization-prog-4-1">
              <h5>Add a New Program</h5>
              <p>
                You can create your own program, or use an Encapsulator template
                as a base.
              </p>
            </div>
            <FontAwesomeIcon
              icon={faPlusCircle}
              color="green"
              style={{ fontSize: 25 }}
            ></FontAwesomeIcon>
          </Link>
        </div>
      </div>

      <div className="mt-4 admin-prog-nav-search-box">
        <nav>
          <div className="nav nav-tabs nav-fill nav-tabs-scrollable" id="nav-tab" role="tablist">
            <button
              className={classNames("nav-link ", {
                active: filter?.status === STATUS.ACTIVE,
              })}
              type="button"
              onClick={() => handleFilterByStatus(STATUS.ACTIVE)}
            >
              <span>Active</span>
              {counts.hasOwnProperty("published") && (
                <span className="tab-counts-badge">{counts.published}</span>
              )}
            </button>

            <button
              className={classNames("nav-link ", {
                active: filter.status === STATUS.ARCHIVED,
              })}
              type="button"
              onClick={(e) => handleFilterByStatus(STATUS.ARCHIVED)}
            >
              <span>Archived</span>
              {counts.hasOwnProperty("archived") && (
                <span className="tab-counts-badge">{counts.archived}</span>
              )}
            </button>

            <button
              className={classNames("nav-link ", {
                active: filter.status === STATUS.DRAFT,
              })}
              type="button"
              onClick={() => handleFilterByStatus(STATUS.DRAFT)}
            >
              <span>Draft</span>
              {counts.hasOwnProperty("draft") && (
                <span className="tab-counts-badge">{counts.draft}</span>
              )}
            </button>

            <button
              className={classNames("nav-link ", {
                active: filter.status === STATUS.ALL,
              })}
              type="button"
              onClick={() => handleFilterByStatus(STATUS.ALL)}
            >
              <span>All</span>
              {counts.hasOwnProperty("all") && (
                <span className="tab-counts-badge">{counts.all}</span>
              )}
            </button>
          </div>
        </nav>
        <div className="d-sm-flex">
          <div className="mb-3 mb-sm-0">
            <Link
              to="/arrange-programs"
              className="btn btn-primary rounded-2 text-nowrap me-2"
            >
              Arrange Programs
            </Link>
          </div>
          <div className="input-group rounded organization-search-box">
            <input
              type="search"
              className="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            />
            <span className="input-group-text border-0" id="search-addon">
              <FontAwesomeIcon
                icon={faSearch}
                className="search-icon"
              ></FontAwesomeIcon>
            </span>
          </div>
        </div>
      </div>

      <div>
        {loading && <LoadingBlock>Loading...</LoadingBlock>}

        {programs?.data?.length > 0 && (
          <div className="tab-content">
            <div className="container-fluid">
              <div className="row prog-row">
                {programs?.data?.map((program, index) => (
                  <div className="col col-sm-6 col-md-4 col-lg-3" key={index}>
                    <div className="organization-prog-grid-3-1 card">
                      <div className="imgBox">
                        <img
                          src={program.cover_image_url}
                          className="img"
                          alt="img"
                        />
                      </div>

                      <div className="program-type">
                        {program.is_free === false ? (
                          <p className="paid">Pro</p>
                        ) : (
                          <p className="free">Lite</p>
                        )}
                      </div>
                      <div className="program-card-body">
                        {program.category && (
                          <h6 className="training">{program.category}</h6>
                        )}
                        <Link
                          to={
                            filter.status !== STATUS.ARCHIVED
                              ? `/admin/programs/${program.id}`
                              : ""
                          }
                        >
                          <h5>
                            {program.program_name &&
                            program.program_name.length > 20
                              ? `${program.program_name.substring(0, 20)}...`
                              : program.program_name}
                          </h5>
                        </Link>
                        {program.created_at && program.updated_at && (
                          <p>
                            Created: {formatDate(program.created_at.raw)} |
                            Modified: {formatDate(program.updated_at.raw)}
                          </p>
                        )}

                        <div className="organization-prog-grid-3-4">
                          <div className="organization-prog-grid-3-3">
                            <h5>{program.completed_by_count ?? 0}</h5>
                            <h6>Completed</h6>
                          </div>
                          <div className="organization-prog-grid-3-3">
                            <h5>{program.active_users_count ?? 0}</h5>
                            <h6>Active</h6>
                          </div>
                          <div className="organization-prog-grid-3-3">
                            <h5>{program.enrolled_count ?? 0}</h5>
                            <h6>Enrolled</h6>
                            <br />
                          </div>
                        </div>
                        {filter.status !== STATUS.ARCHIVED ? (
                          <div className="organization-prog-grid-3-2">
                            <Link to={`/admin/programs/${program.id}`}>
                              <h5>View Program</h5>
                              <FontAwesomeIcon
                                className="organization-prog-arrow-icon"
                                icon={faArrowRight}
                                color="#FFFFFF"
                                style={{ fontSize: 15 }}
                              ></FontAwesomeIcon>
                            </Link>
                          </div>
                        ) : (
                          <div className="btn-group">
                            <Button
                              type="button"
                              className="btn-restore"
                              onClick={() => RestoreProgram(program.id)}
                            >
                              <p>Restore</p>
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {programs?.meta && (
              <div className="container-fluid">
                <div className="mt-4 d-flex justify-content-between align-items-center">
                  <PaginationSize
                    perPage={filter.per_page}
                    options={[2, 4, 8, 16, 40, 60, 100]}
                    onChange={(value) =>
                      setFilter({ ...filter, per_page: value })
                    }
                  />
                  <Pagination
                    meta={programs.meta}
                    onChange={(page) => fetchPrograms(page)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {!loading && !programs?.data?.length && (
        <NoDataCard
          title="You don't have any programs yet"
          text="Try adjusting your filter. Click on the Add Prorgam button to add a new program"
          action={<AddNewBtn />}
        />
      )}
    </>
  );
}

const AddNewBtn = () => {
  return (
    <Link to="/add-program" className="btn btn-primary">
      Add program
    </Link>
  );
};

export default AdminProgram;
