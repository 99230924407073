import { useContext, useState } from "react";
import { Form } from "react-router-dom";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";
import { ButtonSpinner } from "../../../Components/Encap";
import { guestApi } from "../../../api";
import { AuthContext } from "../../../context/AuthContext";
import TermsOfService from "../../../Components/auth/TermsOfService";
import PrivacyPolicy from "../../../Components/auth/PrivacyPolicy";

const SetupAccount = ({ email, token, onComplete }) => {
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [showTOSModal, setShowTOSModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);

  const [state, setState] = useState({
    email: email,
    token: token,
    first_name: "",
    password: "",
    password_confirmation: "",
  });
  const { login } = useContext(AuthContext);

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setState({ ...state, [name]: value });
  };

  const handleCompleteRegistration = () => {
    setProcessing(true);
    guestApi
      .post("organization-admin-invitation/setup-account", {
        token: state.token,
        first_name: state.first_name,
        last_name: state.last_name,
        password: state.password,
        password_confirmation: state.password_confirmation,
      })
      .then((response) => {
        if (response.data.token && response.data.user) {
          login(response.data.token, response.data.user);
          onComplete && onComplete();
        }
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          return;
        }

        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
            return false;
          }
          
        toast.error("Server Error");
      })
      .finally(() => setProcessing(false));
  };

  const RegisterIcon = () => {
    return (
      <div className="step-icon-wrap">
        <div className="step-icon">
          <FontAwesomeIcon icon={faArrowRightToBracket}></FontAwesomeIcon>
        </div>
      </div>
    );
  };

  return (
    <Form
      onSubmit={(e) => e.preventDefault()}
      className="setup-account"
      autoComplete="off"
    >
      <section>
        <RegisterIcon />
        <h5 className="step-heading">Setup Account</h5>

        <div className="mb-3">
          <input
            type="text"
            name="first_name"
            className={classNames("form-control rounded-1", {
              "is-invalid": errors.has("first_name"),
            })}
            onChange={handleInputChange}
            placeholder="First name"
          />
          {errors.has("first_name") && (
            <div className="d-block invalid-feedback">
              {errors.first("first_name")}
            </div>
          )}
        </div>
        <div className="mb-3">
          <input
            type="text"
            name="last_name"
            className={classNames("form-control rounded-1", {
              "is-invalid": errors.has("last_name"),
            })}
            onChange={handleInputChange}
            placeholder="Last name"
          />
          {errors.has("last_name") && (
            <div className="d-block invalid-feedback">
              {errors.first("last_name")}
            </div>
          )}
        </div>

        <div className="mb-3">
          <input
            type="password"
            name="password"
            className={classNames("form-control rounded-1", {
              "is-invalid": errors.has("password"),
            })}
            onChange={handleInputChange}
            placeholder="Password"
          />
          {errors.has("password") && (
            <div className="d-block invalid-feedback">
              {errors.first("password")}
            </div>
          )}
          <div className="password-help-text mt-1 text-muted">
            Minimum 8 characters,Uppercase & lowercase
            characters(A-Z),Digits(0-9)
          </div>
        </div>
        <div className="mb-3">
          <input
            type="password"
            name="password_confirmation"
            className="form-control rounded-1"
            onChange={handleInputChange}
            placeholder="Confirm password"
          />
        </div>
        <div className="mb-3 d-flex text-muted">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="terms-and-conditions"
              onChange={() => setPolicyAccepted(!policyAccepted)}
              checked={policyAccepted}
            />
            <label class="form-check-label" for="terms-and-conditions">
              I agree to the{" "}
              <button
                type="button"
                onClick={() => setShowTOSModal(true)}
                tabIndex="-1"
              >
                Terms of Service
              </button>{" "}
              and{" "}
              <button
                type="button"
                onClick={() => setShowPrivacyPolicyModal(true)}
                tabIndex="-1"
              >
                Privacy Policy
              </button>
            </label>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleCompleteRegistration}
            disabled={processing || !policyAccepted}
          >
            <ButtonSpinner processing={processing} />
            Register
          </button>
        </div>
      </section>
      <TermsOfService
        show={showTOSModal}
        onHide={() => setShowTOSModal(false)}
      />
      <PrivacyPolicy
        show={showPrivacyPolicyModal}
        onHide={() => setShowPrivacyPolicyModal(false)}
      />
    </Form>
  );
};

export default SetupAccount;
