import { Modal } from "react-bootstrap";
import ContributorForm from "./ContributorForm";

const ContributorFormModal = ({
  show,
  onHide,
  programId,
  contributor = null,
  onChange
}) => {
  const updateMode = contributor ? true : false;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="support-feedback-modal font-secondary p-4 bg-white rounded">
          <div className="mb-3">
            <div className="d-flex gap-2">
              <h4 className="fw-bolder">
                {updateMode ? "Edit" : "Add"} Contributor
              </h4>
            </div>
          </div>
          <div>
            <ContributorForm
              programId={programId}
              contributor={contributor}
              onCancel={onHide}
              onChange={onChange}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContributorFormModal;
