import { useState } from "react";
import { Modal } from "react-bootstrap";
import api from "../../../api";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";
import { ButtonSpinner } from "../../../Components/Encap";

const ExtendLicenseModal = ({ show, onHide, id, numberOfLicense }) => {
  const [state, setState] = useState({
    years: 1,
    cost_per_license: 99,
    total_cost: 99 * numberOfLicense,
    invoice_number: "",
  });
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);

  const submit = () => {
    setProcessing(true);
    api
      .post(`extend-license`, {
        organization_id: id,
        ...state,
      })
      .then((response) => {
        toast.success("Action completed");
        window.dispatchEvent(new CustomEvent("organizationSeatsChanged"));
        onHide();
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          toast.error("Validation Failed");
          return;
        }
        console.log(error);
        if (error?.response?.data?.error) {
          toast.error("Server error: " + error.response.data.error);
          return;
        }
        toast.error("Server Error");
        console.log(error);
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="p-4 bg-white rounded">
          <div className="d-flex gap-2">
            <h4 className="mb-3 font-secondary fw-bolder">Extend License</h4>
          </div>

          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="mb-1">No. of seats</label>
              <input
                type="number"
                className="form-control"
                value={numberOfLicense}
                disabled
              />
            </div>

            <div className="mb-3 col-md-6">
              <label className="mb-1">Number of years</label>
              <input
                type="number"
                className="form-control"
                value={state.years}
                onChange={(e) => setState({ ...state, years: e.target.value })}
                min={1}
              />
              {errors.has("years") && (
                <div className="d-block invalid-feedback">
                  {errors.first("years")}
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="mb-1">Cost per seat</label>
              <input
                type="number"
                className="form-control"
                value={state.cost_per_license}
                onChange={(e) =>
                  setState({ ...state, cost_per_license: e.target.value })
                }
                min={0}
              />
              {errors.has("cost_per_license") && (
                <div className="d-block invalid-feedback">
                  {errors.first("cost_per_license")}
                </div>
              )}
            </div>

            <div className="mb-3 col-md-6">
              <label className="mb-1">Total Price</label>
              <input
                type="number"
                className="form-control"
                value={state.total_cost}
                onChange={(e) =>
                  setState({ ...state, total_cost: e.target.value })
                }
                min={0}
              />
              {errors.has("total_cost") && (
                <div className="d-block invalid-feedback">
                  {errors.first("total_cost")}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="mb-1">Invoice number</label>
            <input
              type="text"
              className="form-control"
              value={state.invoice_number}
              onChange={(e) =>
                setState({ ...state, invoice_number: e.target.value })
              }
            />
            {errors.has("invoice_number") && (
              <div className="d-block invalid-feedback">
                {errors.first("invoice_number")}
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end gap-2">
            <button
              type="button"
              className="btn btn-light rounded-1 font-secondary"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-1 font-secondary fw-bolder"
              onClick={submit}
              disabled={processing}
            >
              <ButtonSpinner processing={processing}/>
              <span>Submit</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExtendLicenseModal;
