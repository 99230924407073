import { useEffect, useState } from "react";
import api from "../../api";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../Components/Encap";
import Errors from "../../utils/Errors";

const AddOrganizationSeats = ({ organizationId }) => {
  const initialState = {
    number_of_license: 0,
    invoice_number: "",
    cost_per_license: "",
    total_cost: "",
  };
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);

  const handleSubmit = () => {
    setProcessing(true);
    api
      .post(`add-organization-seats/${organizationId}`, state)
      .then((response) => {
        toast.success("Seats have been added");
        window.dispatchEvent(new CustomEvent("organizationSeatsChanged"));
        setState(initialState);
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          toast.error("Validation Failed");
          return;
        }
        toast.error("Server Error");
        console.log(error);
      })
      .finally(() => setProcessing(false));
  };

  useEffect(() => {
    setErrors(new Errors());
  }, [state]);

  return (
    <div>
      <div className="my-3">
        <h5>Add Seats</h5>
      </div>
      <div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="" className="form-label">
              No. of seats
            </label>
            <input
              type="number"
              className="form-control"
              value={state.number_of_license}
              onChange={(e) =>
                setState({ ...state, number_of_license: e.target.value })
              }
            />
            {errors.has("number_of_license") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("number_of_license")}
              </div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="" className="form-label">
              Cost per seat
            </label>
            <input
              type="number"
              className="form-control"
              value={state.cost_per_license}
              onChange={(e) =>
                setState({ ...state, cost_per_license: e.target.value })
              }
            />
            {errors.has("cost_per_license") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("cost_per_license")}
              </div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="" className="form-label">
              Total price
            </label>
            <input
              type="number"
              className="form-control"
              value={state.total_cost}
              onChange={(e) =>
                setState({ ...state, total_cost: e.target.value })
              }
            />
            {errors.has("total_cost") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("total_cost")}
              </div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="" className="form-label">
              Invoice number
            </label>
            <input
              type="text"
              className="form-control"
              value={state.invoice_number}
              onChange={(e) =>
                setState({ ...state, invoice_number: e.target.value })
              }
            />
            {errors.has("invoice_number") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("invoice_number")}
              </div>
            )}
          </div>
        </div>
        {/* {state.number_of_license > 0 && (
          <div>
            <h5>Order Summary</h5>
            <div>
              {state.number_of_license} seats x $99 AUD each /year ={" "}
              <span className="fw-bold">${state.number_of_license * 99}</span>{" "}
              AUD
            </div>
          </div>
        )} */}

        <div className="mt-3">
          <button
            className="btn btn-primary rounded-1 px-4"
            variant="primary"
            type="button"
            onClick={handleSubmit}
            disabled={processing}
          >
            <ButtonSpinner processing={processing} />
            Add Seats
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddOrganizationSeats;
