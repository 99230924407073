import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const InvitationAcceptedMessage = ({ organizationName }) => {
  return (
    <div>
      <div
        className="alert alert-success d-flex align-items-center"
        role="alert"
      >
        <FontAwesomeIcon icon={faCircleCheck} size="2xl" className="me-2" />
        <div>
          <div>Your acceptance to this invitation is now complete</div>
          <div className="mt-1">
            You are now a manager of {organizationName}
          </div>
        </div>
      </div>
      <h4>What's next?</h4>
      <p>
        You will be empowered to manage your {organizationName} members and
        programs within Encapsulator.
      </p>
      <Link to="/program-library" className="btn btn-primary rounded-1">
        Go to Home
      </Link>
    </div>
  );
};

export default InvitationAcceptedMessage;
