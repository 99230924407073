import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  faArrowRight,
  faArrowRightFromBracket,
  faLock,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import UserProfileForm from "./partials/UserProfileForm";
import ChangePasswordForm from "../../Components/auth/ChangePasswordForm";
import { Link } from "react-router-dom";
import "../../assets/css/dash_setting.css";
import SettingsNavigation from "./partials/SettingsNavigation";
import AvatarUpload from "./partials/AvatarUpload";
import MyBadges from "./partials/MyBadges";
import { AuthContext } from "../../context/AuthContext";
import DeleteAccount from "./partials/DeleteAccount";

const MyProfile = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const url = new URL(window.location.href);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(
    url.searchParams.has("change-password")
  );

  const logoutUser = () => {
    logout();
    //localStorage.removeItem("token");
    //localStorage.removeItem("user");
    navigate("/program-library");
    //window.location.reload();
  };

  return (
    <div className="row setting-row">
      <div className="col-sm-3 col">
        <div className="setting-grid-1">
          <h4>Settings</h4>
          <p>Update your personal settings here.</p>
        </div>

        <SettingsNavigation />
      </div>

      <div className="col-sm-9 col">
        <div className="setting-grid-3">
          <div className="setting-grid-3-1">
            <h6>My Profile</h6>
            <FontAwesomeIcon
              icon={faPencil}
              color="#000"
              style={{ fontSize: 20, paddingLeft: 16 }}
            ></FontAwesomeIcon>
          </div>

          <UserProfileForm />

          <AvatarUpload />

          <MyBadges />

          <Modal
            show={showChangePasswordForm}
            onHide={() => setShowChangePasswordForm(false)}
            // backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body className="p-5">
              <ChangePasswordForm />
            </Modal.Body>
          </Modal>

          <div className="mt-4 d-flex flex-column flex-sm-row gap-2 align-items-center">
            <div>
              <DeleteAccount />
            </div>
            <div>
              <button
                type="button"
                className="setting-grid-3-9 m-0"
                onClick={() => setShowChangePasswordForm(true)}
              >
                <FontAwesomeIcon
                  icon={faLock}
                  className="lock"
                ></FontAwesomeIcon>
                <h5>Change Password</h5>
              </button>
            </div>
            <div>
              <button
                className="setting-grid-3-10 m-0"
                onClickCapture={logoutUser}
              >
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  className="lock"
                  fontSize={18}
                ></FontAwesomeIcon>
                <h5>Logout</h5>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
