import { useState, useEffect } from "react";
import "../../../assets/css/progress_bar.css";

export default function StepsProgressBar({ current, total }) {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent((current / total) * 100);
  }, [current, total]);

  return (
    <div className="progress-container">
      <div className="progress-text">
        <p>
          Step <span>{current}</span> of <span>{total}</span>
        </p>
      </div>
      <div className="progress" style={{ minWidth: "200px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: percent + "%" }}
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
}
