import {
  faCheckCircle,
  faCloudUpload,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../../../../assets/scss/cover_image_uploader.scss";
import { useState } from "react";
import api from "../../../../api";
import { toast } from "react-toastify";

export default function CoverImageUploader({
  program = null,
  onUploadComplete = null,
}) {
  const [preview, setPreview] = useState(program?.cover_image_url ?? null);
  const [stauts, setStatus] = useState(null); // Either NULL | uploading | success | error

  const handleChange = async (e) => {
    setStatus("uploading");
    let file = e.target.files[0];
    setPreview(URL.createObjectURL(file));

    let url = program
      ? `/update-program-cover-image/${program.id}`
      : `/temporary-upload`;

    const formData = new FormData();
    formData.append("file", file, file.name);
    await api
      .post(url, formData)
      .then((res) => {
        setStatus("success");
        toast.success("Uploaded successfully");
        onUploadComplete && onUploadComplete(res.data);
      })
      .catch((error) => {
        toast.error("Something went wrong while uploading");
        setStatus("error");
      });
  };

  return (
    <div className="cover-image-uploader">
      <section className="preview-wrap">
        {preview ? (
          <img src={preview} alt="cover" />
        ) : (
          <div className="zone">
            <FontAwesomeIcon icon={faCloudUpload} className="icon" />
            <div className="mt-2">Upload Image</div>
          </div>
        )}

        {stauts === "success" && (
          <div className="mt-2 text-center text-success">
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            <span>Uploaded</span>
          </div>
        )}

        {stauts === "error" && (
          <div className="mt-2 text-center text-danger">
            <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
            <span>Upload Error</span>
          </div>
        )}
      </section>

      <section className="mt-3">
        {stauts === "uploading" ? (
          <div className="uploading-label">
            <span>Uploading...</span>
            <div class="spinner-border spinner-border-sm me-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <label>
            <input
              id=""
              type="file"
              accept="Image/*"
              className="d-none"
              onChange={handleChange}
            />
            Select Image
          </label>
        )}
      </section>
    </div>
  );
}
