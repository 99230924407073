import "./../../assets/scss/program_placeholder.scss";

const ProgramPlaceholder = () => {
  return (
    <div className="program__placeholder" aria-hidden="true">
      <figure>
        <div className="activity"></div>
      </figure>
      <div className="body">
        <div className="placeholder__title"></div>
        <p></p>
        <p></p>
      </div>
    </div>
  );
};

export default ProgramPlaceholder;
