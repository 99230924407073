import {
  faCircleMinus,
  faCirclePlus,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";

function Prompts({ onChange, errors, promptData }) {
  //getting old prompts val
  const [oldPromptData, setOldPromptData] = useState(promptData);

  const PromptsVal = (promptData) => {
    //let oldPrompts =
    promptData &&
      promptData.map((data) => {
        return {
          title: data.title,
          questions: Object.assign({}, data.questions),
        };
      });
    //return oldPrompts;
  };

  const [prompts, setPrompts] = useState(
    // oldPromptData
    [
      {
        title: "",
        questions: [""],
      },
    ]
  );

  useEffect(() => {
    setOldPromptData(PromptsVal(promptData));
    console.log(oldPromptData);
    onChange(prompts);
  }, [prompts]);

  function addPrompt() {
    setPrompts([
      ...prompts,
      {
        title: "",
        questions: [""],
      },
    ]);
  }

  function handlePromptQuesTitle(e, promptIndex) {
    let temp = [...prompts];
    temp[promptIndex].title = e.target.value;
    setPrompts(temp);
  }

  function addPromptQuestion(e, promptIndex) {
    let newPrompts = [...prompts];
    newPrompts[promptIndex].questions.push("");
    setPrompts(newPrompts);
  }

  function handlePromptQuestionChange(e, promptIndex, questionIndex) {
    let newPrompts = [...prompts];
    newPrompts[promptIndex].questions[questionIndex] = e.target.value;
    setPrompts(newPrompts);
  }

  function removePromptQuestion(promptIndex, questionIndex) {
    let newPrompts = [...prompts];
    newPrompts[promptIndex].questions.splice(questionIndex, 1);
    setPrompts(newPrompts);
  }

  function removePromtSection(promptIndex) {
    let temp = [
      ...prompts.slice(0, promptIndex),
      ...prompts.slice(promptIndex + 1),
    ];
    setPrompts(temp);
  }

  return (
    <div className="col col-sm-9">
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: 10 }}
      >
        <div className="program-detail-prompt-1-2">
          <div className="program-detail-prompt-1">
            <FontAwesomeIcon
              color="#529c00"
              icon={faCirclePlus}
              style={{ fontSize: 16 }}
            ></FontAwesomeIcon>
            <h6 onClick={(e) => addPrompt(e)}>Add Prompts</h6>
          </div>
        </div>
      </div>
      <Form.Label>
        {/* edit mode */}
        {promptData &&
          promptData.map((prompt, promptIndex) => (
            <div
              className="prompt-box"
              // style={{ marginLeft: 20 }}
              key={"prompt" + promptIndex}
            >
              {/* <Form.Label>Prompt Title</Form.Label> */}
              <div className="program-detail-prompt-question">
                <div style={{ marginRight: 20 }}>
                  <Form.Control
                    type="text"
                    name="title"
                    value={prompt.title ? prompt.title : ""}
                    className="add-prompt-form"
                    placeholder="Add prompt question title here"
                    onChange={(e) => handlePromptQuesTitle(e, promptIndex)}
                  />
                  {errors.has(`prompts.${promptIndex}.title`) && (
                    <div className="d-block invalid-feedback">
                      {errors.first(`prompts.${promptIndex}.title`)}
                    </div>
                  )}
                  {/* <Button
                    style={{ marginTop: 10 }}
                    type="button"
                    className="program-detail-prompt-2-3"
                    onClick={() => removePromtSection(promptIndex)}
                  >
                    Delete prompt{" "}
                    <FontAwesomeIcon
                      color="red"
                      icon={faMinusCircle}
                      style={{ fontSize: 16 }}
                    ></FontAwesomeIcon>
                  </Button> */}
                </div>
                {/* <Form.Label className="mt-2">Questions</Form.Label> */}
                <div>
                  {prompt.questions &&
                    prompt.questions.map((question, quesIndex) => (
                      <div
                        style={{ marginBottom: 10 }}
                        className="d-flex"
                        key={"prompt" + promptIndex + "-question-" + quesIndex}
                      >
                        <Form.Control
                          type="text"
                          name="question"
                          className="add-prompt-form"
                          placeholder="How can i help you?"
                          value={question}
                          onChange={(e) =>
                            handlePromptQuestionChange(
                              e,
                              promptIndex,
                              quesIndex
                            )
                          }
                        />

                        {/* <Button
                        className="program-detail-prompt-2-2"
                        style={{ marginLeft: 20 }}
                        type="button"
                        onClick={(e) => addPromptQuestion(e, promptIndex)}
                      >
                        <FontAwesomeIcon
                          color="green"
                          icon={faCirclePlus}
                          style={{ fontSize: 16 }}
                        ></FontAwesomeIcon>
                      </Button> */}

                        {/* {prompt.questions.length > 1 && (
                        <Button
                          type="button"
                          className="program-detail-prompt-2-2"
                          onClick={(e) =>
                            removePromptQuestion(promptIndex, quesIndex)
                          }
                        >
                          <FontAwesomeIcon
                            color="red"
                            icon={faCircleMinus}
                            style={{ fontSize: 16 }}
                          ></FontAwesomeIcon>
                        </Button>
                      )} */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}

        {/* create mode */}
        {prompts.map((prompt, promptIndex) => (
          <div
            className="prompt-box"
            // style={{ marginLeft: 20 }}
            key={"prompt" + promptIndex}
          >
            {/* <Form.Label>Prompt Title</Form.Label> */}
            <div className="program-detail-prompt-question">
              <div
                className="first-question col-sm-5"
                style={{ marginRight: 20 }}
              >
                <Form.Control
                  type="text"
                  name="title"
                  value={prompt.title}
                  className="add-prompt-form"
                  placeholder="Add prompt question title here"
                  onChange={(e) => handlePromptQuesTitle(e, promptIndex)}
                />
                {errors.has(`prompts.${promptIndex}.title`) && (
                  <div className="d-block invalid-feedback">
                    {errors.first(`prompts.${promptIndex}.title`)}
                  </div>
                )}
                <Button
                  style={{ marginTop: 10 }}
                  type="button"
                  className="program-detail-prompt-2-3"
                  onClick={() => removePromtSection(promptIndex)}
                >
                  Delete prompt{" "}
                  <FontAwesomeIcon
                    color="red"
                    icon={faMinusCircle}
                    style={{ fontSize: 16 }}
                  ></FontAwesomeIcon>
                </Button>
              </div>
              {/* <Form.Label className="mt-2">Questions</Form.Label> */}
              <div className="second-question col-sm-6">
                {prompt.questions &&
                  prompt.questions.map((question, quesIndex) => (
                    <div
                      style={{ marginBottom: 10 }}
                      className="d-flex"
                      key={"prompt" + promptIndex + "-question-" + quesIndex}
                    >
                      <Form.Control
                        type="text"
                        name="question"
                        className="add-prompt-form"
                        placeholder="How can i help you?"
                        value={question}
                        onChange={(e) =>
                          handlePromptQuestionChange(e, promptIndex, quesIndex)
                        }
                      />

                      <Button
                        className="program-detail-prompt-2-2"
                        style={{ marginLeft: 20 }}
                        type="button"
                        onClick={(e) => addPromptQuestion(e, promptIndex)}
                      >
                        <FontAwesomeIcon
                          color="green"
                          icon={faCirclePlus}
                          style={{ fontSize: 16 }}
                        ></FontAwesomeIcon>
                      </Button>

                      {prompt.questions.length > 1 && (
                        <Button
                          type="button"
                          className="program-detail-prompt-2-2"
                          onClick={(e) =>
                            removePromptQuestion(promptIndex, quesIndex)
                          }
                        >
                          <FontAwesomeIcon
                            color="red"
                            icon={faCircleMinus}
                            style={{ fontSize: 16 }}
                          ></FontAwesomeIcon>
                        </Button>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div className="d-flex justify-content-between mt-2">
              {/* <Button
            type="button"
            onClick={(e) => addPromptQuestion(e, promptIndex)}
          >
            + Add Question
          </Button> */}
              {/* <Button
            type="button"
            className="btn-danger"
            onClick={() => removePromtSection(promptIndex)}
          >
            Remove
          </Button> */}
            </div>
          </div>
        ))}

        {/* <div className="add-program3-niraj-2-2">
      <FontAwesomeIcon
        color="green"
        icon={faCirclePlus}
        style={{ fontSize: 12 }}
      ></FontAwesomeIcon>
      <h6
        className="add-program3-grid-2-3"
        onClick={(e) => addPrompt(e)}
      >
        Add Prompts
      </h6>
    </div> */}
      </Form.Label>
    </div>
  );
}

export default Prompts;
