import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import EntriesNavList from "./EntriesNavList";
import WizardProgressBar from "./StepsProgressBar";
import "../../../assets/css/dashboard_addprogram3.css";
import EntryForm from "./EntryForm";
import {
  ACCESS_TYPE,
  ANSWERING_OPTION,
  PROMPT_TYPE,
} from "../../../const/Entry";

export default function CreateEntry() {
  const navigate = useNavigate();
  const { programId } = useParams();

  const newEntry = {
    program_id: programId,
    title: "",
    answering_options: ANSWERING_OPTION.TEXT_AND_VIDEO,
    intro_video_id: "",
    intro_video_url: "",
    explanation_text: "",
    resource_id: "",
    response_privacy: false,
    notify_instructor: false,
    prompt_type: PROMPT_TYPE.NONE,
    prompt_id: "",
    access_type: ACCESS_TYPE.IMMEDIATE,
    access_time: { value: "", unit: "" },
  };

  function closeEntry(){
    navigate("/programs");
  
  }

  return (
    <main>
      <div className="add-program2-header" onClick={closeEntry}>
        <h5>Add A New Entry</h5>
        <FontAwesomeIcon
          icon={faCircleXmark}
          color="#fff"
          style={{ fontSize: 25 }}
          onClick={() => navigate("/")}
        ></FontAwesomeIcon>
      </div>
      <div className="add-program3-grid">
        <div className="add-program3-grid-1">
          <div className="add-program3-grid-1-1">
            <h5>Entries</h5>
            <p>Each of the lessons and excercises within this program.</p>
          </div>
          <WizardProgressBar current="3" total="4" />
        </div> 

        <div className="add-program3-grid-1-4">
          <div className="add-program3-grid-1-5">
            <h6>Items</h6>
            <EntriesNavList programId={programId}></EntriesNavList>
          </div>
        </div>

        <EntryForm entry={newEntry} forWizard={true} />
      </div>
    </main>
  );
}
