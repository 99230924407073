import { Button, Form, Modal, Stack } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";
import "../../../assets/css/single_prompt_modal.css";

function SinglePromptModal({
  show,
  onHide,
  refetchData,
  prompt = null,
  programId = null,
}) {
  const [errors, setErrors] = useState(new Errors());
  let updateMode = false;

  const initialState = {
    title: "",
    questions: [""],
  };

  if (prompt) {
    updateMode = true;
    initialState.title = prompt.title;
    initialState.questions = prompt.questions;
  }

  const [form, setForm] = useState(initialState);

  const addQuestion = () => {
    let questions = form.questions;
    questions.push("");
    setForm({ ...form, questions });
  };

  const removeQuestion = (index) => {
    let questions = [
      ...form.questions.slice(0, index),
      ...form.questions.slice(index + 1),
    ];
    setForm({ ...form, questions });
  };

  const handleQuestionChange = (e, index) => {
    let temp = { ...form };
    temp.questions[index] = e.target.value;
    setForm(temp);
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      const response = await api({
        method: updateMode ? "PUT" : "POST",
        url: updateMode
          ? `prompts/${prompt.id}`
          : `programs/${programId}/prompts`,
        data: form,
      });

      toast.success("Prompt has been added");
      refetchData();
      onHide();
    } catch (error) {
      if (error.response) {
        if ([401, 403].includes(error.response.status)) {
          setErrors(new Errors(error.response.data.message));
          toast.error(
            updateMode
              ? "Access denied ! As organization you are not allowed to update public program"
              : "Errors"
          );
        }
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Validation Failed");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="single-prompt-modal"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {updateMode ? "Edit Prompt" : "Add New Prompt"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white border">
        <form onSubmit={(e) => submit(e)}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
            {errors.has("title") && (
              <div className="d-block invalid-feedback">
                {errors.first("title")}
              </div>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Questions</Form.Label>
            <Stack gap={2}>
              {form.questions &&
                form.questions.map((question, index) => (
                  <div className="d-flex" key={index}>
                    <Form.Control
                      value={question}
                      onChange={(e) => handleQuestionChange(e, index)}
                    />
                    <Button
                      type="button"
                      className="program-detail-prompt-2-2"
                      onClick={(e) => removeQuestion(index)}
                    >
                      <FontAwesomeIcon
                        color="red"
                        icon={faCircleMinus}
                        style={{ fontSize: 16 }}
                      ></FontAwesomeIcon>
                    </Button>
                  </div>
                ))}
              {errors.has("questions") && (
                <div className="d-block invalid-feedback">
                  {errors.first("questions")}
                </div>
              )}
              <div>
                <Button
                  className="program-detail-prompt-2-2"
                  type="button"
                  onClick={(e) => addQuestion()}
                >
                  <FontAwesomeIcon
                    color="green"
                    icon={faCirclePlus}
                    style={{ fontSize: 16 }}
                  ></FontAwesomeIcon>
                </Button>
              </div>
            </Stack>
          </Form.Group>

          <Modal.Footer>
            <Button type="button" className="close-btn" onClick={onHide}>
              Close
            </Button>
            <Button
              type="button"
              className="save-btn"
              onClick={(e) => submit(e)}
            >
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SinglePromptModal;
