import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link, useParams, useSearchParams } from "react-router-dom";
import api, { guestApi } from "../../api";
import Errors from "../../utils/Errors";
import OTPInput from "react-otp-input";

export default function PasswordResetPage() {
  let { email } = useParams();
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState(new Errors());
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [state, setState] = useState({
    email,
    otp: searchParams.get("otp"),
    password: "",
    password_confirmation: "",
  });

  function submit(e) {
    e.preventDefault();
    setProcessing(true);
    guestApi
      .post("reset-password", state)
      .then((response) => setResetSuccessful(true))
      .catch((error) => {
        if (error.response.status === 422)
          setErrors(new Errors(error.response.data.errors));
        console.error(error);
      })
      .finally(() => setProcessing(false));
  }

  const otpInputStyle = {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "0.25rem",
    border: "1px solid #ced4da",
  };

  return (
    <Container className="p-4">
      <Row md={4}>
        <Col
          md={{ span: 6, offset: 3 }}
          className="border bg-white p-4 rounded"
        >
          {resetSuccessful ? (
            <div className="text-center">
              <h2 className="mb-3">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="me-2"
                ></FontAwesomeIcon>
                Password changed
              </h2>
              <p className="text-muted">
                Your password was updated successfully. Please login to continue
                using Encapsulator.
              </p>
              <div className="d-grid col-md-6 mx-auto">
                <Link to="/login" className="btn btn-primary btn-block btn-lg">
                  Login
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <div className="text-center">
                <h1>Reset Password</h1>
                <p className="text-muted">
                  Please create a new password for you
                </p>
              </div>

              <Form onSubmit={(e) => submit(e)} autoComplete="new-password">
                <Form.Group className="mb-3">
                  <Form.Label>Enter OTP</Form.Label>
                  <OTPInput
                    value={state.otp}
                    onChange={(value) => setState({ ...state, otp: value })}
                    numInputs={6}
                    renderSeparator={<span className="mx-2">-</span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={otpInputStyle}
                    inputType="text"
                    autoComplete="off"
                  />
                  {errors.has("otp") && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.first("otp")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Create new password"
                    size="lg"
                    value={state.password}
                    onChange={(e) =>
                      setState({ ...state, password: e.target.value })
                    }
                    autoComplete="new-password"
                  />
                  {errors.has("password") && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.first("password")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                
                <Form.Text className="password-help-text text-muted">
                  Minimum 8 characters,Uppercase & lowercase
                  characters(A-Z),Digits(0-9)
                </Form.Text>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Confirm your password"
                    size="lg"
                    value={state.password_confirmation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        password_confirmation: e.target.value,
                      })
                    }
                    autoComplete="false"
                  />
                </Form.Group>

                <Form.Group className="d-grid mb-3">
                  <Button
                    type="submit"
                    variant="primary btn-block"
                    size="lg"
                    className="d-inline-flex justify-content-center align-items-center gap-2"
                    disabled={processing}
                  >
                    {processing && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                      />
                    )}
                    <span>Reset</span>
                  </Button>
                </Form.Group>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
