import {
  faArrowRight,
  faCheck,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "../../../Components/progressbar/ProgressBar";
import "../../../assets/css/answered_exercise.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useFetch from "../../../hooks/useFetch";
import { Link, useSearchParams } from "react-router-dom";
import { reactHtmlEntities } from "../../../utils/helpers";
import { Card, CardImg } from "react-bootstrap";
import AfterSurveyModal from "../../../Components/Modals/AfterSurveyModal";
import Confetti from "../../../Components/Confetti";
import VideoPlayer from "../../../Components/VideoPlayer";
import moment from "moment";
import ProgramFeedback from "../../../Components/program/ProgramFeedback";

function ProgramCompletedPage() {
  const { programId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [program, setProgram] = useState();
  const { data, loading, error } = useFetch(`my-programs/${programId}`);
  const [showSurveyModal, setSurveyModal] = useState(true);
  const [expandedIds, setExpandedIds] = useState([]);

  const [showConfetti, setShowConfetti] = useState(
    searchParams.get("just-completed")
  );

  useEffect(() => {
    if (data) {
      setProgram(data.data);
    }
  }, [data]);

  if (!program) {
    return <div>Loading..</div>;
  }

  const toggleExpanded = (id) => {
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter((item) => item !== id));
    } else {
      setExpandedIds([...expandedIds, id]);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("D MMM, YYYY");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-5">
          <div className="answer-grid-1">
            <h4 className="pt-0">{program.program_name}</h4>
            {/* <p>{program.short_description}</p> */}
            <p>
              {program.short_description &&
              program.short_description.length > 80 ? (
                <div>
                  {expandedIds.includes(program.id) ? (
                    <>
                      {program.short_description}
                      <button onClick={() => toggleExpanded(program.id)}>
                        Show less
                      </button>
                    </>
                  ) : (
                    <>
                      {program.short_description.substring(0, 80)}...
                      <button onClick={() => toggleExpanded(program.id)}>
                        Show more
                      </button>
                    </>
                  )}
                </div>
              ) : (
                program.short_description
              )}
            </p>
          </div>

          <ProgressBar
            bgcolor="#529C00"
            current={program.progress.completedCount}
            total={program.progress.entriesCount}
            progress={program.progress.completionPercentage}
          />
          <div className="answer-1">
            {program.entries &&
              program.entries.map((en, index) => (
                <Link to={`/programs/${program.id}/entries/${en.id}`}>
                  <div className="answer-grid-2" key={index}>
                    <h6>{en.title}</h6>
                    <FontAwesomeIcon
                      className="answer-arrow-icon"
                      icon={faCheck}
                      color="#FFFFFF"
                      style={{ fontSize: 12 }}
                    ></FontAwesomeIcon>
                  </div>
                </Link>
              ))}
          </div>

          {program.isCompleted && (
            <div className="mt-2 text-muted">
              You completed this program in{" "}
              {formatDate(program.completed_at)}.
            </div>
          )}

          {program.progress.completionPercentage === 100 &&
          program.survey_after ? (
            <AfterSurveyModal
              show={showSurveyModal}
              survey_after={program.survey_after}
              onHide={() => setSurveyModal(false)}
            />
          ) : (
            <></>
          )}
        </div>

        <div className="col-sm-7">
          <div className="answer-grid-3 mb-3">
            <h4>
              {reactHtmlEntities(program.congratulation_heading) ||
                "Congratulations"}
            </h4>
          </div>

          {program.congratulation_video_url && (
            <div className="my-2 vid p-0">
              <VideoPlayer url={program.congratulation_video_url} />
            </div>
          )}

          <p className="answer-grid-3-1">
            {reactHtmlEntities(program.congratulation_text)}
          </p>

          <p className="answer-grid-3-1">
            {reactHtmlEntities(program.explanation_text)}
          </p>

          {program.badge_url && (
            <>
              <div className="answer-grid-3-2">
                <h5>New Badge!</h5>
              </div>
              <div className="answer-grid-3-3 mb-3">
                <img
                  src={program.badge_url}
                  alt="badge"
                  height={65}
                  width={65}
                />
              </div>
            </>
          )}

         <ProgramFeedback programId={program.master_program_id}/>
        </div>
      </div>

      {showConfetti && <Confetti />}
    </div>
  );
}
export default ProgramCompletedPage;
