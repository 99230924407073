import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/AuthContext";
import SessionExpiredModal from "./Components/Modals/SessionExpiredModal";
import { HelmetProvider } from "react-helmet-async";
import EntryReaction from "./Components/reaction/EntryReaction";
import DeviceInfo from "./utils/DeviceInfo";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <AuthProvider>
      {/* <React.StrictMode> */}
      <EntryReaction/>
      <App />
      {/* </React.StrictMode> */}
      <ToastContainer autoClose="3000" />
      <SessionExpiredModal />
      <DeviceInfo />
    </AuthProvider>
  </HelmetProvider>
);
