import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const InvitationAcceptedMessage = ({organizationName}) => {
    return (
      <div>
        <div
          className="alert alert-success d-flex align-items-center"
          role="alert"
        >
          <FontAwesomeIcon icon={faCircleCheck} size="2xl" className="me-2" />
          <div>
            <div>Your acceptance to this invitation is now complete</div>
            <div className="mt-1">
              You are now a member of {organizationName}
            </div>
          </div>
        </div>

        <div className="alert alert-success d-none" role="alert">
          <h4 className="alert-heading">Invitation Accepted!</h4>
          <p>Your acceptance to this invitation is now complete</p>
          <hr />
          <p className="mb-0">
            You are currently a member of {organizationName}
          </p>
        </div>
        <h4>What's next?</h4>
        <p>
          You will be granted access to the programs owned by{" "}
          {organizationName}
        </p>
        <Link to="/program-library" className="btn btn-primary rounded-1">
          Go to Home
        </Link>
      </div>
    );
  };

  export default InvitationAcceptedMessage;