import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import "../../../assets/css/dashboard_addprogram_1A.css";
import { useEffect, useState } from "react";
import api from "../../../api";
import NoDataCard from "../../../Components/NoDataCard";
import Pagination from "../../../Components/Pagination";
import LoadingBlock from "../../../Components/LoadingBlock";
import PremadeProgramCard from "./partials/PremadeProgramCard";

function PremadeProgram() {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);

  function fetchPrograms(page = 1) {
    setLoading(true);
    setPrograms([]);
    api
      .get("program-wizard/get-premade-programs", {
        params: {
          page,
          per_page: 16,
        },
      })
      .then((response) => {
        setPrograms(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPrograms();
  }, []);

  return (
    <>
      <div className="addprogram-1a-header">
        <h5>Add A New Program</h5>
        <FontAwesomeIcon
          icon={faCircleXmark}
          color="#fff"
          style={{ fontSize: 25 }}
        ></FontAwesomeIcon>
      </div>

      <div className="addprogram-1a-grid">
        <div className="addprogram-1a-grid-1">
          <div className="addprogram-1a-grid-1-1">
            <h5>Select a Premade Program</h5>
            <p>Don’t worry, you can make tweaks if needed.</p>
          </div>
          {/* <ProgressBar bgcolor="#529C00" progress='20'/> */}
        </div>
      </div>

      <div>
        {loading && <LoadingBlock>Loading...</LoadingBlock>}

        {programs?.data?.length > 0 && (
          <div className="tab-content">
            <div className="container-fluid">
              <div className="row prog-row">
                {programs?.data?.map((program) => (
                  <PremadeProgramCard program={program} key={program.id} />
                ))}
              </div>
            </div>

            {programs?.meta?.last_page > 1 && (
              <div className="mt-4 d-flex justify-content-center">
                <Pagination
                  meta={programs.meta}
                  onChange={(page) => fetchPrograms(page)}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {!loading && !programs?.data?.length && <NoDataCard />}
    </>
  );
}

export default PremadeProgram;
