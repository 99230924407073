import { toast } from "react-toastify";
import { guestApi } from "../api";

export function sendVerificationEmail(email) {
  const promise = guestApi.post(
    `send-email-verification-notification/${email}`
  );

  toast.promise(promise, {
    pending: "Send verification email",
    success: "Email sent",
    error: "Something went wrong!",
  });

  return promise;
}
