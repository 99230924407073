import React, { useContext, useEffect, useState } from "react";
import SettingsNavigation from "./partials/SettingsNavigation";
import UserSubscriptionDetail from "./partials/UserSubscriptionDetail";
import OrganizationSubscriptionDetail from "./partials/OrganizationSubscriptionDetail";
import { AuthContext } from "../../context/AuthContext";

function BillingAndSubscriptions() {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();

  return (
    <div className="row setting-row">
      <div className="col-sm-3 col">
        <div className="setting-grid-1 mb-3">
          <h4>Settings</h4>
          <p>Update your personal settings here.</p>
        </div>
        <SettingsNavigation />
      </div>

      <div className="col-sm-9 col">
        {user.role === "orgadmin" ? (
          <OrganizationSubscriptionDetail />
        ) : (
          <UserSubscriptionDetail />
        )}
      </div>
    </div>
  );
}

export default BillingAndSubscriptions;
