import { useState } from "react";
import Errors from "../../utils/Errors";
import { Modal } from "react-bootstrap";
import "./../../assets/scss/support_feedback.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { guestApi } from "../../api";
import { toast } from "react-toastify";

const SupportFeedbackModal = ({ show, onHide }) => {
  const initialState = {
    title: "",
    type: "support",
    description: "",
    file: "",
  };
  const [form, setForm] = useState(initialState);
  const [screenshotPreviewURL, setScreenshotPreviewUrl] = useState();
  const [errors, setErrors] = useState(new Errors());

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleScreenshotChange = (e) => {
    const files = e.target.files;
    if (files) {
      console.log(e.target.files[0]);
      let file = e.target.files[0];
      setForm({ ...form, file: file });
      setScreenshotPreviewUrl(URL.createObjectURL(file));
    }
  };

  const submit = () => {
    let payload = new FormData();
    payload.append("title", form.title);
    payload.append("type", form.type);
    payload.append("description", form.description);
    payload.append("file", form.file);

    guestApi
      .post("submit-support", payload)
      .then((response) => {
        toast.success("Thank you. You query has been submitted.");
        resetState();
        onHide && onHide();
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          console.error(error);
          setErrors(new Errors(error.response.data.errors));
          return;
        }
        toast.error("Server Error. Please try again");
      });
  };

  const resetState = () => {
    setForm(initialState);
    setScreenshotPreviewUrl(null);
  };

  const handleCancel = () => {
    onHide();
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="support-feedback-modal font-secondary p-4 bg-white rounded">
          <div className="mb-3">
            <div className="d-flex gap-2">
              <h4 className="fw-bolder">Support / Feedback / Bug</h4>
            </div>
            <div className="text-muted">
              Share your thoughts, report issues, or request assistance
            </div>
          </div>
          <div>
            <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
              <div className="mb-3">
                <label class="form-label">Title</label>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  value={form.title}
                  onChange={(e) => handleInputChange(e)}
                />
                {errors.has("title") && (
                  <div className="d-block invalid-feedback error-text">
                    {errors.first("title")}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label class="form-label">Type</label>
                <select
                  name="type"
                  className="form-select"
                  onChange={(e) => handleInputChange(e)}
                  value={form.type}
                >
                  <option value="support">Support</option>
                  <option value="feedback">Feedback</option>
                  <option value="bug">Bug</option>
                </select>
                {errors.has("type") && (
                  <div className="d-block invalid-feedback error-text">
                    {errors.first("type")}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label class="form-label">Description</label>
                <textarea
                  name="description"
                  cols="30"
                  rows="3"
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  value={form.description}
                ></textarea>
                {errors.has("description") && (
                  <div className="d-block invalid-feedback error-text">
                    {errors.first("description")}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label class="form-label">Upload Screenshot</label>
                <div className="screenshot-selector">
                  <div className="preview">
                    {screenshotPreviewURL ? (
                      <img src={screenshotPreviewURL} alt="" />
                    ) : (
                      <div className="image-placeholder">
                        <FontAwesomeIcon icon={faCloudArrowUp} />
                      </div>
                    )}
                  </div>
                  <label className="select-btn">
                    <span>Select Image</span>
                    <input
                      type="file"
                      className="d-none"
                      accept="image/*"
                      onChange={(e) => handleScreenshotChange(e)}
                    />
                  </label>
                </div>
                {errors.has("file") && (
                  <div className="d-block invalid-feedback error-text">
                    {errors.first("file")}
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-danger rounded-1 px-4 fw-bold"
                    onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary rounded-1 px-4 fw-bold"
                  onClick={submit}
                >
                  <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SupportFeedbackModal;
