import { useEffect, useState } from "react";
import Errors from "../../../utils/Errors";
import api from "../../../api";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../../Components/Encap";

const ContributorForm = ({
  programId,
  contributor = null,
  onCancel,
  onChange,
}) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    avatar: "",
    contact: "",
    bio: "",
    url: "",
  });
  const [updateMode, setUpdateMode] = useState(contributor ? true : false);
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (contributor) {
      setForm((prevForm) => ({
        ...prevForm,
        name: contributor.name || "",
        email: contributor.email || "",
        contact: contributor.contact || "",
        bio: contributor.bio || "",
      }));
    }
  }, [contributor, setForm]);

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    errors?.clear && errors.clear(e.target.name);
  };

  const handleFileChanged = (e) => {
    const files = e.target.files;
    if (files) {
      setForm({ ...form, avatar: files[0] });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    let formData = new FormData();
    formData.append("name", form.name);
    formData.append("email", form.email);
    if (form.avatar instanceof File) {
      formData.append("avatar", form.avatar, form.avatar.name);
    }
    formData.append("contact", form.contact);
    formData.append("bio", form.bio);
    formData.append("url", form.url);

    const apiUrl = updateMode
      ? `program-contributors/${contributor.id}/update`
      : `program-contributors/${programId}`;

    api({
      method: "POST",
      url: apiUrl,
      data: formData,
    })
      .then((response) => {
        toast.success(
          updateMode
            ? "Contributor has been updated"
            : "Contributor has been added"
        );
        onChange && onChange();
        onCancel && onCancel();
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          toast.error(error.response.data.message);
          setErrors(new Errors(error.response.data.errors));
          return;
        }
        toast.error("Server Error. Please try again");
      })
      .finally(() => setProcessing(false));
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
      <div className="mb-3">
        <label className="form-label">Name</label>
        <input
          type="text"
          className="form-control"
          name="name"
          value={form.name}
          onChange={handleInputChange}
        />
        {errors.has("name") && (
          <div className="d-block invalid-feedback">{errors.first("name")}</div>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Email</label>
        <input
          type="text"
          className="form-control"
          name="email"
          value={form.email}
          onChange={handleInputChange}
        />
        {errors.has("email") && (
          <div className="d-block invalid-feedback">
            {errors.first("email")}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Contact</label>
        <input
          type="text"
          className="form-control"
          name="contact"
          value={form.contact}
          onChange={handleInputChange}
        />
        {errors.has("contact") && (
          <div className="d-block invalid-feedback">
            {errors.first("contact")}
          </div>
        )}
      </div>

      <div className="mb-3">
        <label className="form-label">Bio</label>
        <textarea
          name="bio"
          value={form.bio}
          onChange={handleInputChange}
          className="form-control"
          cols="30"
          rows="3"
        ></textarea>
        {errors.has("bio") && (
          <div className="d-block invalid-feedback">{errors.first("bio")}</div>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Booking URL</label>
        <input
          type="text"
          className="form-control"
          name="url"
          value={form.url}
          onChange={handleInputChange}
        />
        {errors.has("url") && (
          <div className="d-block invalid-feedback">{errors.first("url")}</div>
        )}
      </div>

      <div className="mb-3">
        <label className="form-label">Image</label>
        <input
          type="file"
          onChange={handleFileChanged}
          className="form-control"
          accept="image/*"
        />
      </div>

      <div className="d-flex gap-3 justify-content-end">
        <button
          type="button"
          className="btn btn-light rounded-1"
          onClick={() => onCancel && onCancel()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary rounded-1"
          disabled={processing}
        >
          <ButtonSpinner />
          <span>Save</span>
        </button>
      </div>
    </form>
  );
};

export default ContributorForm;
