import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import OTPInput from "react-otp-input";
import Errors from "../../utils/Errors";

export default function CheckOTP() {
  //   const [errors, setErrors] = useState(new Errors());
  // const otpInputStyle = {
  //   width: "100%",
  //   padding: "0.5rem",
  //   borderRadius: "0.25rem",
  //   border: "1px solid #ced4da",
  // };
  return (
    <div>loading...</div>
    // <Container className="p-4">
    //   <Row md={4}>
    //     <Col
    //       md={{ span: 6, offset: 3 }}
    //       className="border bg-white p-4 rounded"
    //     >
    //       <div>
    //         <div className="text-center">
    //           <h1>CONFIRM EMAIL</h1>
    //           <p className="text-muted">Enter the verification code which you have received in your email</p>
    //         </div>

    //         <Form>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Enter OTP</Form.Label>
    //             <OTPInput
    //               value={"test11"}
    //               //onChange={}
    //               numInputs={6}
    //               renderSeparator={<span className="mx-2">-</span>}
    //               renderInput={(props) => <input {...props} />}
    //               inputStyle={otpInputStyle}
    //               inputType="text"
    //             />

    //           </Form.Group>

    //           <Form.Group className="d-grid mb-3">
    //             <Button
    //               type="submit"
    //               variant="primary btn-block"
    //               size="lg"
    //               className="d-inline-flex justify-content-center align-items-center gap-2"
    //               //disabled={processing}
    //             >
    //               <span>Submit</span>
    //             </Button>
    //           </Form.Group>
    //         </Form>
    //       </div>
    //     </Col>
    //   </Row>
    // </Container>
  );
}
