import { useEffect, useState } from "react";
import api from "../../api";
import { isoToAusDate } from "../../utils/helpers";
import RemoveSeatsModal from "./subscription/RemoveSeatsModal";
import ExtendLicenseModal from "./subscription/ExtendLicenseModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ChangeLicenseUntilDateModal from "../../Components/organisation/ChangeLicenseUntilDateModal";

const LicenseSummary = ({ id }) => {
  const [data, setData] = useState(null);
  const [showRemoveSeatsModal, setShowRemoveSeatsModal] = useState(false);
  const [showExtendLicenseModal, setShowExtendLicenseModal] = useState(false);
  const [showChangeLicenseDateModal, setChangeLicenseDateModal] =
    useState(false);

  const fetchData = () => {
    api.get(`get-organization-license-detail/${id}`).then((response) => {
      setData(response.data);
    });
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("organizationSeatsChanged", fetchData);

    return () => {
      window.removeEventListener("organizationSeatsChanged", fetchData);
    };
  }, []);

  const getOccupiedPercentage = () => {
    if (!data) {
      return 0;
    }

    return (data.used_seats / data.total_license) * 100;
  };

  if (!data) {
    return (
      <div class="placeholder-glow">
        <div className="d-flex justify-content-between mb-2">
          <span class="placeholder col-2 rounded-1"></span>
          <span class="placeholder col-2 rounded-1"></span>
        </div>
        <span class="placeholder col-12 rounded-1"></span>
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>Remaining Seats</div>
        <div>
          <span>Renews on </span>
          <span className="fw-bolder">
            {isoToAusDate(data.valid_until.raw)}
          </span>
          <span>
            <button
              type="button"
              className="btn btn-light btn-sm ms-2 rounded-1"
              onClick={() => setChangeLicenseDateModal(true)}
            >
              <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
            </button>
            <ChangeLicenseUntilDateModal
              id={id}
              currentValue={data.valid_until.raw}
              show={showChangeLicenseDateModal}
              onHide={() => setChangeLicenseDateModal(false)}
              onChange={() => fetchData()}
            />
          </span>
        </div>
      </div>
      <div class="progress my-2">
        <div
          class="progress-bar"
          role="progressbar"
          style={{ width: `${getOccupiedPercentage()}%` }}
          aria-valuenow={getOccupiedPercentage()}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <span className="fw-bold">{data.used_seats}</span> of{" "}
          <span className="fw-bold">{data.total_license}</span> seats used
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary btn-sm rounded-1"
            onClick={() => setShowRemoveSeatsModal(true)}
          >
            Remove Seats
          </button>
          <RemoveSeatsModal
            show={showRemoveSeatsModal}
            onHide={() => setShowRemoveSeatsModal(false)}
            id={id}
          />
          <button
            type="button"
            className="btn btn-primary btn-sm rounded-1 ms-2"
            onClick={() => setShowExtendLicenseModal(true)}
          >
            Extend License
          </button>
          <ExtendLicenseModal
            show={showExtendLicenseModal}
            onHide={() => setShowExtendLicenseModal(false)}
            id={id}
            numberOfLicense={data.total_license}
          />
        </div>
      </div>
    </div>
  );
};

export default LicenseSummary;
