import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import useFetch from "../../../hooks/useFetch";
import Errors from "../../../utils/Errors";
import WizardProgressBar from "./StepsProgressBar";
import Prompts from "./Prompts";
import { toast } from "react-toastify";
import "../../../assets/css/dashboard_addprogram2.css";
import BasicTextEditor from "../../../Components/editor/BasicTextEditor";
import MyFilepond from "./MyFilepond";
import { getAuthUser, hasRole, isAuth } from "../../../utils/helpers";
import FilepondPreview from "./FilepondPreview";
import FilePondImagePreview from "./FilePondImagePreview";
import SingleFileUploader from "../../../Components/SingleFileUploader";
import CoverImageUploader from "./partials/CoverImageUploader";
import CloseFormButton from "../../../Components/layouts/CloseFormButton";

export default function ProgramForm({ program = null, isWizard = true }) {
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  let updateMode = false;
  const { data: categories } = useFetch("categories");
  let addedBy = isAuth ? getAuthUser().id : "";

  const initialState = {
    program_name: "",
    category_id: "",
    added_by: addedBy,
    short_description: "",
    long_description: "",
    intro_video_id: "",
    video_url: "",
    psychological_distress_check: false,
    access_type: false,
    response_privacy: false,
    cover_image_id: "",
    cover_image: "",
    survey_before: "",
    survey_after: "",
    is_free: true,
    prompts: [
      {
        title: "",
        questions: [""],
      },
    ],
  };

  if (program) {
    updateMode = true;
    initialState.program_name = program.program_name;
    initialState.category_id = program.category_id;
    initialState.short_description = program.short_description;
    initialState.long_description = program.long_description;
    initialState.intro_video_id = program.intro_video_id;
    initialState.video_url = program.video_url;
    initialState.psychological_distress_check =
      program.psychological_distress_check;
    initialState.access_type = program.access_type;
    initialState.response_privacy = program.response_privacy;
    initialState.added_by = program.added_by;
    initialState.prompts = program.prompts;
    initialState.survey_before = program.survey_before;
    initialState.survey_after = program.survey_after;
    initialState.is_free = program.is_free;
  }

  const [form, setForm] = useState(initialState);

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);
    let next = e.nativeEvent.submitter.value;

    try {
      const response = await api({
        method: updateMode ? "put" : "post",
        url: updateMode
          ? `program-wizard/update-program/${program.id}`
          : "program-wizard/store-program",
        data: form,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success(
          updateMode ? "Program has been updated" : "Program details saved"
        );

        let programId = response.data.id;

        switch (next) {
          //   case "exit":
          //     navigate(`/programs/${programId}`);
          //     break;
          case "next":
          case "exit":
            navigate(`/programs/${programId}/entries/add`);
            break;
          default:
            break;
        }
      }

      //console.log(response);
    } catch (error) {
      console.log(error);
      setProcessing(false);
      //console.log(error.response.headers);
      //console.log(error.response.data.message);
      if (error?.response?.data?.errors) {
        setErrors(new Errors(error.response.data.errors));
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        return;
      }

      toast.error("Error while creating program");
    }

    setProcessing(false);
  }

  return (
    <Form method="post" onSubmit={(e) => handleSubmit(e)}>
      <div className="add-program2-grid">
        {!updateMode && (
          <div className="add-program2-grid-1">
            <div className="add-program2-grid-1-1">
              <h5>Program Details</h5>
              <p>Enter the basic details of the program.</p>
            </div>
            <WizardProgressBar current="2" total="4" />
          </div>
        )}

        <div>
          <div className="add-program2-grid-2-1">
            <div className="col col-sm-3">
              <h6>Program Name</h6>
            </div>
            <div className="col col-sm-6">
              <Form.Control
                type="text"
                name="program_name"
                className="add-program2-form"
                onChange={handleChange}
                value={form.program_name}
              />
              {errors.has("program_name") && (
                <div className="d-block invalid-feedback">
                  {errors.first("program_name")}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="add-program2-grid-2-2">
          <div className="col col-sm-3">
            <h6>Category</h6>
          </div>
          <div className="col col-sm-6">
            <Form.Select
              name="category_id"
              className="add-category"
              onChange={handleChange}
              value={form.category_id}
            >
              {categories &&
                categories.data &&
                categories.data.map((category, index) => (
                  <option value={category.id} key={index}>
                    {category.name}
                  </option>
                ))}
            </Form.Select>
            {errors.has("category_id") && (
              <div className="d-block invalid-feedback">
                {errors.first("category_id")}
              </div>
            )}
          </div>
        </div>

        <div className="add-program2-grid-2-3">
          <div className="col col-sm-3">
            <h6>Short Description</h6>
            <p>Used when the program is displayed in a list.</p>
          </div>
          <div className="col col-sm-6">
            <Form.Control
              as="textarea"
              name="short_description"
              className="add-program2-form"
              onChange={handleChange}
              value={form.short_description}
            />
            {errors.has("short_description") && (
              <div className="d-block invalid-feedback">
                {errors.first("short_description")}
              </div>
            )}
          </div>
        </div>

        <div className="add-program2-grid-2-4">
          <div className="col col-sm-3">
            <h6>Long Description</h6>
            <p>Displays when viewing the program.</p>
          </div>
          <div className="col col-sm-6">
            <BasicTextEditor
              value={form.long_description}
              onChange={(value) =>
                setForm({ ...form, long_description: value })
              }
            />
            {/* <Form.Control
                as="textarea"
                rows={4}
                name="long_description"
                className="add-program2-form"
                onChange={handleChange}
                value={form.long_description}
              /> */}
            {errors.has("long_description") && (
              <div className="d-block invalid-feedback">
                {errors.first("long_description")}
              </div>
            )}
          </div>
        </div>

        <div className="add-program2-grid-2-5">
          <div className="col col-sm-3">
            <h6>Video Intro</h6>
          </div>

          <div className="col col-sm-6">
            <div>
              {/* {!updateMode ? (

                <MyFilepond
                  maxFiles={1}
                  allowedFileTypes={["video/*"]}
                  onUploadComplete={(ids) => {
                    ids.length && setForm({ ...form, intro_video_id: ids[0] });
                  }}
                />
              ) : (
                <UpdateProgramIntroVideo program_id={program.id}/>
              )} */}

              <MyFilepond
                maxFiles={1}
                allowedFileTypes={["video/*"]}
                onUploadComplete={(ids) => {
                  ids.length && setForm({ ...form, intro_video_id: ids[0] });
                }}
              />
            </div>
            <FilepondPreview />
            <div>
              {updateMode && form.intro_video_id ? (
                <div className="vid">
                  <video controls className="video">
                    <source src={program.intro_video_url} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="text-center my-2">OR</div>
            <Form.Control
              className="add-program2-form-1"
              type="url"
              name="video_url"
              size="50"
              onChange={handleChange}
              value={form.video_url}
              placeholder="Paste an video URL"
            />
          </div>
        </div>

        <div className="add-program2-grid-2-6">
          <div className="col col-sm-3">
            <h6>Cover Image</h6>
          </div>

          <div className="col col-sm-6">
            {/* <div className="add-program2-form-1"> */}
            <CoverImageUploader
              program={program}
              onUploadComplete={(data) =>
                setForm({ ...form, cover_image: data.path })
              }
            />
            {/* <div>
              <SingleFileUploader
                allowedFileTypes={["image/*"]}
                serverUrl={
                  updateMode
                    ? `/update-program-cover-image/${program.id}`
                    : "/temporary-upload"
                }
                onUploadComplete={(data) =>
                  setForm({ ...form, cover_image: data.path })
                }
              />
            </div>
            <div>
              <FilePondImagePreview />
              {updateMode && program.cover_image_url && (
                <div className="imgBox">
                  <Card.Img
                    src={program.cover_image_url}
                    height={220}
                    width={50}
                    className="img"
                  />
                </div>
              )}
            </div> */}
          </div>
        </div>

        <div className="add-program2-grid-2-7">
          <div className="col col-sm-3">
            <h6>Psychological Distress Check</h6>
            <p>
              Measure the impact of your program. Learn more about the Kessler
              Psychological Distress Scale here.
            </p>
          </div>

          <div className="add-program2-grid-100 col col-sm-9">
            <Form.Check
              type="radio"
              className="add-program2-grid-2-71"
              name="psychological_distress_check"
              id="radio-no-survey"
              label="No Survey"
              onChange={() =>
                setForm({ ...form, psychological_distress_check: false })
              }
              checked={form.psychological_distress_check === false}
            />

            <div>
              <Form.Check
                type="radio"
                className="add-program2-grid-2-71"
                name="psychological_distress_check"
                id="radio-survey-before-after"
                onChange={() =>
                  setForm({ ...form, psychological_distress_check: true })
                }
                checked={form.psychological_distress_check === true}
                label="Survey before and after"
              />
              {form.psychological_distress_check === true && (
                <div className="survey-url">
                  <Form.Control
                    type="text"
                    name="survey_before"
                    className="add-program2-form"
                    onChange={handleChange}
                    placeholder="Survey before url here"
                    value={form.survey_before}
                  />

                  <Form.Control
                    type="text"
                    name="survey_after"
                    className="add-program2-form"
                    onChange={handleChange}
                    placeholder="Survey  after url here"
                    value={form.survey_after}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {hasRole("superadmin") && (
          <div className="add-program2-grid-2-8">
            <div className="col col-sm-3">
              <h6>Program Type</h6>
              <p>Set program type free or paid</p>
            </div>
            <div className="add-program2-grid-100 col col-sm-9">
              <Form.Check
                type="checkbox"
                className="add-program2-grid-2-81"
                name="is_free"
                id="is_free-wide"
                value={form.is_free}
                onChange={() => setForm({ ...form, is_free: !form.is_free })}
                label="Is Free"
                checked={form.is_free}
              />
            </div>
          </div>
        )}

        {hasRole("orgadmin") && (
          <>
            <div className="add-program2-grid-2-8">
              <div className="col col-sm-3">
                <h6>Access Type</h6>
                <p>
                  Is this available to anyone in your organisation, or by invite
                  only?
                </p>
              </div>
              <div className="add-program2-grid-100 col col-sm-9">
                <Form.Check
                  type="radio"
                  className="add-program2-grid-2-81"
                  name="access_type"
                  id="radio-organization-wide"
                  onChange={() => setForm({ ...form, access_type: false })}
                  checked={form.access_type === false}
                  label="Organisation Wide"
                />
                <Form.Check
                  type="radio"
                  className="add-program2-grid-2-81"
                  name="access_type"
                  id="radio-invitation-only"
                  label="Invitation Only"
                  onChange={() => setForm({ ...form, access_type: true })}
                  checked={form.access_type === true}
                />
              </div>
            </div>

            <div className="add-program2-grid-2-9">
              <div className="col col-sm-3">
                <h6>Response Privacy</h6>
                <p>
                  Can the organisation see responses, or will they be private.
                </p>
              </div>
              <div className="add-program2-grid-100 col col-sm-9">
                <Form.Check
                  type="radio"
                  className="add-program2-grid-2-91"
                  name="response_privacy"
                  id="radio-visible-response"
                  label="Visible Responses"
                  onChange={() => setForm({ ...form, response_privacy: false })}
                  checked={form.response_privacy === false}
                />
                <Form.Check
                  type="radio"
                  className="add-program2-grid-2-91"
                  name="response_privacy"
                  id="radio-private-response"
                  label="Private Responses"
                  onChange={() => setForm({ ...form, response_privacy: true })}
                  checked={form.response_privacy === true}
                />
              </div>
            </div>
          </>
        )}

        {/* TODO::Remove this after no issue reporting from client */}
        {/* {!updateMode && (
          <div className="add-program2-grid-2-10">
            <div className="col col-sm-3">
              <h6>Question Prompts</h6>
              <p>You can add some prompts to help guide answering</p>
            </div>

            <Prompts
              errors={errors}
              onChange={(prompts) => setForm({ ...form, prompts })}
              promptData={program && program.prompts}
            ></Prompts>
          </div>
        )} */}

        {isWizard && (
          <div className="d-flex align-items-center justify-content-between">
            {/* <button className="add-program2-grid-6" type="reset">
              <p>Cancel</p>
            </button> */}
            <CloseFormButton />

            <div className="d-flex align-items-center">
              {/* <Button
                className="add-program2-grid-6-1"
                type="submit"
                value="exit"
                disabled={processing}
              >
                <p>Save and Exit</p>
              </Button> */}
              <Button
                className="add-program2-grid-6-2"
                type="submit"
                value="next"
                disabled={processing}
              >
                <p>Save and Next</p>
              </Button>
            </div>
          </div>
        )}

        {!isWizard && (
          <div className="d-flex">
            <Button
              className="add-program2-grid-6-2 ms-auto"
              type="submit"
              disabled={processing}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
}
