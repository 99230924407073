import { Modal } from "react-bootstrap";
import api from "../../api";
import { useState } from "react";
import { ButtonSpinner } from "../Encap";
import Errors from "../../utils/Errors";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";

const ChangeLicenseUntilDateModal = ({
  show,
  onHide,
  id,
  currentValue,
  onChange,
}) => {
  const [date, setDate] = useState(currentValue);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());

  const handleDateSelected = (inputDate) => {
    setErrors(new Errors());
    let toISOIgnoreTimezone = null;
    if (inputDate instanceof Date) {
      toISOIgnoreTimezone =
        inputDate.getFullYear() +
        "-" +
        ("0" + (inputDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + inputDate.getDate()).slice(-2) +
        "T00:00:00.000Z";
    }

    setDate(toISOIgnoreTimezone);
  };

  const handleSubmit = () => {
    api
      .post(`organization/${id}/change-license-date`, {
        date: date,
      })
      .then((response) => {
        toast.success("License expiry date updated");
        onChange && onChange();
        onHide && onHide();
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to submit feedback");
          return;
        }
        toast.error("Something went wrong");
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="p-4 bg-white rounded">
          <div className="d-flex gap-2">
            <h4 className="mb-3 font-secondary fw-bolder">
              Change License Expiry Date
            </h4>
          </div>
          <div className="mb-3">
            <label className="mb-1">New Expiry Date</label>
            <ReactDatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              {...(date
                ? {
                    selected: Date.parse(date),
                  }
                : {})}
              onChange={(date) => handleDateSelected(date)}
              wrapperClassName="d-block"
              showIcon
            />
            {errors.has("date") && (
              <div className="d-block invalid-feedback error-text">
                {errors.first("date")}
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end gap-2">
            <button
              type="button"
              className="btn btn-light rounded-1 font-secondary"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-1 font-secondary fw-bolder"
              onClick={handleSubmit}
              disabled={processing}
            >
              <ButtonSpinner processing={processing} />
              <span>Submit</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeLicenseUntilDateModal;
