import React from "react";
import '../../assets/css/organization_button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

function OrganisationButton(){
    return(
        <div className="organization-1">
            <Link to='/organization-dashboard'>
                <button className="organization-grid-2">
                    <h6>Dashboard</h6>
                    <FontAwesomeIcon className='organization-arrow-icon' 
                        icon={faArrowRight} color='#FFFFFF' style={{fontSize:12}}></FontAwesomeIcon>
                </button>
            </Link>

            <Link to='/programs'>
                <button className="organization-grid-2">
                    <h6>Our Programs</h6>
                    <FontAwesomeIcon className='organization-arrow-icon' 
                        icon={faArrowRight} color='#FFFFFF' style={{fontSize:12}}></FontAwesomeIcon>
                </button>
            </Link>

            <Link to='/organizations/user'>
                <button className="organization-grid-2">
                    <h6>Users</h6>
                    <FontAwesomeIcon className='organization-arrow-icon' 
                        icon={faArrowRight} color='#FFFFFF' style={{fontSize:12}}></FontAwesomeIcon>
                </button>
            </Link>

            {/* <Link to='/organisation/billing-sharing'> */}
                <button className="organization-grid-2" disabled>
                    <h6>Billing & Subscriptions</h6>
                    <FontAwesomeIcon className='organization-arrow-icon' 
                        icon={faArrowRight} color='#FFFFFF' style={{fontSize:12}}></FontAwesomeIcon>
                </button>
            {/* </Link> */}

            {/* <Link to=''> */}
                <button className="organization-grid-2" disabled>
                    <h6>Organisation Settings</h6>
                    <FontAwesomeIcon className='organization-arrow-icon' 
                        icon={faArrowRight} color='#FFFFFF' style={{fontSize:12}}></FontAwesomeIcon>
                </button>
            {/* </Link> */}
        </div>
    );
}

export default OrganisationButton;