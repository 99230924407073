import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripe_publishable_key } from "../../../config/config";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../../api";
import { syncUserInformation } from "../../../utils/helpers";

export default function ChangePaymentMethodForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Use Stripe.js to handle payment method collection
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        throw new Error(error.message);
      }

      api
        .post("update-payment-method", {
          paymentMethod: paymentMethod.id
        })
        .then((response) => {
          if (response.status === 200) {
            syncUserInformation();
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response && error.response.data.errors) {
            console.error(error);
            toast.error("Error while subscription");
            return;
          }
        });

      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      toast.error("An error occurred. " + error.message);
    }
  };

  const style = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      ":focus": {
        border: "1px solid hsla(210, 96%, 45%, 50%)",
      },
    },
    invalid: {
      color: "##dc2727",
      iconColor: "#fa755a",
    },
  };

  return (
    <>
      <div className="mb-4">
        <label className="form-label">Card Number</label>
        <div className="stripe-input">
          <CardNumberElement
            options={{ showIcon: true, iconStyle: "solid", style }}
          />
        </div>
      </div>
      <div className="d-flex gap-3 mb-4">
        <div className="w-100">
          <label className="form-label">Expiration</label>
          <div className="stripe-input">
            <CardExpiryElement
              options={{ showIcon: true, iconStyle: "solid", style }}
            />
          </div>
        </div>
        <div className="w-100">
          <label className="form-label">CVC</label>
          <div className="stripe-input">
            <CardCvcElement
              options={{ showIcon: true, iconStyle: "solid", style }}
            />
          </div>
        </div>
      </div>
      <div>
        {processing ? (
          <Button className="btn">Please wait...</Button>
        ) : (
          <Button className="btn" onClick={handleSubmit}>
            Update card details
          </Button>
        )}
      </div>
    </>
  );
}
