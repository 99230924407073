import { useEffect, useState } from "react";
import Errors from "../../utils/Errors";
import api from "../../api";
import { toast } from "react-toastify";

const AppSettings = () => {
  const [state, setState] = useState({
    android_version: "",
    ios_version: "",
    monthly_subscription_price: "",
    monthly_subscription_price_description: "",
    monthly_subscription_discount_percent: "",
    yearly_subscription_price: "",
    yearly_subscription_price_description: "",
    yearly_subscription_discount_percent: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(new Errors());

  function handleInputChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    console.log(state);
  }

  const getSettings = () => {
    api.get("settings").then((res) => {
      setState(res.data);
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    setErrors(new Errors());
    api
      .post("settings", state)
      .then((res) => {
        toast.success("Settings updated !");
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Validation Error");
          return;
        }
        toast.error("Server error. Please try again");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => getSettings(), []);

  return (
    <div className="p-4 container-fluid">
      <div className="admin-user-grid-3">
        <h5>App Settings </h5>
        <p>Encapsulator related settings</p>
      </div>

      <section className="bg-white border" style={{ maxWidth: "900px" }}>
        <div className="p-4">
          <div>
            <h4 className="font-secondary fw-bolder mb-3">App Versions</h4>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3 pt-0">
              <label class="form-label">Android App Version</label>
              <input
                className="form-control"
                type="text"
                name="android_version"
                value={state.android_version}
                onChange={(e) => handleInputChange(e)}
                placeholder="Andriod app version"
              />
              {errors.has("android_version") && (
                <div className="d-block invalid-feedback error-text">
                  {errors.first("android_version")}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3 pt-0">
              <label class="form-label">IOS App Version</label>
              <input
                className="form-control"
                type="text"
                name="ios_version"
                value={state.ios_version}
                onChange={(e) => handleInputChange(e)}
                placeholder="IOS app version"
              />
              {errors.has("ios_version") && (
                <div className="d-block invalid-feedback error-text">
                  {errors.first("ios_version")}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3 pt-0">
              <label class="form-label">Monthly Subscription Price</label>
              <input
                className="form-control"
                type="text"
                name="monthly_subscription_price"
                value={state.monthly_subscription_price}
                onChange={(e) => handleInputChange(e)}
                placeholder="Monthly subscription price"
              />
              {errors.has("monthly_subscription_price") && (
                <div className="d-block invalid-feedback error-text">
                  {errors.first("monthly_subscription_price")}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3 pt-0">
              <label class="form-label">Monthly Subscription Description</label>
              <input
                className="form-control"
                type="text"
                name="monthly_subscription_price_description"
                value={state.monthly_subscription_price_description}
                onChange={(e) => handleInputChange(e)}
                placeholder=""
              />
              {errors.has("monthly_subscription_price_description") && (
                <div className="d-block invalid-feedback error-text">
                  {errors.first("monthly_subscription_price_description")}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3 pt-0">
              <label class="form-label">
                Monthly Subscription Discount Percent
              </label>
              <input
                className="form-control"
                type="text"
                name="monthly_subscription_discount_percent"
                value={state.monthly_subscription_discount_percent}
                onChange={(e) => handleInputChange(e)}
                placeholder=""
              />
              {errors.has("monthly_subscription_discount_percent") && (
                <div className="d-block invalid-feedback error-text">
                  {errors.first("monthly_subscription_discount_percent")}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3 pt-0">
              <label class="form-label">Yearly Subscription Price</label>
              <input
                className="form-control"
                type="text"
                name="yearly_subscription_price"
                value={state.yearly_subscription_price}
                onChange={(e) => handleInputChange(e)}
                placeholder="Yearly subscription price"
              />
              {errors.has("yearly_subscription_price") && (
                <div className="d-block invalid-feedback error-text">
                  {errors.first("yearly_subscription_price")}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3 pt-0">
              <label class="form-label">Yearly Subscription Desccription</label>
              <input
                className="form-control"
                type="text"
                name="yearly_subscription_price_description"
                value={state.yearly_subscription_price_description}
                onChange={(e) => handleInputChange(e)}
                placeholder="Yearly subscription price"
              />
              {errors.has("yearly_subscription_price_description") && (
                <div className="d-block invalid-feedback error-text">
                  {errors.first("yearly_subscription_price_description")}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3 pt-0">
              <label class="form-label">
                Yearly Subscription Discount Percent
              </label>
              <input
                className="form-control"
                type="text"
                name="yearly_subscription_discount_percent"
                value={state.yearly_subscription_discount_percent}
                onChange={(e) => handleInputChange(e)}
                placeholder=""
              />
              {errors.has("yearly_subscription_discount_percent") && (
                <div className="d-block invalid-feedback error-text">
                  {errors.first("yearly_subscription_discount_percent")}
                </div>
              )}
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary rounded-1 px-4"
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Saving" : "Save"}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppSettings;
