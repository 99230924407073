import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { guestApi } from "../../../api";
import { toast } from "react-toastify";
import LoadingBlock from "../../../Components/LoadingBlock";
import UnauthorizedToAcceptInvitation from "./UnauthorizedToAcceptInvitation";
import SetupAccountPrompt from "./SetupAccountPrompt";
import SetupAccount from "./SetupAccount";
import { AuthContext } from "../../../context/AuthContext";
import AutoLogin from "./AutoLogin";
import InvitationConfirmationPrompt from "./InvitationConfirmationPrompt";
import InvitationAcceptedMessage from "./InvitationAcceptedMessage";
import InvitationDeclinedMessage from "./InvitationDeclinedMessage";
import InvalidInvitationMessage from "./InvalidInvitationMessage";
import "./../../../assets/scss/organization_admin_invitation.scss";

const STAGE = {
  INITIAL: "INITIAL",
  AUTO_LOGIN: "auto_login",
  ACCOUNT_SETUP_PROMPT: "account_setup_prompt",
  ACCOUNT_SETUP: "account_setup",
  INVITATION_ACCEPTED: "invitation_accepted",
  INVITATION_DECLINED: "invitation_declined",
  INVALID_INVITATION: "invalid_invitation",
  UNAUTHORIZED: "unauthorized",
};

export default function OrganizationAdminInvitation() {
  const { token } = useParams();
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();
  const [stage, setStage] = useState(STAGE.INITIAL);
  const [invitation, setInvitation] = useState(null);

  const getInvitationDetail = () => {
    return guestApi
      .post(`organization-admin-invitation/get-invitation-detail`, {
        token: token,
      })
      .then((response) => {
        setInvitation(response.data);
        if (!response.data.is_valid) {
          setStage(STAGE.INVALID_INVITATION);
          return;
        }

        // Logout if loggedin with a different email
        if (user && user.email !== response.data.email) {
          setStage(STAGE.UNAUTHORIZED);
          return;
        }

        if (response.data.user_has_account) {
          if (response.data.email === user?.email) {
            console.log("ready to accept");
            setStage(STAGE.ACCEPT_PROMPT);
          } else {
            console.log("need login");
            setStage(STAGE.AUTO_LOGIN);
          }
        } else {
          console.log("need registration");
          setStage(STAGE.ACCOUNT_SETUP_PROMPT);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setStage(STAGE.INVALID_INVITATION);
          return;
        }
        toast.error("Server Error");
      });
  };

  useEffect(() => {
    getInvitationDetail();
  }, []);

  return (
    <div className="organization-invitation-container">
      {stage === STAGE.INITIAL && <LoadingBlock />}
      {stage === STAGE.UNAUTHORIZED && <UnauthorizedToAcceptInvitation />}

      {stage === STAGE.AUTO_LOGIN && (
        <AutoLogin
          token={token}
          onLoggedIn={() => setStage(STAGE.ACCEPT_PROMPT)}
        />
      )}

      {stage === STAGE.ACCOUNT_SETUP_PROMPT && (
        <SetupAccountPrompt
          organizationName={invitation.organization}
          email={invitation.email}
          onAccept={() => setStage(STAGE.ACCOUNT_SETUP)}
        />
      )}

      {stage === STAGE.ACCOUNT_SETUP && (
        <SetupAccount
          email={invitation.email}
          token={token}
          onComplete={() => setStage(STAGE.ACCEPT_PROMPT)}
        />
      )}

      {stage === STAGE.ACCEPT_PROMPT && (
        <InvitationConfirmationPrompt
          token={token}
          organizationName={invitation.organization}
          onAccept={() => setStage(STAGE.INVITATION_ACCEPTED)}
          onDecline={() => setStage(STAGE.INVITATION_DECLINED)}
        />
      )}

      {stage === STAGE.INVITATION_ACCEPTED && (
        <InvitationAcceptedMessage organizationName={invitation.organization} />
      )}

      {stage === STAGE.INVITATION_DECLINED && <InvitationDeclinedMessage />}

      {stage === STAGE.INVALID_INVITATION && <InvalidInvitationMessage />}
    </div>
  );
}
