import React from 'react';
import './../assets/css/pagination.css'; 

function Pagination({ meta, onChange }) {
  const totalPages = Math.ceil(meta.total / meta.per_page);
  const maxPagesToShow = 6;
  const currentPage = meta.current_page;

  let startPage = currentPage - Math.floor(maxPagesToShow / 2);
  startPage = Math.max(1, startPage);
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => onChange(i)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="pagination">
      <button
        onClick={() => onChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      >
        &lt; 
      </button>
      {renderPageButtons()}
      <button
        onClick={() => onChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
      >
         &gt;
      </button>
    </div>
  );
}

export default Pagination;
