import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import api from "../../../../api";
import { ClockLoader, SyncLoader } from "react-spinners";

function ViewVideoAnswer({ answer }) {
  const [processing, setProcessing] = useState(answer.video_processing);
  const [videoUrl, setVideoUrl] = useState(answer.video_response_url);
  let isMounted = true;

  useEffect(() => {
    const fetchAnswer = () => {
      if (!isMounted) {
        return;
      }

      api(`answer/${answer.id}`).then((res) => {
        setVideoUrl(res.data.video_response_url);
        setProcessing(res.data.video_processing);
        if (res.data.video_processing) {
          setTimeout(fetchAnswer, 5000);
        }
      });
    };

    fetchAnswer();

    return () => {
      isMounted = false;
    };
  }, [answer]);

  if (processing) {
    return (
      <div className="bg-white p-5 border rounded text-center mb-3">
        <div className="mb-3 text-center /">
          <ClockLoader
            color="#529C00"
            aria-label="Loading Spinner"
            className="d-inline-block"
          />
        </div>
        <div>
          Your video is being processed. It will show here once available.
        </div>
      </div>
    );
  }

  return (
    <div className="mb-3 entry-video-answer">
      <ReactPlayer url={videoUrl} controls={true} pip={true} />
    </div>
  );
}

export default ViewVideoAnswer;
