import { Link } from "react-router-dom";
import "../../assets/scss/download_app_mini_banner.scss";
import { MobileView, isAndroid, isIOS } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppStore, faGooglePlay } from "@fortawesome/free-brands-svg-icons";

const DownloadAppMiniBanner = () => {
  const appStoreLink = isIOS
    ? "https://apps.apple.com/us/app/encapsulator/id1567388070"
    : "https://play.google.com/store/apps/details?id=io.encapsulator.app";

  const Icon = () => {
    if (isAndroid) {
      return <FontAwesomeIcon icon={faGooglePlay} />;
    }

    if (isIOS) {
      return <FontAwesomeIcon icon={faAppStore} />;
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.75529 3.74655C2.67545 3.74655 1.80007 4.79646 1.80007 6.0916C1.80007 7.38673 2.67545 8.43664 3.75529 8.43664C4.38405 8.43664 4.73289 8.51481 5.43151 7.76209C5.96938 7.18257 6.83978 8.06664 6.42741 8.67114C5.84085 9.53099 4.95031 10 3.75529 10C1.95556 10 0.4966 8.25015 0.4966 6.0916C0.4966 3.93304 1.95556 2.18319 3.75529 2.18319C5.44977 2.18319 6.92085 3.72803 6.99923 5.71739C7.01397 6.0916 6.81845 6.63877 6.29706 6.63877C5.66465 6.63877 4.70877 6.63877 3.42942 6.63877C3.06947 6.63877 2.77768 6.2888 2.77768 5.85709C2.77768 5.4323 3.06479 5.08795 3.41897 5.08795C3.41897 5.08795 4.12785 5.10161 5.53869 5.12895C5.23238 4.3139 4.54908 3.74655 3.75529 3.74655ZM0.651737 3.15094C0.291793 3.15094 0 2.80097 0 2.36926C0 1.93755 0.291793 1.58758 0.651737 1.58758C1.01168 1.58758 1.30347 1.93755 1.30347 2.36926C1.30347 2.80097 1.01168 3.15094 0.651737 3.15094ZM2.90448 0L4.54092 0C4.8989 0 5.18911 0.34997 5.18911 0.781681C5.18911 1.21339 4.8989 1.56336 4.54092 1.56336H2.90448C2.5465 1.56336 2.25629 1.21339 2.25629 0.781681C2.25629 0.34997 2.5465 0 2.90448 0Z"
          fill="white"
        />
      </svg>
    );
  };

  return (
    <MobileView>
      <div className="download-app-mini-banner mt-4">
        <div>
          <span className="encap-icon">
            <Icon />
          </span>
        </div>
        <div>Download Encapsulator app to make video entries</div>
        <div>
          <Link to={appStoreLink} className="get-btn">
            GET
          </Link>
        </div>
      </div>
    </MobileView>
  );
};

export default DownloadAppMiniBanner;
