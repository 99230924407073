import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import api from "../../../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PromptSelectionModal from "./PromptSelectionModal";
import AdditionalEntryModal from "../../../../Components/Modals/AdditionalEntryModal";

export default function AdditionalEntry({ program }) {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [promptSelectionModal, setShowPromptSelectionModal] = useState(false);

  const handlePromptSelected = (id) => {
    let prompt = program.prompts.find((prompt) => {
      return prompt.id == id;
    });
    setPrompt(prompt);
    setShowPromptSelectionModal(false);
    setShowAnswerModal(true);
  };

  const handlePromptSelectionclose = () => {
    setShowPromptSelectionModal(false);
  };

  const addAdditionalEntry = () => {
    // We will pause the entry's intro video when this modal is shown
    let event = new CustomEvent("videoPlaybackChange", {
      detail: {
        name: "entry_intro_video",
        playing: false,
      },
    });
    dispatchEvent(event);

    // if answer type is any and prompts available, we need to allow selecting prompts
    if (program.prompts.length) {
      setShowPromptSelectionModal(true);
      return;
    }
    setShowAnswerModal(true);
  };

  const createEntry = () => {
    setProcessing(true);
    api
      .post(`user-entry/${program.id}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // toast.success("Entry Added Sucessfully !");
          // onComplete && onComplete();
          let entryId = response.data.entry.id;
          navigate(`/programs/${program.id}/entries/${entryId}?addAnswer=true`);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      })
      .finally(() => setProcessing(false));
  };

  return (
    <div>
      <button
        type="button"
        className="btn icon-btn"
        onClick={addAdditionalEntry}
      >
        <FontAwesomeIcon
          className="icon"
          icon={faPlus}
          color="#FFFFFF"
        ></FontAwesomeIcon>
        {processing ? (
          <span>Please wait...</span>
        ) : (
          <span>Additional Entry</span>
        )}
      </button>

      <AdditionalEntryModal
        show={showAnswerModal}
        onHide={() => setShowAnswerModal(false)}
        program={program}
        prompt={prompt}
      />

      <PromptSelectionModal
        show={promptSelectionModal}
        prompts={program.prompts}
        onPromptSelected={handlePromptSelected}
        onPromptSelectionClose={handlePromptSelectionclose}
      />
    </div>
  );
}
