import {
  faCloudDownloadAlt,
  faFileAlt,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../api";
import { useState } from "react";

const ResourcePreviewItem = ({ resource }) => {
  const [deleted, setDeleted] = useState(false);

  const handleDelete = () => {
    api
      .post(`delete-entry-resource/${resource.id}`)
      .then((res) => setDeleted(true));
  };

  if (deleted) {
    return <></>;
  }

  return (
    <div className="resource-preview">
      <div className="file-type">
        <FontAwesomeIcon icon={faFileAlt} />
      </div>
      <div className="info">
        <div className="filename">{resource.display_name}</div>
        <div className="text-muted">{resource.size_human}</div>
      </div>
      <div className="action">
        <a href={resource.url} download target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faCloudDownloadAlt} />
        </a>
        <button type="button" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      </div>
    </div>
  );
};

export default ResourcePreviewItem;
