import { useRef, useState } from "react";
import defaultCoverImage from "../../assets/image/default-image.png";

const ProgramCoverImage = ({ program, ...props }) => {
  const [imageUrl, setImageUrl] = useState({
    original: program.cover_image_url,
    small: program.cover_image_small_url,
    medium: program.cover_image_medium_url,
  });

  const handleImageError = (e) => {
    setImageUrl({
      original: defaultCoverImage,
      small: defaultCoverImage,
      medium: defaultCoverImage,
    });
  };

  return (
    <img
      src={imageUrl.original}
      srcSet={`${imageUrl.small} 480w, ${imageUrl.medium} 800w`}
      sizes="(max-width: 480px) 480px,
                (max-width: 800px) 800px,
                100%"
      onError={(e) => {
        handleImageError(e);
      }}
      alt={program.program_name ? program.program_name : "Cover Image"}
      {...props}
    />
  );
};

export default ProgramCoverImage;
