import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import "./../../../assets/scss/publication_status.scss";

const PublicationStatus = ({ program }) => {
  const isPublished = program.is_published;

  if (isPublished) {
    return (
      <div className="publication__status published">
        <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
        <span>Published</span>
      </div>
    );
  }

  return (
    <div className="publication__status draft">
      <FontAwesomeIcon icon={faWarning}></FontAwesomeIcon>
      <span>Not published</span>
    </div>
  );
};

export default PublicationStatus;
