import { useNavigate } from "react-router-dom";
import api from "../api";

export function isAuth() {
  const token = localStorage.getItem("token");
  return token ? true : false;
}

export function isLoggedIn() {
  return isAuth();
}

export function getAuthUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function getSubscribedAuth() {
  return JSON.parse(localStorage.getItem("is_subscribed"));
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("original_token");
  localStorage.removeItem("original_user");
  localStorage.removeItem("subscribed"); // Make sure its in use
}

export function hasRole(role) {
  const user = getAuthUser();

  if (!user) {
    return false;
  }

  if (user.role === role) {
    return true;
  }

  return false;
}

export function hasAnyRole(roles) {
  if (!Array.isArray(roles)) {
    roles = [roles];
  }

  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    return false;
  }

  roles.forEach((role) => {
    if (user.role === role) {
      return true;
    }
  });

  return false;
}

export const syncUserInformation = async () => {
  await api.get("get-auth-user-info").then((response) => {
    localStorage.setItem("user", JSON.stringify(response.data.data));
  });
};

export function reactHtmlEntities(htmlStr) {
  if (htmlStr) {
    const regex = htmlStr.replace(/(<([^>]+)>)/gi, "");
    return regex;
  }
  return htmlStr;
}

export function isoToAusDate(date) {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) {
    return "Invalid date";
  }

  const options = { day: "2-digit", month: "short", year: "numeric" };
  return parsedDate.toLocaleDateString("en-AU", options);
}

export function CloseFormAction(redirect_url) {
  const navigate = useNavigate();
  return navigate(redirect_url);
}
