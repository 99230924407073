import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AuthorizedUserListing = ({ users, onRevokeAccess }) => {
  const revokeAccess = (userId) => {
    onRevokeAccess && onRevokeAccess(userId);
  };

  return (
    <section className="authorized-user-list-wrap">
      <search className="search-area">
        <input type="text" placeholder="Search user" />
      </search>
      <div className="content-area">
        {users?.map((user) => (
          <article className="authorized-user-list-item">
            <div className="user-info">
              <img src={user.avatar} alt={user.first_name} />
              <div>
                <h5>
                  {user.first_name} {user.last_name}
                </h5>
                <div>
                  <small>{user.email}</small>
                </div>
              </div>
            </div>
            <div className="mx-auto">{/* <span>Started</span> */}</div>
            <div className="ms-auto">
              {/* <FontAwesomeIcon icon={faTrashAlt} /> */}
              <button
                type="button"
                className="remove-access-btn font-secondary"
                onClick={(revokeAccess) => onRevokeAccess(user.id)}
              >
                Remove
              </button>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default AuthorizedUserListing;
