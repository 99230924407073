import { Link, useNavigate, useParams } from "react-router-dom";
import EntryLists from "./partials/EntryLists";
import ProgressBar from "./../../../Components/progressbar/ProgressBar";
import LoadingBlock from "./../../../Components/LoadingBlock";
import "../../../assets/css/prog_summary.css";
import { useEffect, useState } from "react";
import api from "../../../api";
import { toast } from "react-toastify";
import BeforeSurveyModal from "../../../Components/Modals/BeforeSurveyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import CompleteProgramButton from "./partials/CompleteProgramButton";
import ShowProgramEntryRightSection from "./partials/ShowProgramEntryRightSection";
import moment from "moment";
import GoBackButton from "../../../Components/GoBackButton";

function ShowProgramEntry() {
  let { programId, entryId = "first" } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [showSurveyModal, setSurveyModal] = useState(true);
  let nextItem = null;

  const fetchData = () => {
    setLoading(true);
    api
      .get(`programs/${programId}/entries/${entryId}`)
      .then((res) => {
        setLoading(false);
        res.data && setData(res.data);
        // Set next item
        let entries = res.data.program.entries;
        let currentItemIndex = entries.findIndex((entry) => {
          return parseInt(entry.id) === parseInt(entryId);
        });
        nextItem = entries[currentItemIndex + 1] ?? null;
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [programId, entryId]);

  useEffect(() => {
    const handleAnswerSubmitted = () => {
      if (!nextItem) {
        fetchData();
        return;
      }
      navigate(`/programs/${programId}/entries/${nextItem.id}?autoplay=false`);
    };

    window.addEventListener("answerSubmitted", handleAnswerSubmitted);
    return () => {
      window.removeEventListener("answerSubmitted", handleAnswerSubmitted);
    };
  }, [entryId]);

  const formatDate = (date) => {
    return moment(date).format("D MMM, YYYY");
  };

  if (loading || !data) {
    return <LoadingBlock />;
  }

  return (
    <div className="entry-conatiner">
      {data.program.progress.completedCount < 1 &&
        data.program.survey_before && (
          <BeforeSurveyModal
            show={showSurveyModal}
            survey_before={data.program.survey_before}
            onHide={() => setSurveyModal(false)}
          />
        )}

      <div className="row">
        <div className="col-12 d-flex">
          <GoBackButton
            to={`/my-programs/${programId}`}
            text="View Description"
          />
          <div className="ms-auto Hello">
            <GoBackButton to="/my-programs">
              <span className="me-2">Close</span>
              <FontAwesomeIcon icon={faClose} className=" me-2" />
            </GoBackButton>
          </div>
        </div>
        <div className="col-sm-5 entry-left">
          <div className="d-sm-flex mb-4">
            {data.program && (
              <div>
                <h4>{data.program.program_name}</h4>
                <p>{data.program.short_description}</p>
              </div>
            )}
          </div>
          {data.program && data.program.entries && (
            <>
              <div className="mb-4">
                <ProgressBar
                  bgcolor="#529C00"
                  current={data.program.progress.completedCount}
                  total={data.program.progress.entriesCount}
                  progress={data.program.progress.completionPercentage}
                />
              </div>
              <EntryLists
                entries={data.program.entries}
                program={data.program}
              />
            </>
          )}

          {!data.program.isCompleted && (
            <div className="mt-2 text-muted">
              To restart this program, please complete all the steps.
            </div>
          )}

          {data.program.isCompleted && (
            <div className="mt-2 text-muted">
              You completed this program in{" "}
              {formatDate(data.program.completed_at)}.
            </div>
          )}

          {data.program.progress.completionPercentage >= 100 &&
            !data.program.isCompleted && (
              <CompleteProgramButton program={data.program} />
            )}
        </div>
        <div className="col-sm-7 entry-right">
          <ShowProgramEntryRightSection
            program={data.program}
            entry={data.entry}
          />
        </div>
      </div>
    </div>
  );
}

export default ShowProgramEntry;
