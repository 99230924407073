import { Outlet, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import ProgramForm from "../wizard/ProgramForm";
import WizardNavigation from "./WizardNavigation";
import LoadingBlock from "../../../Components/LoadingBlock";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../api";

function EditProgramLayout() {
  const { programId } = useParams();
  const [program, setProgram] = useState(null);
  // const { data: program, loading } = useFetch(
  //   `program-wizard/edit-program/${programId}`
  // );

  const fetchProgram = useCallback(() => {
    api
      .get(`program-wizard/edit-program/${programId}`)
      .then((response) => {
        setProgram(response.data);
      })
      .catch((error) => toast.error("Server error"));
  }, [programId]);

  useEffect(() => {
    fetchProgram();
  }, [fetchProgram]);

  return (
    <div>
      {program && (
        <WizardNavigation program={program} onProgramChange={fetchProgram} />
      )}
      <Outlet />
    </div>
  );
}

export default EditProgramLayout;
