import OrganizationSubscriptionItem from "./OrganizationSubscriptionItem";

const OrganizationSubscriptionList = ({ organizations }) => {
  return (
    <div>
      <div className="bs-title mb-4">
        <h6>Memberships</h6>
      </div>
      {organizations.map((organization) => (
        <OrganizationSubscriptionItem organization={organization} />
      ))}
    </div>
  );
};

export default OrganizationSubscriptionList;
