const ProgramPublishText = ({ program }) => {
  if (program.is_published === false)
    return (
      <div className="text-danger fst-italic blink-text">
        Program is not published yet
      </div>
    );
};

export default ProgramPublishText;
