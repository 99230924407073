import { createBrowserRouter } from "react-router-dom";
import AuthLayout from "../Components/layouts/AuthLayout";
import AuthRoutes from "./AuthRoutes";
import GuestLayout from "../Components/layouts/GuestLayout";
import Root from "../Components/layouts/Root";
import PageNotFound from "../pages/errors/PageNotFound";
import Notification from "../pages/notification/Notification";
import AdminProgram from "../pages/program/AdminProgram";
import ProgramLibrary from "../pages/program/library/ProgramLibrary";
import ProgramLibraryDetail from "../pages/program/library/ProgramLibraryDetail";
import PremadeProgram from "../pages/program/wizard/PremadeProgram";
import ProgramCompletedPage from "../pages/program/user/ProgramCompletedPage";
import MyProgram from "../pages/program/user/MyProgram";
import ShowProgramEntry from "../pages/program/user/ShowProgramEntry";
import AddProgram from "../pages/program/wizard/AddProgram";
import CreateEntry from "../pages/program/wizard/CreateEntry";
import CreateProgram from "../pages/program/wizard/CreateProgram";
import EditProgramPage from "../pages/program/edit/EditProgramPage";
import ProtectedRoute from "../utils/ProtectedRoute";
import EditEntryPage from "../pages/program/edit/EditEntryPage";
import EditProgramCompletion from "../pages/program/edit/EditProgramCompletionPage";
import EditPromptsPage from "../pages/program/edit/EditPromptsPage";
import ProgramCompletionPage from "../pages/program/wizard/ProgramCompletionPage";
import ViewMyProgram from "../pages/program/user/ViewMyProgram";
import SubscriptionPage from "../pages/subscription/SubscriptionPage";
import ContinueProgram from "../pages/program/user/ContinueProgram";
import EditOrganisationPage from "../pages/organisation/EditOrganisationPage";
import BillingAndSharing from "../pages/organisation/BillingAndSharing";
import BillingAndSubscriptions from "../pages/setting/BillingAndSubscription";
import ContinueMasterProgram from "../pages/program/user/ContinueMasterProgram";
import MyProfile from "../pages/setting/MyProfile";
import ProgramDetail from "../pages/program/admin/ProgramDetail";
import ViewResponse from "../pages/program/admin/ViewResponse";
import SubscriptionStep from "../pages/organisation/wizard/SubscriptionStep";
import AppSettings from "../pages/setting/AppSettings";
import AuthorizeUserPage from "../pages/program/edit/AuthorizeUserPage";
import OrganizationSetting from "../pages/setting/organization/OrganzationSetting";
import NotificationsPage from "../pages/notification/NotificationsPage";
import { lazy } from "react";
import EditProgramLayout from "../pages/program/edit/EditProgramLayout";
import OverviewTab from "../pages/program/edit/OverviewTab";
import PromptsTab from "../pages/program/edit/PromptsTab";
import EntriesTab from "../pages/program/edit/EntriesTab";
import CompletionTab from "../pages/program/edit/CompletionTab";
import AuthorizeUserTab from "../pages/program/edit/AuthorizeUserTab";
import ContributorsTab from "../pages/program/edit/ContributorsTab";

const Dashboard = lazy(() => import("../pages/dashboard/admin/Dashboard"));
const ArrangeProgramsPage = lazy(() =>
  import("../pages/program/ArrangeProgramsPage")
);
const UsersList = lazy(() => import("../pages/user/UsersList"));
const UserDetailPage = lazy(() => import("../pages/user/UserDetailPage"));

const OrganisationDashboard = lazy(() =>
  import("../pages/organisation/OrganisationDashboard")
);
const OrganisationProgram = lazy(() =>
  import("../pages/organisation/OrganisationProgram")
);
const OrganizationListingPage = lazy(() =>
  import("../pages/organisation/OrganizationListingPage")
);
const OrganisationUserListing = lazy(() =>
  import("../pages/organisation/OrganisationUserListing")
);
const CreateOrgForm = lazy(() =>
  import("../pages/organisation/wizard/CreateOrgForm")
);
const InviteOrgUser = lazy(() =>
  import("../pages/organisation/wizard/InviteOrgUser")
);

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        element: <GuestLayout />,
        children: AuthRoutes,
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: "/",
            element: <ProgramLibrary />,
          },
          {
            path: "program-library",
            element: <ProgramLibrary />,
          },
          {
            path: "program-library/:slug",
            element: <ProgramLibraryDetail />,
          },
          {
            path: "admin/programs/:programId",
            element: <ProgramDetail />,
          },
          {
            path: "view-response/:programId",
            element: <ViewResponse />,
          },
          // {
          //   path: "verify-email/:email/:otp",
          //   element: <VerifyEmail />,
          // },
          // {
          //   path: "subscribe",
          //   element:<Subscribe />
          // },
          {
            element: <ProtectedRoute />,
            children: [
              {
                path: "/dashboard",
                element: <Dashboard />,
              },
              {
                path: "notifications",
                element: <NotificationsPage />,
              },
              {
                path: "subscribe",
                element: <SubscriptionPage />,
              },
              {
                path: "programs",
                element: <AdminProgram />,
              },
              {
                path: "arrange-programs",
                element: <ArrangeProgramsPage />,
              },

              // Program Wizard
              {
                path: "add-program",
                element: <AddProgram />,
              },
              {
                path: "programs/add",
                element: <CreateProgram />,
              },
              {
                // path: "edit-program/:id",
                path: "programs/:programId/edit",
                element: <EditProgramLayout />,
                children: [
                  {
                    index: true,
                    element: <OverviewTab />,
                  },
                  {
                    path: "prompts",
                    element: <PromptsTab />,
                  },
                  {
                    path: "entries",
                    element: <EntriesTab />,
                  },
                  {
                    path: "completion",
                    element: <CompletionTab/>
                  },
                  {
                    path: "authorized-users",
                    element: <AuthorizeUserTab/>
                  },
                  {
                    path: "contributors",
                    element: <ContributorsTab/>
                  }
                ],
              },
              // {
              //   path: "programs/:id/edit",
              //   element: <EditProgramPage />,
              // },
              // {
              //   path: "programs/:programId/edit-prompts",
              //   element: <EditPromptsPage />,
              // },
              {
                path: "programs/:programId/entries/add",
                element: <CreateEntry />,
              },
              // {
              //   path: "programs/:programId/entries/:entryId/edit",
              //   element: <CreateEntry />,
              // },

              // {
              //   path: "programs/:programId/edit-entries",
              //   element: <EditEntryPage />,
              // },
              {
                path: "programs/:programId/completion",
                element: <ProgramCompletionPage />,
              },
              // {
              //   path: "programs/:programId/completion/edit",
              //   element: <EditProgramCompletion />,
              // },
              // {
              //   path: "programs/:programId/authorized-users",
              //   element: <AuthorizeUserPage />,
              // },

              {
                path: "my-programs",
                element: <MyProgram />,
              },
              {
                path: "my-programs/:programId",
                element: <ViewMyProgram />,
              },
              {
                path: "programs/:programId/entries",
                element: <ShowProgramEntry />,
              },
              {
                path: "programs/:programId/entries/:entryId",
                element: <ShowProgramEntry />,
              },
              {
                path: "continue-program/:programId",
                element: <ContinueProgram />,
              },
              {
                path: "continue-master-program/:masterProgramId",
                element: <ContinueMasterProgram />,
              },
              {
                path: "my-programs/:programId/complete",
                element: <ProgramCompletedPage />,
              },

              {
                path: "premade-program",
                element: <PremadeProgram />,
              },

              //user side entry

              // {
              //   path:"programs/:programId/new-entry",
              //   element:<CreateUserEntry />
              // },

              // Organization
              {
                path: "organizations-list",
                element: <OrganizationListingPage />,
              },

              {
                path: "add-organization",
                element: <CreateOrgForm />,
              },
              {
                path: "add-organization/:orgId/billing",
                element: <SubscriptionStep />,
              },

              {
                path: "edit-organization/:orgId",
                element: <EditOrganisationPage />,
              },

              {
                path: "organization-dashboard",
                element: <OrganisationDashboard />,
              },

              // {
              //   path: "organizations/:id",
              //   element: <OrganisationDashboard />,
              // },
              {
                path: "organizations/program",
                element: <OrganisationProgram />,
              },
              {
                path: "organizations/user",
                element: <OrganisationUserListing />,
              },
              {
                path: "organizations/billing-sharing",
                element: <BillingAndSharing />,
              },

              {
                path: "organizations/user/invite-users",
                element: <InviteOrgUser />,
              },

              // Users
              {
                path: "users",
                element: <UsersList />,
              },
              {
                path: "users/:id",
                element: <UserDetailPage />,
              },

              // Settings
              {
                path: "settings",
                children: [
                  {
                    path: "profile",
                    element: <MyProfile />,
                  },
                  {
                    path: "billing-and-subscription",
                    element: <BillingAndSubscriptions />,
                  },
                  {
                    path: "notifications",
                    element: <Notification />,
                  },
                  {
                    path: "app-settings",
                    element: <AppSettings />,
                  },
                  {
                    path: "organization-settings",
                    element: <OrganizationSetting />,
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

export default router;
