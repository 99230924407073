import { useNavigate } from "react-router-dom";
import api from "../../../../api";

export default function CompleteProgramButton({ program }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (program.isCompleted) {
      navigate(`/my-programs/${program.id}/complete`);
      return;
    }

    api
      .post(`my-programs/${program.id}/complete`)
      .then((response) => {
        console.log(response);
        navigate(`/my-programs/${program.id}/complete?just-completed=true`);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="mt-4">
      <button
        type="button"
        className="btn"
        onClick={handleClick}
        // to={`/programs/${data.program.id}/complete`}
      >
        <h6 className="complete-lesson-btn">Complete Program</h6>
      </button>
    </div>
  );
}
