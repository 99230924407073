import { useEffect, useState } from "react";
import api from "../../api";
import { ButtonSpinner } from "../../Components/Encap";
import InvitationNotificationItem from "./InvitationNotificationItem";
import classNames from "classnames";

const InvitationNotifications = () => {
  const [invitations, setInvitations] = useState([]);
  const getInvitations = () => {
    api
      .get("invitation/my-invitations")
      .then((response) => {
        setInvitations(response.data.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => getInvitations(), []);

  if (!invitations || !invitations?.length > 0) {
    return;
  }

  return (
    <div className="invitation-notifications-container">
      {invitations?.map((invitation) => (
        <InvitationNotificationItem invitation={invitation} />
      ))}
    </div>
  );
};

export default InvitationNotifications;
