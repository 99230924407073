import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { registerPlugin } from "filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

registerPlugin(FilePondPluginImagePreview);
function FilePondImagePreview() {
  return <div className="container mt-5"></div>;
}

export default FilePondImagePreview;
