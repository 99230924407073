import { Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import api from "../../../../api";
import { useState } from "react";
import { toast } from "react-toastify";

const AuthorizeUserModal = ({ show, onHide, programId, onUsersAdded }) => {
  const [selectedUsers, setSelectedUsers] = useState(null);

  const options = [
    { value: "red", label: "red" },
    { value: "green", label: "green" },
    { value: "vanilla", label: "Vanilla" },
    { value: "James", label: "James" },
    { value: "Jane", label: "Jane" },
    { value: "John", label: "Vanilla" },
  ];

  const loadOptions = (inputValue, callback) => {
    // callback(
    //   // options.filter((option) =>
    //   //   option.value
    //   //     .trim()
    //   //     .toLowerCase()
    //   //     .startsWith(inputValue.trim().toLowerCase())
    //   // )
    // );

    return api
      .post(`program-authorization/get-users`, {
        organization_id: 37,
        term: inputValue,
      })
      .then((res) => {
        return res.data.map((user) => {
          return {
            value: user.id,
            label: (
              <div className="user-select-option-label">
                <img height="20px" width="20px" src={user.avatar}  alt={user.first_name}/>
                <div>
                  <div>{user.first_name}</div>
                  <div>{user.email}</div>
                </div>
              </div>
            ),
          };
        });
      });
  };

  const handleSubmit = () => {
    let userIds = selectedUsers.map((user) => user.value);
    api
      .post(`program-authorization/authorize-users/${programId}`, {
        user_ids: userIds,
      })
      .then((response) => {
        toast.success("Users authorized");
        onUsersAdded && onUsersAdded();
        onHide && onHide();
        setSelectedUsers(null);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="p-4 bg-white rounded">
          <div className="d-flex gap-2">
            <h4 className="mb-3 font-secondary fw-bolder">Authorize Users</h4>
          </div>
          <div className="mb-3">
            <AsyncSelect
              loadOptions={loadOptions}
              isMulti
              onChange={(data) => setSelectedUsers(data)}
            />
            {/* {selectedUsers &&
              selectedUsers.map((user) => (
                <div>
                  {user.value} : {user.label}
                </div>
              ))} */}
          </div>
          <div>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary w-100 rounded-1"
            >
              Authorize
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AuthorizeUserModal;
