import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import AddOrganizationSeats from "./AddOrganizationSeats";
import LicenseSummary from "./LicenseSummary";
import EditOrganizationForm from "./EditOrganizationForm";
import LicensePurchaseHistory from "./subscription/LicensePurchaseHistory";
import DeactivateOrganizationSection from "./DeactivateOrganizationSection";
import { useEffect, useState } from "react";
import Errors from "../../utils/Errors";
import api from "../../api";
import ActivateOrganizationSection from "./ActivateOrganizationSection";
import AdminSection from "./AdminSection";

function EditOrganizationPage() {
  const { orgId } = useParams();
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(false);

  function fetchOrganization() {
    setLoading(true);
    api
      .get(`organizations/${orgId}`)
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        setOrganization(data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchOrganization();
  }, []);

  return (
    <div className="p-4 container-fluid">
      <div className="admin-user-grid-3">
        <h5>Edit Organization </h5>
        <p>Edit organization details</p>
      </div>

      {organization && (
        <>
          <EditOrganizationForm organization={organization} id={orgId} />
          <AdminSection organization={organization} id={orgId} />
        </>
      )}

      <section className="bg-white border mt-4" style={{ maxWidth: "900px" }}>
        <div className="p-4">
          <h4 className="font-secondary fw-bolder mb-3">Billing</h4>
          <LicenseSummary id={orgId} />

          <AddOrganizationSeats organizationId={orgId} />
        </div>
      </section>

      <section className="bg-white border mt-4" style={{ maxWidth: "900px" }}>
        <div className="p-4">
          <h4 className="font-secondary fw-bolder mb-3">Purchase History</h4>
          <LicensePurchaseHistory id={orgId} />
        </div>
      </section>

      {organization && organization.is_enabled ? (
        <DeactivateOrganizationSection
          id={orgId}
          onStatusChange={fetchOrganization}
        />
      ) : (
        <ActivateOrganizationSection
          id={orgId}
          onStatusChange={fetchOrganization}
        />
      )}
    </div>
  );
}

export default EditOrganizationPage;
