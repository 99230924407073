import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "react-bootstrap";
import visaCard from "../../assets/image/visaCard.jpeg";
import "../../assets/css/subscribe.css";
import { useState } from "react";
import Errors from "../../utils/Errors";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { stripe_publishable_key, stripe_secret } from "../../config/config";

function Subscribe() {
  const stripePromise = loadStripe(stripe_publishable_key);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: 'pk_test_51NDJfOFHDWOZLQWummR4sLrqCgnFwpG96X3etO920E1vrWf4cFm2VvfKbntMz1WbI55OyEH2ZzkzJmeZG7xsnfUJ00KYo7Fhe5',
  };
  return (
    <Elements stripe={stripePromise}>
      <StripePaymentForm />
    </Elements>
  );
}

export default Subscribe;
