import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import "./../assets/scss/video_recorder.scss";
import Record from "videojs-record/dist/videojs.record.js";
import "webrtc-adapter";
import { Button, Spinner } from "react-bootstrap";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import PromptQuestionOverlay from "./PromptQuestionOverlay";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

function VideoRecorder({ entry, onRecordComplete, prompt }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [postProcecssing, setPostProcecssing] = useState(false);
  const [elementVisible, setElementVisible] = useState(true);
  const [showPromt, setShowPromt] = useState(false);
  const [showPromtArrow, setShowPromptArrow] = useState(false);

  let segmentNumber = 0;
  let entryId = entry?.id ?? uuid();

  const options = {
    controls: true,
    autoplay: false,
    fluid: true,
    loop: false,
    // width: 684,
    // height: 442,
    aspectRatio: "16:9",
    bigPlayButton: false,
    controlBar: {
      fullscreenToggle: false,
      recordToggle: false,
      volumePanel: false,
    },
    plugins: {
      // configure videojs-record plugin
      record: {
        audio: true,
        // video: true,
        video: {
          // video media constraints: set resolution of camera
          width: 1280,
          height: 720,
        },
        frameWidth: 640,
        frameHeight: 480,
        debug: true,
        timeSlice: 4000,
        maxLength: 600,
        videoMimeType: "video/webm;codecs=H264",
      },
    },
  };

  const onReady = (player) => {
    playerRef.current = player;

    // device is ready
    // setElementVisible(false);
    player.on("deviceReady", () => {
      console.log("device is ready!");
      player.record().start();
    });

    // user clicked the record button and started recording
    player.on("startRecord", () => {
      console.log("started recording!");
      setRecording(true);
      setElementVisible(false);
      setShowPromt(true);
      setShowPromptArrow(true);

      // Dispatch videoRecordingStarted event
      window.dispatchEvent(new CustomEvent("videoRecordingStarted"));
    });

    // user completed recording and stream is available
    player.on("finishRecord", () => {
      // recordedData is a blob object containing the recorded data that
      // can be downloaded by the user, stored on server etc.
      console.log("finished recording: ", playerRef.current.recordedData);

      // let previewFile = new File(
      //   [playerRef.current.recordedData],
      //   playerRef.current.recordedData.name,
      //   {
      //     type: playerRef.current.recordedData.type,
      //   }
      // );

      // let previewUrl = URL.createObjectURL(previewFile);

      setPostProcecssing(true);
      api
        .post("finish-video-recording", {
          entryId,
        })
        .then((response) => {
          let fileId = response.data.id;
          setPostProcecssing(false);
          onRecordComplete(fileId);
        });
      setRecording(false);
    });

    player.on("timestamp", function () {
      if (
        playerRef.current.recordedData &&
        playerRef.current.recordedData.length > 0
      ) {
        segmentNumber++;

        var binaryData =
          playerRef.current.recordedData[
            playerRef.current.recordedData.length - 1
          ];

        uploadChunk(binaryData);
      }
    });

    // error handling
    player.on("error", (element, error) => {
      console.warn(error);
    });

    player.on("deviceError", () => {
      console.log(playerRef?.deviceErrorCode);
      toast.error(
        "Please check your browser settings for camera and microphone access to record"
      );
    });
  };

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.className = "video-js vjs-default-skin";
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change
    } else {
      // const player = playerRef.current;
      // playerRef.record().getDevice();
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  function startRecording() {
    setElementVisible(false);
    setShowPromt(true);
    setShowPromptArrow(true);
    playerRef.current.record().getDevice();
  }

  function pauseRecording() {
    playerRef.current.record().pause();
    setPaused(true);
  }

  function resumeRecording() {
    playerRef.current.record().resume();
    setPaused(false);
  }

  function finishRecording() {
    playerRef.current.record().stopDevice();
  }

  function uploadChunk(binaryData) {
    let extension = binaryData.name.split(".").pop();

    let reader = new FileReader();
    reader.readAsDataURL(binaryData);

    reader.onloadend = function () {
      let chunk = reader.result;

      var formData = new FormData();
      formData.append("entryId", entryId);
      formData.append("index", segmentNumber);
      formData.append("data", chunk);
      formData.append("extension", extension);

      api
        .post("upload-video-chunk", formData)
        .then((response) => console.log(response))
        .catch((error) => console.error(error));
    };
  }

  return (
    <div>
      <div className="video-recorder-wrap">
        <section data-vjs-player className="video-recorder-container">
          <div ref={videoRef} className="video-recorder"></div>

          {prompt?.questions ? (
            <PromptQuestionOverlay
              questions={prompt.questions}
              element={elementVisible}
              promtp={showPromt}
              arrow={showPromtArrow}
            ></PromptQuestionOverlay>
          ) : (
            <div className="main-caption">
              {elementVisible && (
                <h4>
                  Hit Start Recording when you are ready, you have 10 minutes to
                  respond to the following questions. Use the arrows or swipe
                  for next question !
                </h4>
              )}
            </div>
          )}

          {/* {!recording && (
          <div className="recorder-overlay d-flex justify-content-center align-items-center">
            <Button onClick={() => {}}>
              <FontAwesomeIcon icon={faVideoCamera} />
            </Button>
          </div>
        )} */}
        </section>
      </div>

      <section>
        {!playerRef.current && (
          <div className="text-center py-2">
            <Button type="button" onClick={startRecording}>
              Start Recording
            </Button>
          </div>
        )}
        {playerRef.current && (
          <div className="d-flex py-2 gap-3">
            {recording && !paused && (
              <Button type="button" onClick={pauseRecording}>
                Pause
              </Button>
            )}
            {paused && <Button onClick={resumeRecording}>Resume</Button>}
            {recording && (
              <div className="ms-auto">
                <Button type="button" onClick={finishRecording}>
                  Finish
                </Button>
              </div>
            )}
          </div>
        )}

        {postProcecssing && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" size="sm" />{" "}
            <span>
              &nbsp; Your recording is being processed.This may take up to 30
              seconds.
            </span>
          </div>
        )}
      </section>
    </div>
  );
}

export default VideoRecorder;
