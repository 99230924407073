import { faArrowsDownToLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import api from "../../../api";
import moment from "moment";
import classNames from "classnames";

export default function InvoicesTable() {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState();

  const getInvoices = () => {
    setLoading(true);
    api
      .get("get-invoices")
      .then((response) => {
        setInvoices(response.data);
      })
      .finally(() => setLoading(false));
  };

  const formatDate = (timestamp) => {
    return moment.unix(timestamp).format("D/M/Y");
  };

  const formattedAmount = (cents) => {
    let amount = (cents / 100).toFixed(2);
    return "$" + amount;
  };

  useEffect(() => {
    getInvoices();
  }, []);

  if (loading) {
    return null;
  }

  if (!loading && !invoices?.length) {
    return <div>You don't have any invoices yet.</div>;
  }

  return (
    <Table bordered responsive="lg">
      <thead>
        <tr>
          <th>Date</th>
          <th>Invoice Number</th>
          <th>Total Amount</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => {
          return (
            <tr>
              <td>{formatDate(invoice.created)}</td>
              <td>{invoice.id}</td>
              <td>{formattedAmount(invoice.total)}</td>
              <td>
                <span className={classNames("invoices-status text-capitalize", invoice.status)}>
                  {invoice.status}
                </span>
              </td>
              {/* <td>
                <FontAwesomeIcon
                  className="bs-arrow-icon"
                  icon={faArrowsDownToLine}
                  color="#FFFFFF"
                ></FontAwesomeIcon>
              </td> */}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
