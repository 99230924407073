import React, { useEffect } from "react";

import { BrowserRouter as Router, RouterProvider } from "react-router-dom";
import router from "./route/Router";
import { syncUserInformation } from "./utils/helpers";
import { useAxiosAuthInterceptor } from "./api";
import markerSDK from "@marker.io/browser";
import { marker_project_id } from "./config/config";
import "./assets/scss/app.scss";
// import { setupHeartbeatListeners } from "./Components/Heartbeat.js";

const App = () => {
  useAxiosAuthInterceptor();

  useEffect(() => {
    // const cleanup = setupHeartbeatListeners();

    const markerWidget = async () => {
      try {
        await markerSDK.loadWidget({
          project: marker_project_id,
        });
      } catch (error) {
        console.error(error);
      }
    };

    markerWidget();

    // return cleanup;
  }, []);

  // syncUserInformation();

  return <RouterProvider router={router} />;
};

export default App;
