import moment from "moment";
import "../../../../assets/scss/countdown.scss";
import { useEffect, useState } from "react";

export default function ItemAvailableCountdown({ availableAt, onReady }) {
  const [isReady, setIsReady] = useState(false);
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const target = moment.utc(availableAt);

  useEffect(() => {
    const tick = () => {
      const now = moment.utc();

      if (target.isBefore(now)) {
        clearInterval(interval);
        setIsReady(true);
      }

      const diff = target.diff(now);
      const duration = moment.duration(diff);

      setDays(target.diff(now,'days')); //duration.days()) does now work for larger values
      setHours(duration.hours());
      setMinutes(duration.minutes());
      setSeconds(duration.seconds());
    };

    tick();
    const interval = setInterval(tick, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isReady) {
      onReady && onReady();
    }
  }, [isReady]);

  const pad = (value) => {
    return String(value).padStart(2, "0");
  };

  return (
    <div>
      <div className="text-muted mb-2">This item will be available in</div>
      <div className="countdown-wrapper">
        <div className="countdown-box">
          <div className="value">{pad(days)}</div>
          <div className="unit">Days</div>
        </div>
        <div className="countdown-box">
          <div className="value">{pad(hours)}</div>
          <div className="unit">Hours</div>
        </div>
        <div className="countdown-box">
          <div className="value">{pad(minutes)}</div>
          <div className="unit">Minutes</div>
        </div>
        <div className="countdown-box">
          <div className="value">{seconds}</div>
          <div className="unit">Seconds</div>
        </div>
      </div>
    </div>
  );
}
