import { Link, useParams } from "react-router-dom";
import LoadingBlock from "../../../Components/LoadingBlock";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import RichTextViewer from "../../../Components/RichTextViewer";
import ProgramStartOrContinuebutton from "./partials/ProgramStartOrContinueButton";
import GoBackButton from "../../../Components/GoBackButton";
import VideoPlayer from "../../../Components/VideoPlayer";

function ViewMyProgram() {
  const { programId } = useParams();
  const [program, setProgram] = useState();
  const { data, loading, error } = useFetch(`my-programs/${programId}`);
  const programEditLink = `/programs/${programId}/edit`;

  useEffect(() => {
    if (data) {
      setProgram(data.data);
    }
  }, [data]);

  if (loading || !program) {
    return <LoadingBlock>Loading..</LoadingBlock>;
  }

  return (
    <div className="row view-my-program">
      <div className="col-12">
        <nav className="summary-grid-1 d-flex ">
          <div className="ms-auto Hello">
            <GoBackButton to="/my-programs">
              <span className="me-2">Close</span>
              <FontAwesomeIcon icon={faClose} className="me-2" />
            </GoBackButton>
          </div>
        </nav>
      </div>
      <div className="col-sm-6 col">
        <div className="summary-grid-1">
          <div className="summary-grid-1-1">
            <h4>{program.program_name}</h4>
          </div>
          {/* <p>{program.short_description}</p> */}
          {program.intro_video_url && (
            <VideoPlayer url={program.intro_video_url} autoplay={!program.isCompleted} />
          )}

          {/* We will not show cover image if intro video is available */}
          {program.cover_image_url && !program.intro_video_url && (
            <div className="mb-3">
              <img
                src={program.cover_image_url}
                className="img-fluid"
                alt={program.title}
              />
            </div>
          )}
        </div>

        {program.video_url && (
          <div className="vid">
            <iframe
              width="560"
              height="315"
              src={program.video_url}
              title={program.program_name}
              frameborder="0"
              allow="accelerometer; autoplay;"
              allowfullscreen
            ></iframe>
          </div>
        )}

        <div className="summary-grid-11">
          <nav>
            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-program-detail-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-program-detail"
                type="button"
                role="tab"
                aria-controls="nav-program-detail"
                aria-selected="true"
              >
                Program Details
              </button>

              <button
                className="nav-link"
                id="nav-program-overview-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-program-overview"
                type="button"
                role="tab"
                aria-controls="nav-program-overview"
                aria-selected="false"
              >
                Program Overview
              </button>

              {/* <button
                className="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                Creator
              </button> */}
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-program-detail"
              role="tabpanel"
              aria-labelledby="nav-program-detail-tab"
              tabndex="0"
            >
              <div className="summary-grid-2">
                <RichTextViewer content={program.long_description} />
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="nav-program-overview"
              role="tabpanel"
              aria-labelledby="nav-program-overview-tab"
              tabndex="0"
            >
              <div className="summary-grid-2">
                <p>{program.short_description}</p>
              </div>
            </div>

            {/* <div
              className="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
              tabndex="0"
            >
              {program.added_by && (
                <div className="summary-grid-2">
                  <h5>About Program Creator</h5>
                  <div>
                    <span>Name:</span>
                    <strong>
                      {program.added_by?.first_name}
                      {program.added_by?.last_name}
                    </strong>
                  </div>
                  <div>
                    <span>Email:</span>
                    <strong>{program.added_by?.email}</strong>
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="col-sm-6 col"
        // style={{ marginLeft: 60 }}
      >
        <div className="summary-grid-3">
          <h4>Entries</h4>
          <div className="summary-grid-31 mb-4">
            {program.entries &&
              program.entries.map((entry, index) => (
                <div
                  //to={`/programs/${program.id}/entries/${entry.id}`}
                  className="summary-grid-3-1"
                  key={index}
                >
                  <h6>
                    {index + 1}. {entry.title}
                  </h6>
                  {entry.is_available ? (
                    <p>
                      {program.progress.completedCount < 1
                        ? "Available Immediately"
                        : "Available Now "}
                    </p>
                  ) : (
                    <p>{entry.available_at?.human ?? "Later"}</p>
                  )}
                </div>
              ))}
          </div>

          {program?.progress.completionPercentage == 0 && (
            <p>This program hasn't been started yet.</p>
          )}

          <div className="text-center">
            <ProgramStartOrContinuebutton program={program} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewMyProgram;
