import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const GoBackButton = ({ to, children, text = "Go Back"}) => {
  return (
    <Link to={to} className="d-inline-block mb3" style={{ color: "#0f5c93" }}>
      {children ? (
        children
      ) : (
        <>
          <FontAwesomeIcon icon={faAngleDoubleLeft} className="me-2" />
          <span>{text}</span>
        </>
      )}
    </Link>
  );
};

export default GoBackButton;
