import { useEffect, useState } from "react";
import api from "../../../api";
import { isoToAusDate } from "../../../utils/helpers";

const LicensePurchaseHistory = ({ id }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    api.get(`get-organization-license-history/${id}`).then((response) => {
      setData(response.data.data);
    });
  }, []);

  if (!data) {
    return <div></div>;
  }

  return (
    <table className="table table-bordered bg-white">
      <thead>
        <tr>
          <td>Date</td>
          <td>Invoice No.</td>
          <td>Seats</td>
          <td>Cost per seat</td>
          <td>Total Price</td>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{isoToAusDate(item.purchase_date.date_time)}</td>
            <td>{item.invoice_number}</td>
            <td>{item.number_of_license}</td>
            <td>${item.cost_per_license} AUD</td>
            <td>${item.total_cost} AUD</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LicensePurchaseHistory;
