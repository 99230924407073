import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import "./../assets/scss/video_recorder.scss";
import Record from "videojs-record/dist/videojs.record.js";
import "webrtc-adapter";
import { Button, Spinner } from "react-bootstrap";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import PromptQuestionOverlay from "./PromptQuestionOverlay";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import axios from "axios";

function VideoRecorderS3({ entry, onRecordComplete, prompt }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [postProcecssing, setPostProcecssing] = useState(false);
  const [elementVisible, setElementVisible] = useState(true);
  const [showPromt, setShowPromt] = useState(false);
  const [showPromtArrow, setShowPromptArrow] = useState(false);
  const [key, setkey] = useState(null);
  const [uploadUrls, setUploadUrls] = useState([]);

  let chunks = [];
  let entryId = entry?.id ?? uuid();

  const options = {
    controls: true,
    autoplay: false,
    fluid: true,
    loop: false,
    // width: 684,
    // height: 442,
    aspectRatio: "16:9",
    bigPlayButton: false,
    controlBar: {
      fullscreenToggle: false,
      recordToggle: false,
      volumePanel: false,
    },
    plugins: {
      // configure videojs-record plugin
      record: {
        audio: true,
        // video: true,
        video: {
          // video media constraints: set resolution of camera
          width: 1280,
          height: 720,
        },
        frameWidth: 640,
        frameHeight: 480,
        debug: true,
        timeSlice: 30000,
        maxLength: 600,
        videoMimeType: "video/webm;codecs=vp8",
        audioRecorderType: "StereoAudioRecorder",
      },
    },
  };

  const onReady = (player) => {
    playerRef.current = player;

    player.on("deviceReady", () => {
      console.log("device is ready!");
      player.record().start();
    });

    player.on("startRecord", () => {
      console.log("started recording!");
      setRecording(true);
      setElementVisible(false);
      setShowPromt(true);
      setShowPromptArrow(true);

      // Dispatch videoRecordingStarted event
      window.dispatchEvent(new CustomEvent("videoRecordingStarted"));
    });

    player.on("timestamp", function () {
      if (
        playerRef.current.recordedData &&
        playerRef.current.recordedData.length > 0
      ) {
        let currentIndex = chunks.length ? chunks.length + 1 : 1;
        chunks.push({
          index: currentIndex,
          uploaded: false,
        });

        console.log("chunks", chunks);

        var binaryData =
          playerRef.current.recordedData[
            playerRef.current.recordedData.length - 1
          ];

        uploadChunk(binaryData, currentIndex);
      }
    });

    player.on("finishRecord", () => {
      console.log("finished recording: ", playerRef.current.recordedData);

      setPostProcecssing(true);
      setRecording(false);

      // let previewFile = new File(
      //   [playerRef.current.recordedData],
      //   playerRef.current.recordedData.name,
      //   {
      //     type: playerRef.current.recordedData.type,
      //   }
      // );

      // let previewUrl = URL.createObjectURL(previewFile);
    });

    player.on("error", (element, error) => {
      console.warn(error);
    });

    player.on("deviceError", () => {
      console.log(playerRef?.deviceErrorCode);
      toast.error(
        "Please check your browser settings for camera and microphone access to record"
      );
    });
  };

  useEffect(() => {
    api
      .get("s3-video-recording/request-urls", {
        params: {
          count: 25,
          extension: "webm",
        },
      })
      .then((response) => {
        setUploadUrls(response.data.urls);
        setkey(response.data.key);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    console.log("URLs changed", uploadUrls);
    if (uploadUrls.length) {
      if (!playerRef.current) {
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
        const videoElement = document.createElement("video-js");

        videoElement.className = "video-js vjs-default-skin";
        videoRef.current.appendChild(videoElement);

        const player = (playerRef.current = videojs(
          videoElement,
          options,
          () => {
            onReady && onReady(player);
          }
        ));
      }
    }
  }, [uploadUrls]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  function startRecording() {
    setElementVisible(false);
    setShowPromt(true);
    setShowPromptArrow(true);
    playerRef.current.record().getDevice();
  }

  function pauseRecording() {
    playerRef.current.record().pause();
    setPaused(true);
  }

  function resumeRecording() {
    playerRef.current.record().resume();
    setPaused(false);
  }

  function finishRecording() {
    playerRef.current.record().stopDevice();
  }

  const uploadChunk = (binaryData, currentIndex) => {
    let url = uploadUrls[currentIndex - 1];

    axios
      .put(url, binaryData, {
        headers: {
          "Content-Type": "video/webm;codecs=vp8",
        },
      })
      .then((response) => {
        console.log(
          "current currentIndex: " +
            currentIndex +
            " Total chunks: " +
            chunks[chunks.length - 1]?.index
        );
        console.log(
          "is Recording: " + playerRef.current.record().isRecording()
        );
        chunks[currentIndex - 1]["uploaded"] = true;

        if (!playerRef.current.record().isRecording()) {
          sendFinishRecordingSignal();
        }
      })
      .catch((error) => console.error(error));
  };

  const sendFinishRecordingSignal = () => {
    setPostProcecssing(true);

    const allUploaded = chunks.every((chunk) => chunk.uploaded === true);
    if (!allUploaded) {
      console.log("Not all chunks are uploaded");
      return;
    }

    api
      .post("s3-video-recording/finish", {
        program_id: entry.program_id,
        key,
      })
      .then((response) => {
        let fileId = response.data.id;
        setPostProcecssing(false);
        onRecordComplete(fileId);
      });
  };

  return (
    <div>
      <div className="video-recorder-wrap">
        <section data-vjs-player className="video-recorder-container">
          <div ref={videoRef} className="video-recorder"></div>

          {prompt?.questions ? (
            <PromptQuestionOverlay
              questions={prompt.questions}
              element={elementVisible}
              promtp={showPromt}
              arrow={showPromtArrow}
            ></PromptQuestionOverlay>
          ) : (
            <div className="main-caption">
              {elementVisible && (
                <h4>
                  Hit Start Recording when you are ready, you have 10 minutes to
                  respond to the following questions. Use the arrows or swipe
                  for next question !
                </h4>
              )}
            </div>
          )}

          {/* {!recording && (
          <div className="recorder-overlay d-flex justify-content-center align-items-center">
            <Button onClick={() => {}}>
              <FontAwesomeIcon icon={faVideoCamera} />
            </Button>
          </div>
        )} */}
        </section>
      </div>

      <section>
        {!playerRef.current && (
          <div className="text-center py-2">
            <Button type="button" onClick={startRecording}>
              {/* TODO::Remove Experimental */}
              Start Recording (Experimental)
            </Button>
          </div>
        )}
        {playerRef.current && (
          <div className="d-flex py-2 gap-3">
            {recording && !paused && (
              <Button type="button" onClick={pauseRecording}>
                Pause
              </Button>
            )}
            {paused && <Button onClick={resumeRecording}>Resume</Button>}
            {recording && (
              <div className="ms-auto">
                <Button type="button" onClick={finishRecording}>
                  Finish
                </Button>
              </div>
            )}
          </div>
        )}

        {postProcecssing && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" size="sm" />{" "}
            <span>
              &nbsp; Your recording is being processed.This may take up to 30
              seconds.
            </span>
          </div>
        )}
      </section>
    </div>
  );
}

export default VideoRecorderS3;
