import {
  faBuilding,
  faCog,
  faCreditCard,
  faDashboard,
  faPenSquare,
  faPenToSquare,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { hasRole } from "../../utils/helpers";
import SidebarNotificationLink from "./partials/SidebarNotificationLink";
import SupportFeedbackLink from "./partials/SupportFeedbackLink";

function OrgSidebar() {
  return (
    <>
      <div className="sidebar__title">
        <p>User</p>
      </div>

      <div className="sidebar__separator"></div>

      {/* <NavLink to="/" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faBuilding}
        ></FontAwesomeIcon>
        <span>Dashboard</span>
      </NavLink> */}

      <NavLink to="/" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faPenToSquare}
          style={{ fontSize: 20 }}
        ></FontAwesomeIcon>
        <span>Program Library</span>
      </NavLink>

      <NavLink to="/my-programs" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faPenToSquare}
        ></FontAwesomeIcon>
        <span>My Programs</span>
      </NavLink>

      <SidebarNotificationLink />

      {/* <NavLink to="/program" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faPenSquare}
          style={{ fontSize: 20 }}
        ></FontAwesomeIcon>
        <span>Program</span>
      </NavLink> */}

      <div className="sidebar__title">
        <p>Organization</p>
      </div>

      <div className="sidebar__separator"></div>

      {/* <NavLink to="/organization-dashboard" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faPenSquare}
          style={{ fontSize: 20 }}
        ></FontAwesomeIcon>
        <span>My Organisation</span>
      </NavLink> */}

      <NavLink to="/organization-dashboard" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faDashboard}
          style={{ fontSize: 12 }}
        ></FontAwesomeIcon>
        <span>Dashboard</span>
      </NavLink>

      <NavLink to="/programs" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faPenSquare}
          style={{ fontSize: 12 }}
        ></FontAwesomeIcon>
        <span>Our Programs</span>
      </NavLink>

      <NavLink to="/organizations/user" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faUsers}
          style={{ fontSize: 12 }}
        ></FontAwesomeIcon>
        <span>Users</span>
      </NavLink>

      <SupportFeedbackLink />

      {/* <NavLink to="/#" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faCreditCard}
          style={{ fontSize: 12 }}
        ></FontAwesomeIcon>
        <span>Billing & Subscriptions</span>
      </NavLink>

      <NavLink to="/#" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faCog}
          style={{ fontSize: 12 }}
        ></FontAwesomeIcon>
        <span>Organization Settings</span>
      </NavLink> */}

      {/* <NavLink to="/users" className="sidebar__link">
        <FontAwesomeIcon
          className="sidebar__icon"
          icon={faUser}
        ></FontAwesomeIcon>
        <span>User</span>
      </NavLink> */}
    </>
  );
}

export default OrgSidebar;
