import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function AfterSurveyModal(props){
    const handleClose = () => props.onHide();
return(
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal {...props} className="after-survey-modal" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Start Your Survey</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          
          <iframe src={props.survey_after} width="100%" height="600px"></iframe>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
)
}
export default AfterSurveyModal;