import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import api from "../../api";
import LoadingBlock from "../../Components/LoadingBlock";
import { Link } from "react-router-dom";
import Errors from "../../utils/Errors";
import { toast } from "react-toastify";
import classNames from "classnames";

const AdminSection = ({ organization }) => {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState();
  const [resendingInvitation, setResendingInvitation] = useState(false);

  const fetchData = () => {
    setData(null);
    api
      .get(`organization/${organization.id}/get-admin-detail`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdminChanged = () => {
    fetchData();
    setShowModal(false);
  };

  const resendInvitation = (invitationId) => {
    setResendingInvitation(true);
    api
      .post(`organization-admin-invitation/resend/${invitationId}`)
      .then((response) => {
        fetchData();
        toast.success("Invitation has been resent");
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setTimeout(() => setResendingInvitation(false), 5000);
      });
  };

  if (!data) {
    return <LoadingBlock />;
  }

  return (
    <section className="bg-white border mt-4" style={{ maxWidth: "900px" }}>
      <div className="p-4">
        <div>
          <h4 className="font-secondary fw-bolder mb-3">Administrative</h4>
        </div>
        <div className="d-flex items-center gap-3 justify-content-between">
          {data.user && (
            <div>
              <label className="text-muted">Email</label>
              <div style={{ wordBreak: "break-all" }}>{data.user.email}</div>
            </div>
          )}
          {data.invitation && (
            <div>
              <label className="text-danger ">Pending Invitation</label>
              <div style={{ wordBreak: "break-all" }}>
                {data.invitation.email}
              </div>
              <small
                className={classNames({
                  "text-muted": !data.invitation.has_expired,
                  "text-danger": data.invitation.has_expired,
                })}
              >
                {data.invitation.has_expired ? "Expired" : "Expires"}{" "}
                {data.invitation.expires_at.human}
              </small>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-sm rounded-1 mt-1"
                  onClick={() => {
                    resendInvitation(data.invitation.id);
                  }}
                  disabled={resendingInvitation}
                >
                  Resend Invitation
                </button>
              </div>
            </div>
          )}
          <div>
            <button
              type="button"
              className="btn btn-primary rounded-1"
              onClick={() => setShowModal(true)}
            >
              Change Admin
            </button>
            <ChangeAdminModal
              show={showModal}
              onHide={() => setShowModal(false)}
              id={organization.id}
              onAdminChanged={handleAdminChanged}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const ChangeAdminModal = ({ show, onHide, id, onAdminChanged }) => {
  const [email, setEmail] = useState();
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());

  const handleChangeEmail = (e) => {
    e.preventDefault();
    setProcessing(true);
    api
      .post(`change-organization-admin/${id}`, {
        email: email,
      })
      .then((response) => {
        toast.success("Invitation sent");
        onAdminChanged();
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.data?.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error(error.response.data.message ?? "Validation Failed");
          return;
        }
        toast.error(error?.response?.data?.message ?? "Server Error");
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <form
          onSubmit={handleChangeEmail}
          className="p-4 bg-white rounded"
          autoComplete="false"
        >
          <div className="d-flex gap-2">
            <h4 className="mb-3 font-secondary fw-bolder">Change Admin</h4>
          </div>

          <div>
            <div class="alert alert-warning" role="alert">
              Changing the admin will immediately terminate access of current
              admin and send an invitation to new email address.
            </div>
          </div>

          <div className="mb-3">
            <label className="mb-1">New Admin's Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter new admin email"
              required
            />
            {errors.has("email") && (
              <div className="d-block invalid-feedback">
                {errors.first("email")}
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end gap-2">
            <button
              type="button"
              className="btn btn-light rounded-1 font-secondary"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary rounded-1 font-secondary fw-bolder"
              disabled={processing}
            >
              Change
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AdminSection;
