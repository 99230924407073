import { useEffect } from "react";
import { useState } from "react";
import api from "../../../../api";

const ProgramEnrollmentCount = ({ program }) => {
  const [enrolCount, setEnrolCount] = useState();

  useEffect(() => {
    api.get(`get-program-usage-stats/${program.id}`).then((response) =>{
      setEnrolCount(response.data);
    })
  }, []);

  
  return (
    <div className="subhead-right">
      <div className="subhead-righttext">
        <p className="right-text-1">{enrolCount?.completed_by_count}</p>
        <p className="right-text-2">Completed</p>
      </div>
      <div className="subhead-righttext">
        <p className="right-text-1">{enrolCount?.active_users_count}</p>
        <p className="right-text-2">Active</p>
      </div>
      <div className="subhead-righttext">
        <p className="right-text-1">{enrolCount?.enrolled_count}</p>
        <p className="right-text-2">Enrolled</p>
      </div>
    </div>
  );
};

export default ProgramEnrollmentCount;
