import { Link } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";

export default function EntriesNavList({ programId }) {
  const { data: entries, loading } = useFetch(
    `programs/${programId}/get-entries-basic-info`
  );

  return (
    <nav className="nav">
      {loading && <div>Loading...</div>}
      {entries && (
        <ol type="1" className="list-group">
          {entries.map((entry, index) => (
            <li key={index} role="button" className="list-group-item">
              <Link to={`/programs/${programId}/entries/${entry.id}/edit`}>
                {index + 1}. {entry.title}
              </Link>
            </li>
          ))}
        </ol>
      )}
    </nav>
  );
}
