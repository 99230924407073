import { useParams } from "react-router-dom";
import { PureComponent, useCallback, useEffect, useState } from "react";
import SinglePromptModal from "../wizard/SinglePromptModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";
import { toast } from "react-toastify";
import LoadingBlock from "../../../Components/LoadingBlock";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import TabHeading from "./TabHeading";

function PromptsTab() {
  const { programId } = useParams();
  const [loading, setLoading] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(false);
  const [showAddPromptModal, setShowAddPromptModal] = useState(false);
  const ReactSwal = withReactContent(Swal);

  const fetchData = useCallback(() => {
    setLoading(true);
    api
      .get(`program-prompt-list/${programId}`)
      .then((res) => {
        res.data && setPrompts(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  }, [programId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEditPromptModalClose = () => {
    setActiveIndex(false);
  };

  const handleNewPromptModalClose = () => {
    setShowAddPromptModal(false);
  };

  const deletePrompt = async (id) => {
    const result = await ReactSwal.fire({
      title: <p className="text-start">Confirm delete</p>,
      html: `<div class="text-start">
              <div>Are you sure you want to delete this prompt?</div>
            </div>`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      // cancelButtonText: "No",
    });

    if (!result.isConfirmed) {
      return;
    }

    api
      .delete(`prompts/${id}`)
      .then((res) => {
        toast.success("Prompt deleted");
        fetchData();
      })
      .catch((error) => toast.error("Something went wrong"));
  };

  return (
    <main className="edit-prompts-page pt-3" style={{ maxWidth: "1000px" }}>
      <section className="d-flex justify-content-between align-items-center mb-3">
        <TabHeading title="Prompts"></TabHeading>
        <div>
          <button
            type="button"
            className="btn btn-outline-white rounded-1"
            onClick={() => setShowAddPromptModal(true)}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="text-green fa-lg me-2"
            />
            <span>Add New</span>
          </button>
        </div>
      </section>

      {loading && (
        <div className="py-5 text-center">
          <LoadingBlock />
        </div>
      )}

      {!loading && (
        <section>
          {prompts?.map((prompt, index) => (
            <div className="panel__white mb-3" key={prompt.id}>
              <h5 className="mb-2">{prompt.title}</h5>
              <div>
                <ol className="text-muted">
                  {prompt.questions.map((question, index) => (
                    <li key={index}>{question}</li>
                  ))}
                </ol>
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-white rounded-1 px-4"
                    onClick={() => setActiveIndex(index)}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="text-green me-2"
                    />
                    <span>Edit</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-white rounded-1 px-4"
                    onClick={() => deletePrompt(prompt.id)}
                  >
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="text-red me-2"
                    />
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            </div>
          ))}

          {prompts?.map((prompt, index) => (
            <SinglePromptModal
              show={activeIndex === index}
              onHide={handleEditPromptModalClose}
              refetchData={fetchData}
              prompt={prompt}
              key={index}
            ></SinglePromptModal>
          ))}
          <SinglePromptModal
            show={showAddPromptModal}
            onHide={handleNewPromptModalClose}
            refetchData={fetchData}
            programId={programId}
          ></SinglePromptModal>
        </section>
      )}

      {!loading && prompts.length === 0 && (
        <div className="mt-5 d-flex justify-content-center align-items-center flex-column gap-3">
          <div>There are no prompts added yet.</div>
          <div>
            <button
              type="button"
              className="btn btn-outline-white rounded-1"
              onClick={() => setShowAddPromptModal(true)}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-green fa-lg me-2"
              />
              <span>Add New</span>
            </button>
          </div>
        </div>
      )}
    </main>
  );
}

export default PromptsTab;
