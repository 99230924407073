import classNames from "classnames";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../api";
import { toast } from "react-toastify";
import LoadingBlock from "../../../Components/LoadingBlock";
import "./../../../assets/scss/view_response.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import RichTextViewer from "../../../Components/RichTextViewer";
import moment from "moment";
import ViewVideoAnswer from "../user/partials/ViewVideoAnswer";
import GoBackButton from "../../../Components/GoBackButton";

const ViewResponse = () => {
  const { programId } = useParams();
  const [program, setProgram] = useState(null);
  const [student, setStudent] = useState(null);
  const [activeEntryId, setActiveEntryId] = useState(null);
  const [loadingEntry, setLoadingEntry] = useState(true);
  const [entry, setEntry] = useState(null);

  const fetchProgram = () => {
    api
      .get(`view-response/get-program/${programId}`)
      .then((response) => {
        let data = response.data;
        setStudent(data.student);
        setProgram(data.program);
        setActiveEntryId(data?.program?.entries[0]?.id);
      })
      .catch((error) => toast.error("Server Error"));
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  const fetchEntry = () => {
    setLoadingEntry(true);
    api.get(`view-response/get-entry/${activeEntryId}`).then((response) => {
      setEntry(response.data.data);
      setLoadingEntry(false);
    });
  };

  useEffect(() => {
    if (activeEntryId) {
      fetchEntry();
    }
  }, [activeEntryId]);

  const formatDate = (date) => {
    return moment(date).format("D MMM, YYYY");
  };

  if (!program || !student) {
    return <LoadingBlock></LoadingBlock>;
  }

  return (
    <div className="view-response-page">
      <div className="mb-2">
        <GoBackButton to={`/admin/programs/${programId}`} />
      </div>
      <div className="row">
        <div className="col-md-5">
          <div className="prog-grid-2 mb-4">
            <h4 className="pt-0">Response Viewer</h4>
            <p>
              Course: {program.program_name} | Student: {student.full_name}
            </p>
          </div>
          <aside>
            <div className="mb-4">
              <h4 className="section-heading-big">Excercise</h4>
              <div>Select an excercise to see response.</div>
            </div>
            <div>
              <div className="items-list-wrap">
                {program.entries.map((entry, index) => (
                  <button
                    type="button"
                    className={classNames("", {
                      "not-available": !entry.is_available,
                      active: entry.id === parseInt(activeEntryId),
                      "not-completed": !entry.is_completed,
                      "is-additional": entry.is_additional,
                    })}
                    onClick={() =>
                      entry.is_completed && setActiveEntryId(entry.id)
                    }
                    key={index}
                  >
                    <div className="entry-title">
                      {index + 1}. {entry.title}
                    </div>
                    <div className="item-meta">
                      {entry.completed_at && (
                        <small className="submitted-date">
                          Submitted: {formatDate(entry.completed_at.raw)}
                        </small>
                      )}
                      <FontAwesomeIcon
                        className="item-arrow-icon"
                        icon={faArrowRight}
                        color="#fff"
                      ></FontAwesomeIcon>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </aside>
        </div>
        <div className="col-md-7">
          <section className="section-box">
            <h4 className="section-heading-big">Student Details</h4>
            <div className="student-box">
              <div>
                <img src={student.avatar_url} alt={student.full_name} />
              </div>
              <div>
                <h4>{student.full_name}</h4>
                <div className="text-muted">
                  Enrolled: {formatDate(program.started_at)}
                </div>
                <div className="text-muted">
                  Completed:{" "}
                  {program.completed_at
                    ? formatDate(program.completed_at)
                    : "Not completed yet"}
                </div>
              </div>
            </div>
          </section>
          {loadingEntry && <LoadingBlock />}
          {!loadingEntry && entry && (
            <section className="section-box mt-4">
              <div>
                <h4 className="item__title">{entry.title}</h4>
                {entry?.answer?.created_at && (
                  <div className="text-muted">
                    Response Submitted:{" "}
                    {formatDate(entry.answer.created_at.raw)}
                  </div>
                )}
              </div>
              <h5 className="sub-section mt-3">Item Description</h5>
              <RichTextViewer content={entry.explanation_text} />
              <h5 className="sub-section">Response</h5>
              {entry.answer?.written_answer && (
                <RichTextViewer content={entry.answer.written_answer} />
              )}
              {entry.answer?.video_response_url && (
                <ViewVideoAnswer answer={entry.answer} />
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewResponse;
