import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { submitWrittenAnswer } from "../../utils/Answer";
import "./../../assets/scss/not_applicable.scss"

const NotApplicable = ({ entry }) => {
  const MySwal = withReactContent(Swal);
  const answer = "N/A";

  async function handleNotApplicable() {
    const result = await MySwal.fire({
      title: <p className="text-center">Confirmation</p>,

      html: `<div>
            <div>Are you sure this is not applicable to you?</div>
          </div>`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) {
      return;
    }

    const form = {
      type: "written",
      entry_id: entry.id,
      written_answer: answer,
    };

    submitWrittenAnswer(form).then((response) => {
      // Trigger an event that will reload the data
      const event = new CustomEvent("answerSubmitted");
      window.dispatchEvent(event);
    });
  }

  return (
    <div className="entry-not-applicable-section">
      <button
        type="button"
        onClick={handleNotApplicable}
      >
        Not Applicable
      </button>
    </div>
  );
};

export default NotApplicable;
