import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SettingsNavigation from "../partials/SettingsNavigation";
import { useContext, useState } from "react";
import api from "../../../api";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";
import { AuthContext } from "../../../context/AuthContext";
import OrganizationLogoUpload from "./OrganizationLogoUpload";

const OrganizationSetting = () => {
  const { getAuthUser, syncUserInformation } = useContext(AuthContext);
  const organization = getAuthUser().organization;
  const [state, setState] = useState({
    name: organization.name,
    email: organization.email,
    contact: organization.contact,
    website: organization.website,
  });
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());

  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    api
      .post(`save-detail/${organization.id}`, state)
      .then((response) => {
        syncUserInformation();
        toast.success("Information saved");
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
          return;
        }
        toast.error("Something went wrong");
      })
      .finally(() => setProcessing(false));
  };

  return (
    <div className="row setting-row">
      <div className="col-sm-3 col">
        <div className="setting-grid-1 mb-3">
          <h4>Settings</h4>
          <p>Update your personal settings here.</p>
        </div>
        <SettingsNavigation />
      </div>
      <div className="col-sm-9 col">
        <div className="setting-grid-3">
        <div className="setting-grid-3">
          <div className="setting-grid-3-1">
            <h6>Organization Settings</h6>
          </div>
        </div>

        <div className="row">
          <div className="col-md-9">
            <div className="setting-grid-3-3">
              <h6 className="col-sm-2 label">Your Name</h6>
              <div className="mb-3 col-sm-10 input">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={state.name}
                  onChange={(e) => handleChange(e)}
                />
                {errors.has("name") && (
                  <div className="d-block invalid-feedback error-text">
                    {errors.first("name")}
                  </div>
                )}
              </div>
            </div>

            <div className="setting-grid-3-3">
              <h6 className="col-sm-2 label">Email</h6>
              <div className="mb-3 col-sm-10 input">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={state.email}
                  onChange={(e) => handleChange(e)}
                />
                {errors.has("email") && (
                  <div className="d-block invalid-feedback error-text">
                    {errors.first("email")}
                  </div>
                )}
              </div>
            </div>

            <div className="setting-grid-3-3">
              <h6 className="col-sm-2 label">Phone</h6>
              <div className="mb-3 col-sm-10 input">
                <input
                  type="text"
                  name="contact"
                  className="form-control"
                  value={state.contact}
                  onChange={(e) => handleChange(e)}
                />
                {errors.has("contact") && (
                  <div className="d-block invalid-feedback error-text">
                    {errors.first("contact")}
                  </div>
                )}
              </div>
            </div>

            <div className="setting-grid-3-3">
              <h6 className="col-sm-2 label">Website</h6>
              <div className="mb-3 col-sm-10 input">
                <input
                  type="url"
                  name="website"
                  className="form-control"
                  value={state.website}
                  onChange={(e) => handleChange(e)}
                />
                {errors.has("website") && (
                  <div className="d-block invalid-feedback error-text">
                    {errors.first("website")}
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="offset-md-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={processing}
                  onClick={handleSubmit}
                >
                  {processing && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <OrganizationLogoUpload />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default OrganizationSetting;
