import { Modal } from "react-bootstrap";
import CreateEntry from "./CreateEntry";
import EntryForm from "./EntryForm";
import '../../../assets/css/entry_form_modal.css'

function EntryFormModal({ show, onHide, refetchData, entry }) {
  const handleComplete = () => {
    refetchData();
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide} size="xl" className="entry-form-modal">
      <Modal.Header closeButton>
        <Modal.Title>{entry.id ? "Update Entry" : "Add New Entry"}</Modal.Title>
      </Modal.Header>
      <Modal.Body
      // className="p-5"
      >
        <EntryForm
          entry={entry}
          onComplete={handleComplete}
          forWizard={false}
          onHide={onHide}
        />
      </Modal.Body>
    </Modal>
  );
}

export default EntryFormModal;
