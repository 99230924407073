import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import api from "../../../api";
import { isoToAusDate } from "../../../utils/helpers";
import LicensePurchaseHistory from "../../organisation/subscription/LicensePurchaseHistory";
import LoadingBlock from "../../../Components/LoadingBlock";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import LicenseAlert from "../../organisation/LicenseAlert";
import RequestSeatsModal from "../../organisation/subscription/RequestSeatsModal";

const OrganizationSubscriptionDetail = () => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();
  const [data, setData] = useState(null);
  const [showRequestSeatsModal, setShowRequestSeatsModal] = useState(false);
  useEffect(() => {
    api
      .get(`get-organization-license-detail/${user.organization.id}`)
      .then((response) => {
        setData(response.data);
      });
  }, []);

  const getStatus = (renewalDate) => {
    const currentDate = new Date();
    const expiryDateObj = new Date(renewalDate);
    const timeDifference = expiryDateObj - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference <= 0) {
      return "Expired";
    }

    return "Active";
  };

  if (!data) {
    return <LoadingBlock />;
  }

  return (
    <div className="setting-grid-3">
      <div className="bs-title">
        <h6>Billing & Subscriptions</h6>
      </div>

      <LicenseAlert/>
      
      <div className="bs-content">
        <div className="bs-content-7">
          <div className="label">
            <p>Subscription Status</p>
          </div>
          <div className="input-value">
            <div className="input-value-1">
              <p className="text-capitalize">
                {getStatus(data.valid_until.raw)}
              </p>
            </div>
          </div>
        </div>
        <div className="bs-content-1">
          <div className="label">
            <p>License Usage</p>
          </div>
          <div className="input-value">
            <div>
              <span className="fw-bold">{data.used_seats}</span> of{" "}
              <span className="fw-bold">{data.total_license}</span> seats used
            </div>
            <button type="button" className="button-plus" onClick={() => setShowRequestSeatsModal(true)}>
              <FontAwesomeIcon
                className="bs-plus-icon"
                icon={faPlusCircle}
                color="#FFFFFF"
              ></FontAwesomeIcon>
              <p>Request additional seats</p>
            </button>
            <RequestSeatsModal show={showRequestSeatsModal} onHide={() => setShowRequestSeatsModal(false)}/>
          </div>
        </div>

        <div className="bs-content-2">
          <div className="label">
            <p>Renewal Date</p>
          </div>
          <div className="input-value">
            <div className="input-value-1">
              <p>{isoToAusDate(data.valid_until.raw)}</p>
            </div>
          </div>
        </div>

        <div className="bs-title">
          <h6>License Purchase History</h6>
        </div>
        <div className="mt-3">
          <LicensePurchaseHistory id={user?.organization?.id} />
        </div>

        {/* {subscription?.organization?.length > 0 && (
            <OrganizationSubscriptionList
            organizations={subscription.organization}
            />
        )} */}
      </div>
    </div>
  );
};

export default OrganizationSubscriptionDetail;
