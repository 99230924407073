import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CloseFormButton(){
    const navigate = useNavigate();

  function CloseForm() {
    navigate("/programs");
  }
  return(

      <Button variant="danger" type="button" onClick={CloseForm}>Cancel</Button>
  )
}

export default CloseFormButton;