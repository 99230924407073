import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import api from "../../../api";

const SidebarNotificationLink = () => {
  const [count, setCount] = useState(0);

  const fetchNotificationsCount = () => {
    api
      .get("unread-notifications-count")
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => fetchNotificationsCount(), []);

  return (
    <NavLink to="/notifications" className="sidebar__link">
      <FontAwesomeIcon
        className="sidebar__icon"
        icon={faBell}
      ></FontAwesomeIcon>
      <span>Notifications</span>
      {count > 0 && <span className="notification-counter-badge">{count}</span>}
    </NavLink>
  );
};

export default SidebarNotificationLink;
