import { faDownload, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../Components/layouts/header";
import SideBar from "../../Components/layouts/sidebar";
import OrganisationButton from "../../Components/organisation/OrganisationButton";

import "../../assets/css/organization_billing.css";

function BillingAndSharing() {
  return (
    <div className="organization-billing">
      <div className="row">
        <div className="col-sm-5">
          <div className="organization-billing-grid-1">
            <h4>My Organisation</h4>
            <p>Manage Your Company</p>
          </div>

          <div>
            <OrganisationButton />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="organization-billing-grid-3">
            <h4>Billing & Subscriptions</h4>
          </div>

          <div className="organization-billing-grid-3-1">
            <div className="organization-billing-grid-3-2">
              <h6>Current Plan</h6>

              <div className="organization-billing-grid-3-3">
                <h5>Monthly</h5>
                <h6>32 users</h6>
              </div>

              <button className="organization-billing-grid-3-4">
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="green"
                  style={{ fontSize: 15 }}
                ></FontAwesomeIcon>
                <h5>Switch to Annual</h5>
              </button>
            </div>

            <div className="organization-billing-grid-3-2">
              <h6>Renewal Date</h6>

              <div className="organization-billing-grid-3-3">
                <h5>December 11</h5>
                <h6>in 32 days</h6>
              </div>
            </div>

            <div className="organization-billing-grid-3-2">
              <h6>Next Payment</h6>

              <div className="organization-billing-grid-3-3">
                <h5>AU$824.00</h5>
              </div>
            </div>

            <div className="organization-billing-grid-4">
              <h6>Payment Details</h6>

              <div className="organization-billing-grid-4-1">
                <h5>VISA **** **** **** 1234</h5>
              </div>

              <button className="organization-billing-grid-4-2">
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="green"
                  style={{ fontSize: 15 }}
                ></FontAwesomeIcon>
                <h5>Update Card</h5>
              </button>
            </div>

            <div className="organization-billing-grid-4">
              <h6>Payment Details</h6>

              <div className="organization-billing-grid-4-1">
                <h5>
                  Demo McTest C/- Fun Time Big Company 1234 Main Street Sydney
                  NSW 2000
                </h5>
              </div>

              <button className="organization-billing-grid-4-2">
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="green"
                  style={{ fontSize: 15 }}
                ></FontAwesomeIcon>
                <h5>Update Details</h5>
              </button>
            </div>

            <div className="organization-billing-grid-5">
              <h6>Invoice History</h6>

              <table className="table organization-billing-table">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Invoice Number</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>11/11/22</th>
                    <td>90647</td>
                    <td>$824.00</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faDownload}
                        color="#014779"
                        style={{ fontSize: 15 }}
                      ></FontAwesomeIcon>
                    </td>
                  </tr>
                  <tr>
                    <th>11/11/22</th>
                    <td>90647</td>
                    <td>$824.00</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faDownload}
                        color="#014779"
                        style={{ fontSize: 15 }}
                      ></FontAwesomeIcon>
                    </td>
                  </tr>
                  <tr>
                    <th>11/11/22</th>
                    <td>90647</td>
                    <td>$824.00</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faDownload}
                        color="#014779"
                        style={{ fontSize: 15 }}
                      ></FontAwesomeIcon>
                    </td>
                  </tr>
                  <tr>
                    <th>11/11/22</th>
                    <td>90647</td>
                    <td>$824.00</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faDownload}
                        color="#014779"
                        style={{ fontSize: 15 }}
                      ></FontAwesomeIcon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingAndSharing;
