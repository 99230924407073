import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../api";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

const DeleteAccount = () => {
  const { logout } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);

  const handleDelete = async () => {
    const { value: password } = await Swal.fire({
      title: `<div class="text-start font-secondary">Delete Account</div>`,
      html: `<div class="text-start lh-base">
        <div>Please type your password to confirm deletion of your account. When you delete your account, all of the data associated with it will be permanently removed.</div>
        <div class="mt-2 fw-bolder">Note: This action cannot be undone</div>
      </div>`,
      input: "text",
      inputPlaceholder: "Enter password",
      inputValue: "",
      showCancelButton: true,
      confirmButtonText: "Yes, delete account",
      confirmButtonColor: "#f33f3f",
      showLoaderOnConfirm: true,
      customClass: {
        actions: "ms-4",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please provide your password to confirm account deletion";
        }
      },
      preConfirm: async (password) => {
        return api
          .post("delete-account", { password })
          .then((response) => {
            toast.success("Account has been permanently deleted");
            logout();
            window.location.href = "/";
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              Swal.showValidationMessage(error.response.data.message);
              return false;
            }
            Swal.showValidationMessage("Server Error");
            return false;
          });
      },
    });
  };

  return (
    <button type="button" className="delete-account-btn" onClick={handleDelete}>
      <FontAwesomeIcon icon={faTrashAlt} className="icon"></FontAwesomeIcon>
      <span>Delete Account</span>
    </button>
  );
};

export default DeleteAccount;
