import { useContext } from "react";
import "./../../../assets/scss/header_badges.scss";
import { AuthContext } from "../../../context/AuthContext";
import useFetch from "../../../hooks/useFetch";

const HeaderBadges = () => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();

  const { data: badges } = useFetch(`/user/${user.id}/badges`);

  return (
    <div className="header-badges">
      {badges &&
        badges
          .slice(0, 5)
          .map((badge, index) => (
            <img src={badge.badge_url} alt={badge.program} key={index} />
          ))}

      {badges && badges.length > 5 && (
        <div className="plus-badges-count">+{badges.length - 5}</div>
      )}
    </div>
  );
};

export default HeaderBadges;
