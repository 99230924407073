import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./../../assets/scss/entry_reaction.scss";
import api from "../../api.js";

const EntryReaction = () => {
  const [show, setShow] = useState(false);
  const [entryId, setEntryId] = useState(null);

  useEffect(() => {
    const handleAnswerSubmittedEvent = (e) => {
      let entryId = e?.detail?.entryId;
      if (entryId) {
        setEntryId(entryId);
        setShow(true);
      }
    };

    window.addEventListener("answerSubmitted", handleAnswerSubmittedEvent);
    return () => {
      window.removeEventListener("answerSubmitted", handleAnswerSubmittedEvent);
    };
  }, []);

  const handleSubmit = (reaction) => {
    api
      .post("reactions", {
        entry_id: entryId,
        reaction: reaction,
      })
      .then((response) => {
        console.log("Experience submitted");
      })
      .catch((error) => console.error(error))
      .finally(() => setShow(false));
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <div className="p-5 bg-white rounded">
          <h4 className="text-center font-secondary fw-bolder">
            What was your experience after making this entry?
          </h4>
          <div className="d-flex justify-content-center gap-3 mt-4 text-muted text-center">
            <button type="button" onClick={() => handleSubmit(1)}>
              <div style={{ fontSize: "3rem" }}>😟</div>
              <div>Poor</div>
            </button>
            <button type="button" onClick={() => handleSubmit(2)}>
              <div style={{ fontSize: "3rem" }}>😐</div>
              <div>Okay</div>
            </button>
            <button type="button" onClick={() => handleSubmit(3)}>
              <div style={{ fontSize: "3rem" }}>🙂</div>
              <div>Good</div>
            </button>
            <button type="button" onClick={() => handleSubmit(4)}>
              <div style={{ fontSize: "3rem" }}>😊</div>
              <div>Great</div>
            </button>
            <button type="button" onClick={() => handleSubmit(5)}>
              <div style={{ fontSize: "3rem" }}>😍</div>
              <div>Awesome</div>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EntryReaction;
