const TabHeading = ({ title, subTitle = null }) => {
  return (
    <div>
      <h4 className="fw-bold font-secondary mb-0">{title}</h4>
      {subTitle && <p>{subTitle}</p>}
    </div>
  );
};

export default TabHeading;
