import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "../../../assets/css/prog_summary.css";
import useFetch from "../../../hooks/useFetch";
import RichTextViewer from "../../../Components/RichTextViewer";
import LoadingBlock from "../../../Components/LoadingBlock";
import StartProgramButton from "../user/StartProgramButton";
import GoBackButton from "../../../Components/GoBackButton";
import VideoPlayer from "../../../Components/VideoPlayer";
import ProgramActionButton from "../admin/partials/ProgramActionButton";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../context/AuthContext";
import ContributorsList from "./ContributorsList";

function ProgramLibraryDetail() {
  const { slug } = useParams();
  const [program, setProgram] = useState();
  const { data, loading } = useFetch(`program-templates/${slug}`);

  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();

  useEffect(() => {
    if (data) {
      setProgram(data.data);
    }
  }, [data]);

  if (loading || !program) {
    return <LoadingBlock>Loading..</LoadingBlock>;
  }

  return (
    <>
      <Helmet>
        {program.cover_image_url && (
          <meta property="og:image" content={program.cover_image_url} />
        )}
        <meta
          property="og:description"
          content={
            program.short_description ??
            "Reflect, grow and develop with Encapsulator&#039;s tailored programs. Experience personal growth like never before with our unique video journaling platform."
          }
        />
      </Helmet>
      <div className="row prog-sum">
        <div className="col-12">
          <GoBackButton to="/program-library" />
        </div>
        <div className="col-12 mb-3">
          {!program.is_published && (
            <div className="text-danger fst-italic blink-text">
              Program is not published yet
            </div>
          )}

          <div className="d-lg-flex align-items-ceter">
            <div className="summary-grid-1-1">
              <h4>
                {isLoggedIn() && !program.is_free && !user.is_subscribed && (
                  <span>
                    <svg
                      className="d-inline-block me-2"
                      data-slot="icon"
                      fill="none"
                      stroke-width="2"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                      ></path>
                    </svg>
                  </span>
                )}
                <span>{program.program_name}</span>
              </h4>
              {/* <p>{program.short_description}</p> */}
            </div>

            <div className="ms-auto summary-grid-1-2">
              <div className="d-flex gap-2 align-items-center">
                <ProgramActionButton program={program} />
              </div>
            </div>
          </div>
        </div>

        <div className="col col-sm-6">
          {program.intro_video_url && (
            <VideoPlayer url={program.intro_video_url} />
          )}

          {/* We will now show cover image if intro video is available */}
          {program.cover_image_url && !program.intro_video_url && (
            <div className="mb-3">
              <img
                src={program.cover_image_url}
                className="img-fluid rounded-1"
                alt={program.title}
              />
            </div>
          )}

          <div className="summary-grid-11">
            <nav>
              <div
                className="nav nav-tabs nav-fill nav-tabs-scrollable"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="program-details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#program-details"
                  type="button"
                  role="tab"
                  aria-controls="program-details"
                  aria-selected="true"
                >
                  Program Details
                </button>

                <button
                  className="nav-link"
                  id="program-overview-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#program-overview"
                  type="button"
                  role="tab"
                  aria-controls="program-overview"
                  aria-selected="false"
                >
                  Program Overview
                </button>

                {/* <button
                className="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                Creator
              </button> */}
              </div>
            </nav>
            <div className="tab-content pt-2" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="program-details"
                role="tabpanel"
                aria-labelledby="program-details-tab"
                tabndex="0"
              >
                <div className="summary-grid-2">
                  <RichTextViewer content={program.long_description} />
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="program-overview"
                role="tabpanel"
                aria-labelledby="program-overview-tab"
                tabndex="0"
              >
                <div className="summary-grid-2">
                  <p>{program.short_description}</p>
                </div>
                {program?.contributors?.length > 0 && (
                 <ContributorsList contributors={program.contributors} />
                )}
              </div>

              {/* <div
              className="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
              tabndex="0"
            >
              {program.added_by && (
                <div className="summary-grid-2">
                  <h5>About Program Creator</h5>
                  <div>
                    <span>Name:</span>
                    <strong>
                      {program.added_by?.first_name}
                      {program.added_by?.last_name}
                    </strong>
                  </div>
                  <div>
                    <span>Email:</span>
                    <strong>{program.added_by?.email}</strong>
                  </div>
                </div>
              )}
            </div> */}
            </div>
          </div>
        </div>

        <div className="col col-sm-6">
          <div className="summary-grid-3">
            <h4>Entries</h4>
            <div className="summary-grid-31">
              {!program.entries.length && (
                <div className="bg-white px-3 py-5 rounded text-center">
                  This program does not have any entries
                </div>
              )}
              {program.entries &&
                program.entries.map((en, index) => (
                  <div className="summary-grid-3-1" key={index}>
                    <h6>
                      {index + 1}. {en.title}
                    </h6>
                    {/* <p>Available Now</p> */}
                  </div>
                ))}
            </div>

            <div className="text-center">
              <StartProgramButton program={program} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgramLibraryDetail;
