import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { guestApi } from "../../../api";
import LoadingBlock from "../../../Components/LoadingBlock";
import { toast } from "react-toastify";

const AutoLogin = ({ token, onLoggedIn }) => {
  const { logout, login } = useContext(AuthContext);

  useEffect(() => {
    logout();
    guestApi
      .post("organization-admin-invitation/auto-login", {
        token: token,
      })
      .then(async (response) => {
        await login(response.data.token, response.data.user);
        onLoggedIn();
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return false;
        }

        toast.error("Server Error");
      });
  }, []);

  return <LoadingBlock />;
};

export default AutoLogin;
