import CheckOTP from "../pages/auth/CheckOTP";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import PasswordResetPage from "../pages/auth/PasswordResetPage";
import Register from "../pages/auth/Register";
import VerifyEmail from "../pages/auth/VerifyEmail";
import AcceptInvitation from "../pages/invitation/AcceptInvitation";
import OrganizationAdminInvitation from "../pages/organisation/admin-invitation/OrganizationAdminInvitation";

const AuthRoutes = [
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "password-reset/:email",
    element: <PasswordResetPage />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "check-otp",
    element: <CheckOTP />,
  },
  {
    path: "verify-email",
    element: <VerifyEmail />,
  },

  {
    path: "organization-admin-invitation/:token",
    element: <OrganizationAdminInvitation />,
  },
  {
    path: "accept-invitation/:token",
    element: <AcceptInvitation />,
  },
  // TODO::verify
  {
    path: "accept-invitation",
    element: <AcceptInvitation />,
  },
];

export default AuthRoutes;
